import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { Location } from '@angular/common';
import { GlobalConstants } from '../common/global-constants';
import { fabric } from "fabric";
import { Analysis } from '../entity/Analysis';
import { Filter } from '../entity/Filter';
import { Web } from '../entity/Web';
import { ConfirmationService, ConfirmEventType, MenuItem, MessageService} from 'primeng/api';
import { Case } from '../entity/Case';
import { faGlobeAmericas, faExchangeAlt, faSync, faLayerGroup} from '@fortawesome/free-solid-svg-icons';
import { timer } from 'rxjs/internal/observable/timer';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Point, Rect } from 'fabric/fabric-impl';
import { Shape } from '../entity/Shape';
import { ShapePoint } from '../entity/ShapePoint';
import { ElementFinder } from 'protractor';
import { BlockUIModule } from 'primeng/blockui';

interface Rows{
  value: number;
}

interface ImageFilter{
  name: string,
  key: string
}

@Component({
  selector: 'app-duplication-viewer',
  templateUrl: './duplication-viewer.component.html',
  styleUrls: ['./duplication-viewer.component.scss']
})
export class DuplicationViewerComponent implements OnInit {
  
  @HostListener('window:resize', ['$event'])
  onScroll(event: any) {
    this.resizeUpdateScreen();
  }

  resizeUpdateScreen(){
    var gap = 25;
    var tempWindowWidth = document.body.clientWidth;
    var tempWindowHeight = document.body.clientHeight;

    var reloadflag = false; 
    if(tempWindowWidth - this.windowWidth > gap ||
      tempWindowHeight - this.windowHeight > gap ||
      this.windowWidth - tempWindowHeight > gap ||
      this.windowHeight - tempWindowHeight > gap){
        reloadflag = true;
    }

    if(reloadflag){
      this.blocked = true;
      const reload = timer(1500);
            reload.subscribe({
              next: function(value) {
                location.reload();
              },
              complete: function() {
                location.reload();
              },
              error: function(error) {
                location.reload();
              }
            });
    }

  }
  
  // Enable this for debug purpose
  debug: boolean = false;

  windowWidth: number;
  windowHeight: number;

  faExchangeAlt = faExchangeAlt;
  faSync = faSync;
  faLayerGroup = faLayerGroup;
  faGlobeAmericas = faGlobeAmericas;

  rows: Rows[];
  canvas: any;

  baseUrl: string;
  id: string;
  record:any;
  result:any; 
  img1id: string;
  img2id: string;
  pair: any;
  analysis: string;

  leftPanel: boolean;
  rightPanel: boolean;

  // pairAnalysis: string;

  leftFilename: string;
  leftFilenameCacheKey: string;
  leftFilenameRecordId: string;
  rightFilename: string;
  rightFilenameCacheKey: string;
  rightFilenameRecordId: string;

  sourceImageImg: any;
  sourceImageImgFilterID: string;
  sourceImageImgFilterTemp: any;
  sourceImageImgWebID: string;
  similarImageImg: any;
  similarImageImgFilterID: string;
  similarImageImgFilterTemp: any;
  similarImageImgWebID: string;
  
  internetImageImgWebTemp: any;
  
  sourceImageFilterPanel: boolean;
  similarImageFilterPanel: boolean;
  hasAnalyzed: boolean;

  blocked: boolean;

  analysisResult: any;
  analysisTraceId: string;

  filterList: ImageFilter[];
  filterListSourceSelected: ImageFilter;
  filterListSimilarSelected: ImageFilter;

  adjustmentSourceContrast: number;
  adjustmentSourceBrightness: number;
  adjustmentSourceSaturation: number;
  adjustmentSourceTransparency: number;
  adjustmentSourceInvert: boolean;

  adjustmentSimilarContrast: number;
  adjustmentSimilarBrightness: number;
  adjustmentSimilarSaturation: number;
  adjustmentSimilarTransparency: number;
  adjustmentSimilarInvert: boolean;

  searchResultDialog: boolean;
  addtionalInformationDialog: boolean;
  addtionalInformationSource:any;
  addtionalInformationSimilar:any;
  addtionalInformationDisplay:any;
 

  drawingColor: string;
  isDrawingMode: boolean;
  isIntersectingMode: boolean;

  selectedCount: number;
  caseSummary: any;

  showDrawInfo: boolean;
  hasLeftDrawInfo: boolean;
  hasRightDrawInfo: boolean;

  displayLeftDrawInfo: boolean;
  displayRightDrawInfo: boolean;

  minHighlightSize: number;

  defaultLang: string;

  speed:number;
  maximumDistance: number;

  controlMedium: number;
  controlSimilarity: number;

  analyzedAngle: number;
  analyzedScaleX: number;
  analyzedScaleY: number;
  analyzedFlip: boolean;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private location: Location,
    private route: ActivatedRoute,
    private proute: Router,
    public cookieService: CookieService,
    public translate: TranslateService,
    private dataService: DataService) {

      // initial analyzed values
      this.analyzedAngle = 0;
      this.analyzedScaleX = 0;
      this.analyzedScaleY = 0;
      this.analyzedFlip = false;

      // Other values
      this.drawingColor = 'black';
      this.isDrawingMode = false;
      this.isIntersectingMode = false;

      this.adjustmentSourceContrast = 0;
      this.adjustmentSourceBrightness = 0;
      this.adjustmentSourceSaturation = 0;
      this.adjustmentSourceTransparency = 0;
      this.adjustmentSourceInvert = false;

      this.adjustmentSimilarContrast = 0;
      this.adjustmentSimilarBrightness = 0;
      this.adjustmentSimilarSaturation = 0;
      this.adjustmentSimilarTransparency = 0;
      this.adjustmentSimilarInvert = false;


      this.searchResultDialog = false;
      this.addtionalInformationDialog = false;
      this.hasAnalyzed = false;
      this.sourceImageFilterPanel = true;
      this.similarImageFilterPanel = true;

      this.leftPanel = true;
      this.rightPanel = true;
      
      this.baseUrl = GlobalConstants.apiURL;
      this.filterList = GlobalConstants.filters;

      this.maximumDistance = 6;
      this.speed = 500;

      // Minimal highlight asift point size
      this.minHighlightSize = 3;

      // this.pairAnalysis = '';
      this.selectedCount = 0;

      this.controlSimilarity = 0;
      this.controlMedium = GlobalConstants.medium;

      this.route.params.subscribe(params => {
        if(params['img1'] != undefined){
          this.img1id = params['img1']; 
        }
        if(params['img2'] != undefined){
          this.img2id = params['img2']; 
        }
      });

      this.hasLeftDrawInfo = false;
      this.hasRightDrawInfo = false;

      this.displayLeftDrawInfo = false;
      this.displayRightDrawInfo = false;


      let lang = this.proute['browserUrlTree']['queryParams']['lang'];
      if(lang != undefined){        
        this.cookieService.set('lang', lang);
        translate.use(lang);
        this.defaultLang = lang;
      }else{
        let tempLang = this.cookieService.get('lang');
        if(tempLang != '' && tempLang != undefined){
          translate.use(tempLang);
          this.defaultLang = tempLang;
        }else{          
          translate.use(translate.getBrowserLang());
          this.defaultLang = translate.getBrowserLang();
        }
      }

    }

  ngOnInit() {
    
    this.windowWidth = document.body.clientWidth;
    this.windowHeight = document.body.clientHeight;
    this.filterListSourceSelected = this.filterList[0];
    this.filterListSimilarSelected = this.filterList[0];

    this.id = this.route.snapshot.paramMap.get('id');
    this.loadRecordMetadata();

    // Fabrics

    this.canvas = new fabric.Canvas('viewer', {
      backgroundColor: '#ebebef',
      selection: false,
      preserveObjectStacking: true
    });

    this.canvas.on('object:scaling', object => {

      // console.log(object);

      if(object['target']['cacheKey'] == this.leftFilenameCacheKey){
        this.sourceImageImg['after_width'] = Math.round(object['target']['width'] * object['target']['scaleX']);
        this.sourceImageImg['after_height'] = Math.round(object['target']['height'] * object['target']['scaleY']);
      }else if(object['target']['cacheKey'] == this.rightFilenameCacheKey){
        this.similarImageImg['after_width'] = Math.round(object['target']['width'] * object['target']['scaleX']);
        this.similarImageImg['after_height'] = Math.round(object['target']['height'] * object['target']['scaleY']);
      }
      this.hasAnalyzed = false;
      // if(this.isIntersectingMode == true){
      //   this.canvas.forEachObject(function(obj) {
      //     if (obj === object.target) return;
      //     obj.set('opacity' , object.target.intersectsWithObject(obj) ? 0.5 : 1);
      //   });
      // }
    });

    this.canvas.on("object:moving", object => {
      // if(this.isIntersectingMode == true){
      //   this.canvas.forEachObject(function(obj) {
      //     if (obj === object.target) return;
      //     obj.set('opacity' , object.target.intersectsWithObject(obj) ? 0.5 : 1);
      //   });
      // }
    });

    this.canvas.on("object:rotating", object => {
      // if(this.isIntersectingMode == true){
      //   this.canvas.forEachObject(function(obj) {
      //     if (obj === object.target) return;
      //     obj.set('opacity' , object.target.intersectsWithObject(obj) ? 0.5 : 1);
      //   });
      // }
    });

    this.canvas.on("mouse:dblclick", object => {
      if(this.canvas.getActiveObject()['cacheKey'] ==undefined){
        this.canvas.remove(this.canvas.getActiveObject());
        this.messageService.add({life: GlobalConstants.life, severity:'warn', summary:'Remove The Object'});  
      }
    });
    

    this.canvas.on("mouse:wheel", object => {
      // console.log(this.canvas.getActiveObject());
      var delta = object.e.deltaY;
      if(this.canvas.getActiveObject() != undefined){
        if(delta < 0){
          var current = this.canvas.getActiveObject()['scaleX'];
          current = current * 1.05;
          this.canvas.getActiveObject().scale(current);
          this.canvas.renderAll();  
        }else{
          var current = this.canvas.getActiveObject()['scaleX'];
          current = current * 0.95;
          this.canvas.getActiveObject().scale(current);
          this.canvas.renderAll(); 
        }
        
        if(this.canvas.getActiveObject()['cacheKey'] == this.leftFilenameCacheKey){
          this.sourceImageImg['after_width'] = Math.round(this.canvas.getActiveObject()['width'] * this.canvas.getActiveObject()['scaleX']);
          this.sourceImageImg['after_height'] = Math.round(this.canvas.getActiveObject()['height'] * this.canvas.getActiveObject()['scaleY']);
        }else{
          this.similarImageImg['after_width'] = Math.round(this.canvas.getActiveObject()['width'] * this.canvas.getActiveObject()['scaleX']);
          this.similarImageImg['after_height'] = Math.round(this.canvas.getActiveObject()['height'] * this.canvas.getActiveObject()['scaleY']);
        }

      }
    }); 
    
    this.updateCanvasSize();
    this.loadCaseSummary();
 
  }


  loadCaseSummary(){
    this.dataService.getStoreCaseSummary(this.id)
                      .subscribe(data =>{
                        // console.log('=====================================');
                        // console.log(data);
                        // console.log('=====================================');
                        this.caseSummary = data;
                      });
  }


  loadRecordMetadata(){
    this.dataService.metadata(this.id, 'all')
                      .subscribe(data =>{
                        this.record = data;
                        this.loadDuplicationResult();
                      });
  }

  loadDuplicationResult(){
    this.dataService.duplication(this.id)
                      .subscribe(data =>{

                        this.result = data;
                        // console.log('=====================================');
                        // console.log(data);
                        // console.log('=====================================');

                        for(let i=0; i < this.result['similarity'].length ; i++){
                          // console.log('=====================================');
                          // console.log(this.result['similarity'][i]);
                          // console.log('=====================================');

                          let pair = this.result['similarity'][i];
                          let img1 = pair['img1'];
                          let img1File = img1['original_file'];
                          let img1Page = img1File.match(/index-(\d{1,4})/g)[0].match(/(\d{1,4})/g)[0];
                          let img2 = pair['img2'];
                          let img2File = img2['original_file'];
                          let img2Page = img2File.match(/index-(\d{1,4})/g)[0].match(/(\d{1,4})/g)[0];
                          this.result['similarity'][i]['img1']['page'] = parseInt(img1Page);
                          this.result['similarity'][i]['img2']['page'] = parseInt(img2Page);
                          this.result['similarity'][i]['histogramP'] = Math.floor(this.result['similarity'][i]['histogram'] * 100);
                          this.result['similarity'][i]['phashP'] = Math.floor((1-this.result['similarity'][i]['phash']) * 100);
                          // this.pairAnalysis = pair['analysis'];

                         if(pair['img1']['id'] == this.img1id && pair['img2']['id'] == this.img2id){
                            this.pair = pair;
                            this.analysis = pair['analysis'];
                            this.leftFilename = img1File;
                            this.leftFilenameCacheKey = img1File+"_left";
                            this.leftFilenameRecordId = img1['record'];
                            this.rightFilename = img2File;
                            this.rightFilenameCacheKey = img2File+"_right";
                            this.rightFilenameRecordId = img2['record'];
                            this.controlSimilarity = this.result['similarity'][i]['similarity'];
                            // alert(this.controlSimilarity);
                          }

                        }

                        if(this.pair['img1']['draw_info'] != undefined && 
                            this.pair['img1']['draw_info']['radius'] != undefined &&
                            this.pair['img1']['draw_info']['radius'] > 0){
                              this.hasLeftDrawInfo = true;
                              // this.displayLeftDrawInfo = true;
                        }

                        if(this.pair['img2']['draw_info'] != undefined && 
                            this.pair['img2']['draw_info']['radius'] != undefined &&
                            this.pair['img2']['draw_info']['radius'] > 0){
                              this.hasRightDrawInfo = true;
                              // this.displayRightDrawInfo = true;
                        }

                        if(this.pair != undefined){
                          this.addSourceImageToViewer();
                          this.addSimilarImageToViewer();
                        }

                      }); 
  }

  onChangeHighlightArea(){
    this.removeAndUpdateCircle();
  }

  back() {
    this.location.back();
  }

  close(){
    window.close();
  }

  switchLeft(){
    if(this.leftPanel){
      this.leftPanel = false;
    }else{
      this.leftPanel = true;
    }
    this.updateCanvasSize();
  }

  switchRight(){
    if(this.rightPanel){
      this.rightPanel = false;
    }else{
      this.rightPanel = true;
    }
    this.updateCanvasSize();
  }

  updateCanvasSize(){
    
    let gap = 42;
    if(this.rightPanel){
      gap += 200;
    }
    if(this.leftPanel){
      gap += 200;
    }
    this.canvas.setWidth(document.getElementById("maincontent").offsetWidth - gap);
    this.canvas.setHeight(document.getElementById("viewercontent").offsetHeight - 76);
  } 

  updateWindowPosition(){ 
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }

  addSourceImageToViewer(){
 
    this.sourceImageFilterPanel = true; 
    this.sourceImageImgWebID = null;
    this.internetImageImgWebTemp = null;

    this.removeTextFromCanvas();
    this.resetViewerDisplayByTheCacheKey(this.leftFilenameCacheKey);

    this.blocked = true;
    fabric.Image.fromURL(this.baseUrl+'/image/'+this.id+'/'+this.leftFilename, (img) => {
   
      let scale = 1;
      if(img.width > img.height){
        let scaleWidth = (this.canvas.width/2) * 0.8;
        scale = scaleWidth / img.width;
      }else{
        let scaleHeight = this.canvas.height * 0.8;
        scale = scaleHeight / img.height;
      }
      scale = scale * 0.8;
      let top = (this.canvas.height - (img.height*scale)) / 2;
      let left = (this.canvas.width - (img.width*scale)) / 9;

      this.sourceImageImg = img;
      this.hasAnalyzed = false;

      this.sourceImageImg['original_width'] = img.width;
      this.sourceImageImg['original_height'] = img.height;

      img.set({
          cacheKey: this.leftFilenameCacheKey,
          scaleX: scale,
          scaleY: scale, 
          top: top,
          left: left,
          stroke: 'blue',
          strokeWidth: 2
      });

      this.sourceImageImg['after_width'] = Math.round(img.width * scale);
      this.sourceImageImg['after_height'] = Math.round(img.height * scale);
      
      this.canvas.add(img);

      this.blocked = false;

      // this.submitFilterImage('source');
      // Here to draw the highlight area
      // console.log('add source to viewer ' );
      // console.log(this.displayLeftDrawInfo);
      
    // var groupRight = new fabric.Group();
      // if(this.displayLeftDrawInfo){
      //   this.highlightLeftImage();
      // }

    }, {crossOrigin: 'anonymous'});
  }

  addSimilarImageToViewer(){
    
    this.similarImageFilterPanel = true;
    this.hasAnalyzed = false;
    this.similarImageImgWebID = null;
    this.internetImageImgWebTemp = null;

    this.removeTextFromCanvas();
    this.resetViewerDisplayByTheCacheKey(this.rightFilenameCacheKey);

    this.blocked = true;
    fabric.Image.fromURL(this.baseUrl+'/image/'+this.id+'/'+this.rightFilenameRecordId+"_"+this.rightFilename, (img) => {
  
      let scale = 1;
      if(img.width > img.height){
        let scaleWidth = (this.canvas.width/2) * 0.8;
        scale = scaleWidth / img.width;
      }else{
        let scaleHeight = this.canvas.height * 0.8;
        scale = scaleHeight / img.height;
      }
      scale = scale * 0.8;
      let top = (this.canvas.height - (img.height*scale)) / 2;
      let left = (this.canvas.width - (img.width*scale)) * 0.9;

      this.similarImageImg = img;
      this.hasAnalyzed = false;

      img.set({
          cacheKey: this.rightFilenameCacheKey,
          scaleX: scale,
          scaleY: scale,
          top: top,
          left: left,
          stroke: 'red',
          strokeWidth: 2,
      });
      
      this.similarImageImg['original_width'] = img.width;
      this.similarImageImg['original_height'] = img.height;
      this.similarImageImg['after_width'] = Math.round(img.width * scale);
      this.similarImageImg['after_height'] = Math.round(img.height * scale);

      this.canvas.add(img);
      this.blocked = false;

      // if(this.displayRightDrawInfo){
      //   this.highlightRightImage();
      // }
      // this.submitFilterImage('similar');
    }, {crossOrigin: 'anonymous'});
    
  }
  
  resetViewerDisplayByTheCacheKey(key:string){

    let found = false;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == key){
        this.canvas.remove(this.canvas.getObjects()[i]);
        found = true;
        break;
      }
    }
    if(found){
      this.resetViewerDisplayByTheCacheKey(key);
    }
  }


  removeTextFromCanvas(){
    let hasText = false;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] != this.rightFilenameCacheKey
            && this.canvas.getObjects()[i]['cacheKey'] != this.leftFilenameCacheKey){
        this.canvas.remove(this.canvas.getObjects()[i]);
        hasText = true;
      }
    }
    if(hasText){
      this.removeTextFromCanvas();
    }
  }

  resetViewerDisplay(){
    
    this.reloadImage('source');
    this.reloadImage('similar');
    this.isIntersectingMode = false;

    if(this.isDrawingMode){
      this.toggleDrawingMode(this.drawingColor);
    }

  }

  // enterFullscreenMode(){
  //   document.body.requestFullscreen();
  // }

  toggleFilterPanel(type: string){
    if(type == 'source'){
      this.switchLeft();
    }else{
      this.switchRight();
    }
  }


  analyzeImageSimilarity(){

    this.resetBothImage();
    this.isIntersectingMode = false;

    if(this.hasAnalyzed){
      this.hasAnalyzed = false;
    }else{
      this.hasAnalyzed = true;

      this.blocked = true;
    //downloadSimilarityResult(authkey:string, hash:string, img1id:string, img2id:string){
    this.dataService.downloadSimilarityResult(this.id, this.img1id, this.img2id)
            .subscribe(result => {
              
              // console.log('download result');
              // console.log(result);
              this.analysisResult = result;

              let resultText = '';
              for(let i=0; i < result['analysis'].length ; i++){
                // console.log(result['analysis'][i]['name'] + ' : ' + result['analysis'][i]['similarity']);
                if(resultText.length > 0){
                  resultText += '  ;  ';
                }
                let pvalue = result['analysis'][i]['similarity'] * 100;
                    
                resultText += result['analysis'][i]['name'] + ' : ' + Math.floor(pvalue) + '%';
              } 

              this.blocked = false;
              this.hasAnalyzed = true;

              this.showText(resultText, 10);

              if(this.analysisResult['asift']['status']){
                this.highlightPair();
              }

            });

    }
    
  }

  showText(resultText:string, top: number){
    // Add the result to the canvas
    var info = new fabric.Text(resultText, {
          fontSize: 18,
          fontWeight: 'normal',
          stroke: 'black'
        });
    var left = (this.canvas.width - info.width) /2;
          info.set({
                left: left,
                top: top
          });
    
    this.canvas.add(info);
  }

  highlightPair(){


      let leftRectArray = [];
      let rightRectArray = [];

      var groupLeft = new fabric.Group();
      var groupRight = new fabric.Group();
      var groupLine = new fabric.Group();

      var leftScale = this.sourceImageImg['after_width'] / this.sourceImageImg['original_width'];
      var leftSpotSizeWidth = this.sourceImageImg['after_width'] / 100;
      var leftSpotSizeHeight = this.sourceImageImg['after_height'] / 100;
      var leftSporSize = leftSpotSizeWidth * leftSpotSizeHeight;

      if(this.sourceImageImg['after_width'] < 250 && leftSporSize < 10){
        var templeftSpotSize = 10;
        leftSporSize = Math.ceil(templeftSpotSize);
      }else if (this.sourceImageImg['after_width'] > 250){
        var templeftSpotSize = leftSporSize ;
        leftSporSize = Math.ceil(templeftSpotSize);
      }else if (this.sourceImageImg['after_width'] > 350){
        var templeftSpotSize = leftSporSize / 2;
        leftSporSize = Math.ceil(templeftSpotSize);
      }else if (this.sourceImageImg['after_width'] > 500){
        var templeftSpotSize = leftSporSize / 4;
        leftSporSize = Math.ceil(templeftSpotSize);
      }else if (this.sourceImageImg['after_width'] > 700){
        var templeftSpotSize = leftSporSize / 5;
        leftSporSize = Math.ceil(templeftSpotSize);
      }else if (this.sourceImageImg['after_width'] > 900){
        var templeftSpotSize = leftSporSize / 6;
        leftSporSize = Math.ceil(templeftSpotSize);
      }

      if(this.analysisResult['asift']['left'].length >=  this.minHighlightSize){
        for(var i=0; i < this.analysisResult['asift']['left'].length ; i++){
          var obj = this.analysisResult['asift']['left'][i];
          
          // var leftScale = this.analysisResult['asift']['leftW'] / this.sourceImageImg['after_width'];
          var leftX = (obj['x'] *leftScale) + this.sourceImageImg['left'];
          var leftY = (obj['y'] *leftScale) + this.sourceImageImg['top'];
          
          // Add the result to the canvas
          var leftRect = new fabric.Rect({
            left: leftX - leftSporSize,
            top: leftY - leftSporSize,
            width: (leftSporSize * 2),
            height: (leftSporSize * 2),
            fill: '#fabed477'
          });

          leftRectArray.push(new ShapePoint(leftX, leftY));
          groupLeft.addWithUpdate(leftRect);
        }
        
      }
    
    var rightScale = this.similarImageImg['after_width'] / this.similarImageImg['original_width'];
    
    var rightSpotSizeWidth = this.similarImageImg['after_width'] / 100;
    var rightSpotSizeHeight = this.similarImageImg['after_height'] / 100;
    var rightSpotSize = rightSpotSizeWidth * rightSpotSizeHeight;

    if(this.similarImageImg['after_width'] < 250 && rightSpotSize < 10){
      var temprightSpotSize = 10;
      rightSpotSize = Math.ceil(temprightSpotSize);
    }else if (this.similarImageImg['after_width'] > 250){
      var temprightSpotSize = rightSpotSize ;
      rightSpotSize = Math.ceil(temprightSpotSize);
    }else if (this.similarImageImg['after_width'] > 350){
      var temprightSpotSize = rightSpotSize / 2;
      rightSpotSize = Math.ceil(temprightSpotSize);
    }else if (this.similarImageImg['after_width'] > 500){
      var temprightSpotSize = rightSpotSize / 4;
      rightSpotSize = Math.ceil(temprightSpotSize);
    }else if (this.similarImageImg['after_width'] > 700){
      var temprightSpotSize = rightSpotSize / 5;
      rightSpotSize = Math.ceil(temprightSpotSize);
    }else if (this.similarImageImg['after_width'] > 900){
      var temprightSpotSize = rightSpotSize / 6;
      rightSpotSize = Math.ceil(temprightSpotSize);
    }

    // var rightSpotSize = ((this.similarImageImg['after_width'] * this.similarImageImg['after_height']));
    if(this.analysisResult['asift']['right'].length >= this.minHighlightSize){
      for(var i=0; i < this.analysisResult['asift']['right'].length ; i++){
        var obj = this.analysisResult['asift']['right'][i];
        
        // var leftScale = this.analysisResult['asift']['leftW'] / this.sourceImageImg['after_width'];
        var rightX = (obj['x']  * rightScale)+ this.similarImageImg['left'];
        var rightY = (obj['y'] * rightScale)+ this.similarImageImg['top'] ;
        // Add the result to the canvas
          var rightRect = new fabric.Rect({
            left: rightX - rightSpotSize,
            top: rightY - rightSpotSize,
            width: (rightSpotSize* 2),
            height: (rightSpotSize* 2),
            fill: '#fabed477'
          });

          rightRectArray.push(new ShapePoint(rightX, rightY));
          groupRight.addWithUpdate(rightRect);
      }

        // adjust the brightness of the left/right images
        this.adjustmentSourceBrightness = -35;
        this.adjustmentSimilarBrightness = -35;

    }


    // Create Connected Lines
    for(var j=0; j < leftRectArray.length ; j++){
        var line = new fabric.Line([
          leftRectArray[j].left, leftRectArray[j].top,rightRectArray[j].left, rightRectArray[j].top
          ], {
          fill: '#fabed4cc',
          stroke: '#fabed4cc',
          strokeWidth: 2,
          selectable: false,
          evented: false
        });

        // console.log(line);
        groupLine.addWithUpdate(line);
    }

    // Adjust the filter display
    this.onChangeFilterBrightness(this.leftFilenameCacheKey);
    this.onChangeFilterBrightness(this.rightFilenameCacheKey);
    
    this.canvas.add(groupLeft);
    this.canvas.add(groupRight);
    this.canvas.add(groupLine);
    
  }

  
  removeAndUpdateCircle(){
    
    // console.log(this.displayLeftDrawInfo);
    
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['type'] == 'circle'){
        this.canvas.remove(this.canvas.getObjects()[i]);
      }
    }
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['type'] == 'circle'){
        this.canvas.remove(this.canvas.getObjects()[i]);
      }
    }
    
    if(this.displayLeftDrawInfo){
      this.highlightLeftImage();

    }

    if(this.displayRightDrawInfo){
      this.highlightRightImage();
    }

  }

  

  highlightLeftImage(){ 

    // if(this.analysisResult['asift']['left'].length <  this.minHighlightSize){
    //   return;
    // }

    // var groupLeft = new fabric.Group();
    var imgDrawInfo = this.pair['img1']['draw_info'];

    var diff =  this.sourceImageImg['after_width'] /  this.sourceImageImg['width'];
    var radiusDiff =  (imgDrawInfo['radius'] * diff);      
    var leftX = ((imgDrawInfo['center_x']*diff) + this.sourceImageImg['left'] ) - radiusDiff;
    var leftY = ((imgDrawInfo['center_y']*diff) + this.sourceImageImg['top'] ) - radiusDiff;

    // Add the result to the canvas
    var leftCircle = new fabric.Circle({
      radius: radiusDiff ,
      left: leftX,
      top: leftY,
      fill: '#ffe11922',
      stroke: '#ff0000',
      strokeWidth: 5
    });

    // groupLeft.addWithUpdate(leftCircle);
    this.canvas.add(leftCircle);
    
  }

  highlightRightImage(){ 

    // if(this.analysisResult['asift']['right'].length <  this.minHighlightSize){
    //   return;
    // }

    // var groupRight = new fabric.Group();
    var imgDrawInfo = this.pair['img2']['draw_info'];

    var diff =  this.similarImageImg['after_width'] /  this.similarImageImg['width'];
    var radiusDiff =  (imgDrawInfo['radius'] * diff);      
    var leftX = ((imgDrawInfo['center_x']*diff) + this.similarImageImg['left'] ) - radiusDiff;
    var leftY = ((imgDrawInfo['center_y']*diff) + this.similarImageImg['top'] ) - radiusDiff;

    // Add the result to the canvas
    var rightCircle = new fabric.Circle({
      radius: radiusDiff ,
      left: leftX,
      top: leftY,
      fill: '#ffe11922',
      stroke: '#ff0000',
      strokeWidth: 5
    });

    // groupRight.addWithUpdate(rightCircle);
    this.canvas.add(rightCircle);
    
  }


  // removeAndUpdateCircle(){
    
  //   // console.log(this.displayLeftDrawInfo);
    
  //   for(let i=0; i < this.canvas.getObjects().length ; i++){
  //     if(this.canvas.getObjects()[i]['type'] == 'polygon'){
  //       this.canvas.remove(this.canvas.getObjects()[i]);
  //     }
  //   }
  //   for(let i=0; i < this.canvas.getObjects().length ; i++){
  //     if(this.canvas.getObjects()[i]['type'] == 'polygon'){
  //       this.canvas.remove(this.canvas.getObjects()[i]);
  //     }
  //   }
    
  //   if(this.displayLeftDrawInfo){
  //     this.highlightLeftImage();

  //   }

  //   if(this.displayRightDrawInfo){
  //     this.highlightRightImage();
  //   }

  // }


  // highlightLeftImage(){ 

  //   var self = this;

  //   this.blocked = true;
  //   //downloadSimilarityResult(authkey:string, hash:string, img1id:string, img2id:string){
  //   this.dataService.downloadSimilarityResult(this.id, this.img1id, this.img2id)
  //           .subscribe(result => {
              
  //         this.analysisResult = result;

  //         this.blocked = false;


  //         // IGNORE the analysis if one of them is undefined
  //         if(this.analysisResult['asift']['left'] == undefined || this.analysisResult['asift']['right'] == undefined){
  //           self.messageService.add({life: GlobalConstants.life, severity:'warn', summary:'Cannot auto matching duplicated area, please manually adjust the display. '});     
  //           return;
  //         }

  //         let leftShape = new Shape();
  //             leftShape.scaleX = this.sourceImageImg['after_width'] / this.sourceImageImg['original_width'];
  //             leftShape.scaleY = this.sourceImageImg['after_height'] / this.sourceImageImg['original_height'];

  //         // iterator the left shape coordinates to find the most top left and right bottom point
  //         for(let i=0; i < this.analysisResult['asift']['left'].length ; i++){

  //           let x = this.analysisResult['asift']['left'][i].x;
  //           let y = this.analysisResult['asift']['left'][i].y;

  //           if(leftShape.ltx == -1 || leftShape.lty == -1){
  //             leftShape.ltx = x;
  //             leftShape.lty = y;
  //             leftShape.ltidx = i;
  //           }else{
  //             // To find the most left and top point
  //             if(x < leftShape.ltx && y < leftShape.lty){
  //               leftShape.ltx = x;
  //               leftShape.lty = y;
  //               leftShape.ltidx = i;
  //             }
  //           }

  //           if(leftShape.rbx == -1 || leftShape.rby == -1){
  //             leftShape.rbx = x;
  //             leftShape.rby = y;
  //             leftShape.rbidx = i;
  //           }else{
  //             // To find the most right and bottom point
  //             if(x > leftShape.rbx && y > leftShape.rby){
  //               leftShape.rbx = x;
  //               leftShape.rby = y;
  //               leftShape.rbidx = i;
  //             }
  //           }

  //           if(leftShape.lbx == -1 || leftShape.lby == -1){
  //             leftShape.lbx = x;
  //             leftShape.lby = y;
  //             leftShape.lbidx = i;
  //           }else{
  //             // To find the most left and bottom point
  //             if(x < leftShape.lbx && y > leftShape.lby){
  //               leftShape.lbx = x;
  //               leftShape.lby = y;
  //               leftShape.lbidx = i;
  //             }
  //           }

  //           if(leftShape.rtx == -1 || leftShape.rty == -1){
  //             leftShape.rtx = x;
  //             leftShape.rty = y;
  //             leftShape.rtidx = i;
  //           }else{
  //             // To find the most right and top point
  //             if(x > leftShape.rtx && y < leftShape.rty){
  //               leftShape.rtx = x;
  //               leftShape.rty = y;
  //               leftShape.rtidx = i;
  //             }
  //           }
  //         }


  //         // Draw line based on the left shape four points to draw polygon
  //         let leftShapePoints = [
  //           { x:  leftShape.getScaleLtx() + this.sourceImageImg['left'], y: leftShape.getScaleLty()+  this.sourceImageImg['top'] },
  //           { x:  leftShape.getScaleRtx() + this.sourceImageImg['left'], y: leftShape.getScaleRty()+  this.sourceImageImg['top'] },
  //           { x:  leftShape.getScaleRbx() + this.sourceImageImg['left'], y: leftShape.getScaleRby()+  this.sourceImageImg['top'] },
  //           { x:  leftShape.getScaleLbx() + this.sourceImageImg['left'], y: leftShape.getScaleLby()+  this.sourceImageImg['top'] }
  //         ];


  //         // iterator the right shape coordinates to find the most top left and right bottom point
  //         let polygonLeftShape = new fabric.Polygon(leftShapePoints, {
  //           stroke: 'blue',
  //           strokeWidth: 2,
  //           fill: '#ffe11922',
  //           selectable: true
  //         });

  //         self.canvas.add(polygonLeftShape);

  //   });

    
  // }

  // highlightRightImage(){ 

    

  //   var self = this;

  //   this.blocked = true;
  //   //downloadSimilarityResult(authkey:string, hash:string, img1id:string, img2id:string){
  //   this.dataService.downloadSimilarityResult(this.id, this.img1id, this.img2id)
  //           .subscribe(result => {
              
  //         this.analysisResult = result;

  //         this.blocked = false;


  //         // IGNORE the analysis if one of them is undefined
  //         if(this.analysisResult['asift']['left'] == undefined || this.analysisResult['asift']['right'] == undefined){
  //           self.messageService.add({life: GlobalConstants.life, severity:'warn', summary:'Cannot auto matching duplicated area, please manually adjust the display. '});     
  //           return;
  //         }

  //         let leftShape = new Shape();
  //             leftShape.scaleX = this.sourceImageImg['after_width'] / this.sourceImageImg['original_width'];
  //             leftShape.scaleY = this.sourceImageImg['after_height'] / this.sourceImageImg['original_height'];

  //         // iterator the left shape coordinates to find the most top left and right bottom point
  //         for(let i=0; i < this.analysisResult['asift']['left'].length ; i++){

  //           let x = this.analysisResult['asift']['left'][i].x;
  //           let y = this.analysisResult['asift']['left'][i].y;

  //           if(leftShape.ltx == -1 || leftShape.lty == -1){
  //             leftShape.ltx = x;
  //             leftShape.lty = y;
  //             leftShape.ltidx = i;
  //           }else{
  //             // To find the most left and top point
  //             if(x < leftShape.ltx && y < leftShape.lty){
  //               leftShape.ltx = x;
  //               leftShape.lty = y;
  //               leftShape.ltidx = i;
  //             }
  //           }

  //           if(leftShape.rbx == -1 || leftShape.rby == -1){
  //             leftShape.rbx = x;
  //             leftShape.rby = y;
  //             leftShape.rbidx = i;
  //           }else{
  //             // To find the most right and bottom point
  //             if(x > leftShape.rbx && y > leftShape.rby){
  //               leftShape.rbx = x;
  //               leftShape.rby = y;
  //               leftShape.rbidx = i;
  //             }
  //           }

  //           if(leftShape.lbx == -1 || leftShape.lby == -1){
  //             leftShape.lbx = x;
  //             leftShape.lby = y;
  //             leftShape.lbidx = i;
  //           }else{
  //             // To find the most left and bottom point
  //             if(x < leftShape.lbx && y > leftShape.lby){
  //               leftShape.lbx = x;
  //               leftShape.lby = y;
  //               leftShape.lbidx = i;
  //             }
  //           }

  //           if(leftShape.rtx == -1 || leftShape.rty == -1){
  //             leftShape.rtx = x;
  //             leftShape.rty = y;
  //             leftShape.rtidx = i;
  //           }else{
  //             // To find the most right and top point
  //             if(x > leftShape.rtx && y < leftShape.rty){
  //               leftShape.rtx = x;
  //               leftShape.rty = y;
  //               leftShape.rtidx = i;
  //             }
  //           }
  //         }

  //         // Setup the right shape
  //         let rightShape = new Shape();
  //             rightShape.scaleX = this.similarImageImg['after_width'] / this.similarImageImg['original_width'];
  //             rightShape.scaleY = this.similarImageImg['after_height'] / this.similarImageImg['original_height']; 

  //         // Based on the leftShape's ltidx, rtidx, rbidx, lbidx to find the right shape's corresponding points
  //         rightShape.ltx = this.analysisResult['asift']['right'][leftShape.ltidx].x;
  //         rightShape.lty = this.analysisResult['asift']['right'][leftShape.ltidx].y;
  //         rightShape.rtx = this.analysisResult['asift']['right'][leftShape.rtidx].x;
  //         rightShape.rty = this.analysisResult['asift']['right'][leftShape.rtidx].y;
  //         rightShape.rbx = this.analysisResult['asift']['right'][leftShape.rbidx].x;
  //         rightShape.rby = this.analysisResult['asift']['right'][leftShape.rbidx].y;
  //         rightShape.lbx = this.analysisResult['asift']['right'][leftShape.lbidx].x;
  //         rightShape.lby = this.analysisResult['asift']['right'][leftShape.lbidx].y;

  //         // also configure the index values
  //         rightShape.ltidx = leftShape.ltidx;
  //         rightShape.rtidx = leftShape.rtidx;
  //         rightShape.rbidx = leftShape.rbidx;
  //         rightShape.lbidx = leftShape.lbidx;
          
  //         let rightShapePoints = [
  //           { x: rightShape.getScaleLtx() + this.similarImageImg['left'], y: rightShape.getScaleLty() +  this.similarImageImg['top'] },
  //           { x: rightShape.getScaleRtx() + this.similarImageImg['left'], y: rightShape.getScaleRty() +  this.similarImageImg['top'] },
  //           { x: rightShape.getScaleRbx() + this.similarImageImg['left'], y: rightShape.getScaleRby() +  this.similarImageImg['top'] },
  //           { x: rightShape.getScaleLbx() + this.similarImageImg['left'], y: rightShape.getScaleLby() +  this.similarImageImg['top'] }
  //         ];

  //         // Draw the right shape
  //         let polygonRightShape = new fabric.Polygon(rightShapePoints, {
  //           fill: '#ffe11922',
  //           stroke: 'red',
  //           strokeWidth: 2,
  //           selectable: true
  //         });

  //         self.canvas.add(polygonRightShape);

  //   });
    
  // }

  switchToFilterImage(type:string){

    this.hasAnalyzed = false;

    this.adjustmentSourceContrast = 0;
    this.adjustmentSourceBrightness = 0;
    this.adjustmentSourceSaturation = 0;
    this.adjustmentSourceTransparency = 0;
    this.adjustmentSourceInvert = false;

    this.adjustmentSimilarContrast = 0;
    this.adjustmentSimilarBrightness = 0;
    this.adjustmentSimilarSaturation = 0;
    this.adjustmentSimilarTransparency = 0;
    this.adjustmentSimilarInvert = false;
    
    if(type == 'source'){
      this.resetViewerDisplayByTheCacheKey(this.leftFilenameCacheKey);
      if(this.filterListSourceSelected['key'] == 'normal'){
        this.sourceImageImgFilterTemp['top'] = this.sourceImageImg['top'];
        this.sourceImageImgFilterTemp['left'] = this.sourceImageImg['left'];
        this.sourceImageImg = this.sourceImageImgFilterTemp;
        this.canvas.add(this.sourceImageImgFilterTemp);
        this.removeAllFilter(this.leftFilenameCacheKey);
      }else{

        fabric.Image.fromURL(this.baseUrl+'/filter/'+this.sourceImageImgFilterID+'?type='+this.filterListSourceSelected['key'], (img) => {
      
          let scale = 1;
          let top = this.sourceImageImg['top'];
          let left = this.sourceImageImg['left'];
          let srcWidth = this.sourceImageImg['after_width'];

              scale = srcWidth / img.width;

          this.sourceImageImg = img;
          this.hasAnalyzed = false;
    
          img.set({ 
              cacheKey: this.leftFilenameCacheKey,
              top: top,
              left: left,
              scaleX: scale,
              scaleY: scale,
              stroke: 'blue',
              strokeWidth: 2,
          });
    
          this.sourceImageImg['after_width'] = Math.floor(img['width'] * img['scaleX']);
          this.sourceImageImg['after_height'] = Math.floor(img['height'] * img['scaleY']);
          this.sourceImageImg['original_width'] = this.sourceImageImgFilterTemp['original_width'];
          this.sourceImageImg['original_height'] = this.sourceImageImgFilterTemp['original_height'];
    

          this.canvas.add(img);
          this.removeAllFilter(this.leftFilenameCacheKey);
          this.updateWindowPosition();
        }, {crossOrigin: 'anonymous'});

      }
    }else{

      this.resetViewerDisplayByTheCacheKey(this.rightFilenameCacheKey);

      if(this.filterListSimilarSelected['key'] == 'normal'){
        this.similarImageImgFilterTemp['top'] = this.similarImageImg['top'];
        this.similarImageImgFilterTemp['left'] = this.similarImageImg['left'];
        this.similarImageImg = this.similarImageImgFilterTemp;
        this.canvas.add(this.similarImageImgFilterTemp);
        this.removeAllFilter(this.rightFilenameCacheKey);
      }else{

        fabric.Image.fromURL(this.baseUrl+'/filter/'+this.similarImageImgFilterID+'?type='+this.filterListSimilarSelected['key'], (img) => {
      
          let scale = 1;
          let top = this.similarImageImg['top'];
          let left = this.similarImageImg['left'];
          let srcWidth = this.similarImageImg['after_width'];

              scale = srcWidth / img.width;

          this.similarImageImg = img;
          this.hasAnalyzed = false;
    
          img.set({ 
              cacheKey: this.rightFilenameCacheKey,
              top: top,
              left: left,
              scaleX: scale,
              scaleY: scale,
              stroke: 'red',
              strokeWidth: 2,
          });
    
          this.similarImageImg['after_width'] = Math.floor(img['width'] * img['scaleX']);
          this.similarImageImg['after_height'] = Math.floor(img['height'] * img['scaleY']);
          this.similarImageImg['original_width'] = this.similarImageImgFilterTemp['original_width'];
          this.similarImageImg['original_height'] = this.similarImageImgFilterTemp['original_height'];
    
          this.canvas.add(img);
          this.removeAllFilter(this.rightFilenameCacheKey);
          this.updateWindowPosition();
        }, {crossOrigin: 'anonymous'});

      }
    }
  }

  // submitFilterImage(type:string){

  //   let idx = 0;
  //   var dataURLSrc = '';
  //   if(type == 'source'){
  //     this.sourceImageImgFilterTemp = this.sourceImageImg;
      
  //     this.sourceImageImg.set({strokeWidth: 0});
  //     dataURLSrc = this.sourceImageImg.toDataURL({
  //       format: "png",
  //       left: 0,
  //       top: 0,
  //     });
  //     this.sourceImageImg.set({strokeWidth: 2});
      
  //   }else{
  //     this.similarImageImgFilterTemp = this.similarImageImg;
      
  //     this.similarImageImg.set({strokeWidth: 0});
  //     dataURLSrc = this.similarImageImg.toDataURL({
  //       format: "png",
  //       left: 0,
  //       top: 0,
  //     });
  //     this.similarImageImg.set({strokeWidth: 2});
  //   }

  //   let anaobj:Filter = new Filter();
  //       anaobj.target = dataURLSrc;

  //   this.dataService.submitForFilterAnalysis(anaobj)
  //     .subscribe(result => {
  //       if(result['status'] == 'success'){
  //         let key = result['key'];
  //         if(type == 'source'){
  //           this.sourceImageImgFilterID = key;
  //         }else{
  //           this.similarImageImgFilterID = key;
  //         }
  //       }
  //   });

  // }
  
  onChangeFilterBrightness(type:string){

    let idx = 0;
      for(let i=0; i < this.canvas.getObjects().length ; i++){
        if(this.canvas.getObjects()[i]['cacheKey'] == type){
          idx = i;
          break;
        }
      }

      let value = 0;
      if(type == this.leftFilenameCacheKey){
        value = this.adjustmentSourceBrightness / 100;
      }else{
        value = this.adjustmentSimilarBrightness / 100;
      }

      var filter = new fabric.Image.filters.Brightness({
        brightness: value
      });
      
      let hasFilter  = false;
      for(let j = 0; j < this.canvas.getObjects()[idx].filters.length ; j++){
        if(this.canvas.getObjects()[idx].filters[j]['brightness'] != undefined){
          this.canvas.getObjects()[idx].filters[j]['brightness'] = value;
          hasFilter = true;
        }
      }
      if(!hasFilter){
        this.canvas.getObjects()[idx].filters.push(filter);
      }
      if(type == this.leftFilenameCacheKey){
        this.sourceImageImg = this.canvas.getObjects()[idx];
      }else{
        this.similarImageImg = this.canvas.getObjects()[idx];
      }

      this.canvas.getObjects()[idx].applyFilters();
      this.canvas.renderAll();
  }


  onChangeFilterContrast(type:string){

    let idx = 0;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == type){
        idx = i;
        break;
      }
    }


    let value = 0;
    if(type == this.leftFilenameCacheKey){
      value = this.adjustmentSourceContrast / 100;
    }else{
      value = this.adjustmentSimilarContrast / 100;
    }

    var filter = new fabric.Image.filters.Contrast({
      contrast: value
    });
    
    let hasFilter  = false;
    for(let j = 0; j < this.canvas.getObjects()[idx].filters.length ; j++){
      if(this.canvas.getObjects()[idx].filters[j]['contrast'] != undefined){
        this.canvas.getObjects()[idx].filters[j]['contrast'] = value;
        hasFilter = true;
      }
    }
    if(!hasFilter){
      this.canvas.getObjects()[idx].filters.push(filter);
    }
    if(type == this.leftFilenameCacheKey){
      this.sourceImageImg = this.canvas.getObjects()[idx];
    }else{
      this.similarImageImg = this.canvas.getObjects()[idx];
    }

    this.canvas.getObjects()[idx].applyFilters();
    this.canvas.renderAll();
  }
  /**
      var invert = new fabric.Image.filters.Invert({});
    
      img.filters.push(invert);
      img.applyFilters(); */


  onChangeInvertColor(type:string){

        let idx = 0;
        for(let i=0; i < this.canvas.getObjects().length ; i++){
          if(this.canvas.getObjects()[i]['cacheKey'] == type){
            idx = i;
            break;
          }
        }
    
        let value = false;
        if(type == this.leftFilenameCacheKey){
          value = this.adjustmentSourceInvert;
        }else{
          value = this.adjustmentSimilarInvert;
        }
            
    
        var filter = new fabric.Image.filters.Invert({});
            
        let hasFilter  = false;
        for(let j = 0; j < this.canvas.getObjects()[idx].filters.length ; j++){
          if(this.canvas.getObjects()[idx].filters[j]['invert'] != undefined){
            this.canvas.getObjects()[idx].filters[j]['invert'] = value;
            hasFilter = true;
          }
        }
        if(!hasFilter){
          this.canvas.getObjects()[idx].filters.push(filter);
        }

        if(type == this.leftFilenameCacheKey){
          this.sourceImageImg = this.canvas.getObjects()[idx];
        }else{
          this.similarImageImg = this.canvas.getObjects()[idx];
        }
    
        this.canvas.getObjects()[idx].applyFilters();
        this.canvas.renderAll();
  }

  onChangeFilterSaturation(type:string){

    let idx = 0;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == type){
        idx = i;
        break;
      }
    }

    let value = 0;
    if(type == this.leftFilenameCacheKey){
      value = this.adjustmentSourceSaturation / 100;
    }else{
      value = this.adjustmentSimilarSaturation / 100;
    }

    var filter = new fabric.Image.filters.Saturation({
      saturation: value
    });
    
    let hasFilter  = false;
    for(let j = 0; j < this.canvas.getObjects()[idx].filters.length ; j++){
      if(this.canvas.getObjects()[idx].filters[j]['saturation'] != undefined){
        this.canvas.getObjects()[idx].filters[j]['saturation'] = value;
        hasFilter = true;
      }
    }
    if(!hasFilter){
      this.canvas.getObjects()[idx].filters.push(filter);
    }
    if(type == this.leftFilenameCacheKey){
      this.sourceImageImg = this.canvas.getObjects()[idx];
    }else{
      this.similarImageImg = this.canvas.getObjects()[idx];
    }

    this.canvas.getObjects()[idx].applyFilters();
    this.canvas.renderAll();
  }

  onChangeFilterTransparency(type:string){

    if(type == this.leftFilenameCacheKey){
      var tempTValue = 100 - this.adjustmentSourceTransparency;
  
      if(tempTValue == 0){
        tempTValue = 1;
      }
  
      var confirmValue = tempTValue / 100; 
      
      console.log('Confirm source transparency ' + ' / ' + confirmValue)
      this.sourceImageImg.set('opacity', confirmValue);
    }else{
      var tempTValue = 100 - this.adjustmentSimilarTransparency;
  
      if(tempTValue == 0){
        tempTValue = 1;
      }
  
      var confirmValue = tempTValue / 100; 
      
      console.log('Confirm similar transparency ' + ' / ' + confirmValue)
      this.similarImageImg.set('opacity', confirmValue);
    }

    // this.canvas.getObjects()[idx].applyFilters();
    this.canvas.renderAll();
  }


  
  removeAllFilter(type:string){

    let idx = 0;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == type){
        idx = i;
        break;
      }
    }
    for(let j = 0; j < this.canvas.getObjects()[idx].filters.length ; j++){
      if(this.canvas.getObjects()[idx].filters[j]['saturation'] != undefined){
        this.canvas.getObjects()[idx].filters[j]['saturation'] = 0;
      }
      if(this.canvas.getObjects()[idx].filters[j]['contrast'] != undefined){
        this.canvas.getObjects()[idx].filters[j]['contrast'] = 0;
      }
      if(this.canvas.getObjects()[idx].filters[j]['brightness'] != undefined){
        this.canvas.getObjects()[idx].filters[j]['brightness'] = 0;
      }
      if(this.canvas.getObjects()[idx].filters[j]['opacity'] != undefined){
        this.canvas.getObjects()[idx].filters[j]['opacity'] = 0;
      }
      if(this.canvas.getObjects()[idx].filters[j]['invert'] != undefined){
        this.canvas.getObjects()[idx].filters[j]['invert'] = false;
      }
    }
    if(type == this.leftFilenameCacheKey){
      this.sourceImageImg = this.canvas.getObjects()[idx];
    }else{
      this.similarImageImg = this.canvas.getObjects()[idx];
    }

    this.canvas.getObjects()[idx].applyFilters();
    this.canvas.renderAll();

  }
  
  submitWebImage(type:string){

    this.blocked = true;

    this.sourceImageImgWebID = null;
    this.similarImageImgWebID = null;
    this.internetImageImgWebTemp = null;

    if(type == 'source'){

      let imageid = this.leftFilename;
      this.dataService.submitForInternetSearch(this.id, imageid)
      .subscribe(result => {
        console.log(result);
        if(result['status'] == 'success'){
          let key = result['key'];
          this.sourceImageImgWebID = key;
          this.downloadInternetReport(type);
        }
      });

      // fabric.Image.fromURL(this.baseUrl+'/image/'+this.id+'/'+this.leftFilename, (img) => {

      //   // var dataURLSrc = img.toDataURL({});
      //   // let anaobj:Web = new Web();
      //   // anaobj.target = dataURLSrc;


      // }, {crossOrigin: 'anonymous'});
      
    }else{

      let imageid =  this.rightFilenameRecordId + '_' + this.rightFilename;
      this.dataService.submitForInternetSearch(this.id, imageid)
      .subscribe(result => {
        console.log(result);
        if(result['status'] == 'success'){
          let key = result['key'];
          this.similarImageImgWebID = key;
          this.downloadInternetReport(type);
        }
      });

      // fabric.Image.fromURL(this.baseUrl+'/image/'+this.rightFilenameRecordId+'/'+this.rightFilename, (img) => {
  
      //   // var dataURLSrc = img.toDataURL({});
      //   // let anaobj:Web = new Web(); 
      //   // anaobj.target = dataURLSrc;

      //   this.dataService.submitForInternetSearch(this.id, imageid)
      //     .subscribe(result => {
      //       if(result['status'] == 'success'){
      //         let key = result['key'];
      //         this.similarImageImgWebID = key;
      //         this.downloadInternetReport(type);
      //       }
      //   });

      // }, {crossOrigin: 'anonymous'});

    } 
  }


  downloadInternetReport(type:string){

    let traceid = '';
    if(type == 'source'){
      traceid = this.sourceImageImgWebID;
    }else{
      traceid = this.similarImageImgWebID;
    }

    this.dataService.downloadInternetResult(this.id, traceid)
            .subscribe(result => {
        
        this.blocked = false;
              
        if(type == 'source'){
          this.internetImageImgWebTemp = result;
          this.internetImageImgWebTemp['type'] = 'source';
        }else{
          this.internetImageImgWebTemp = result;
          this.internetImageImgWebTemp['type'] = 'similar';
        }

        console.log(this.internetImageImgWebTemp);

        this.searchResultDialog = true;

    });

  }

  
  confirmStoreInternetCase() {

    console.log('run');
    this.confirmationService.confirm({
        message: 'Are you sure that you want to save this Internet search Results?',
        header: 'Save Analysis Case',
        icon: 'pi pi-save',
        accept: () => {
          this.processStoreInternetCase();
        },
        reject: (type) => {
        }
    });
  }

  processStoreInternetCase(){
    
    // here to store the Internet Analysis Case

    this.blocked = true;

    var type = this.internetImageImgWebTemp['type'];
        
    if(type == 'source'){

        // Here to store the source case result
        this.addtionalInformationSource = null;
        
        this.dataService.getImageMetadata(this.id, this.leftFilename)
        .subscribe(source =>{

          if(source['status'] == 'success'){
            this.addtionalInformationSource = source;
          }
          
          let caseobj:Case = new Case();
          
          caseobj.type = 'internet';
          caseobj.source = 'source';
          caseobj.internet = this.sourceImageImgWebID;
          caseobj.analysis = this.analysis;
          
          // caseobj.analysis = this.analysis+'_'+this.pair['img1']['original_file']+'_'+this.pair['img2']['original_file']; 
          caseobj.institution = this.record['institution'];

          caseobj.sourceFilename = this.leftFilename;
          caseobj.sourceRecord = this.leftFilenameRecordId;
          caseobj.sourceRepository = this.pair['img1']['repository'];
          
          if(this.leftFilename != null){
            caseobj.sourceId = this.id + "_" + this.leftFilename;
          }
          
          if(this.addtionalInformationSource != null){
            caseobj.sourceTitle = this.addtionalInformationSource['record']['title'];
            if(this.addtionalInformationSource['image'] != null){
              caseobj.sourceDescription = this.addtionalInformationSource['image']['description'];
            }
            if(this.addtionalInformationSource['record'] != null && this.addtionalInformationSource['record']['doi'] != null){
              caseobj.sourceDoi = this.addtionalInformationSource['record']['doi'];
            }
          }
      
          this.dataService.saveAnalysisCase(this.id, caseobj)
          .subscribe(result => {
            if(result['status'] == 'success'){
              this.messageService.add({life: GlobalConstants.life, severity:'success', summary:'Save Result', detail:'The internet result has been saved successfully.'});
            }   
            this.loadCaseSummary();
            this.blocked = false;   
    
          });
    
        }); 

    }else{

        // Here to store the similar case result
        this.addtionalInformationSimilar = null;

        // this.dataService.getSimilarImageMetadata(this.id, this.rightFilenameRecordId, this.rightFilename)
        this.dataService.getImageMetadata(this.id, this.rightFilenameRecordId +'_'+ this.rightFilename)
        .subscribe(target =>{
          if(target['status'] == 'success'){
            this.addtionalInformationSimilar = target;
          }

          // Here to update the case

          let caseobj:Case = new Case();
        
          caseobj.type = 'internet';
          caseobj.source = 'similar';
          caseobj.internet = this.similarImageImgWebID;
          
          caseobj.analysis = this.analysis;
          caseobj.institution = this.record['institution'];

          caseobj.targetFilename = this.rightFilename;
          caseobj.targetRecord = this.rightFilenameRecordId;
          caseobj.targetRepository = this.pair['img2']['repository'];

          if(this.rightFilename != null && this.rightFilenameRecordId != null){
            caseobj.targetId = this.rightFilenameRecordId + "_" + this.rightFilename;
          }

          if(this.addtionalInformationSimilar != null){
            caseobj.targetTitle = this.addtionalInformationSimilar['record']['title'];
            if(this.addtionalInformationSimilar['image'] != null){
             caseobj.targetDescription = this.addtionalInformationSimilar['image']['description'];
            }
            if(this.addtionalInformationSimilar['record'] != null && this.addtionalInformationSimilar['record']['doi'] != null){
             caseobj.targetDoi = this.addtionalInformationSimilar['record']['doi'];
            }
          }
      
          this.dataService.saveAnalysisCase(this.id, caseobj)
          .subscribe(result => {
            if(result['status'] == 'success'){
              this.messageService.add({life: GlobalConstants.life, severity:'success', summary:'Save Result', detail:'The internet result has been saved successfully.'});
            }   
            this.loadCaseSummary();
            this.blocked = false;   
          });

        });
  
    } // end of the setting
    

  }
  
  confirmStoreCase() {

    console.log('run');
    this.confirmationService.confirm({
        message: this.translate.instant('duplication-viewer.dialog-saved-case-confirm'),
        header: this.translate.instant('duplication-viewer.dialog-saved-case-title'),
        icon: 'pi pi-save',
        accept: () => {
          this.processStoreAnalysisCase();  
        },
        reject: (type) => {
        }
    });
  }

  processStoreAnalysisCase(){

    this.blocked = true;
    var dataUrl = this.canvas.toDataURL({
      format: "png",
      left: 0,
      top: 0,
    }); 

    this.addtionalInformationSource = null;
      this.dataService.getImageMetadata(this.id, this.leftFilename)
      .subscribe(source =>{
        if(source['status'] == 'success'){
          this.addtionalInformationSource = source;
        }
        
        this.addtionalInformationSimilar = null;
        // this.dataService.getSimilarImageMetadata(this.id, this.rightFilenameRecordId, this.rightFilename)
        this.dataService.getImageMetadata(this.id, this.rightFilenameRecordId +'_'+ this.rightFilename)
        .subscribe(target =>{
          if(target['status'] == 'success'){
            this.addtionalInformationSimilar = target;
          }

          // Here to update the case

          let caseobj:Case = new Case();
        
          caseobj.type = 'manually';
          
          caseobj.analysis = this.analysis; 

          //caseobj.analysis = pair['analysis']+'_'+pair['img1']['original_file']+'_'+pair['img2']['original_file']; 
          caseobj.institution = this.record['institution'];

          caseobj.sourceFilename = this.leftFilename;
          caseobj.sourceRecord = this.leftFilenameRecordId;
          caseobj.sourceRepository = this.pair['img1']['repository'];
          
          if(this.leftFilename != null){
            caseobj.sourceId = this.leftFilenameRecordId + "_" + this.leftFilename;
          }
          caseobj.targetFilename = this.rightFilename;
          caseobj.targetRecord = this.rightFilenameRecordId;
          caseobj.targetRepository = this.pair['img2']['repository'];

          if(this.rightFilename != null && this.rightFilenameRecordId != null){
            caseobj.targetId = this.rightFilenameRecordId + "_" + this.rightFilename;
          }

          caseobj.image = dataUrl;
          
          if(this.addtionalInformationSource != null){
            caseobj.sourceTitle = this.addtionalInformationSource['record']['title'];
           if(this.addtionalInformationSource['image'] != null){
            caseobj.sourceDescription = this.addtionalInformationSource['image']['description'];
           }
           if(this.addtionalInformationSource['record'] != null && this.addtionalInformationSource['record']['doi'] != null){
            caseobj.sourceDoi = this.addtionalInformationSource['record']['doi'];
           }
          }
          if(this.addtionalInformationSimilar != null){
            caseobj.targetTitle = this.addtionalInformationSimilar['record']['title'];
            if(this.addtionalInformationSimilar['image'] != null){
             caseobj.targetDescription = this.addtionalInformationSimilar['image']['description'];
            }
            if(this.addtionalInformationSimilar['record'] != null && this.addtionalInformationSimilar['record']['doi'] != null){
             caseobj.targetDoi = this.addtionalInformationSimilar['record']['doi'];
            }
          }
      
          this.dataService.saveAnalysisCase(this.id, caseobj)
          .subscribe(result => {
            if(result['status'] == 'success'){
              this.messageService.add({life: GlobalConstants.life, severity:'success', summary: this.translate.instant('duplication-viewer.toast-saved-case-title') , detail: this.translate.instant('duplication-viewer.toast-saved-case-success') });
            }   
            this.loadCaseSummary();
            this.blocked = false;   
          });

        });
  
    }); 

  }


  flipImage(type:string){
    if(type == 'source'){
      for(let i=0; i < this.canvas.getObjects().length ; i++){
        if(this.canvas.getObjects()[i]['cacheKey'] == this.leftFilenameCacheKey){
          this.canvas.getObjects()[i].toggle('flipX');
          this.canvas.renderAll();
        }
      }
    }else{
      for(let i=0; i < this.canvas.getObjects().length ; i++){
        if(this.canvas.getObjects()[i]['cacheKey'] == this.rightFilenameCacheKey){
          this.canvas.getObjects()[i].toggle('flipX');
          this.canvas.renderAll();
        }
      }
    }
  }

  turnRight(type:string){
    if(type == 'source'){
      for(let i=0; i < this.canvas.getObjects().length ; i++){
        if(this.canvas.getObjects()[i]['cacheKey'] == this.leftFilenameCacheKey){
          var tempvalue = this.canvas.getObjects()[i]['angle'];
          if(tempvalue == 270){
            tempvalue = 0;
          }else{
            tempvalue += 90;
          }
          this.canvas.getObjects()[i].rotate(tempvalue);
          this.canvas.renderAll();
        }
      }
    }else{
      for(let i=0; i < this.canvas.getObjects().length ; i++){
        if(this.canvas.getObjects()[i]['cacheKey'] == this.rightFilenameCacheKey){
          var tempvalue = this.canvas.getObjects()[i]['angle'];
          if(tempvalue == 270){
            tempvalue = 0;
          }else{
            tempvalue += 90;
          }
          this.canvas.getObjects()[i].rotate(tempvalue);
          this.canvas.renderAll();
        }
      }
    }
  } 

  reloadImage(type:string){
    if(type == 'source'){ 
      this.addSourceImageToViewer();
      this.filterListSourceSelected = this.filterList[0];
      this.adjustmentSourceContrast = 0;
      this.adjustmentSourceBrightness = 0;
      this.adjustmentSourceSaturation = 0;
      this.adjustmentSourceTransparency = 0;
      this.adjustmentSourceInvert = false;
    }else{
      this.addSimilarImageToViewer();
      this.filterListSimilarSelected = this.filterList[0];
      this.adjustmentSimilarContrast = 0;
      this.adjustmentSimilarBrightness = 0;
      this.adjustmentSimilarSaturation = 0;
      this.adjustmentSimilarTransparency = 0;
      this.adjustmentSimilarInvert = false;
    }
  }
  

  resetBothImage(){

    this.filterListSourceSelected = this.filterList[0];
    this.adjustmentSourceContrast = 0;
    this.adjustmentSourceBrightness = 0;
    this.adjustmentSourceSaturation = 0;
    this.adjustmentSourceTransparency = 0;
    this.adjustmentSourceInvert = false;

    this.filterListSimilarSelected = this.filterList[0];
    this.adjustmentSimilarContrast = 0;
    this.adjustmentSimilarBrightness = 0;
    this.adjustmentSimilarSaturation = 0;
    this.adjustmentSimilarTransparency = 0;
    this.adjustmentSimilarInvert = false;
    
    this.removeTextFromCanvas();
    this.resetViewerDisplayByTheCacheKey(this.leftFilenameCacheKey);
    this.resetViewerDisplayByTheCacheKey(this.rightFilenameCacheKey);
      
    fabric.Image.fromURL(this.baseUrl+'/image/'+this.id+'/'+this.leftFilename, (img) => {
   
      let scale = 1;
      if(img.width > img.height){
        let scaleWidth = (this.canvas.width/2) * 0.8;
        scale = scaleWidth / img.width;
      }else{
        let scaleHeight = this.canvas.height * 0.8;
        scale = scaleHeight / img.height;
      }
      scale = scale * 0.8;
      let top = (this.canvas.height - (img.height*scale)) / 2;
      let left = (this.canvas.width - (img.width*scale)) / 9;

      this.sourceImageImg = img;
      this.hasAnalyzed = false;

      this.sourceImageImg['original_width'] = img.width;
      this.sourceImageImg['original_height'] = img.height;

      img.set({
          cacheKey: this.leftFilenameCacheKey,
          scaleX: scale,
          scaleY: scale, 
          top: top,
          left: left,
          stroke: 'blue',
          strokeWidth: 2
      });

      this.sourceImageImg['after_width'] = Math.round(img.width * scale);
      this.sourceImageImg['after_height'] = Math.round(img.height * scale);
      
      this.canvas.add(img);

    }, {crossOrigin: 'anonymous'});
    

    fabric.Image.fromURL(this.baseUrl+'/image/'+this.id+'/'+this.rightFilenameRecordId+"_"+this.rightFilename, (img) => {
  
      let scale = 1;
      if(img.width > img.height){
        let scaleWidth = (this.canvas.width/2) * 0.8;
        scale = scaleWidth / img.width;
      }else{
        let scaleHeight = this.canvas.height * 0.8;
        scale = scaleHeight / img.height;
      }
      scale = scale * 0.8;
      let top = (this.canvas.height - (img.height*scale)) / 2;
      let left = (this.canvas.width - (img.width*scale)) * 0.9;

      this.similarImageImg = img;
      this.hasAnalyzed = false;

      img.set({
          cacheKey: this.rightFilenameCacheKey,
          scaleX: scale,
          scaleY: scale,
          top: top,
          left: left,
          stroke: 'red',
          strokeWidth: 2,
      });
      
      this.similarImageImg['original_width'] = img.width;
      this.similarImageImg['original_height'] = img.height;
      this.similarImageImg['after_width'] = Math.round(img.width * scale);
      this.similarImageImg['after_height'] = Math.round(img.height * scale);

      this.canvas.add(img);

    }, {crossOrigin: 'anonymous'});


  }

 
  loadAdditionalInformation(type:string){
    this.addtionalInformationDisplay = null;
    if(type == 'source'){
      this.addtionalInformationSource = null;
      this.dataService.getImageMetadata(this.id, this.leftFilename)
      .subscribe(source =>{
        if(source['status'] == 'success'){
          this.addtionalInformationSource = source;
          this.addtionalInformationDisplay = source;
          this.addtionalInformationDisplay['width'] = this.sourceImageImg.original_width;
          this.addtionalInformationDisplay['height'] = this.sourceImageImg.original_height;
          if(this.addtionalInformationDisplay != null){
            this.addtionalInformationDialog = true;
          }else{
            this.messageService.add({life: GlobalConstants.life, severity:'warn', summary:this.translate.instant('duplication-viewer.toast-load-metadata-title'), detail:this.translate.instant('duplication-viewer.toast-load-metadata-error')});     
          }
        }else{
          this.messageService.add({life: GlobalConstants.life, severity:'warn', summary:this.translate.instant('duplication-viewer.toast-load-metadata-title'), detail:this.translate.instant('duplication-viewer.toast-load-metadata-error')});     
        }
      });  
    }else{
      this.addtionalInformationSimilar = null;
      //rightFilenameRecordId
      //this.dataService.getImageMetadata(this.id, this.rightFilename)
      // alert(this.rightFilenameRecordId+"_"+this.rightFilename);
      this.dataService.getImageMetadata(this.id, this.rightFilenameRecordId+"_"+this.rightFilename)
      .subscribe(target =>{
        console.log(target);
        if(target['status'] == 'success'){
          this.addtionalInformationSimilar = target;
          this.addtionalInformationDisplay = target;
          this.addtionalInformationDisplay['width'] = this.similarImageImg.original_width;
          this.addtionalInformationDisplay['height'] = this.similarImageImg.original_height;
          if(this.addtionalInformationDisplay != null){
            this.addtionalInformationDialog = true;
          }else{
            this.messageService.add({life: GlobalConstants.life, severity:'warn', summary:this.translate.instant('duplication-viewer.toast-load-metadata-title'), detail:this.translate.instant('duplication-viewer.toast-load-metadata-error')});     
          }
        }else{
          this.messageService.add({life: GlobalConstants.life, severity:'warn', summary:this.translate.instant('duplication-viewer.toast-load-metadata-title'), detail:this.translate.instant('duplication-viewer.toast-load-metadata-error')});     
        }
      });
    }
  }

  ZoomIn(type:string){
    var idx = 0;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == type){
        idx = i;
        break;
      }
    }
    var current = this.canvas.getObjects()[idx]['scaleX'];
    current = current * 1.25;
    this.canvas.getObjects()[idx].scale(current);
    this.canvas.renderAll(); 
    
    if(type == this.leftFilenameCacheKey){
      this.sourceImageImg['after_width'] = Math.round(this.canvas.getObjects()[idx]['width'] * this.canvas.getObjects()[idx]['scaleX']);
      this.sourceImageImg['after_height'] = Math.round(this.canvas.getObjects()[idx]['height'] * this.canvas.getObjects()[idx]['scaleY']);
    }else{
      this.similarImageImg['after_width'] = Math.round(this.canvas.getObjects()[idx]['width'] * this.canvas.getObjects()[idx]['scaleX']);
      this.similarImageImg['after_height'] = Math.round(this.canvas.getObjects()[idx]['height'] * this.canvas.getObjects()[idx]['scaleY']);
    }

    
  }

  ZoomOut(type:string){
    var idx = 0;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == type){
        idx = i;
        break;
      }
    }
    var current = this.canvas.getObjects()[idx]['scaleX'];
    current = current * 0.75;
    this.canvas.getObjects()[idx].scale(current);
    this.canvas.renderAll();  
    
    if(type == this.leftFilenameCacheKey){
      this.sourceImageImg['after_width'] = Math.round(this.canvas.getObjects()[idx]['width'] * this.canvas.getObjects()[idx]['scaleX']);
      this.sourceImageImg['after_height'] = Math.round(this.canvas.getObjects()[idx]['height'] * this.canvas.getObjects()[idx]['scaleY']);
    }else{
      this.similarImageImg['after_width'] = Math.round(this.canvas.getObjects()[idx]['width'] * this.canvas.getObjects()[idx]['scaleX']);
      this.similarImageImg['after_height'] = Math.round(this.canvas.getObjects()[idx]['height'] * this.canvas.getObjects()[idx]['scaleY']);
    }

  }

  linkViaDoi(doi:string){
    if(doi.indexOf('doi') != -1){
      let afterdoi = doi.substring(doi.indexOf('doi') + 1);
          afterdoi = doi.substring(doi.indexOf('/')+1);
          doi = afterdoi;
    }
    window.open('https://doi.org/'+doi);
  }
  
  leaveDrawingMode(){
    this.isDrawingMode = false;
    this.canvas.set({
      isDrawingMode: false
    });
    this.messageService.add({life: GlobalConstants.life, severity:'success', summary:this.translate.instant('duplication-viewer.toast-leave-edit-mode')});
  }

  toggleDrawingMode(color:string){
    this.drawingColor = color;
    this.isDrawingMode = true;
    this.canvas.set({
      isDrawingMode: true,
    });
    this.canvas.freeDrawingBrush.color = this.drawingColor;
    this.canvas.freeDrawingBrush.width = 5;
    this.messageService.add({life: GlobalConstants.life, severity:'success', summary:this.translate.instant('duplication-viewer.toast-enter-edit-mode')});
  }

  toggleIntersectingMode(){
    if(this.hasAnalyzed == false){
      if(this.isIntersectingMode == true){
        this.reloadImage('source');
        this.reloadImage('similar');
        this.isIntersectingMode = false;
        this.messageService.add({life: GlobalConstants.life, severity:'success', summary:this.translate.instant('duplication-viewer.toast-leave-intersection-mode') });
      }else{
        this.similarFlip = false;
        this.similarAngle = 0;
        this.isIntersectingMode = true;
        this.messageService.add({life: GlobalConstants.life, severity:'success', summary:this.translate.instant('duplication-viewer.toast-enter-intersection-mode')});
        this.autoIntersectingPrepare();
      }
    }else{
      this.analyzeImageSimilarity();
    }
  }

  // autoDuplicationMatchingCanvas(){
  //   console.log('auto');
  //   // this.canvas.renderAll.bind(this.canvas);
  // }

  

  // autoDuplicationMatching(){
  //   this.reloadImage('source');
  //   this.reloadImage('similar');
  //   var self = this;
  //   let load = timer(1500);
  //        load.subscribe({
  //           next: function(value) {
  //           },
  //           complete: function() {
  //             this.autoDuplicationMatchingSourceImage();
  //           },
  //           error: function(error) {
  //           }
  //         });
  // }

  similarFlip:boolean = false;
  similarAngle:number = 0;

  mFlip:boolean = false;
  vFlip:boolean = false;
  hFlip:boolean = false;

  autoIntersectingPrepare(){

    var self = this;

    this.blocked = true;
    //downloadSimilarityResult(authkey:string, hash:string, img1id:string, img2id:string){
    this.dataService.downloadSimilarityResult(this.id, this.img1id, this.img2id)
            .subscribe(result => {
              
          this.analysisResult = result;

          this.blocked = false;

          // if this.analysisResult['asift'] has transformFactors
          if(this.analysisResult['asift'] != undefined && this.analysisResult['asift']['transformFactors'] != undefined){
            console.log(this.analysisResult['asift']['transformFactors']);
            this.mFlip = this.analysisResult['asift']['transformFactors']['mFlip'];
            this.vFlip = this.analysisResult['asift']['transformFactors']['vFlip'];
            this.hFlip = this.analysisResult['asift']['transformFactors']['hFlip'];
          }

          // Flip the similar image if the mFlip is true
          // if(false){
          if(this.mFlip || this.vFlip || this.hFlip){
            this.similarFlip = true;

            // Store if the similar image has been flipped
            this.analyzedFlip = true;

            // Flip the similar image
            for(let i=0; i < this.canvas.getObjects().length ; i++){
              if(this.canvas.getObjects()[i]['cacheKey'] == this.rightFilenameCacheKey){
                
                // flip the image horizontally
                this.canvas.getObjects()[i].toggle('flipX');
                this.canvas.renderAll();

                // here to update every similar image's coordinates by the flip 
                for(let j=0; j < this.analysisResult['asift']['right'].length ; j++){
                  this.analysisResult['asift']['right'][j].x = this.similarImageImg['original_width'] - this.analysisResult['asift']['right'][j].x;
                }
                
              }
            }

          }


          // IGNORE the analysis if one of them is undefined
          if(this.analysisResult['asift']['left'] == undefined || this.analysisResult['asift']['right'] == undefined){
            self.messageService.add({life: GlobalConstants.life, severity:'warn', summary:'Cannot auto matching duplicated area, please manually adjust the display. '});     
            return;
          }

          let leftShape = new Shape();
              leftShape.scaleX = this.sourceImageImg['after_width'] / this.sourceImageImg['original_width'];
              leftShape.scaleY = this.sourceImageImg['after_height'] / this.sourceImageImg['original_height'];

          // iterator the left shape coordinates to find the most top left and right bottom point
          for(let i=0; i < this.analysisResult['asift']['left'].length ; i++){

            let x = this.analysisResult['asift']['left'][i].x;
            let y = this.analysisResult['asift']['left'][i].y;

            if(leftShape.ltx == -1 || leftShape.lty == -1){
              leftShape.ltx = x;
              leftShape.lty = y;
              leftShape.ltidx = i;
            }else{
              // To find the most left and top point
              if(x < leftShape.ltx && y < leftShape.lty){
                leftShape.ltx = x;
                leftShape.lty = y;
                leftShape.ltidx = i;
              }
            }

            if(leftShape.rbx == -1 || leftShape.rby == -1){
              leftShape.rbx = x;
              leftShape.rby = y;
              leftShape.rbidx = i;
            }else{
              // To find the most right and bottom point
              if(x > leftShape.rbx && y > leftShape.rby){
                leftShape.rbx = x;
                leftShape.rby = y;
                leftShape.rbidx = i;
              }
            }

            if(leftShape.lbx == -1 || leftShape.lby == -1){
              leftShape.lbx = x;
              leftShape.lby = y;
              leftShape.lbidx = i;
            }else{
              // To find the most left and bottom point
              if(x < leftShape.lbx && y > leftShape.lby){
                leftShape.lbx = x;
                leftShape.lby = y;
                leftShape.lbidx = i;
              }
            }

            if(leftShape.rtx == -1 || leftShape.rty == -1){
              leftShape.rtx = x;
              leftShape.rty = y;
              leftShape.rtidx = i;
            }else{
              // To find the most right and top point
              if(x > leftShape.rtx && y < leftShape.rty){
                leftShape.rtx = x;
                leftShape.rty = y;
                leftShape.rtidx = i;
              }
            }
          }


          // Draw line based on the left shape four points to draw polygon
          let leftShapePoints = [
            { x:  leftShape.getScaleLtx() + this.sourceImageImg['left'], y: leftShape.getScaleLty()+  this.sourceImageImg['top'] },
            { x:  leftShape.getScaleRtx() + this.sourceImageImg['left'], y: leftShape.getScaleRty()+  this.sourceImageImg['top'] },
            { x:  leftShape.getScaleRbx() + this.sourceImageImg['left'], y: leftShape.getScaleRby()+  this.sourceImageImg['top'] },
            { x:  leftShape.getScaleLbx() + this.sourceImageImg['left'], y: leftShape.getScaleLby()+  this.sourceImageImg['top'] }
          ];


          // iterator the right shape coordinates to find the most top left and right bottom point
          // let polygonLeftShape = new fabric.Polygon(leftShapePoints, {
          //   fill: 'rgba(0,0,0,0)',
          //   stroke: 'blue',
          //   strokeWidth: 2,
          //   selectable: true
          // });

          // self.canvas.add(polygonLeftShape);

          // Setup the right shape
          let rightShape = new Shape();
              rightShape.scaleX = this.similarImageImg['after_width'] / this.similarImageImg['original_width'];
              rightShape.scaleY = this.similarImageImg['after_height'] / this.similarImageImg['original_height']; 

          // Based on the leftShape's ltidx, rtidx, rbidx, lbidx to find the right shape's corresponding points
          rightShape.ltx = this.analysisResult['asift']['right'][leftShape.ltidx].x;
          rightShape.lty = this.analysisResult['asift']['right'][leftShape.ltidx].y;
          rightShape.rtx = this.analysisResult['asift']['right'][leftShape.rtidx].x;
          rightShape.rty = this.analysisResult['asift']['right'][leftShape.rtidx].y;
          rightShape.rbx = this.analysisResult['asift']['right'][leftShape.rbidx].x;
          rightShape.rby = this.analysisResult['asift']['right'][leftShape.rbidx].y;
          rightShape.lbx = this.analysisResult['asift']['right'][leftShape.lbidx].x;
          rightShape.lby = this.analysisResult['asift']['right'][leftShape.lbidx].y;

          // also configure the index values
          rightShape.ltidx = leftShape.ltidx;
          rightShape.rtidx = leftShape.rtidx;
          rightShape.rbidx = leftShape.rbidx;
          rightShape.lbidx = leftShape.lbidx;
          
          // self.canvas.add(polygonRightShape);
          
          // Draw line based on the left shape four points to draw polygon
          let rightShapePoints = [
            { x: rightShape.getScaleLtx() + this.similarImageImg['left'], y: rightShape.getScaleLty() +  this.similarImageImg['top'] },
            { x: rightShape.getScaleRtx() + this.similarImageImg['left'], y: rightShape.getScaleRty() +  this.similarImageImg['top'] },
            { x: rightShape.getScaleRbx() + this.similarImageImg['left'], y: rightShape.getScaleRby() +  this.similarImageImg['top'] },
            { x: rightShape.getScaleLbx() + this.similarImageImg['left'], y: rightShape.getScaleLby() +  this.similarImageImg['top'] }
          ];

          // Draw the right shape
          // let polygonRightShape = new fabric.Polygon(rightShapePoints, {
          //   fill: 'rgba(0,0,0,0)',
          //   stroke: 'purple',
          //   strokeWidth: 2,
          //   selectable: true
          // });

          // self.canvas.add(polygonRightShape);

          // alert this.similarFlip
          // alert(this.similarFlip);

          // Calculate rotation of the right shape by using calculateRotationDegree
          let rightShapeRotationDegree = this.calculateRotationDegree(leftShape, rightShape);

          // The angle is the reverse of the rotation degree
          let rightShapeRotationAngle = rightShapeRotationDegree;

          // Store the analyzed angle
          this.analyzedAngle = rightShapeRotationAngle;

          // console.log('rightShapeRotationDegree: ' + rightShapeRotationDegree);
          // if the rightShapeRotationAngle > 180, then we need to rotate the right shape in the opposite direction
          // if(rightShapeRotationAngle > 180){
          //   rightShapeRotationAngle = 360 - rightShapeRotationAngle;
          // }

          // console.log('rightShapeRotationDegree: ' + rightShapeRotationDegree);

          // Here to rotate the right shape

          // console.log('rightShapeRotationAngle: ' + rightShapeRotationAngle);


          if(!this.similarFlip){

            if( rightShapeRotationAngle > 0 && rightShapeRotationAngle < 360){
              for(let i=0; i < this.canvas.getObjects().length ; i++){
                if(this.canvas.getObjects()[i]['cacheKey'] == this.rightFilenameCacheKey){
                  // Rotate the right shape from the center    
                  
                  if(rightShapeRotationAngle <= 180){   
                    let startAngle = 0;     
                    let taskRotateImage = setInterval(() => {
                      this.canvas.getObjects()[i].centeredRotation = true;
                      this.canvas.getObjects()[i].rotate(startAngle);
                      this.canvas.renderAll();
    
                      // Stop the rotation when the angle is reached
                      if(startAngle == Math.round(rightShapeRotationAngle)){
                        clearInterval(taskRotateImage);
                        this.calculateResizeRatio(leftShape, rightShape, rightShapeRotationAngle);
                      }else{
                        startAngle++;
                      }
  
                    }, 5);
                  }else{   
                    let startAngle = 360;     
                    // reverse rotation
                    let taskRotateImage = setInterval(() => {
                      this.canvas.getObjects()[i].centeredRotation = true;
                      this.canvas.getObjects()[i].rotate(startAngle);
                      this.canvas.renderAll();
    
                      // Stop the rotation when the angle is reached
                      if(startAngle == Math.round(rightShapeRotationAngle)){
                        clearInterval(taskRotateImage);
                        this.calculateResizeRatio(leftShape, rightShape, rightShapeRotationAngle);
                      }else{
                        startAngle--;
                      }
  
                    }, 5);
                  }
  
                }
              }

            }else{
             this.calculateResizeRatio(leftShape, rightShape, rightShapeRotationAngle);
            }
  
          }else{
            // Draw the right shape
            
            // Draw the right shape
            let polygonRightShape = new fabric.Polygon(rightShapePoints, {
              fill: 'rgba(0,0,0,0)',
              stroke: 'purple',
              strokeWidth: 2,
              selectable: true
            });

            self.canvas.add(polygonRightShape);

            // Draw the left shape
            let polygonLeftShape = new fabric.Polygon(leftShapePoints, {
              fill: 'rgba(0,0,0,0)',
              stroke: 'blue',
              strokeWidth: 2,
              selectable: true
            });

            self.canvas.add(polygonLeftShape);

            this.explainResultSimple();

          }
    });
  }

  calculateResizeRatio(leftShape: Shape, rightShape: Shape, degree: number) {


    let self = this;

    // Based on the degree to calculate the x and y ratio of the right shape
    let rightShapeLT = this.rotatePoint(rightShape.ltx, rightShape.lty, degree);
    rightShape.ltx = rightShapeLT['x'];
    rightShape.lty = rightShapeLT['y'];

    let rightShapeRT = this.rotatePoint(rightShape.rtx, rightShape.rty, degree);
    rightShape.rtx = rightShapeRT['x'];
    rightShape.rty = rightShapeRT['y'];

    let rightShapeRB = this.rotatePoint(rightShape.rbx, rightShape.rby, degree);
    rightShape.rbx = rightShapeRB['x'];
    rightShape.rby = rightShapeRB['y'];

    let rightShapeLB = this.rotatePoint(rightShape.lbx, rightShape.lby, degree);
    rightShape.lbx = rightShapeLB['x'];
    rightShape.lby = rightShapeLB['y'];
    
    // return
    // Calculate the leftShape and rightShape differences
    let leftWidthDiff = Math.abs(leftShape.getScaleWidth() - rightShape.getScaleWidth());
    let leftHeightDiff = Math.abs(leftShape.getScaleHeight() - rightShape.getScaleHeight());

    // Console.log 
    // console.log('leftWidthDiff, leftHeightDiff: ' + leftWidthDiff + ' , ' + leftHeightDiff);

    // Calculate the rightShape Scale X,Y Ratio
    let rightShapeResizeScaleX = leftShape.getScaleWidth() / rightShape.getScaleWidth();
    let rightShapeResizeScaleY = leftShape.getScaleHeight() / rightShape.getScaleHeight();

    // update resizeScaleX of the right shape
    rightShape.resizeScaleX = rightShapeResizeScaleX;
    // update resizeScaleY of the right shape
    rightShape.resizeScaleY = rightShapeResizeScaleY;

    // analyzedSizeX and analyzedSizeY
    this.analyzedScaleX = rightShapeResizeScaleX;
    this.analyzedScaleY = rightShapeResizeScaleY;

    // Console
    console.log('rightShapeResizeScaleX, rightShapeResizeScaleY: ' + rightShapeResizeScaleX + ' , ' + rightShapeResizeScaleY);

    // Draw the polygon based on the new rightShapeResizeScaleX and rightShapeResizeScaleY
    let rightShapePointsResize = [
      { x: ( rightShape.getScaleLtx() * rightShapeResizeScaleX) + this.similarImageImg['left'], y: (rightShape.getScaleLty() * rightShapeResizeScaleY) +  this.similarImageImg['top'] },
      { x: ( rightShape.getScaleRtx() * rightShapeResizeScaleX) + this.similarImageImg['left'], y: (rightShape.getScaleRty() * rightShapeResizeScaleY) +  this.similarImageImg['top'] },
      { x: ( rightShape.getScaleRbx() * rightShapeResizeScaleX) + this.similarImageImg['left'], y: (rightShape.getScaleRby() * rightShapeResizeScaleY) +  this.similarImageImg['top'] },
      { x: ( rightShape.getScaleLbx() * rightShapeResizeScaleX) + this.similarImageImg['left'], y: (rightShape.getScaleLby() * rightShapeResizeScaleY) +  this.similarImageImg['top'] }
    ];

    // Draw the right shape
    // let polygonRightShapeResize = new fabric.Polygon(rightShapePointsResize, {
    //   fill: 'rgba(0,0,0,0)',
    //   stroke: 'purple',
    //   strokeWidth: 2,
    //   selectable: true
    // });

    // this.canvas.add(polygonRightShapeResize);

    // return;

    // Special RTX Y for later calculation
    let calculatedRightRtY = (rightShape.getScaleRty() * rightShapeResizeScaleY) +  this.similarImageImg['top'];

    // Resize the right shape
    this.similarImageImg.animate({
          scaleX: rightShape.scaleX * rightShapeResizeScaleX,
          scaleY: rightShape.scaleY * rightShapeResizeScaleY,
          top: this.similarImageImg.top,
          left: this.similarImageImg.left,
          stroke: 'red',
          strokeWidth: 2
        },{
          duration: this.speed,
          onChange: this.canvas.renderAll.bind(this.canvas),
          onComplete: function() {
            self.calculateMovingLeftShape(leftShape, rightShape);
          },
          easing: fabric.util.ease['easeInQuad']
    }); 
    
  }

  // Move the source image
  calculateMovingLeftShape(leftShape:Shape, rightShape:Shape){

    var self = this;

    let sourceImageNewLeft =  (this.canvas.width / 2) - (this.sourceImageImg['after_width']/2);

      this.canvas.getObjects().concat().forEach(function(obj) {
        
          if(obj['cacheKey'] != undefined && obj['cacheKey'].indexOf('left') != -1){
            
            obj.set('opacity', 0.5);

            // Move Horizontal Direction
            obj.animate('left', sourceImageNewLeft, {
              duration: self.speed,
              onChange: self.canvas.renderAll.bind(self.canvas),
              onComplete: function() {
                  self.sourceImageImg = obj;
                  self.sourceImageImg.left = sourceImageNewLeft; 

                  // Move Vertical Direction
                  let sourceImageNewTop =  (self.canvas.height / 2) - (self.sourceImageImg['after_height']/2);
                  obj.animate('top', sourceImageNewTop, {
                    duration: self.speed,
                    onChange: self.canvas.renderAll.bind(self.canvas),
                    onComplete: function() {
                        self.sourceImageImg = obj;
                        self.sourceImageImg.top = sourceImageNewTop;
                        
                        // Draw line based on the left shape four points to draw polygon
                        // let leftShapePoints = [
                        //   { x:  leftShape.getScaleLtx() + self.sourceImageImg['left'], y: leftShape.getScaleLty()+  self.sourceImageImg['top'] },
                        //   { x:  leftShape.getScaleRtx() + self.sourceImageImg['left'], y: leftShape.getScaleRty()+  self.sourceImageImg['top'] },
                        //   { x:  leftShape.getScaleRbx() + self.sourceImageImg['left'], y: leftShape.getScaleRby()+  self.sourceImageImg['top'] },
                        //   { x:  leftShape.getScaleLbx() + self.sourceImageImg['left'], y: leftShape.getScaleLby()+  self.sourceImageImg['top'] }
                        // ];

                        // // iterator the right shape coordinates to find the most top left and right bottom point
                        // let polygonLeftShape = new fabric.Polygon(leftShapePoints, {
                        //   fill: 'rgba(0,0,0,0)',
                        //   stroke: 'orange',
                        //   strokeWidth: 2,
                        //   selectable: true
                        // });

                        // self.canvas.add(polygonLeftShape);

                        self.calculateMovingRightShape(leftShape, rightShape);
                        
                    },
                    easing: fabric.util.ease['easeInQuad']
                  });

              },
              easing: fabric.util.ease['easeInQuad']
            });
          }
        }
      );
  }

  // Move the source image
  calculateMovingRightShape(leftShape:Shape, rightShape:Shape){

    var self = this;
    // rightShape.getScaleLtx() * rightShapeResizeScaleX)
    let similarEdgeToMostLeft = rightShape.getScaleMostLeftX() * rightShape.resizeScaleX;
    let similarImageNewLeft =  leftShape.getScaleMostLeftX() + this.sourceImageImg['left'] - similarEdgeToMostLeft;

    this.canvas.getObjects().concat().forEach(function(obj) {
      
        if(obj['cacheKey'] != undefined && obj['cacheKey'].indexOf('right') != -1){
          
          obj.set('opacity', 0.5);

          // Move Horizontal Direction
          obj.animate('left', similarImageNewLeft, {

            duration: self.speed,
            onChange: self.canvas.renderAll.bind(self.canvas),
            
            onComplete: function() {

                // console.log('leftShape.getScaleMostTopY(): ' + leftShape.getScaleMostTopY());
                // console.log('rightShape.getScaleMostTopY(): ' + rightShape.getScaleMostTopY());

                let similarImgTopEdgeToMostTop = rightShape.getScaleMostTopY() * rightShape.resizeScaleY;
                // console.log('similarImgTopEdgeToMostTop: ' + similarImgTopEdgeToMostTop);
                let sourceImgTopEdgeToMostTop = leftShape.getScaleMostTopY()+  self.sourceImageImg['top'];
                // console.log('sourceImgTopEdgeToMostTop: ' + sourceImgTopEdgeToMostTop);

                let similarImageNewTop = sourceImgTopEdgeToMostTop - similarImgTopEdgeToMostTop;

                // console.log('leftShapeRtxY: ' + leftShapeRtxY);
                // console.log('calculatedRightRtY: ' + calculatedRightRtY);

                //  rightShape.getScaleLtx() * rightShapeResizeScaleX) + this.similarImageImg['left']

                obj.animate('top', similarImageNewTop, {
                  duration: self.speed,
                  onChange: self.canvas.renderAll.bind(self.canvas),
                  onComplete: function() {
                      self.similarImageImg = obj;
                      self.similarImageImg.top = similarImageNewTop;
                      self.blocked = false;
                      
                      // Adjust the display
                      self.onChangeInvertColor(self.rightFilenameCacheKey);
                      self.adjustmentSimilarSaturation = -50;
                      self.onChangeFilterSaturation(self.rightFilenameCacheKey);
                      self.adjustmentSimilarContrast = 25;
                      self.onChangeFilterContrast(self.rightFilenameCacheKey);
                      self.adjustmentSimilarTransparency = 50;
                      self.adjustmentSourceTransparency = 50;
                      self.onChangeFilterTransparency(self.rightFilenameCacheKey);
                      self.onChangeFilterTransparency(self.leftFilenameCacheKey);

                      // last step to 
                      self.adjustmentSimilarInvert = true;

                      // Explain the result
                      self.explainResult(leftShape, rightShape);

                  },
                  easing: fabric.util.ease['easeInQuad']
                });

            },
            easing: fabric.util.ease['easeInQuad']
          });
        }
      }
    );

  }

  // Calculate the rotation angle based on two Shapes
  calculateRotationDegree(leftShape: Shape, rightShape: Shape): number {

    function getAngle(x1: number, y1: number, x2: number, y2: number): number {
        return Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI);
    }

    const leftAngle = getAngle(leftShape.ltx, leftShape.lty, leftShape.rbx, leftShape.rby);
    const rightAngle = getAngle(rightShape.ltx, rightShape.lty, rightShape.rbx, rightShape.rby);
    // const leftAngle = getAngle(leftShape.ltx, leftShape.lty, leftShape.lbx, leftShape.lby);
    // const rightAngle = getAngle(rightShape.ltx, rightShape.lty, rightShape.lbx, rightShape.lby);

    let rotationDegree = leftAngle - rightAngle;

    // Normalize the angle to the range [0, 360)
    if (rotationDegree < 0) {
        rotationDegree += 360;
    }

    if (rotationDegree == 360) {
        rotationDegree = 0;
    }

    // Ceil the rotation degree
    rotationDegree = Math.ceil(rotationDegree);

    return rotationDegree;
}

  updateTransparencyForBothImages(){
    
    var self = this;
    self.adjustmentSimilarTransparency = 50;
    self.adjustmentSourceTransparency = 50;
    self.onChangeFilterTransparency(self.rightFilenameCacheKey);
    self.onChangeFilterTransparency(self.leftFilenameCacheKey);
  }

  explainResult(leftShape:Shape, rightShape:Shape){
    
    let explainText = '';
    let imageSizeDiff = (this.sourceImageImg['width'] * this.sourceImageImg['height']) / 
                                  (this.similarImageImg['width'] * this.similarImageImg['height']);

    if(imageSizeDiff == 1){
      explainText += 'Size Difference: Left  =  Right' ;  
    }else{
      if(imageSizeDiff > 1){
        var explainSizeValue = ((imageSizeDiff-1)*100).toFixed(2) + '';
            explainText += 'Size Difference: Left  >  Right  ' + explainSizeValue + '%' ;   
      }else{ 
        var explainSizeValue = ((1-imageSizeDiff)*100).toFixed(2) + '';
            explainText += 'Size Difference: Left  <  Right  ' + explainSizeValue + '%' ;   
      }
    }
 
    // if(this.analyzedFlip){
    //   if(explainText.length > 0){
    //     explainText += '  ;  ';
    //   }
    //   explainText += 'Flip: True' ; 
    // }
 
    if(this.analyzedAngle > 0 && this.analyzedAngle < 360){
         if(explainText.length > 0){
           explainText += '  ;  ';
         }
         let value = this.analyzedAngle;
         let valueConverted = value.toFixed(2);

         explainText += 'Angle ∡ : ' + valueConverted +'°';
    }else{
      if(explainText.length > 0){
        explainText += '  ;  ';
      }
      explainText += 'Angle ∡ : 0°';
    }

    this.showText(explainText, 10);

    // let leftPoints = [
    //   { x: leftShape.getScaleLtx() + this.sourceImageImg['left'], y: leftShape.getScaleLty() +  this.sourceImageImg['top'] },
    //   { x: leftShape.getScaleRtx() + this.sourceImageImg['left'], y: leftShape.getScaleRty() +  this.sourceImageImg['top'] },
    //   { x: leftShape.getScaleRbx() + this.sourceImageImg['left'], y: leftShape.getScaleRby() +  this.sourceImageImg['top'] },
    //   { x: leftShape.getScaleLbx() + this.sourceImageImg['left'], y: leftShape.getScaleLby() +  this.sourceImageImg['top'] }
    // ];

    // let leftDuplicatedAreaPercentage = this.calculatePolygonToImageRatio(this.sourceImageImg['original_width'], this.sourceImageImg['original_height'], leftPoints);

    // let rightPoints = [
    //   { x: rightShape.getScaleLtx() + this.similarImageImg['left'], y: rightShape.getScaleLty() +  this.similarImageImg['top'] },
    //   { x: rightShape.getScaleRtx() + this.similarImageImg['left'], y: rightShape.getScaleRty() +  this.similarImageImg['top'] },
    //   { x: rightShape.getScaleRbx() + this.similarImageImg['left'], y: rightShape.getScaleRby() +  this.similarImageImg['top'] },
    //   { x: rightShape.getScaleLbx() + this.similarImageImg['left'], y: rightShape.getScaleLby() +  this.similarImageImg['top'] }
    // ];

    // let rightDuplicatedAreaPercentage = this.calculatePolygonToImageRatio(this.similarImageImg['original_width'], this.similarImageImg['original_height'], rightPoints);
    let shapeLeftDuplicatedAreaRatio = 0;
    let shapeRightDuplicatedAreaRatio = 0;

    if(this.analysisResult['asift'] != undefined && this.analysisResult['asift']['transformFactors'] != undefined){
      shapeLeftDuplicatedAreaRatio = this.analysisResult['asift']['transformFactors']['shapeLeftDuplicatedAreaRatio'];
      shapeRightDuplicatedAreaRatio = this.analysisResult['asift']['transformFactors']['shapeRightDuplicatedAreaRatio'];
    }

    // Display The Matched Region Informaiton
    if(shapeLeftDuplicatedAreaRatio > 0 || shapeRightDuplicatedAreaRatio > 0){
      var matchedRegionCoordinate = 'Left Duplicated Area: ' + shapeLeftDuplicatedAreaRatio + '%';
          matchedRegionCoordinate += '  ;  ';
          matchedRegionCoordinate += 'Right Duplicated Area: ' + shapeRightDuplicatedAreaRatio + '%';

      this.showText(matchedRegionCoordinate, 40);
    }

  }

  
  explainResultSimple(){
    
    let explainText = '';
    // let imageSizeDiff = (this.sourceImageImg['width'] * this.sourceImageImg['height']) / 
    //                               (this.similarImageImg['width'] * this.similarImageImg['height']);

    // if(imageSizeDiff == 1){
    //   explainText += 'Size Difference: Left  =  Right' ;  
    // }else{
    //   if(imageSizeDiff > 1){
    //     var explainSizeValue = ((imageSizeDiff-1)*100).toFixed(2) + '';
    //         explainText += 'Size Difference: Left  >  Right  ' + explainSizeValue + '%' ;   
    //   }else{ 
    //     var explainSizeValue = ((1-imageSizeDiff)*100).toFixed(2) + '';
    //         explainText += 'Size Difference: Left  <  Right  ' + explainSizeValue + '%' ;   
    //   }
    // }
 
    if(this.analyzedFlip){
      if(explainText.length > 0){
        explainText += '  ;  ';
      }
      explainText += 'Flip Detection: True' ; 
    }
 
    // if(this.analyzedAngle > 0 && this.analyzedAngle < 360){
    //      if(explainText.length > 0){
    //        explainText += '  ;  ';
    //      }
    //      let value = this.analyzedAngle;
    //      let valueConverted = value.toFixed(2);

    //      explainText += 'Angle ∡ : ' + valueConverted +'°';
    // }else{
    //   if(explainText.length > 0){
    //     explainText += '  ;  ';
    //   }
    //   explainText += 'Angle ∡ : 0°';
    // }

    this.showText(explainText, 10);

    // let leftPoints = [
    //   { x: leftShape.getScaleLtx() + this.sourceImageImg['left'], y: leftShape.getScaleLty() +  this.sourceImageImg['top'] },
    //   { x: leftShape.getScaleRtx() + this.sourceImageImg['left'], y: leftShape.getScaleRty() +  this.sourceImageImg['top'] },
    //   { x: leftShape.getScaleRbx() + this.sourceImageImg['left'], y: leftShape.getScaleRby() +  this.sourceImageImg['top'] },
    //   { x: leftShape.getScaleLbx() + this.sourceImageImg['left'], y: leftShape.getScaleLby() +  this.sourceImageImg['top'] }
    // ];

    // let leftDuplicatedAreaPercentage = this.calculatePolygonToImageRatio(this.sourceImageImg['original_width'], this.sourceImageImg['original_height'], leftPoints);

    // let rightPoints = [
    //   { x: rightShape.getScaleLtx() + this.similarImageImg['left'], y: rightShape.getScaleLty() +  this.similarImageImg['top'] },
    //   { x: rightShape.getScaleRtx() + this.similarImageImg['left'], y: rightShape.getScaleRty() +  this.similarImageImg['top'] },
    //   { x: rightShape.getScaleRbx() + this.similarImageImg['left'], y: rightShape.getScaleRby() +  this.similarImageImg['top'] },
    //   { x: rightShape.getScaleLbx() + this.similarImageImg['left'], y: rightShape.getScaleLby() +  this.similarImageImg['top'] }
    // ];

    // let rightDuplicatedAreaPercentage = this.calculatePolygonToImageRatio(this.similarImageImg['original_width'], this.similarImageImg['original_height'], rightPoints);
    let shapeLeftDuplicatedAreaRatio = 0;
    let shapeRightDuplicatedAreaRatio = 0;

    if(this.analysisResult['asift'] != undefined && this.analysisResult['asift']['transformFactors'] != undefined){
      shapeLeftDuplicatedAreaRatio = this.analysisResult['asift']['transformFactors']['shapeLeftDuplicatedAreaRatio'];
      shapeRightDuplicatedAreaRatio = this.analysisResult['asift']['transformFactors']['shapeRightDuplicatedAreaRatio'];
    }

    // Display The Matched Region Informaiton
    if(shapeLeftDuplicatedAreaRatio > 0 || shapeRightDuplicatedAreaRatio > 0){
      var matchedRegionCoordinate = 'Left Duplicated Area: ' + shapeLeftDuplicatedAreaRatio + '%';
          matchedRegionCoordinate += '  ;  ';
          matchedRegionCoordinate += 'Right Duplicated Area: ' + shapeRightDuplicatedAreaRatio + '%';

      this.showText(matchedRegionCoordinate, 40);
    }

  }


  // Here to calculate the computed rotate x and y based on the center
  rotatePoint(x:number, y:number, angle:number){

    angle = angle % 360;

    let cx = 0;
    let cy = 0;

    let centerPoint = {
      'x': Math.round(cx),
      'y': Math.round(cy)
    }

    let oldPoint = {
      'x': Math.round(x),
      'y': Math.round(y)
    }

    let newPoint = {
      'x': 0,
      'y': 0
    }

    let angleRad = this.toRad(angle);

    // x' = xc + (x - xc)cos(angle) - (y - yc)sin(angle) 
    newPoint.x = +centerPoint.x + (oldPoint.x - centerPoint.x) * Math.cos(angleRad) - (oldPoint.y - centerPoint.y) * Math.sin(angleRad);
    // y' = yc + (x - xc)sin(angle) + (y - yc)cos(angle) 
    newPoint.y = +centerPoint.y + (oldPoint.x - centerPoint.x) * Math.sin(angleRad) + (oldPoint.y - centerPoint.y) * Math.cos(angleRad);

    // newPoint.x = newPoint.x;
    // newPoint.y = newPoint.y;

    return newPoint;

  }

  toRad(value:number){
    return (value) * Math.PI / 180;
  }

  calcAngle(x1, y1, x2, y2) {
    let x = Math.abs(x1-x2);
    let y = Math.abs(y1-y2);
    let z = Math.sqrt(x * x + y * y);

    let angle = Math.round(Math.asin(y /z) / Math.PI * 180)
    return angle;
  }
  
  calcDistance(x1, y1, x2, y2) {
    return Math.hypot(x2-x1, y2-y1);
  }
  
  // Add Marc
  drawByPoint(color:string, note:string, x:number, y:number){
    // return;
    var spot = new fabric.Text(note+' ('+Math.round(x)+','+Math.round(y)+')',{
      left: x,
      top: y,
      fill: color,
      fontSize: 16
    });
    this.canvas.add(spot);
    
  }
  
  // Add Marc
  draw(color:string, note:string, x:number, left:number, y:number, top:number, enable:boolean){
    
    // return;
    if(enable){
      var spot = new fabric.Text(note+' ('+Math.round(x)+','+Math.round(y)+')',{
        left: left+x,
        top: top+y,
        fill: color,
        fontSize: 16
      });
      this.canvas.add(spot);
    }
    
  }

  drawRect(color:string, x:number, y:number, w:number, h:number, enable: boolean){
    // return;
    if(enable){
      var rect = new fabric.Rect({
        left: x,
        top: y,
        width: w, 
        height: h,
        fill: '#00000000',
        stroke: color,
        strokeWidth: 3
      });
      this.canvas.add(rect);  
    }
  }

  enterText:string = '';
  enterTextColor:string = 'black';
  enterTextDialog:boolean = false;
  openEnterTextDialog(color:string){
    this.enterTextColor = color;
    this.enterText = '';
    this.enterTextDialog = true;
  }
  
  // Add Text to the canvas
  drawText(note:string){
    var spot = new fabric.Text(note,{
      left: this.canvas.width/2,
      top: 65,
      fill: this.enterTextColor,
      fontSize: 20
    });
    this.canvas.add(spot);
    
  }

  confirmEnterText(){
    this.enterTextDialog = false;
    if(this.enterText.trim().length > 0){
      this.drawText(this.enterText.trim());
    }
  }
  
  addSquareToCanvas(color:string){

    var leftPosition = Math.round((this.canvas.width / 2) - (250/2));
    var rect = new fabric.Rect({
      left: leftPosition,
      top: 100,
      width: 250,
      height: 250,
      fill: '#00000000',
      stroke: color,
      strokeWidth: 3
    });
    this.canvas.add(rect);
  }
  
  addCircleToCanvas(color:string){
    // create an circle which in red color and size is 250*250 then add to the canvas
    
    var leftPoistion = Math.round((this.canvas.width / 2) - (250/2)); 

    var circle = new fabric.Circle({
      left: leftPoistion,
      top: 100,
      radius: 125,
      fill: '#00000000',
      stroke: color,
      strokeWidth: 3
    });
    this.canvas.add(circle);

  }

  // Calculate distance between two points
  calculateDistance(x1, y1, x2, y2) {
    let x = Math.abs(x1-x2);
    let y = Math.abs(y1-y2);
    return Math.sqrt(x * x + y * y);
  }

  // Calculate the polygon area size portion in the image
  calculatePolygonToImageRatio(
    width: number,
    height: number,
    vertices: { x: number, y: number }[]
  ): number {
    // Function to calculate the area of the polygon using the Shoelace formula
    function calculatePolygonArea(vertices: { x: number, y: number }[]): number {
      const n = vertices.length;
      let area = 0;
  
      for (let i = 0; i < n; i++) {
        const { x: x1, y: y1 } = vertices[i];
        const { x: x2, y: y2 } = vertices[(i + 1) % n];
        area += (x1 * y2 - y1 * x2);
      }
  
      return Math.abs(area) / 2;
    }
  
    // Calculate the area of the image
    const imageArea = width * height;
  
    // Calculate the area of the polygon
    const polygonArea = calculatePolygonArea(vertices);
  
    // Calculate the ratio
    const ratio = polygonArea / imageArea;

    // Convert the ratio to percentage
    let ratioPercentag = ratio * 100;
  
    // Return the ratio rounded to two decimal places
    return parseFloat(ratioPercentag.toFixed(2));
  }
  
  // // Example usage:
  // const imageWidth = 800;
  // const imageHeight = 600;
  // const polygonVertices = [
  //   { x: 100, y: 100 },
  //   { x: 200, y: 100 },
  //   { x: 200, y: 200 },
  //   { x: 100, y: 200 }
  // ];
  
  // const ratio = calculatePolygonToImageRatio(imageWidth, imageHeight, polygonVertices);
  // console.log(`Ratio: ${ratio}`);
  

}
