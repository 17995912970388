<p-toolbar>
    <div class="p-toolbar-group-left">
        <div (click)="close()" class="p-d-flex viewer-back">
            <div class="viewer-back-icon"><i class="pi pi-times" title="{{'within-viewer.back' | translate}}"></i></div>
            <div class="viewer-back-text">{{'within-viewer.close' | translate}}</div>
        </div>
    </div>
    <div class="p-toolbar-group-center">
    </div> 

    <div *ngIf="caseSummary != undefined && caseSummary['size'] > 0"  class="p-d-flex p-jc-between  p-toolbar-group-right">
        <div></div>
        <div class="p-d-flex">
            <div>
                <i class="pi pi-briefcase duplication-case-module"></i>
            </div>
            <div class="duplication-case-highlight">
                <span class="duplication-case-highlight-box">{{caseSummary['size']}}</span>
            </div>
        </div>
    </div> 
</p-toolbar>
<section id="maincontent">
    <div class="p-d-flex viewer-content-section " id="left">
        <div class="viewer-content-section-left " *ngIf="sourceImageFilterPanel == true && leftPanel == true">
            <div *ngIf="asiftPanel == false" class="viewer-content-section-subtitle-item p-mt-2 p-mb-3">{{'within-viewer.panel-sensitivity' | translate}}
                <label *ngIf="adjustmentThreshold >= 8">{{'within-viewer.panel-sensitivity-very-low' | translate}}</label>
                <label *ngIf="adjustmentThreshold < 8 && adjustmentThreshold >= 6">{{'within-viewer.panel-sensitivity-low' | translate}}</label>
                <label *ngIf="adjustmentThreshold < 6 && adjustmentThreshold >= 4">{{'within-viewer.panel-sensitivity-medium' | translate}}</label>
                <label *ngIf="adjustmentThreshold < 4 && adjustmentThreshold >= 2">{{'within-viewer.panel-sensitivity-high' | translate}}</label>
                <label *ngIf="adjustmentThreshold < 2">{{'within-viewer.panel-sensitivity-very-high' | translate}}</label>
            </div>
            <div *ngIf="asiftPanel == true && this.countValidStep > 1" class="viewer-content-section-subtitle-item p-mt-2 p-mb-3">{{'within-viewer.panel-sensitivity' | translate}}
                <!-- <label *ngIf="adjustmentThreshold >= 5">{{'within-viewer.panel-sensitivity-very-low' | translate}}</label> -->
                <label *ngIf="adjustmentThreshold == 2">{{'within-viewer.panel-sensitivity-low' | translate}} </label>
                <label *ngIf="adjustmentThreshold == 3">{{'within-viewer.panel-sensitivity-medium' | translate}} </label>
                <label *ngIf="adjustmentThreshold > 3">{{'within-viewer.panel-sensitivity-high' | translate}} </label>
                <!-- <label *ngIf="adjustmentThreshold < 2">{{'within-viewer.panel-sensitivity-very-high' | translate}}</label> -->
            </div>
            <div *ngIf="asiftPanel == false" class="viewer-content-section-adjustment-box p-ml-2 p-mt-2 p-mb-3">
                <p-slider (onSlideEnd)="onChangeThreshold()" [style]="{width: '130px', height: '5px'}" [(ngModel)]="adjustmentThreshold" [min]="0" [max]="9"></p-slider>
            </div>
            <div *ngIf="asiftPanel == true  && this.countValidStep > 1" class="viewer-content-section-adjustment-box p-ml-2 p-mt-2 p-mb-3">
                <p-slider (onSlideEnd)="onChangeThreshold()" [style]="{width: '130px', height: '5px'}" [(ngModel)]="adjustmentThreshold" [min]="2" [max]="5"></p-slider>
            </div>
            <div *ngIf="withinCurrentDataSet.length > 0">
                <div class="viewer-content-section-subtitle-item p-mt-5 p-mb-3">{{'within-viewer.panel-result-list' | translate}}</div>
                <div class="viewer-content-section-adjustment-box p-ml-2 p-mt-2">
                    
                        <div *ngFor="let category of withinCurrentDataSet; index as i" class="p-field-checkbox">                   
                            <p-checkbox (onChange)="onChangeWithinCurrentItem()" name="within" value="category" [value]="category"  [(ngModel)]="withinCurrentSelected" [inputId]="category.key" ></p-checkbox>
                            <label *ngFor="let item of category.size"  [ngStyle]="setWithinLabelColor(i)" class="p-pl-1" >&nbsp;</label>
                        </div>
                </div>
            </div>
            
            <div *ngIf="withinCurrentDataSet.length == 0" class="p-mt-3 p-mb-3 viewer-internet-dialog-info-item-not-found">
                {{'within-viewer.no-result' | translate}}
             </div>
                
        </div>

        <div class="viewer-content-section-center" id="viewercontent">
            <div class="p-d-flex p-jc-between">
                <div class="viewer-content-section-center-left" (click)="switchLeft()">
                    <i *ngIf="leftPanel == true" class="pi pi-chevron-left" title="Close the Panel"></i>
                    <i *ngIf="leftPanel == false" class="pi pi-chevron-right" title="Open the Panel"></i>
                </div>
                <div class="p-d-flex p-flex-column viewer-content-section-center-canvas">
                    <div class="viewer-content-section-center-info p-d-flex p-jc-center">
                        <div>
                            <span class="viewer-content-section-center-info-item" (click)="resetViewerDisplay()" title="{{'within-viewer.control-reset-display' | translate}}">
                                <i class="pi pi-refresh viewer-icon-hover"> </i>
                            </span>
                        </div>
                        <div class="p-ml-2 p-d-flex">
                            <!-- <span (click)="toggleDrawingMode()" class="p-ml-3 viewer-content-section-center-info-item" [ngClass]="{'viewer-drawing-mode-selected': isDrawingMode == true}" title="{{'within-viewer.control-drawing' | translate}}">
                                <i class="pi pi-pencil viewer-icon-hover"> </i>
                            </span>
                            <span (click)="openEnterTextDialog()" class="p-ml-3 viewer-content-section-center-info-item" title="{{'duplication-viewer.control-comment' | translate}}">
                                <i class="pi pi-comments viewer-icon-hover"> </i>
                            </span> -->
                            
                            <span class="viewer-content-section-seperator">|</span>
                            <span *ngIf="isDrawingMode == true" (click)="leaveDrawingMode(); " class="p-ml-3 viewer-content-section-center-info-item viewer-drawing-mode-selected"  title="{{'duplication-viewer.control-drawing' | translate}}">
                                <i class="pi pi-pencil viewer-icon-hover"> </i>
                            </span>
                            <span *ngIf="isDrawingMode == false" (click)="drawing.toggle($event); " class="p-ml-3 viewer-content-section-center-info-item"  title="{{'duplication-viewer.control-drawing' | translate}}">
                                <i class="pi pi-pencil viewer-icon-hover"> </i>
                                <p-overlayPanel #drawing>
                                    <div class="p-d-flex p-flex-column">
                                        <i (click)="toggleDrawingMode('black')" class="pi pi-palette color-picker-black "></i>
                                        <i (click)="toggleDrawingMode('blue')" class="pi pi-palette color-picker-blue "></i>
                                        <i (click)="toggleDrawingMode('red')" class="pi pi-palette color-picker-red "></i>
                                        <i (click)="toggleDrawingMode('green')" class="pi pi-palette color-picker-green "></i>
                                        <i (click)="toggleDrawingMode('orange')" class="pi pi-palette color-picker-orange "></i>
                                        <i (click)="toggleDrawingMode('magenta')" class="pi pi-palette color-picker-magenta"></i>
                                    </div>
                                </p-overlayPanel>
                            </span>
                            <span (click)="circle.toggle($event)" class="p-ml-3 viewer-content-section-center-info-item" title="{{'duplication-viewer.control-circle' | translate}}">
                                <div class="shape-circle">&nbsp;</div>
                                <p-overlayPanel #circle>
                                    <div class="p-d-flex p-flex-column">
                                        <i (click)="addCircleToCanvas('black')" class="pi pi-palette color-picker-black "></i>
                                        <i (click)="addCircleToCanvas('blue')" class="pi pi-palette color-picker-blue "></i>
                                        <i (click)="addCircleToCanvas('red')" class="pi pi-palette color-picker-red "></i>
                                        <i (click)="addCircleToCanvas('green')" class="pi pi-palette color-picker-green "></i>
                                        <i (click)="addCircleToCanvas('orange')" class="pi pi-palette color-picker-orange "></i>
                                        <i (click)="addCircleToCanvas('magenta')" class="pi pi-palette color-picker-magenta"></i>
                                    </div>
                                </p-overlayPanel>
                            </span> 
                            <span (click)="square.toggle($event)"  class="p-ml-3 viewer-content-section-center-info-item" title="{{'duplication-viewer.control-square' | translate}}">
                                <div class="shape-square">&nbsp;</div>
                                <p-overlayPanel #square>
                                    <div class="p-d-flex p-flex-column">
                                        <i (click)="addSquareToCanvas('black')" class="pi pi-palette color-picker-black "></i>
                                        <i (click)="addSquareToCanvas('blue')" class="pi pi-palette color-picker-blue "></i>
                                        <i (click)="addSquareToCanvas('red')" class="pi pi-palette color-picker-red "></i>
                                        <i (click)="addSquareToCanvas('green')" class="pi pi-palette color-picker-green "></i>
                                        <i (click)="addSquareToCanvas('orange')" class="pi pi-palette color-picker-orange "></i>
                                        <i (click)="addSquareToCanvas('magenta')" class="pi pi-palette color-picker-magenta"></i>
                                    </div>
                                </p-overlayPanel>
                            </span>
                            <span (click)="textsection.toggle($event)" class="p-ml-3 viewer-content-section-center-info-item" title="{{'duplication-viewer.control-comment' | translate}}">
                                <i class="pi pi-comments viewer-icon-hover"> </i>
                                <p-overlayPanel #textsection>
                                    <div class="p-d-flex p-flex-column">
                                        <i (click)="openEnterTextDialog('black')" class="pi pi-palette color-picker-black "></i>
                                        <i (click)="openEnterTextDialog('blue')" class="pi pi-palette color-picker-blue "></i>
                                        <i (click)="openEnterTextDialog('red')" class="pi pi-palette color-picker-red "></i>
                                        <i (click)="openEnterTextDialog('green')" class="pi pi-palette color-picker-green "></i>
                                        <i (click)="openEnterTextDialog('orange')" class="pi pi-palette color-picker-orange "></i>
                                        <i (click)="openEnterTextDialog('magenta')" class="pi pi-palette color-picker-magenta"></i>
                                    </div>
                                </p-overlayPanel>
                            </span>
                            <span class="viewer-content-section-seperator">|</span>

                            <span (click)="confirmStoreCase()" *ngIf="sourceImageImg != undefined " class="p-ml-3 viewer-content-section-center-info-item" title="{{'within-viewer.control-save-result' | translate}}">
                                <i class="pi pi-briefcase viewer-icon-hover"> </i>
                            </span>
                        </div>
                    </div>

                    <div class="p-d-flex p-jc-center">
                        <canvas id="viewer" #viewer></canvas>
                    </div>

                    <div class="p-d-flex p-jc-between">
                        
                        <div></div>
                        <div *ngIf="sourceImageImg != undefined " class="viewer-icon-bottom-icon-left">
                            <span (click)="loadAdditionalInformation('source')" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="{{'within-viewer.control-metadata-info' | translate}}">
                                <i class="pi pi-info-circle viewer-icon-hover"> </i>
                            </span>
                            <!-- <span (click)="submitWebImage('source')" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="Find similar image on the Internet">
                                <fa-icon [icon]="faGlobeAmericas" class="viewer-icon-hover-fontawesome-bottom"></fa-icon>
                            </span> --> 
                            <!-- <span (click)="flipImage('source')" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="{{'within-viewer.control-flip-image' | translate}}">
                                <fa-icon [icon]="faExchangeAlt" class="viewer-icon-hover-fontawesome-bottom"></fa-icon>
                            </span>
                            <span (click)="turnRight('source')" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="{{'within-viewer.control-turn-right' | translate}}">
                                <i class="pi pi-reply viewer-icon-hover"> </i>
                            </span> -->
                            <span (click)="ZoomIn(leftFilenameCacheKey)" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="{{'within-viewer.control-zoom-in' | translate}}">
                                <i class="pi pi-search-plus viewer-icon-hover"> </i>
                            </span>
                            <span (click)="ZoomOut(leftFilenameCacheKey)" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="{{'within-viewer.control-zoom-out' | translate}}">
                                <i class="pi pi-search-minus viewer-icon-hover"> </i>
                            </span>
                            <!-- <span (click)="reloadImage('source')" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="{{'within-viewer.control-reload' | translate}}">
                                <fa-icon [icon]="faSync" class="viewer-icon-hover-fontawesome-bottom"></fa-icon>
                            </span> -->
                        </div>
                        
                        <div></div>
                        
                    </div>


                </div>
            </div>
        </div>

    </div>

</section>

<p-blockUI [blocked]="blocked" [baseZIndex]="20001">
    <p-progressSpinner [style]="{width: '150px', height: '150px'}" styleClass="custom-spinner" strokeWidth="2" animationDuration=".5s"></p-progressSpinner>
</p-blockUI>

<p-dialog  [modal]="true" *ngIf="record" header="Metadata" [(visible)]="addtionalInformationDialog" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <div *ngIf="addtionalInformationDisplay" class="p-col viewer-info-content-section">
        <div *ngIf="addtionalInformationDisplay['image']" class="viewer-info-content-section-record-figure">{{'within-viewer.control-metadata-figure' | translate}} {{addtionalInformationDisplay['image']['description']}}</div>
        <div *ngIf="addtionalInformationDisplay['record']" class="viewer-info-content-section-record-title">{{addtionalInformationDisplay['record']['title']}}</div>
        <div *ngIf="addtionalInformationDisplay['record']" (click)="linkViaDoi(addtionalInformationDisplay['record']['doi'])" class="viewer-info-content-section-record-doi">{{addtionalInformationDisplay['record']['doi']}}</div>
        <div class="viewer-info-content-section-record-resolution p-mt-2">{{'within-viewer.control-metadata-resolution' | translate}} {{addtionalInformationDisplay['width']}} * {{addtionalInformationDisplay['height']}}</div>
    </div>
</p-dialog>

<p-dialog  [modal]="true" *ngIf="record" header="Internet Search Result" [(visible)]="searchResultDialog" [style]="{width: '60vw'}" [baseZIndex]="10000">

    <div *ngIf="internetImageImgWebTemp" class="viewer-internet-dialog-section">
        <div *ngIf="internetImageImgWebTemp" class="p-d-inline-flex p-mt-3">
            <div *ngIf="internetImageImgWebTemp['type'] == 'source'" class="viewer-internet-dialog-img">
                <img src="{{baseUrl}}/image/{{id}}/{{leftFilename}}?width=150" />
            </div>
            <div class="viewer-internet-dialog-info-box">
                <div *ngIf="internetImageImgWebTemp['data'][0]['entity'].length > 0" class="p-ml-3 p-mt-1 viewer-internet-dialog-info-result-section-title">Detected Entities</div>
                <div class="p-grid p-ml-2 p-mt-1">
                    <div class="viewer-internet-dialog-info-item-entity" *ngFor="let item of internetImageImgWebTemp['data'][0]['entity']">
                        <span title="{{item['description']}} : {{item['score']}}">{{item['description']}}</span>
                    </div>
                </div>
            </div>
            <div>
                <!-- <span (click)="confirmStoreInternetCase()" class="viewer-content-section-center-info-item" title="Save the internet search result">
                    <i class="pi pi-briefcase viewer-internet-dialog-store-icon"> </i>
                </span> -->
            </div>
        </div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['page'].length > 0" class="p-mt-3 viewer-internet-dialog-info-result-section-title">Page Contains Images</div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['page'].length > 0" class="p-mt-1 viewer-internet-dialog-info-result-section">
            <div class="p-d-flex p-flex-column" *ngFor="let item of internetImageImgWebTemp['data'][0]['page'] ; index as i ">
                <div class="p-d-flex">
                    <div class="viewer-internet-dialog-info-item-no">{{(i+1)}}</div>
                    <div class="viewer-internet-dialog-info-item-text">
                        <a href="{{item['url']}}" target="_blank">{{item['title']}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['full'].length > 0" class="p-mt-3 viewer-internet-dialog-info-result-section-title">Full Matched</div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['full'].length > 0" class="p-grid p-mt-1 viewer-internet-dialog-info-result-section">
            <div class="p-d-flex" *ngFor="let item of internetImageImgWebTemp['data'][0]['full'] ; index as i ">
                <div class="p-d-flex p-flex-column viewer-internet-dialog-info-item-img">
                    <img src="{{item['url']}}" title="{{item['url']}}" />
                    <div title="{{item['url']}}">
                        <a href="{{item['url']}}" target="_blank">{{item['url'].substring(0, item['url'].indexOf("/", 10)).replace('https://','').replace('http://','')}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['partially'].length > 0" class="p-mt-3 viewer-internet-dialog-info-result-section-title">Partially Matched</div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['partially'].length > 0" class="p-grid p-mt-1 viewer-internet-dialog-info-result-section">
            <div class="p-d-flex" *ngFor="let item of internetImageImgWebTemp['data'][0]['partially'] ; index as i ">
                <div class="p-d-flex p-flex-column viewer-internet-dialog-info-item-img">
                    <img src="{{item['url']}}" title="{{item['url']}}" />
                    <div title="{{item['url']}}">
                        <a href="{{item['url']}}" target="_blank">{{item['url'].substring(0, item['url'].indexOf("/", 10)).replace('https://','').replace('http://','')}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-mt-3 p-mb-3 viewer-internet-dialog-info-item-not-found" *ngIf="internetImageImgWebTemp['data'][0]['partially'].length == 0 && internetImageImgWebTemp['data'][0]['full'].length == 0 && internetImageImgWebTemp['data'][0]['page'].length == 0">
            {{'within-viewer.no-result' | translate}}
        </div>
    </div>

</p-dialog>


<p-dialog  [(visible)]="enterTextDialog" [style]="{width: '80vw'}">
    <p>
        <input type="text" [autofocus]="true" class="p-inputtext-lg enter-text-style" placeholder="Enter text"  pInputText [(ngModel)]="enterText"> 
    </p>
    <p>
        <p-radioButton (click)="enterTextColor = 'black'" id='black' [value]="'black'" [(ngModel)]="enterTextColor"></p-radioButton>
        <label for="black" style="color:black; font-weight: bold; margin-left: 15px; margin-right: 30px;">BLACK</label>
        <p-radioButton (click)="enterTextColor = 'blue'" id='blue' [value]="'blue'" [(ngModel)]="enterTextColor"></p-radioButton>
        <label for="blue" style="color:blue; font-weight: bold; margin-left: 15px; margin-right: 30px;">BLUE</label>
        <p-radioButton (click)="enterTextColor = 'red'" id='red' [value]="'red'" [(ngModel)]="enterTextColor"></p-radioButton>
        <label for="red" style="color:red; font-weight: bold; margin-left: 15px; margin-right: 30px;">RED</label>
        <p-radioButton (click)="enterTextColor = 'green'" id='green' [value]="'green'" [(ngModel)]="enterTextColor"></p-radioButton>
        <label for="green" style="color:green; font-weight: bold; margin-left: 15px; margin-right: 30px;">GREEN</label>
        <p-radioButton (click)="enterTextColor = 'orange'" id='orange' [value]="'orange'" [(ngModel)]="enterTextColor"></p-radioButton>
        <label for="orange" style="color:orange; font-weight: bold; margin-left: 15px; margin-right: 30px;">ORANGE</label>
        <p-radioButton (click)="enterTextColor = 'magenta'" id='magenta' [value]="'magenta'" [(ngModel)]="enterTextColor"></p-radioButton>
        <label for="magenta" style="color:magenta; font-weight: bold; margin-left: 15px; margin-right: 30px;">MAGENTA</label>
    </p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="enterTextDialog=false" label="Cancel" styleClass="p-button-text"></p-button>
        <p-button icon="pi pi-check" (click)="confirmEnterText();" label="Confirm" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>

<p-toast [baseZIndex]="50000"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="30000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>