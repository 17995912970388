<p-toolbar>
    <div class="p-toolbar-group-left">
        <div (click)="close()" class="p-d-flex viewer-back">
            <div class="viewer-back-icon"><i class="pi pi-times" title="{{'manipulation-viewer.back' | translate}}"></i></div>
            <div class="viewer-back-text">{{'manipulation-viewer.close' | translate}}</div>
        </div>
    </div>
    <div class="p-toolbar-group-center">
        <!-- <span (click)="switchPreviousOriginalImage()" class="p-toolbar-group-center-previous"> <i class="pi pi-caret-left"></i></span>
        <span class="p-toolbar-group-center-text">{{(this.currentPosition + 1)}} / {{this.currentOriginalCache.length}}</span>
        <span (click)="switchNextOriginalImage()" class="p-toolbar-group-center-next"><i class="pi pi-caret-right"></i></span> -->
    </div> 
 
    <div *ngIf="caseSummary != undefined && caseSummary['size'] > 0"  class="p-d-flex p-jc-between  p-toolbar-group-right">
        <div></div>
        <div class="p-d-flex">
            <div>
                <i class="pi pi-briefcase manipulation-case-module"></i>
            </div>
            <div class="manipulation-case-highlight">
                <span class="manipulation-case-highlight-box">{{caseSummary['size']}}</span>
            </div>
        </div>
    </div>
</p-toolbar> 

<section id="maincontent">
    <div class="p-d-flex viewer-content-section " id="left">
        <div class="viewer-content-section-left " *ngIf="sourceImageFilterPanel == true && leftPanel == true">
            <div class="viewer-content-section-subtitle viewer-content-section-subtitle-left p-mt-3">{{'manipulation-viewer.panel-adjustment' | translate}}</div>
            <div class="viewer-content-section-subtitle-item p-mt-2 p-mb-1">{{'manipulation-viewer.panel-contrast' | translate}} {{adjustmentSourceContrast}}</div>
            <div class="viewer-content-section-adjustment-box p-ml-2 p-mt-2">
                <p-slider (onSlideEnd)="onChangeFilterContrast(leftFilenameCacheKey)" [style]="{width: '130px', height: '5px'}" [(ngModel)]="adjustmentSourceContrast" [min]="-100" [max]="100"></p-slider>
            </div>
            <div class="viewer-content-section-subtitle-item p-mt-3 p-mb-1">{{'manipulation-viewer.panel-brightness' | translate}}{{adjustmentSourceBrightness}}</div>
            <div class="viewer-content-section-adjustment-box p-ml-2 p-mt-2">
                <p-slider (onSlideEnd)="onChangeFilterBrightness(leftFilenameCacheKey)" [style]="{width: '130px', height: '5px'}" [(ngModel)]="adjustmentSourceBrightness" [min]="-100" [max]="100"></p-slider>
            </div>
            <div class="viewer-content-section-subtitle-item p-mt-3 p-mb-1">{{'manipulation-viewer.panel-saturation' | translate}}{{adjustmentSourceSaturation}}</div>
            <div class="viewer-content-section-adjustment-box p-ml-2 p-mt-2">
                <p-slider (onSlideEnd)="onChangeFilterSaturation(leftFilenameCacheKey)" [style]="{width: '130px', height: '5px'}" [(ngModel)]="adjustmentSourceSaturation" [min]="-100" [max]="100"></p-slider>
            </div>


            <!-- <p-divider></p-divider>
            <div class="viewer-content-section-adjustment-box p-mt-2 p-mb-2">
                <span class="viewer-content-section-adjustment-checkbox">Invert Color : </span>
                <p-checkbox (onChange)="onChangeInvertColor(leftFilenameCacheKey)" [(ngModel)]="adjustmentSourceInvert" [binary]="true"></p-checkbox>
            </div>
            <p-divider></p-divider>
            <div class="viewer-content-section-subtitle p-mt-3 ">Filter</div>

            <p-scrollPanel *ngIf="sourceImageImgFilterID" [style]="{padding: '5px', width: '95%', height: '300px', border: '1px solid lightgray'}">
                <div *ngIf="sourceImageImgFilterID">
                    <div class="p-field-radiobutton viewer-content-section-right-radio" *ngFor="let item of filterList">
                        <p-radioButton (click)="switchToFilterImage('source')" [inputId]="item.name" name="item" [value]="item" [(ngModel)]="filterListSourceSelected"></p-radioButton>
                        <label [for]="item.name">{{item.name}}</label>
                    </div>
                </div>
            </p-scrollPanel>

            <div *ngIf="sourceImageImgFilterID == undefined" class="viewer-content-section-filter-box-text">
                <div>
                    Preparing ...
                </div>
            </div> -->
        </div>

        <div class="viewer-content-section-center" id="viewercontent">
            <div class="p-d-flex p-jc-between">
                <div class="viewer-content-section-center-left" (click)="switchLeft()">
                    <i *ngIf="leftPanel == true" class="pi pi-chevron-left" title="Close the Panel"></i>
                    <i *ngIf="leftPanel == false" class="pi pi-chevron-right" title="Open the Panel"></i>
                </div>
                <div class="p-d-flex p-flex-column viewer-content-section-center-canvas">
                    <div class="viewer-content-section-center-info p-d-flex p-jc-center">
                        <div>
                            <span class="viewer-content-section-center-info-item" (click)="resetViewerDisplay()" title="{{'manipulation-viewer.control-reset-display' | translate}}">
                                <i class="pi pi-refresh viewer-icon-hover"> </i>
                            </span>
                        </div>
                        <!-- <div *ngIf="sourceImageImg != undefined && similarImageImg != undefined" class="p-ml-2 ">
                            <span *ngIf="sourceImageImg != undefined && similarImageImg != undefined && hasAnalyzed == false" (click)="analyzeImageSimilarity()" title="{{'manipulation-viewer.control-extra-analysis' | translate}}">
                                <i class="pi pi-check-circle viewer-icon-hover" ></i>
                            </span>
                            <span *ngIf="sourceImageImg != undefined && similarImageImg != undefined && hasAnalyzed == true">
                                <i class="pi pi-check-circle viewer-icon-hover viewer-content-section-center-has-analyzed" ></i>
                            </span>  
                        </div> -->
                        <div class="p-ml-2 p-d-flex">
                            <span (click)="toggleIntersectingMode()" class="p-ml-3 viewer-content-section-center-info-item" title="{{'manipulation-viewer.control-intersection-mode' | translate}}">
                                <fa-icon [icon]="faLayerGroup" class="viewer-icon-hover-fontawesome" [ngClass]="{'viewer-drawing-mode-selected': isIntersectingMode == true}"></fa-icon>
                            </span>
                            <!-- <span (click)="toggleDrawingMode()" class="p-ml-3 viewer-content-section-center-info-item" [ngClass]="{'viewer-drawing-mode-selected': isDrawingMode == true}" title="{{'manipulation-viewer.control-drawing' | translate}}">
                                <i class="pi pi-pencil viewer-icon-hover"> </i>
                            </span>
                            <span (click)="openEnterTextDialog()" class="p-ml-3 viewer-content-section-center-info-item" title="{{'duplication-viewer.control-comment' | translate}}">
                                <i class="pi pi-comments viewer-icon-hover"> </i>
                            </span> -->

                            
                            <span class="viewer-content-section-seperator">|</span>
                            <span *ngIf="isDrawingMode == true" (click)="leaveDrawingMode(); " class="p-ml-3 viewer-content-section-center-info-item viewer-drawing-mode-selected"  title="{{'duplication-viewer.control-drawing' | translate}}">
                                <i class="pi pi-pencil viewer-icon-hover"> </i>
                            </span>
                            <span *ngIf="isDrawingMode == false" (click)="drawing.toggle($event); " class="p-ml-3 viewer-content-section-center-info-item"  title="{{'duplication-viewer.control-drawing' | translate}}">
                                <i class="pi pi-pencil viewer-icon-hover"> </i>
                                <p-overlayPanel #drawing>
                                    <div class="p-d-flex p-flex-column">
                                        <i (click)="toggleDrawingMode('black')" class="pi pi-palette color-picker-black "></i>
                                        <i (click)="toggleDrawingMode('blue')" class="pi pi-palette color-picker-blue "></i>
                                        <i (click)="toggleDrawingMode('red')" class="pi pi-palette color-picker-red "></i>
                                        <i (click)="toggleDrawingMode('green')" class="pi pi-palette color-picker-green "></i>
                                        <i (click)="toggleDrawingMode('orange')" class="pi pi-palette color-picker-orange "></i>
                                        <i (click)="toggleDrawingMode('magenta')" class="pi pi-palette color-picker-magenta"></i>
                                    </div>
                                </p-overlayPanel>
                            </span>
                            <span (click)="circle.toggle($event)" class="p-ml-3 viewer-content-section-center-info-item" title="{{'duplication-viewer.control-circle' | translate}}">
                                <div class="shape-circle">&nbsp;</div>
                                <p-overlayPanel #circle>
                                    <div class="p-d-flex p-flex-column">
                                        <i (click)="addCircleToCanvas('black')" class="pi pi-palette color-picker-black "></i>
                                        <i (click)="addCircleToCanvas('blue')" class="pi pi-palette color-picker-blue "></i>
                                        <i (click)="addCircleToCanvas('red')" class="pi pi-palette color-picker-red "></i>
                                        <i (click)="addCircleToCanvas('green')" class="pi pi-palette color-picker-green "></i>
                                        <i (click)="addCircleToCanvas('orange')" class="pi pi-palette color-picker-orange "></i>
                                        <i (click)="addCircleToCanvas('magenta')" class="pi pi-palette color-picker-magenta"></i>
                                    </div>
                                </p-overlayPanel>
                            </span> 
                            <span (click)="square.toggle($event)"  class="p-ml-3 viewer-content-section-center-info-item" title="{{'duplication-viewer.control-square' | translate}}">
                                <div class="shape-square">&nbsp;</div>
                                <p-overlayPanel #square>
                                    <div class="p-d-flex p-flex-column">
                                        <i (click)="addSquareToCanvas('black')" class="pi pi-palette color-picker-black "></i>
                                        <i (click)="addSquareToCanvas('blue')" class="pi pi-palette color-picker-blue "></i>
                                        <i (click)="addSquareToCanvas('red')" class="pi pi-palette color-picker-red "></i>
                                        <i (click)="addSquareToCanvas('green')" class="pi pi-palette color-picker-green "></i>
                                        <i (click)="addSquareToCanvas('orange')" class="pi pi-palette color-picker-orange "></i>
                                        <i (click)="addSquareToCanvas('magenta')" class="pi pi-palette color-picker-magenta"></i>
                                    </div>
                                </p-overlayPanel>
                            </span>
                            <span (click)="textsection.toggle($event)" class="p-ml-3 viewer-content-section-center-info-item" title="{{'duplication-viewer.control-comment' | translate}}">
                                <i class="pi pi-comments viewer-icon-hover"> </i>
                                <p-overlayPanel #textsection>
                                    <div class="p-d-flex p-flex-column">
                                        <i (click)="openEnterTextDialog('black')" class="pi pi-palette color-picker-black "></i>
                                        <i (click)="openEnterTextDialog('blue')" class="pi pi-palette color-picker-blue "></i>
                                        <i (click)="openEnterTextDialog('red')" class="pi pi-palette color-picker-red "></i>
                                        <i (click)="openEnterTextDialog('green')" class="pi pi-palette color-picker-green "></i>
                                        <i (click)="openEnterTextDialog('orange')" class="pi pi-palette color-picker-orange "></i>
                                        <i (click)="openEnterTextDialog('magenta')" class="pi pi-palette color-picker-magenta"></i>
                                    </div>
                                </p-overlayPanel>
                            </span>
                            <span class="viewer-content-section-seperator">|</span>

                            <span (click)="confirmStoreCase()" *ngIf="similarImageImg != undefined || sourceImageImg != undefined " class="p-ml-3 viewer-content-section-center-info-item" title="{{'manipulation-viewer.control-save-result' | translate}}">
                                <i class="pi pi-briefcase viewer-icon-hover"> </i>
                            </span>
                        </div>
                    </div>

                    <div class="p-d-flex p-jc-center">
                        <canvas id="viewer" #viewer></canvas>
                    </div>

                    <div class="p-d-flex p-jc-between">
                        
                        <div></div>
                        <div *ngIf="sourceImageImg != undefined " class="viewer-icon-bottom-icon-left">
                            <span (click)="loadAdditionalInformation('source')" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="{{'manipulation-viewer.control-metadata-info' | translate}}">
                                <i class="pi pi-info-circle viewer-icon-hover"> </i>
                            </span>
                            <!-- <span (click)="submitWebImage('source')" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="Find similar image on the Internet">
                                <fa-icon [icon]="faGlobeAmericas" class="viewer-icon-hover-fontawesome-bottom"></fa-icon>
                            </span> -->
                            <!-- <span (click)="toggleFilterPanel('source')" *ngIf="leftPanel == true" class="viewer-content-section-center-info-item" title="Open the filter panel">
                                <i class="pi pi-filter viewer-icon-hover"> </i>
                            </span>
                            <span (click)="toggleFilterPanel('source')" *ngIf="leftPanel == false" class="viewer-content-section-center-info-item" title="Open the filter panel">
                                <i class="pi pi-filter-slash viewer-icon-hover"> </i>
                            </span> -->
                            <span (click)="flipImage('source')" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="{{'manipulation-viewer.control-flip-image' | translate}}">
                                <fa-icon [icon]="faExchangeAlt" class="viewer-icon-hover-fontawesome-bottom"></fa-icon>
                            </span>
                            <span (click)="turnRight('source')" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="{{'manipulation-viewer.control-turn-right' | translate}}">
                                <i class="pi pi-reply viewer-icon-hover"> </i>
                            </span>
                            <span (click)="ZoomIn(leftFilenameCacheKey)" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="{{'manipulation-viewer.control-zoom-in' | translate}}">
                                <i class="pi pi-search-plus viewer-icon-hover"> </i>
                            </span>
                            <span (click)="ZoomOut(leftFilenameCacheKey)" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="{{'manipulation-viewer.control-zoom-out' | translate}}">
                                <i class="pi pi-search-minus viewer-icon-hover"> </i>
                            </span>
                            <span (click)="reloadImage('source')" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="{{'manipulation-viewer.control-reload' | translate}}">
                                <fa-icon [icon]="faSync" class="viewer-icon-hover-fontawesome-bottom"></fa-icon>
                            </span>
                        </div>
                        
                        <div></div>
                        <div *ngIf="similarImageImg != undefined "  class="viewer-icon-bottom-icon-right">
                            <span (click)="loadAdditionalInformation('similar')" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="{{'manipulation-viewer.control-metadata-info' | translate}}">
                                <i class="pi pi-info-circle viewer-icon-hover"> </i>
                            </span>
                            <!-- <span (click)="submitWebImage('similar')" *ngIf="similarImageImg != undefined " class="viewer-content-section-center-info-item" title="Find similar image on the Internet">
                                <fa-icon [icon]="faGlobeAmericas" class="viewer-icon-hover-fontawesome-bottom"></fa-icon>
                            </span> -->
                            <!-- <span (click)="toggleFilterPanel('similar')" *ngIf="rightPanel == true" class="viewer-content-section-center-info-item" title="Open the filter panel">
                                <i class="pi pi-filter viewer-icon-hover"> </i>
                            </span>
                            <span (click)="toggleFilterPanel('similar')" *ngIf="rightPanel == false" class="viewer-content-section-center-info-item" title="Open the filter panel">
                                <i class="pi pi-filter-slash viewer-icon-hover"> </i>
                            </span> -->
                            <span (click)="flipImage('similar')" *ngIf="similarImageImg != undefined " class="viewer-content-section-center-info-item" title="{{'manipulation-viewer.control-flip-image' | translate}}">
                                <fa-icon [icon]="faExchangeAlt" class="viewer-icon-hover-fontawesome-bottom"></fa-icon>
                            </span>
                            <span (click)="turnRight('similar')" *ngIf="similarImageImg != undefined " class="viewer-content-section-center-info-item" title="{{'manipulation-viewer.control-turn-right' | translate}}">
                                <i class="pi pi-reply viewer-icon-hover"> </i>
                            </span>
                            <span (click)="ZoomIn(rightFilenameCacheKey)" *ngIf="similarImageImg != undefined " class="viewer-content-section-center-info-item" title="{{'manipulation-viewer.control-zoom-in' | translate}}">
                                <i class="pi pi-search-plus viewer-icon-hover"> </i>
                            </span>
                            <span (click)="ZoomOut(rightFilenameCacheKey)" *ngIf="similarImageImg != undefined " class="viewer-content-section-center-info-item" title="{{'manipulation-viewer.control-zoom-out' | translate}}">
                                <i class="pi pi-search-minus viewer-icon-hover"> </i>
                            </span>
                            <span (click)="reloadImage('similar')" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="{{'manipulation-viewer.control-reload' | translate}}">
                                <fa-icon [icon]="faSync" class="viewer-icon-hover-fontawesome-bottom"></fa-icon>
                            </span>
                        </div>
                        <div></div>
                    </div>

                </div>
                <div class="viewer-content-section-center-right" (click)="switchRight()">
                    <i *ngIf="rightPanel == true" class="pi pi-chevron-right" title="Close the Panel"></i>
                    <i *ngIf="rightPanel == false" class="pi pi-chevron-left" title="Open the Panel"></i>
                </div>
            </div>
        </div>

        <div class="viewer-content-section-right" id="filterpanel" *ngIf="similarImageFilterPanel == true  && rightPanel == true">

            <p-scrollPanel *ngIf="hasFilterImage" [style]="{width: '100%', height: '100%'}">
                <div *ngFor="let filter of filters">
                    <div class="viewer-content-section-card" (click)="switchToFilterImage(filter)">
                        <div class="viewer-content-section-card-title">{{filter}}</div>
                        <img class="viewer-content-section-card-image" src="{{baseUrl}}/filter/{{filter}}/{{id}}/{{rightFilename}}?width=150" />
                    </div>
                </div>
            </p-scrollPanel>
            <div *ngIf="!hasFilterImage" class="viewer-content-section-right-message">
                {{ 'manipulation-viewer.loading' | translate }}
            </div>
        </div>

    </div>

</section>

<p-blockUI [blocked]="blocked" [baseZIndex]="20001">
    <p-progressSpinner [style]="{width: '150px', height: '150px'}" styleClass="custom-spinner" strokeWidth="2" animationDuration=".5s"></p-progressSpinner>
</p-blockUI>

<p-dialog  [modal]="true" *ngIf="record" header="Metadata" [(visible)]="addtionalInformationDialog" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <div *ngIf="addtionalInformationDisplay" class="p-col viewer-info-content-section">
        <div *ngIf="addtionalInformationDisplay['image']" class="viewer-info-content-section-record-figure">{{'manipulation-viewer.control-metadata-figure' | translate}} {{addtionalInformationDisplay['image']['description']}}</div>
        <div *ngIf="addtionalInformationDisplay['record']" class="viewer-info-content-section-record-title">{{addtionalInformationDisplay['record']['title']}}</div>
        <div *ngIf="addtionalInformationDisplay['record']" (click)="linkViaDoi(addtionalInformationDisplay['record']['doi'])" class="viewer-info-content-section-record-doi">{{addtionalInformationDisplay['record']['doi']}}</div>
        <div class="viewer-info-content-section-record-resolution p-mt-2">{{'manipulation-viewer.control-metadata-resolution' | translate}} {{addtionalInformationDisplay['width']}} * {{addtionalInformationDisplay['height']}}</div>
    </div>
</p-dialog>

<p-dialog  [modal]="true" *ngIf="record" header="Internet Search Result" [(visible)]="searchResultDialog" [style]="{width: '60vw'}" [baseZIndex]="10000">

    <div *ngIf="internetImageImgWebTemp" class="viewer-internet-dialog-section">
        <div *ngIf="internetImageImgWebTemp" class="p-d-inline-flex p-mt-3">
            <div *ngIf="internetImageImgWebTemp['type'] == 'source'" class="viewer-internet-dialog-img">
                <img src="{{baseUrl}}/image/{{id}}/{{leftFilename}}?width=150" />
            </div>
            <div *ngIf="internetImageImgWebTemp['type'] == 'similar'" class="viewer-internet-dialog-img">
                <img src="{{baseUrl}}/image/{{id}}/{{rightFilenameRecordId}}_{{rightFilename}}?width=150" />
            </div>
            <div class="viewer-internet-dialog-info-box">
                <div *ngIf="internetImageImgWebTemp['data'][0]['entity'].length > 0" class="p-ml-3 p-mt-1 viewer-internet-dialog-info-result-section-title">{{'manipulation-viewer.control-detected-entities' | translate}}</div>
                <div class="p-grid p-ml-2 p-mt-1">
                    <div class="viewer-internet-dialog-info-item-entity" *ngFor="let item of internetImageImgWebTemp['data'][0]['entity']">
                        <span title="{{item['description']}} : {{item['score']}}">{{item['description']}}</span>
                    </div>
                </div>
            </div>
            <div>
                <!-- <span (click)="confirmStoreInternetCase()" class="viewer-content-section-center-info-item" title="Save the internet search result">
                    <i class="pi pi-briefcase viewer-internet-dialog-store-icon"> </i>
                </span> -->
            </div>
        </div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['page'].length > 0" class="p-mt-3 viewer-internet-dialog-info-result-section-title">{{'manipulation-viewer.control-page-contains-images' | translate}}</div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['page'].length > 0" class="p-mt-1 viewer-internet-dialog-info-result-section">
            <div class="p-d-flex p-flex-column" *ngFor="let item of internetImageImgWebTemp['data'][0]['page'] ; index as i ">
                <div class="p-d-flex">
                    <div class="viewer-internet-dialog-info-item-no">{{(i+1)}}</div>
                    <div class="viewer-internet-dialog-info-item-text">
                        <a href="{{item['url']}}" target="_blank">{{item['title']}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['full'].length > 0" class="p-mt-3 viewer-internet-dialog-info-result-section-title">{{'manipulation-viewer.control-full-matched' | translate}}</div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['full'].length > 0" class="p-grid p-mt-1 viewer-internet-dialog-info-result-section">
            <div class="p-d-flex" *ngFor="let item of internetImageImgWebTemp['data'][0]['full'] ; index as i ">
                <div class="p-d-flex p-flex-column viewer-internet-dialog-info-item-img">
                    <img src="{{item['url']}}" title="{{item['url']}}" />
                    <div title="{{item['url']}}">
                        <a href="{{item['url']}}" target="_blank">{{item['url'].substring(0, item['url'].indexOf("/", 10)).replace('https://','').replace('http://','')}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['partially'].length > 0" class="p-mt-3 viewer-internet-dialog-info-result-section-title">>{{'manipulation-viewer.control-partially-matched' | translate}}</div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['partially'].length > 0" class="p-grid p-mt-1 viewer-internet-dialog-info-result-section">
            <div class="p-d-flex" *ngFor="let item of internetImageImgWebTemp['data'][0]['partially'] ; index as i ">
                <div class="p-d-flex p-flex-column viewer-internet-dialog-info-item-img">
                    <img src="{{item['url']}}" title="{{item['url']}}" />
                    <div title="{{item['url']}}">
                        <a href="{{item['url']}}" target="_blank">{{item['url'].substring(0, item['url'].indexOf("/", 10)).replace('https://','').replace('http://','')}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-mt-3 p-mb-3 viewer-internet-dialog-info-item-not-found" *ngIf="internetImageImgWebTemp['data'][0]['partially'].length == 0 && internetImageImgWebTemp['data'][0]['full'].length == 0 && internetImageImgWebTemp['data'][0]['page'].length == 0">
            {{'manipulation-viewer.no-result' | translate}}
        </div>
    </div>

</p-dialog>

<p-dialog  [(visible)]="enterTextDialog" [style]="{width: '80vw'}">
    
    <p>
        <input type="text" [autofocus]="true" class="p-inputtext-lg enter-text-style" placeholder="Enter text"  pInputText [(ngModel)]="enterText"> 
    </p>
    <p >
        <p-radioButton (click)="enterTextColor = 'black'" id='black' [value]="'black'" [(ngModel)]="enterTextColor"></p-radioButton>
        <label for="black" style="color:black; font-weight: bold; margin-left: 15px; margin-right: 30px;">BLACK</label>
        <p-radioButton (click)="enterTextColor = 'blue'" id='blue' [value]="'blue'" [(ngModel)]="enterTextColor"></p-radioButton>
        <label for="blue" style="color:blue; font-weight: bold; margin-left: 15px; margin-right: 30px;">BLUE</label>
        <p-radioButton (click)="enterTextColor = 'red'" id='red' [value]="'red'" [(ngModel)]="enterTextColor"></p-radioButton>
        <label for="red" style="color:red; font-weight: bold; margin-left: 15px; margin-right: 30px;">RED</label>
        <p-radioButton (click)="enterTextColor = 'green'" id='green' [value]="'green'" [(ngModel)]="enterTextColor"></p-radioButton>
        <label for="green" style="color:green; font-weight: bold; margin-left: 15px; margin-right: 30px;">GREEN</label>
        <p-radioButton (click)="enterTextColor = 'orange'" id='orange' [value]="'orange'" [(ngModel)]="enterTextColor"></p-radioButton>
        <label for="orange" style="color:orange; font-weight: bold; margin-left: 15px; margin-right: 30px;">ORANGE</label>
        <p-radioButton (click)="enterTextColor = 'magenta'" id='magenta' [value]="'magenta'" [(ngModel)]="enterTextColor"></p-radioButton>
        <label for="magenta" style="color:magenta; font-weight: bold; margin-left: 15px; margin-right: 30px;">MAGENTA</label>
    </p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="enterTextDialog=false" label="Cancel" styleClass="p-button-text"></p-button>
        <p-button icon="pi pi-check" (click)="confirmEnterText();" label="Confirm" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>


<p-toast [baseZIndex]="50000"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="30000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>