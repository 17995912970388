import { Component, OnInit, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { GlobalConstants } from '../common/global-constants';
import { DataService } from '../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faSquare, faCheckSquare, faThList, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { timer } from 'rxjs/internal/observable/timer';
import { interval } from 'rxjs/internal/observable/interval';
import { parse } from '@fortawesome/fontawesome-svg-core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Case } from '../entity/Case';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-manipulation',
  templateUrl: './manipulation.component.html',
  styleUrls: ['./manipulation.component.scss']
})
export class ManipulationComponent implements OnInit {

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    // visible height + pixel scrolled >= total height 
    if ((event['target']['scrollingElement']['offsetHeight'] + event['target']['scrollingElement']['scrollTop'] + 100) >= event['target']['scrollingElement']['scrollHeight']) {
      this.updateResultList();
    }
  }

  blocked: boolean;

  successLoading: boolean;

  faQuestionCircle = faQuestionCircle;
  faSquare = faSquare;
  faCheckSquare = faCheckSquare;

  baseUrl: string;
  id: string;
  record: any;
  result: any;
  resultSimilarityTotal: number;
  resultSimilarityHideTotal: number;
  resultManipulationTotal: number;
  resultManipulationHideTotal: number;

  maximumDisplaySize: number;
  maximumDisplaySizeGap: number;
  manipulation: any[];

  resultImg1Text: string;

  countManipulation: number;
  countWhole: number;
  countPartial: number;
  countHigh: number;
  countMedium: number;
  countLow: number;
  countLowResolution: number;

  countGel: number;
  countFacs: number;
  countMicroscopy: number;
  countMacroscopy: number;
  countOther: number;

  filterContrast: boolean;
  filterEdge: boolean;
  filterNoise: boolean;

  filterList: any[];
  filterOriginalList: any[];
  filterImageDialog: boolean;

  filterImageFilename: string;
  filterImageFilenamePage: number;

  filterExcludesLowResolution: boolean;

  filterSelected: boolean;
  filterHigh: boolean;
  filterMedium: boolean;
  filterLow: boolean;
  filterUsed: boolean;
  filterManipulation = 0;
  filterManipulationMin = 100;
  filterManipulationMax = 0;
  filterManipulationStep = 0;

  filterImageDisplayStep = 25;
  filterImageDisplaySize = 0;
  filterImageDisplaySizeMax = 1000;
  filterImageDisplaySizeMin = 50;

  filterGel: boolean;
  filterFacs: boolean;
  filterMicroscopy: boolean;
  filterMacroscopy: boolean;
  filterOther: boolean;

  selectedCount: number;
  caseSummary: any;

  high: number;
  medium: number;

  toggleListHide: boolean;

  defaultLang: string;

  ai: {};

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private location: Location,
    private route: ActivatedRoute,
    private proute: Router,
    public cookieService: CookieService,
    public translate: TranslateService,
    private dataService: DataService) {

    this.baseUrl = GlobalConstants.apiURL;

    this.blocked = true;
    this.successLoading = true;

    this.filterSelected = false;
    this.filterHigh = false;
    this.filterMedium = false;
    this.filterLow = false;
    this.filterUsed = false;

    this.filterGel = false;
    this.filterFacs = false;
    this.filterMicroscopy = false;
    this.filterMacroscopy = false;
    this.filterOther = false;

    this.filterContrast = false;
    this.filterEdge = false;
    this.filterNoise = false;

    this.maximumDisplaySize = 5;
    this.maximumDisplaySizeGap = 5;
    this.selectedCount = 0;

    this.medium = GlobalConstants.manipulationMedium;
    this.high = GlobalConstants.manipulationHigh;

    this.toggleListHide = false;

    this.ai = undefined;

    this.countGel = 0;
    this.countFacs = 0;
    this.countMicroscopy = 0;
    this.countMacroscopy = 0;
    this.countOther = 0;

    this.filterList = ['contrast_1', 'contrast_2', 'contrast_3', 'contrast_4', 'contrast_5', 'edge_1', 'edge_2', 'edge_3', 'edge_4', 'edge_5', 'noise1_1', 'noise1_2', 'noise1_3', 'noise1_4', 'noise1_5', 'noise2_1', 'noise2_2', 'noise2_3', 'noise2_4', 'noise2_5'];

    let lang = this.proute['browserUrlTree']['queryParams']['lang'];
    if (lang != undefined) {
      this.cookieService.set('lang', lang);
      translate.use(lang);
      this.defaultLang = lang;
    } else {
      let tempLang = this.cookieService.get('lang');
      if (tempLang != '' && tempLang != undefined) {
        translate.use(tempLang);
        this.defaultLang = tempLang;
      } else {
        translate.use(translate.getBrowserLang());
        this.defaultLang = translate.getBrowserLang();
      }
    }

    // Retrieve Filter Image Display Size      
    let filterImageSize = this.cookieService.get('filter_image_display_size');
    if (filterImageSize == undefined || filterImageSize.trim() == '') {
      this.filterImageDisplaySize = 250;
    } else {
      this.filterImageDisplaySize = parseInt(filterImageSize);
      if (this.filterImageDisplaySize <= 100) {
        this.maximumDisplaySize = 2;
      }
    }

    this.resetCountItems();

  }

  updateResultList() {

    // console.log(this.filterManipulation);
    // console.log(this.filterManipulationMin);

    if (
      // this.filterSimilarity == this.filterSimilarityMin && 
      // this.filterHistogram == this.filterHistogramMin &&
      // this.filterPhash == this.filterPhashMin &&
      // this.filterAsift == this.filterAsiftMin &&
      this.filterManipulation == this.filterManipulationMin &&
      this.filterSelected == false &&
      this.filterHigh == false &&
      this.filterMedium == false &&
      this.filterLow == false &&
      this.filterGel == false &&
      this.filterFacs == false &&
      this.filterMicroscopy == false &&
      this.filterMacroscopy == false &&
      this.filterOther == false
    ) {

      var updateTempList = this.result['manipulation'];
      let newsize = this.maximumDisplaySize + this.maximumDisplaySizeGap;
      if (newsize > updateTempList.length) {
        for (let i = this.maximumDisplaySize; i < updateTempList.length; i++) {
          this.manipulation.push(updateTempList[i]);
        }
        this.maximumDisplaySize = updateTempList.length;
      } else {
        for (let i = this.maximumDisplaySize; i < newsize; i++) {
          this.manipulation.push(updateTempList[i]);
        }
        this.maximumDisplaySize = newsize;
      }

      // Here to call the manipulation filter
      // Here to run filter
      this.loadFilterImage();

    }

  }


  ngOnInit() {

    this.id = this.route.snapshot.paramMap.get('id');
    this.loadClassificationResult();

    // Initial Values
    this.resultManipulationTotal = 0;
    this.resultManipulationHideTotal = 0;
    this.resultSimilarityTotal = 0;
    this.resultSimilarityHideTotal = 0;

    // Here to detect the changes of the case
    interval(5000).subscribe(x => {
      this.loadCaseSummary();
    });
  }


  initial() {

    this.blocked = true;
    this.dataService.initial(this.id)
      .subscribe(data => {
        if (data['status'] == 'success') {

          this.loadRecordMetadata();

          this.route.params.subscribe(params => {
            if (params['image'] != 'all' && params['image'] != '' && params['image'] != undefined) {
              let tempfile = params['image'];
              this.filterImageFilename = tempfile.substring(0, tempfile.length - 4);
              this.filterImageFilenamePage = +this.filterImageFilename.substring(this.filterImageFilename.lastIndexOf("-") + 1);
            }
          });

        } else {
          this.successLoading = false;
        }
      });
  }

  loadClassificationResult() {
    this.initial();
  }

  loadRecordMetadata() {
    this.dataService.metadata(this.id, 'all')
      .subscribe(data => {
        this.record = data;
        this.loadCaseSummary();
        this.dataService.getClassificationResult(this.id).subscribe(data => {
          console.log(data);
          this.ai = data;
          this.blocked = false;
          this.loadManipulationResult();
        });
      });
  }

  loadManipulationResult() {

    this.resetCountItems();
    this.resultImg1Text = '';
    this.filterUsed = false;
    this.selectedCount = 0;

    this.dataService.getDuplicationCase(this.id)
      .subscribe(target => {

        var check = '';
        var ref = {};
        // Here to prepare for load te 
        for (var i = 0; i < target['data'].length; i++) {
          if (target['data'][i]['type'] == 'manipulation') {
            check += target['data'][i]['value'];
            ref[target['data'][i]['value']] = target['data'][i]['key'];
          }
        }

        this.dataService.manipulation(this.id)
          .subscribe(data => {

            // console.log(data);

            this.result = data;
            // This is the number for display on the Toolbar Number of duplication size
            this.resultSimilarityTotal = this.result['similarity_count'];
            this.resultSimilarityHideTotal = this.result['similarity_count_hide'];

            // This is the number for displya on the Toolbar number of manipulation size
            this.resultManipulationTotal = this.result['manipulation_count'];
            this.resultManipulationHideTotal = this.result['manipulation_count_hide'];

            let confirmList = [];

            for (let i = 0; i < this.result['manipulation'].length; i++) {

              // Update Selected Cases
              var sourceId = this.result['manipulation'][i]['id'];
              var targetId = this.result['manipulation'][i]['id'];
              var key = sourceId + '-' + targetId;
              if (check.indexOf(key) != -1) {
                this.result['manipulation'][i]['selected'] = true;
                this.result['manipulation'][i]['selected_key'] = ref[key];
                this.selectedCount += 1;
              } else {
                this.result['manipulation'][i]['selected'] = false;
                this.result['manipulation'][i]['selected_key'] = '';
              }

              if (this.result['manipulation'][i]['hide'] == undefined) {
                this.result['manipulation'][i]['hide'] = false;
              }

              if (this.result['manipulation'][i]['checkbox'] == undefined) {
                this.result['manipulation'][i]['checkbox'] = false;
              }

              if (this.toggleListHide == true) {
                if (this.result['manipulation'][i]['hide'] == true) {
                  confirmList.push(this.result['manipulation'][i]);
                }
              } else {
                if (this.result['manipulation'][i]['hide'] == false) {
                  confirmList.push(this.result['manipulation'][i]);
                }
              }

              // console.log(this.result['manipulation'][i]);
              // console.log(this.ai[this.result['manipulation'][i]['original_file']] );

              if (this.ai[this.result['manipulation'][i]['original_file']] != undefined) {
                this.result['manipulation'][i]['classification'] = this.ai[this.result['manipulation'][i]['original_file']]['classification'];
                if (this.ai[this.result['manipulation'][i]['original_file']]['classification'] == 'gel') {
                  this.countGel = this.countGel + 1;
                } else if (this.ai[this.result['manipulation'][i]['original_file']]['classification'] == 'facs') {
                  this.countFacs = this.countFacs + 1;
                } else if (this.ai[this.result['manipulation'][i]['original_file']]['classification'] == 'microscopy') {
                  this.countMicroscopy = this.countMicroscopy + 1;
                } else if (this.ai[this.result['manipulation'][i]['original_file']]['classification'] == 'macroscopy') {
                  this.countMacroscopy = this.countMacroscopy + 1;
                } else {
                  this.countOther = this.countOther + 1;
                }
              }

            }

            this.result['manipulation'] = confirmList;

            // Update Record Metadata
            this.updateRecordMetadata();

            // Sorting the result by similarity level
            this.result['manipulation'].sort(this.sortBySimilarity('manipulation'));

            // Apply Image Selection Filter
            let templist = [];
            for (let i = 0; i < this.result['manipulation'].length; i++) {
              var isValid = true;
              if (this.filterImageFilename != undefined && this.filterImageFilename != '') {
                if (this.result['manipulation'][i]['original_file'].indexOf(this.filterImageFilename + '-') == -1) {
                  isValid = false;
                }
              }
              if (isValid) {
                let analysisObj = this.result['manipulation'][i]['analysis'];
                let total = analysisObj['green'] + analysisObj['blue'] + analysisObj['yellow'] + analysisObj['orange'];
                let diff = 100 - total;
                if (diff != 0) {
                  analysisObj['green'] = analysisObj['green'] + diff;
                  this.result['manipulation'][i]['analysis'] = analysisObj;
                }
                templist.push(this.result['manipulation'][i]);
              }
            }
            this.result['manipulation'] = templist;

            // console.log('prepare result ' + this.result['manipulation'].length);

            this.countResultItem();
            this.countResultItemSimilarity();

            this.countManipulation = this.result['manipulation'].length;

            if (this.result['manipulation'].length <= this.maximumDisplaySize) {
              this.manipulation = this.result['manipulation'];
            } else {
              this.manipulation = this.result['manipulation'].slice(0, this.maximumDisplaySize);
            }

            // Here to run filter
            this.loadFilterImage();

          });


      });

  }

  resetList() {
    this.loadManipulationResult();
  }

  updateFilterList: any;

  onChangeAdvancedFilter() {
    try {
      this.updateFilterList.unsubscribe();
      console.log('unsubscribe');
    } catch (e) { }

    this.updateFilterList = timer(1000).subscribe(t => {
      this.applyAdvancedFilter();
    });

  }

  applyAdvancedFilter() {

    // console.log('subscribe run');
    // console.log('Color: '+this.filterHistogram + ' / ' + this.filterHistogramMin + ' / ' + this.filterHistogramMax);
    // console.log('Phash: '+this.filterPhash + ' / ' + this.filterPhashMin + ' / ' + this.filterPhashMax);
    // console.log('Phash: '+this.filterAsift + ' / ' + this.filterAsiftMin + ' / ' + this.filterAsiftMax);
    // console.log('Similarity: '+this.filterSimilarity + ' / ' + this.filterSimilarityMin + ' / ' + this.filterSimilarityMax);

    this.countManipulation = 0;

    if (

      this.filterManipulation == this.filterManipulationMin &&
      this.filterSelected == false &&
      this.filterHigh == false &&
      this.filterMedium == false &&
      this.filterLow == false &&
      this.filterGel == false &&
      this.filterFacs == false &&
      this.filterMicroscopy == false &&
      this.filterMacroscopy == false &&
      this.filterOther == false
    ) {
      // console.log('not running');
      this.loadManipulationResult();
    } else {

      // console.log('running ... ' + this.result['manipulation'].length);
      this.filterUsed = true;
      var templist = [];
      for (let j = 0; j < this.result['manipulation'].length; j++) {
        if (this.filterManipulation > parseFloat(this.result['manipulation'][j]['manipulation']) * 100) {
          continue;
        }

        // Filter , list only the "selcted" items
        if (this.filterSelected == true) {
          if (this.result['manipulation'][j]['selected'] == false) {
            continue;
          }
        }


        if (this.filterGel == true) {
          if (this.result['manipulation'][j]['classification'] != 'gel') {
            continue;
          }
        }

        if (this.filterOther == true) {
          // console.log('check filterOther = '+this.result['similarity'][j]['classification']);
          if (this.result['manipulation'][j]['classification'] != 'others') {
            continue;
          }
        }

        this.countManipulation++;
        templist.push(this.result['manipulation'][j]);
      }

      if (templist.length > 0) {
        this.manipulation = templist;
        this.manipulation.sort(this.sortBySimilarity('manipulation'));
        this.countTempResultItem();
        this.loadFilterImage();
      } else {
        this.manipulation = [];
      }


    }

  }

  countResultItemSimilarity() {

    for (let i = 0; i < this.result['manipulation'].length; i++) {
      var item = this.result['manipulation'][i];
      // console.log(item);
      if (parseInt(item['manipulation']) * 100 < this.filterManipulationMin) { this.filterManipulationMin = parseFloat(item['manipulation']) * 100; }
      if (parseInt(item['manipulation']) * 100 >= this.filterManipulationMax) { this.filterManipulationMax = parseFloat(item['manipulation']) * 100; }
    }

    this.filterManipulationMin = Math.floor(this.filterManipulationMin);
    this.filterManipulationMax = Math.floor(this.filterManipulationMax);

    this.filterManipulation = this.filterManipulationMin;

  }

  countTempResultItem() {

    this.countWhole = 0;
    this.countPartial = 0;
    this.countHigh = 0;
    this.countMedium = 0;
    this.countLow = 0;
    this.countLowResolution = 0;
    this.countMedium = 0;
    this.countLow = 0;
    this.countGel = 0;
    this.countFacs = 0;
    this.countMicroscopy = 0;
    this.countMacroscopy = 0;
    this.countOther = 0;

    for (let i = 0; i < this.manipulation.length; i++) {
      var item = this.manipulation[i];

      if (item['classification'] == 'gel') {
        this.countGel += 1;
      } else if (item['classification'] == 'facs') {
        this.countFacs += 1;
      } else if (item['classification'] == 'microscopy') {
        this.countMicroscopy += 1;
      } else if (item['classification'] == 'macroscopy') {
        this.countMacroscopy += 1;
      } else {
        this.countOther += 1;
      }

      // if(item['manipulation'] >= GlobalConstants.high){
      //   this.countHigh += 1;
      // }else if(item['manipulation'] >= GlobalConstants.medium && item['manipulation'] < GlobalConstants.high){
      //   this.countMedium += 1;
      // }else{
      //   this.countLow += 1;
      // }
      // if(item['img1']['low_resolution'] || item['img2']['low_resolution']){
      //   this.countLowResolution += 1;
      // }
      // if(item['duplication_type'] == 'whole'){
      //   this.countWhole += 1;
      // }else{
      //   this.countPartial += 1;
      // }
      // if(item['source'] == 'within'){
      //   this.countWithin += 1;
      // }else if(item['source'] == 'user'){
      //   this.countUser += 1;
      // }else if(item['source'] == 'lab'){
      //   this.countLab += 1;
      // }else if(item['source'] == 'institution'){
      //   this.countInstitution += 1;
      // }
    }
  }

  countResultItem() {

    this.countManipulation = 0;
    this.countWhole = 0;
    this.countPartial = 0;
    this.countHigh = 0;
    this.countMedium = 0;
    this.countLow = 0;
    this.countLowResolution = 0;
    this.countGel = 0;
    this.countFacs = 0;
    this.countMicroscopy = 0;
    this.countMacroscopy = 0;
    this.countOther = 0;

    for (let i = 0; i < this.result['manipulation'].length; i++) {

      var item = this.result['manipulation'][i];
      this.countManipulation += 1;
      // if(item['manipulation'] >= GlobalConstants.high){
      //   this.countHigh += 1;
      // }else if(item['manipulation'] >= GlobalConstants.medium && item['manipulation'] < GlobalConstants.high){
      //   this.countMedium += 1;
      // }else{
      //   this.countLow += 1;
      // }
      // if(item['img1']['low_resolution'] || item['img2']['low_resolution']){
      //   this.countLowResolution += 1;
      // }
      // if(item['duplication_type'] == 'whole'){
      //   this.countWhole += 1;
      // }else{
      //   this.countPartial += 1;
      // }
      // if(item['source'] == 'within'){
      //   this.countWithin += 1;
      // }else if(item['source'] == 'user'){
      //   this.countUser += 1;
      // }else if(item['source'] == 'lab'){
      //   this.countLab += 1;
      // }else if(item['source'] == 'institution'){
      //   this.countInstitution += 1;
      // }


      if (item['classification'] == 'gel') {
        this.countGel += 1;
      } else if (item['classification'] == 'facs') {
        this.countFacs += 1;
      } else if (item['classification'] == 'microscopy') {
        this.countMicroscopy += 1;
      } else if (item['classification'] == 'macroscopy') {
        this.countMacroscopy += 1;
      } else {
        this.countOther += 1;
      }

    }


  }

  updateRecordMetadata() {
    this.filterOriginalList = [];
    for (let i = 0; i < this.record['original'].length; i++) {
      let original = this.record['original'][i];
      let originalStr = original['filename'];
      originalStr = originalStr.substring(0, originalStr.length - 4) + '-';
      this.filterOriginalList.push(this.record['original'][i]);
    }
  }


  sortBySimilarity(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return -1;
      } else if (a[prop] < b[prop]) {
        return 1;
      }
      return 0;
    }
  }

  openFilterImage() {
    this.filterImageDialog = true;
  }

  applyFilterFilename(filename: string) {
    filename = filename.substring(0, filename.length - 4);
    this.filterImageFilename = filename;
    this.filterImageFilenamePage = +this.filterImageFilename.substring(this.filterImageFilename.lastIndexOf("-") + 1);
    this.filterImageDialog = false;
    this.loadManipulationResult();
    this.updateUrl();
  }

  resetCountItems() {
    this.resultImg1Text = '';
    this.countWhole = 0;
    this.countPartial = 0;
    this.countHigh = 0;
    this.countMedium = 0;
    this.countLow = 0;
    this.countLowResolution = 0;
    this.countGel = 0;
    this.countFacs = 0;
    this.countMicroscopy = 0;
    this.countMacroscopy = 0;
    this.countOther = 0;

    this.filterManipulation = 0;
    this.filterManipulationMin = 100;
    this.filterManipulationMax = 0;
    this.filterManipulationStep = 0;

    this.filterSelected = false;
    this.filterHigh = false;
    this.filterMedium = false;
    this.filterLow = false;

    this.filterContrast = false;
    this.filterEdge = false;
    this.filterNoise = false;

  }

  resetFilterFilename() {
    this.filterImageFilename = '';
    this.loadManipulationResult();
    this.updateUrl();
  }

  updateUrl() {
    if (this.filterImageFilename == '') {
      let targetChangeUrl: string = "/manipulation/" + this.id + ";image=all";
      this.location.replaceState(targetChangeUrl);
    } else {
      let targetChangeUrl: string = "/manipulation/" + this.id + ";image=" + this.filterImageFilename + ".png";
      this.location.replaceState(targetChangeUrl);
    }
  }


  back() {
    this.location.back();
  }


  loadManipulationImageViewer(img1id: string) {

    var width = document.body.clientWidth;
    var height = document.body.clientHeight;
    var w = Math.round(width * 0.95);
    var h = Math.round(height * 0.9);
    var x = Math.round((width - w) / 2);
    var y = Math.round((height - h) / 2);

    window.open('/manipulation-viewer/' + this.id + ';img1=' + img1id + '?lang=' + this.defaultLang, this.id,
      'resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no width=' + w + ', height=' + h + ', left=' + x + ', top=' + y);

  }

  /**
   * Load the metadata of the record then append to the UI
   * @param idx 
   * @param pair 
   */
  appendInformation(idx: number, pair: any) {

    if (this.manipulation[idx]['information_control'] == undefined) {
      this.manipulation[idx]['information_control'] = true;
    } else if (this.manipulation[idx]['information_control'] == false) {
      this.manipulation[idx]['information_control'] = true;
    } else {
      this.manipulation[idx]['information_control'] = false;
    }

    if (this.manipulation[idx]['information'] == undefined) {
      this.dataService.getImageMetadata(this.id, pair['original_file'])
        .subscribe(source => {
          if (source['status'] == 'success') {
            this.manipulation[idx]['information'] = source;
          }
        });
    }
    // console.log(this.manipulation[idx]);

  }

  linkViaDoi(doi: string) {
    window.open('https://doi.org/' + doi);
  }

  updateSimilarityCase() {

    this.selectedCount = 0;

    this.dataService.getDuplicationCase(this.id)
      .subscribe(target => {

        var check = '';
        var ref = {};
        for (var i = 0; i < target['data'].length; i++) {
          console.log(target['data'][i]);
          check += target['data'][i]['value'];
          ref[target['data'][i]['value']] = target['data'][i]['key'];
        }

        for (var j = 0; j < this.result['manipulation'].length; j++) {
          var sourceId = this.result['manipulation'][j]['id'];
          var targetId = this.result['manipulation'][j]['id'];
          var key = sourceId + '-' + targetId;
          if (check.indexOf(key) != -1) {
            this.result['manipulation'][j]['selected'] = true;
            this.result['manipulation'][j]['selected_key'] = ref[key];
            this.selectedCount += 1;
          } else {
            this.result['manipulation'][j]['selected'] = false;
            this.result['manipulation'][j]['selected_key'] = '';
          }
        }

      });
  }

  removeCase(idx: number) {

    this.dataService.deleteStoreCase(this.id, this.manipulation[idx]['selected_key'])
      .subscribe(result => {
        if (result['status'] == 'success') {
          this.messageService.add({ life: GlobalConstants.life, severity: 'success', summary: this.translate.instant('manipulation.toast-remove-case-title'), detail: this.translate.instant('duplication.toast-remove-case-success') });
        }

        this.dataService.getStoreCaseSummary(this.id)
          .subscribe(data => {
            this.caseSummary = data;
            this.updateSimilarityCase();
            if (this.caseSummary['size'] == 0) {
              this.filterSelected = false;
              this.applyAdvancedFilter();
            }
          });

      });

  }

  appendInformationSimple(idx: number, pair: any) {
    this.dataService.getImageMetadata(this.id, pair['original_file'])
      .subscribe(source => {
        if (source['status'] == 'success') {
          this.manipulation[idx]['information'] = source;
        }
      });
    this.dataService.getImageMetadata(this.id, pair['original_file'])
      .subscribe(target => {
        if (target['status'] == 'success') {
          this.manipulation[idx]['information'] = target;
        }
      });
  }


  submitCase(idx: number, pair: any) {

    this.dataService.getImageMetadata(this.id, this.record['record'] + "_" + pair['original_file'])
      .subscribe(leftMetadata => {

        // console.log('leftMetadata');
        // console.log(leftMetadata);
        this.dataService.getImageMetadata(this.id, pair['id'])
          .subscribe(rightMetadata => {

            // console.log('right metadata');
            // console.log(rightMetadata);
            // console.log(pair['id']);
            let caseobj: Case = new Case();

            caseobj.type = 'manipulation';
            caseobj.analysis = 'manipulation';

            caseobj.sourceFilename = pair['original_file'];
            caseobj.sourceRecord = this.record['record'];
            caseobj.sourceId = pair['id'];

            caseobj.targetFilename = pair['original_file'];
            caseobj.targetRecord = this.record['record'];
            caseobj.targetRepository = pair['repository'];
            caseobj.targetId = pair['id'];

            if (leftMetadata['status'] == 'success') {

              if (leftMetadata['record']['title'] != null) {
                caseobj.sourceTitle = leftMetadata['record']['title'];
              }
              if (leftMetadata['image'] != null) {
                caseobj.sourceDescription = leftMetadata['image']['description'];
              }
              if (leftMetadata['record'] != null && leftMetadata['record']['doi'] != null) {
                caseobj.sourceDoi = leftMetadata['record']['doi'];
              }
            }
            if (rightMetadata['status'] == 'success') {
              if (rightMetadata['record']['title'] != null) {
                caseobj.targetTitle = rightMetadata['record']['title'];
              }
              if (rightMetadata['image'] != null) {
                caseobj.targetDescription = rightMetadata['image']['description'];
              }
              if (rightMetadata['record'] != null && rightMetadata['record']['doi'] != null) {
                caseobj.targetDoi = rightMetadata['record']['doi'];
              }
            }

            this.dataService.saveAnalysisCase(this.id, caseobj)
              .subscribe(result => {
                if (result['status'] == 'success') {
                  this.messageService.add({ life: GlobalConstants.life, severity: 'success', summary: this.translate.instant('manipulation.toast-saved-case-title'), detail: this.translate.instant('manipulation.toast-saved-case-success') });
                }
                this.updateSimilarityCase();
                this.loadCaseSummary();
              });

          });
      });

  }

  toggleFilterSelected(type: string) {
    if (type == 'case') {
      if (this.filterSelected == true) {
        this.filterSelected = false;
      } else {
        this.filterSelected = true;
      }
    } else if (type == 'gel') {
      if (this.filterGel == true) {
        this.filterGel = false;
      } else {
        this.filterGel = true;
      }
    } else if (type == 'facs') {
      if (this.filterFacs == true) {
        this.filterFacs = false;
      } else {
        this.filterFacs = true;
      }
    } else if (type == 'microscopy') {
      if (this.filterMicroscopy == true) {
        this.filterMicroscopy = false;
      } else {
        this.filterMicroscopy = true;
      }
    } else if (type == 'macroscopy') {
      if (this.filterMacroscopy == true) {
        this.filterMacroscopy = false;
      } else {
        this.filterMacroscopy = true;
      }
    } else {
      if (this.filterOther == true) {
        this.filterOther = false;
      } else {
        this.filterOther = true;
      }
    }

    this.applyAdvancedFilter();
  }

  // openHelpDialogFilter(){
  //   this.helpFilterDialogFlag = true;
  // }
  // closeHelpDialogFilter(){
  //   this.helpFilterDialogFlag = false;
  // }

  loadAnalysisCase() {
    this.dataService.getStoreCaseSummary(this.id)
      .subscribe(data => {
        this.caseSummary = data;
        if (this.caseSummary['size'] > 0) {
          this.proute.navigate(['/analysis-case/' + this.id]);
        }
      });
  }



  updateCaseSummary: any;

  loadCaseSummary() {
    // console.log('run load case summary');
    try {
      this.updateCaseSummary.unsubscribe();
    } catch (e) { }

    if (this.updateCaseSummary == null) {
      this.dataService.getStoreCaseSummary(this.id)
        .subscribe(data => {
          this.caseSummary = data;
        });
      this.updateCaseSummary = timer(3000).subscribe(t => {
        this.loadCaseSummary();
      });

    } else {
      this.updateCaseSummary = timer(3000).subscribe(t => {
        this.dataService.getStoreCaseSummary(this.id)
          .subscribe(data => {
            this.caseSummary = data;
            // console.log('run load case summary ' + data);
          });
      });
    }

  }

  overlayDisplayLeftItem: any;
  overlayDisplayRightItem: any;
  overlayPanelLeft: boolean;
  overlayPanelRight: boolean;

  openOverlayPanelLeft(item: any) {
    this.overlayDisplayLeftItem = item;
    this.overlayPanelLeft = true;
  }

  openOverlayPanelRight(item: any) {
    this.overlayDisplayRightItem = item;
    this.overlayPanelRight = true;
  }

  displayItem: any;
  panelLeft: boolean;
  panelRight: boolean;

  openPanelLeft(item: any) {
    this.displayItem = item;
    this.panelLeft = true;
  }

  openPanelRight(item: any) {
    this.displayItem = item;
    this.panelRight = true;
  }


  displayManipulationItem: any;
  manipulationPanelLeft: boolean;
  manipulationBeautificationPanel: boolean;
  manipulationFilterPanel: boolean;
  manipulationFilter: any;

  openManipulationPanelLeft(item: any) {
    this.displayManipulationItem = item;
    this.manipulationPanelLeft = true;
  }

  openManipulationBeautificationPanel(item: any) {
    this.displayManipulationItem = item;
    this.manipulationBeautificationPanel = true;
  }

  openManipulationFilterPanel(item: any, filterName: any) {
    this.displayManipulationItem = item;
    this.manipulationFilterPanel = true;
    this.manipulationFilter = filterName;
  }

  // Below are the functions to process the filter images


  loadFilterImage() {
    for (let i = 0; i < this.manipulation.length; i++) {
      var item = this.manipulation[i];
      // console.log(item); 
      if (this.manipulation[i]['filter'] == undefined) {
        var originalFile = this.manipulation[i]['original_file'];
        this.initialFilterImage(i, originalFile);
      }
    }
  }

  initialFilterImage(idx, originalFile) {
    this.dataService.listFilterImage(this.id, originalFile).subscribe(data => {
      if (data['status'] == 'failed') {
        // console.log('no filter image:' + originalFile);        
        const source = timer(3000);
        source.subscribe(
          (val) => { this.initialFilterImage(idx, originalFile); }
        );

        if (this.manipulation[idx]['filter'] == undefined) {
          this.manipulation[idx]['filter'] = false;
          this.dataService.initialFilterImage(this.id, originalFile)
            .subscribe(data => {
              this.initialFilterImage(idx, originalFile);
            });
        }

      } else {
        // console.log('has filter image:' + this.manipulation[idx]);
        this.manipulation[idx]['filter'] = true;
      }
    });

  }

  switchToFilterImage(filter) {
    // ///filter/{{filter}}/{{id}}/{{rightFilename}}?width=150
    // this.similarImageURL = this.baseUrl+'/filter/'+filter+"/"+this.id+'/'+this.rightFilename;    
    // this.reloadImage('similar');                     
  }

  updateFilterImageSize() {
    this.cookieService.set('filter_image_display_size', this.filterImageDisplaySize + '');
  }

  toggleCase(i: number, caseid: string) {

    if (this.manipulation[i]['hide']) {
      this.manipulation[i]['hide'] = false;
      this.manipulation.splice(i, 1);
      this.resultManipulationHideTotal--;
      this.messageService.add({ life: GlobalConstants.life, severity: 'success', summary: this.translate.instant('manipulation.toast-restore-case-title'), detail: this.translate.instant('manipulation.toast-restore-case-success') });

    } else {
      if (this.manipulation[i]['selected'] == true) {
        this.removeCase(i);
      }
      this.manipulation[i]['hide'] = true;
      this.manipulation.splice(i, 1);
      this.resultManipulationHideTotal++;
      this.messageService.add({ life: GlobalConstants.life, severity: 'success', summary: this.translate.instant('manipulation.toast-hide-case-title'), detail: this.translate.instant('manipulation.toast-hide-case-success') });
    }
    this.dataService.toggleCase(this.id, 'manipulation', caseid).subscribe(data => {
      if (this.resultManipulationHideTotal == 0) {
        this.toggleListHide = false;
        this.initial();
      } else {
        this.updateResultList();
      }
    });
  }

  switchToggleListHide() {
    this.toggleListHide = !this.toggleListHide;
    this.initial();
  }

  toggleFilterType(type) {
    if (type == 'contrast') {
      this.filterContrast = !this.filterContrast;
    } else if (type == 'edge') {
      this.filterEdge = !this.filterEdge;
    } else if (type == 'noise') {
      this.filterNoise = !this.filterNoise;
    }

    // if filterContrast or filterEdge or filterNoise is false then 
    // this.filterUsed = true;
    if (this.filterContrast || this.filterEdge || this.filterNoise) {
      this.filterUsed = true;
    }

  }

  // function to filter the filterList based on the name of the filter
  filterFilterList(sourceFilterList) {

    if (this.filterContrast || this.filterEdge || this.filterNoise) {
      var finalFilterList = [];
      for (let i = 0; i < sourceFilterList.length; i++) {
        var tempFileName = sourceFilterList[i];
        if (this.filterContrast && tempFileName.indexOf('contrast') > -1) {
          finalFilterList.push(tempFileName);
        } else if (this.filterEdge && tempFileName.indexOf('edge') > -1) {
          finalFilterList.push(tempFileName);
        } else if (this.filterNoise && tempFileName.indexOf('noise') > -1) {
          finalFilterList.push(tempFileName);
        }
      }
      return finalFilterList;
    } else {
      return sourceFilterList;
    }

  }


}
