import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute , ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GlobalConstants } from '../common/global-constants';
import { DataService } from '../data.service';


import { ApexTooltip, ChartComponent } from "ng-apexcharts";
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart, 
  ApexDataLabels,
  ApexLegend,
  ApexPlotOptions,
} from "ng-apexcharts";
import { CookieService } from 'ngx-cookie-service';

export type ChartOptions = {
  legend: ApexLegend;
  title: any;
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  colors: string[];
  dataLabels: ApexDataLabels;
  options: ApexPlotOptions;
  tooltip: ApexTooltip;
};
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public chartOptionsEmpty: Partial<ChartOptions>;
  
  blocked: boolean;
  baseUrl: string;
  institution: string;
  id: string;
  record:any;
  result:any;
  resultImg1Text: string;
  hasSimilarity:boolean;

  successLoading: boolean;
  metadata: any;

  caseSummary: any;
  similarityLevel: number[];


  constructor(
              private route: ActivatedRoute,
              private proute: Router,
              private cookieService: CookieService,
              private dataService: DataService) {  

                this.baseUrl = GlobalConstants.apiURL;
                this.hasSimilarity = false;
                this.blocked = true;
                this.successLoading = true;

                this.similarityLevel = [0,0,0];

                this.chartOptions = {
                  options: {
                    pie:{
                      dataLabels:{
                        minAngleToShowLabel: 360
                      },
                      donut:{
                        labels: {
                          show: true,
                          total: {
                            showAlways: true,
                            show: true
                          },
                          value: {
                            show: true,
                            fontSize: '32px',
                            fontWeight: 800,
                          },
                          name:{
                            show: false
                          }                      
                        },
                      }
                        
                    },
                  },
                  legend:{
                    show: true
                  },
                  labels: ['High', 'Medium', 'Low'],
                  colors: ["#C0392B", "#E67E22", "#5499C7"],
                  series: this.similarityLevel,
                  chart: {
                    type: "donut"
                  },
                  responsive: [
                    {
                      breakpoint: 480,
                      options: {
                        chart: {
                          width: 200
                        },
                        legend: {
                          position: "bottom"
                        }
                      }
                    }
                  ]
                };

                
                this.chartOptionsEmpty = {
                  tooltip:{
                    enabled: false
                  },
                  options: {
                    pie:{
                      dataLabels:{
                        minAngleToShowLabel: 360
                      },
                      donut:{
                        labels: {
                          show: true,
                          total: {
                            showAlways: true,
                            show: true,
                            fontSize: '32px',
                            fontWeight: 800,
                            label: '0'
                          },
                          value: {
                            show: false,
                          },
                          name:{
                            show: true,
                            fontSize: '32px',
                            fontWeight: 800,
                            offsetY: 12
                          },                      
                        },
                      }
                        
                    },
                  },
                  legend:{
                    show: true
                  },
                  labels: ['High', 'Medium', 'Low'],
                  colors: ["#AAAAAA", "#AAAAAA", "#AAAAAA"],
                  series: [100,100,100],
                  chart: {
                    type: "donut"
                  },
                  responsive: [
                    {
                      breakpoint: 480,
                      options: {
                        chart: {
                          width: 200
                        },
                        legend: {
                          position: "bottom"
                        }
                      }
                    }
                  ]
                };

  }
 
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id'); 
    this.initial();
  }

  initial(){
    this.blocked = true;
    this.dataService.initial(this.id)
                      .subscribe(data =>{
                        this.blocked = false;
                        if(data['status'] == 'success'){
                          this.loadRecordMetadata();
                        }else{
                          this.successLoading = false;
                        }
                      });
  }

  loadRecordMetadata(){
    this.dataService.metadata(this.id, 'all')
                      .subscribe(data =>{
                        this.record = data;
                        this.loadAdditionalInformation();
                        this.loadDuplicationResult();
                        this.loadCaseSummary();
                      });
  }

  loadCaseSummary(){
    this.dataService.getStoreCaseSummary(this.id)
                      .subscribe(data =>{
                        this.caseSummary = data;
                      });
  }

  loadDuplicationResult(){
    this.resultImg1Text = '';
    this.dataService.duplication(this.id)
                      .subscribe(data =>{
                        this.result = data;
                        var countHigh = 0;
                        var countMedium = 0;
                        var countLow = 0;
                        for(let i=0; i < this.result['similarity'].length ; i++){
                            let pair = this.result['similarity'][i];
                            let img1 = pair['img1'];
                            this.resultImg1Text += img1['original_file'];
                            console.log(this.result['similarity'][i]['similarity']);
                            if(this.result['similarity'][i]['similarity'] >= GlobalConstants.high){
                              countHigh++;
                              continue;
                            } 
                            if(this.result['similarity'][i]['similarity'] >= GlobalConstants.medium && this.result['similarity'][i]['similarity'] < GlobalConstants.high){
                              countMedium++;
                              continue;
                            }
                            if(this.result['similarity'][i]['similarity'] < GlobalConstants.medium){
                              countLow++; 
                              continue;
                            }
                        }
                        
                        if(this.result['similarity'].length > 0 ){                          
                          this.similarityLevel[0] = countHigh;                        
                          this.similarityLevel[1] = countMedium;                        
                          this.similarityLevel[2] = countLow;
                          this.hasSimilarity = true;
                        }else{
                          this.hasSimilarity = false;
                        }
                        this.updateRecordMetadata();
                      });
  }

  updateRecordMetadata(){
    for(let i=0; i < this.record['original'].length ; i++){
      let original = this.record['original'][i];
      let originalStr = original['filename'];
          originalStr = originalStr.substring(0, originalStr.length -4)+'-';
      if(this.resultImg1Text.indexOf(originalStr) != -1){
        this.record['original'][i]['hasDuplication'] = true;
      }else{
        this.record['original'][i]['hasDuplication'] = false;
      }
    }
  }

  loadDuplicationPage(original:string){
    if(original == ''){
      this.proute.navigate(['/duplication/'+this.id]);
    }else{
      this.proute.navigate(['/duplication/'+this.id, {image: original}]);
    }
  }

  loadImageViewer(original:string){
    this.proute.navigate(['/image-viewer/'+this.id, {image: original}]);
  }

  loadAnalysisCase(){
    this.proute.navigate(['/analysis-case/'+this.id]);
  }

  
  loadAdditionalInformation(){
    this.dataService.getRecordMetadata(this.id)
      .subscribe(source =>{
        console.log(source);
        if(source['status'] == 'success'){
          this.metadata = source;
        }
      });  
  } 
  linkViaDoi(doi:string){
    window.open('https://doi.org/'+doi);
  }

}
