
  import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
  import { ActivatedRoute, Router } from '@angular/router';
  import { DataService } from '../data.service';
  import { Location } from '@angular/common';
  import { GlobalConstants } from '../common/global-constants';
  import { fabric } from "fabric";
  import { Analysis } from '../entity/Analysis';
  import { Filter } from '../entity/Filter';
  import { Web } from '../entity/Web';
  import { ConfirmationService, ConfirmEventType, MessageService} from 'primeng/api';
  import { Case } from '../entity/Case';
  import { faGlobeAmericas, faExchangeAlt, faSync, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
  import { timer } from 'rxjs/internal/observable/timer';
  import { TranslateService } from '@ngx-translate/core';
  import { CookieService } from 'ngx-cookie-service';
  
  interface Rows{
    value: number;
  }
  
  interface ImageFilter{
    name: string,
    key: string
  }
   

@Component({
  selector: 'app-manipulation-viewer',
  templateUrl: './manipulation-viewer.component.html',
  styleUrls: ['./manipulation-viewer.component.scss']
})
export class ManipulationViewerComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onScroll(event: any) {
    this.resizeUpdateScreen();
  }

  resizeUpdateScreen(){
    var gap = 25;
    var tempWindowWidth = document.body.clientWidth;
    var tempWindowHeight = document.body.clientHeight;

    var reloadflag = false; 
    if(tempWindowWidth - this.windowWidth > gap ||
       tempWindowHeight - this.windowHeight > gap ||
       this.windowWidth - tempWindowHeight > gap ||
       this.windowHeight - tempWindowHeight > gap){
        reloadflag = true;
    }

    if(reloadflag){
      this.blocked = true;
      const reload = timer(1500);
            reload.subscribe({
              next: function(value) {
                location.reload();
              },
              complete: function() {
                location.reload();
              },
              error: function(error) {
                location.reload();
              }
            });
    }

  }
  
  windowWidth: number;
  windowHeight: number;

  faExchangeAlt = faExchangeAlt;
  faSync = faSync;
  faLayerGroup = faLayerGroup;
  faGlobeAmericas = faGlobeAmericas;

  rows: Rows[];
  canvas: any;

  baseUrl: string;
  id: string;
  record:any;
  result:any; 
  img1id: string;
  img2id: string;
  pair: any;
  analysis: string;

  leftPanel: boolean;
  rightPanel: boolean;

  leftFilename: string;
  leftFilenameCacheKey: string;
  leftFilenameRecordId: string;
  rightFilename: string;
  rightFilenameCacheKey: string;
  rightFilenameRecordId: string;

  sourceImageImg: any;
  sourceImageImgFilterID: string;
  sourceImageImgFilterTemp: any;
  sourceImageImgWebID: string;
  similarImageImg: any;
  similarImageImgFilterID: string;
  similarImageImgFilterTemp: any;
  similarImageImgWebID: string;

  similarImageURL: string;
  
  internetImageImgWebTemp: any;
  
  sourceImageFilterPanel: boolean;
  similarImageFilterPanel: boolean;
  hasAnalyzed: boolean;

  blocked: boolean;

  analysisResult: any;
  analysisTraceId: string;

  filterList: ImageFilter[];
  filterListSourceSelected: ImageFilter;
  filterListSimilarSelected: ImageFilter;

  adjustmentSourceContrast: number;
  adjustmentSourceBrightness: number;
  adjustmentSourceSaturation: number;
  adjustmentSourceInvert: boolean;

  adjustmentSimilarContrast: number;
  adjustmentSimilarBrightness: number;
  adjustmentSimilarSaturation: number;
  adjustmentSimilarInvert: boolean;

  searchResultDialog: boolean;
  addtionalInformationDialog: boolean;
  addtionalInformationSource:any;
  addtionalInformationSimilar:any;
  addtionalInformationDisplay:any;
 

  isDrawingMode: boolean;
  isIntersectingMode: boolean;

  selectedCount: number;
  caseSummary: any;

  filters: any[];
  hasFilterImage: boolean;
  maxFilterRetry: number;
  maxFilterRetryCount: number;

  defaultLang: string;
  drawingColor: string;

  recordDoi: string;

  constructor(

    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private location: Location,
    private route: ActivatedRoute,
    private proute: Router,
    public cookieService: CookieService,
    public translate: TranslateService,
    private dataService: DataService) {

      this.isDrawingMode = false;
      this.isIntersectingMode = false;

      this.adjustmentSourceContrast = 0;
      this.adjustmentSourceBrightness = 0;
      this.adjustmentSourceSaturation = 0;
      this.adjustmentSourceInvert = false;

      this.adjustmentSimilarContrast = 0;
      this.adjustmentSimilarBrightness = 0;
      this.adjustmentSimilarSaturation = 0;
      this.adjustmentSimilarInvert = false;


      this.searchResultDialog = false;
      this.addtionalInformationDialog = false;
      this.hasAnalyzed = false;
      this.sourceImageFilterPanel = true;
      this.similarImageFilterPanel = true;

      this.leftPanel = true;
      this.rightPanel = true;

      this.hasFilterImage = false;
      this.maxFilterRetry = 10;
      this.maxFilterRetryCount = 0;
      
      this.baseUrl = GlobalConstants.apiURL;
      this.filterList = GlobalConstants.filters;

      this.similarImageURL = '';
      this.drawingColor = 'black';

      // this.pairAnalysis = '';
      this.selectedCount = 0;
      this.recordDoi = '';

      this.route.params.subscribe(params => {
        if(params['img1'] != undefined){
          this.img1id = params['img1']; 
          this.img2id = params['img1']; 
        }
      });


      let lang = this.proute['browserUrlTree']['queryParams']['lang'];
      if(lang != undefined){        
        this.cookieService.set('lang', lang);
        translate.use(lang);
        this.defaultLang = lang;
      }else{
        let tempLang = this.cookieService.get('lang');
        if(tempLang != '' && tempLang != undefined){
          translate.use(tempLang);
          this.defaultLang = tempLang;
        }else{          
          translate.use(translate.getBrowserLang());
          this.defaultLang = translate.getBrowserLang();
        }
      }

    }

  ngOnInit() {
    
    this.windowWidth = document.body.clientWidth;
    this.windowHeight = document.body.clientHeight;
    this.filterListSourceSelected = this.filterList[0];
    this.filterListSimilarSelected = this.filterList[0];

    this.id = this.route.snapshot.paramMap.get('id');
    this.loadRecordMetadata();

    // Fabrics

    this.canvas = new fabric.Canvas('viewer', {
      backgroundColor: '#ebebef',
      selection: false,
      preserveObjectStacking: true
    });

    this.canvas.on('object:scaling', object => {
      console.log(object);
      if(object['target']['cacheKey'] == this.leftFilenameCacheKey){
        this.sourceImageImg['after_width'] = Math.round(object['target']['width'] * object['target']['scaleX']);
        this.sourceImageImg['after_height'] = Math.round(object['target']['height'] * object['target']['scaleY']);
      }else if(object['target']['cacheKey'] == this.rightFilenameCacheKey){
        this.similarImageImg['after_width'] = Math.round(object['target']['width'] * object['target']['scaleX']);
        this.similarImageImg['after_height'] = Math.round(object['target']['height'] * object['target']['scaleY']);
      }
      this.hasAnalyzed = false;
      if(this.isIntersectingMode == true){
        this.canvas.forEachObject(function(obj) {
          if (obj === object.target) return;
          obj.set('opacity' , object.target.intersectsWithObject(obj) ? 0.5 : 1);
        });
      }
    });
    this.canvas.on("object:moving", object => {
      if(this.isIntersectingMode == true){
        this.canvas.forEachObject(function(obj) {
          if (obj === object.target) return;
          obj.set('opacity' , object.target.intersectsWithObject(obj) ? 0.5 : 1);
        });
      }
    });
    this.canvas.on("object:rotating", object => {
      if(this.isIntersectingMode == true){
        this.canvas.forEachObject(function(obj) {
          if (obj === object.target) return;
          obj.set('opacity' , object.target.intersectsWithObject(obj) ? 0.5 : 1);
        });
      }
    });
    this.canvas.on("mouse:dblclick", object => {
      if(this.canvas.getActiveObject()['cacheKey'] ==undefined){
        this.canvas.remove(this.canvas.getActiveObject());
        this.messageService.add({life: GlobalConstants.life, severity:'warn', summary:'Remove The Object'});  
      }
    });

    this.canvas.on("mouse:wheel", object => {
      // console.log(this.canvas.getActiveObject());
      var delta = object.e.deltaY;
      if(this.canvas.getActiveObject() != undefined){
        if(delta < 0){
          var current = this.canvas.getActiveObject()['scaleX'];
          current = current * 1.05;
          this.canvas.getActiveObject().scale(current);
          this.canvas.renderAll();  
        }else{
          var current = this.canvas.getActiveObject()['scaleX'];
          current = current * 0.95;
          this.canvas.getActiveObject().scale(current);
          this.canvas.renderAll(); 
        }
        
        if(this.canvas.getActiveObject()['cacheKey'] == this.leftFilenameCacheKey){
          this.sourceImageImg['after_width'] = Math.round(this.canvas.getActiveObject()['width'] * this.canvas.getActiveObject()['scaleX']);
          this.sourceImageImg['after_height'] = Math.round(this.canvas.getActiveObject()['height'] * this.canvas.getActiveObject()['scaleY']);
        }else{
          this.similarImageImg['after_width'] = Math.round(this.canvas.getActiveObject()['width'] * this.canvas.getActiveObject()['scaleX']);
          this.similarImageImg['after_height'] = Math.round(this.canvas.getActiveObject()['height'] * this.canvas.getActiveObject()['scaleY']);
        }

      }
    }); 
    
    this.updateCanvasSize();
    this.loadCaseSummary();
    this.loadFilterImage();
 
  }

  loadFilterImage(){
    
    let original_image = this.img1id;
        original_image = original_image.substring(original_image.indexOf('_')+1);
        
    this.dataService.listFilterImage(this.id, original_image)
          .subscribe(data =>{
            if(data['status'] == 'failed'){              
              const source = timer(3000);
                    source.subscribe(
                      (val) => { this.loadFilterImage(); }
                    );

              // Here to inital image 
              this.initialFilterImage();
              this.hasFilterImage = false;
            }else{
              this.filters = data['data'];
              this.hasFilterImage = true;

              // Adjust the display area size
              document.getElementById("filterpanel").style.height = ( this.windowHeight - 100) + 'px'; 
            }
          });
  }

  initialFilterImage(){

    this.maxFilterRetryCount += 1;
    if(this.maxFilterRetryCount <= this.maxFilterRetry){
      let original_image = this.img1id;
          original_image = original_image.substring(original_image.indexOf('_')+1);
      this.dataService.initialFilterImage(this.id, original_image)
            .subscribe(data =>{
              console.log(data);
            });
    }

  }

  switchToFilterImage(filter){
    ///filter/{{filter}}/{{id}}/{{rightFilename}}?width=150
    this.similarImageURL = this.baseUrl+'/filter/'+filter+"/"+this.id+'/'+this.rightFilename;    
    this.reloadImage('similar');                     
  }

  loadCaseSummary(){
    this.dataService.getStoreCaseSummary(this.id)
                      .subscribe(data =>{
                        console.log(data);
                        this.caseSummary = data;
                      });
  }


  loadRecordMetadata(){
    this.dataService.metadata(this.id, 'all')
                      .subscribe(data =>{
                        this.record = data;
                        this.loadManipulationResult();
                      });
  }

  loadManipulationResult(){
    this.dataService.manipulation(this.id)
                      .subscribe(data =>{

                        this.result = data;

                        // if this.result has doi then setup the this.recordDoi
                        if(this.result['doi'] != undefined){
                          this.recordDoi = this.result['doi'];
                        }

                        for(let i=0; i < this.result['manipulation'].length ; i++){

                          let item = this.result['manipulation'][i];
                          if(item['id'] == this.img1id){
                                this.leftFilename = item['original_file'];
                                this.leftFilenameCacheKey = this.leftFilename+"_left";
                                this.leftFilenameRecordId = item['record'];
                                this.rightFilename = item['original_file'];
                                this.rightFilenameCacheKey = this.rightFilename+"_right";
                                this.rightFilenameRecordId = item['record'];

                                this.similarImageURL = this.baseUrl+'/image/manipulation/'+this.id+'/'+this.rightFilename;
                          }

                        }
                        this.addSourceImageToViewer();
                        this.addSimilarImageToViewer();

                      }); 
  }


  back() {
    this.location.back();
  }

  close(){
    window.close();
  }

  switchLeft(){
    if(this.leftPanel){
      this.leftPanel = false;
    }else{
      this.leftPanel = true;
    }
    this.updateCanvasSize();
  }

  switchRight(){
    if(this.rightPanel){
      this.rightPanel = false;
    }else{
      this.rightPanel = true;
    }
    this.updateCanvasSize();
  }

  updateCanvasSize(){
    
    let gap = 42;
    if(this.rightPanel){
      gap += 200;
    }
    if(this.leftPanel){
      gap += 200;
    }
    this.canvas.setWidth(document.getElementById("maincontent").offsetWidth - gap);
    this.canvas.setHeight(document.getElementById("viewercontent").offsetHeight - 76);
    
  } 

  updateWindowPosition(){ 
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }

  addSourceImageToViewer(){
 
    this.sourceImageFilterPanel = true; 
    this.sourceImageImgWebID = null;
    this.internetImageImgWebTemp = null;

    this.removeTextFromCanvas();
    this.resetViewerDisplayByTheCacheKey(this.leftFilenameCacheKey);

    this.blocked = true;
    fabric.Image.fromURL(this.baseUrl+'/image/'+this.id+'/'+this.leftFilename, (img) => {
   
      let scale = 1;
      if(img.width > img.height){
        let scaleWidth = (this.canvas.width/2) * 0.8;
        scale = scaleWidth / img.width;
      }else{
        let scaleHeight = this.canvas.height * 0.8;
        scale = scaleHeight / img.height;
      }
      scale = scale * 0.8;
      let top = (this.canvas.height - (img.height*scale)) / 2;
      let left = (this.canvas.width - (img.width*scale)) / 9;

      this.sourceImageImg = img;
      this.hasAnalyzed = false;

      this.sourceImageImg['original_width'] = img.width;
      this.sourceImageImg['original_height'] = img.height;

      img.set({
          cacheKey: this.leftFilenameCacheKey,
          scaleX: scale,
          scaleY: scale, 
          top: top,
          left: left,
          stroke: 'blue',
          strokeWidth: 2
      });

      this.sourceImageImg['after_width'] = Math.round(img.width * scale);
      this.sourceImageImg['after_height'] = Math.round(img.height * scale);
      
      // console.log(img);
      this.canvas.add(img);

      this.blocked = false;

    }, {crossOrigin: 'anonymous'});
  }

  addSimilarImageToViewer(){
    
    this.similarImageFilterPanel = true;
    this.hasAnalyzed = false;
    this.similarImageImgWebID = null;
    this.internetImageImgWebTemp = null;

    this.removeTextFromCanvas();
    this.resetViewerDisplayByTheCacheKey(this.rightFilenameCacheKey);

    this.blocked = true;
    fabric.Image.fromURL(this.similarImageURL, (img) => {
  
      let scale = 1;
      if(img.width > img.height){
        let scaleWidth = (this.canvas.width/2) * 0.8;
        scale = scaleWidth / img.width;
      }else{
        let scaleHeight = this.canvas.height * 0.8;
        scale = scaleHeight / img.height;
      }
      scale = scale * 0.8;
      let top = (this.canvas.height - (img.height*scale)) / 2;
      let left = (this.canvas.width - (img.width*scale)) * 0.9;

      this.similarImageImg = img;
      this.hasAnalyzed = false;

      img.set({
          cacheKey: this.rightFilenameCacheKey,
          scaleX: scale,
          scaleY: scale,
          top: top,
          left: left,
          stroke: 'red',
          strokeWidth: 2,
      });
      
      this.similarImageImg['original_width'] = img.width;
      this.similarImageImg['original_height'] = img.height;
      this.similarImageImg['after_width'] = Math.round(img.width * scale);
      this.similarImageImg['after_height'] = Math.round(img.height * scale);

      // console.log(img);
      this.canvas.add(img);
      this.blocked = false;

      // this.submitFilterImage('similar');
    }, {crossOrigin: 'anonymous'});
    
  }
  
  resetViewerDisplayByTheCacheKey(key:string){

    let found = false;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == key){
        this.canvas.remove(this.canvas.getObjects()[i]);
        found = true;
        break;
      }
    }
    if(found){
      this.resetViewerDisplayByTheCacheKey(key);
    }
  }


  removeTextFromCanvas(){
    let hasText = false;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] != this.rightFilenameCacheKey
            && this.canvas.getObjects()[i]['cacheKey'] != this.leftFilenameCacheKey){
        this.canvas.remove(this.canvas.getObjects()[i]);
        hasText = true;
      }
    }
    if(hasText){
      this.removeTextFromCanvas();
    }
  }

  resetViewerDisplay(){
    
    this.reloadImage('source');
    this.reloadImage('similar');

    // for(let i=0; i < this.canvas.getObjects().length ; i++){
    //   this.canvas.remove(this.canvas.getObjects()[i]);
    //   if(this.canvas.getObjects().length > 0){
    //     this.resetViewerDisplay();
    //   }
    // }
    // this.sourceImageImg = null;
    // this.similarImageImg = null;
    // this.sourceImageFilterPanel = true;
    // this.similarImageFilterPanel = true;
    // this.hasAnalyzed = false;
    // this.filterListSourceSelected = this.filterList[0];
    // this.filterListSimilarSelected = this.filterList[0];
    // this.sourceImageImgFilterID = null;
    // this.similarImageImgFilterID = null;
    // this.leftFilename = '';
    // this.rightFilename = '';
    // this.sourceImageImgWebID = null;
    // this.similarImageImgWebID = null;
    // this.internetImageImgWebTemp = null;
    // this.searchResultDialog = false;
    // this.addtionalInformationDialog = false;
    //   this.adjustmentSourceContrast = 0;
    //   this.adjustmentSourceBrightness = 0;
    //   this.adjustmentSourceSaturation = 0; 
    //   this.adjustmentSourceInvert = false;

    //   this.adjustmentSimilarContrast = 0;
    //   this.adjustmentSimilarBrightness = 0;
    //   this.adjustmentSimilarSaturation = 0;
    //   this.adjustmentSimilarInvert = false;
  }

  // enterFullscreenMode(){
  //   document.body.requestFullscreen();
  // }

  toggleFilterPanel(type: string){
    if(type == 'source'){
      this.switchLeft();
    }else{
      this.switchRight();
    }
  }

  // analyzeImageSimilarity(){

  //   this.blocked = true;

  //   this.removeTextFromCanvas();

  //   if(this.sourceImageImg != undefined && this.similarImageImg != undefined){
      
  //     this.canvas.getObjects()[0].set({strokeWidth: 0});
  //     this.canvas.getObjects()[1].set({strokeWidth: 0});

  //     var dataURLSrc;
  //     var dataURLTgt;

  //     if(this.canvas.getObjects()[0]['cacheKey'] == this.leftFilenameCacheKey ){
  //       dataURLSrc = this.canvas.getObjects()[0].toDataURL({
  //         format: "png",
  //         left: 0,
  //         top: 0,
  //       });
  //       dataURLTgt = this.canvas.getObjects()[1].toDataURL({
  //         format: "png",
  //         left: 0,
  //         top: 0
  //       });
  //     }else{
  //       dataURLSrc = this.canvas.getObjects()[1].toDataURL({
  //         format: "png",
  //         left: 0,
  //         top: 0,
  //       });
  //       dataURLTgt = this.canvas.getObjects()[0].toDataURL({
  //         format: "png",
  //         left: 0,
  //         top: 0
  //       });
  //     }

  //     // var dataURLSrc = this.canvas.getObjects()[0].toDataURL({
  //     //   format: "png",
  //     //   left: 0,
  //     //   top: 0,
  //     // });
      
  //     // var dataURLTgt = this.canvas.getObjects()[1].toDataURL({
  //     //   format: "png",
  //     //   left: 0,
  //     //   top: 0
  //     // });
      
  //     this.canvas.getObjects()[0].set({strokeWidth: 2});
  //     this.canvas.getObjects()[1].set({strokeWidth: 2});

  //     let anaobj:Analysis = new Analysis();
  //         anaobj.source = dataURLSrc;
  //         anaobj.target = dataURLTgt;
  
  //     this.dataService.submitForSimilarityAnalysis(anaobj)
  //       .subscribe(result => {
          
  //         if(result['status'] == 'success'){
  //           let key = result['key'];
  //           this.downloadImageSimilarity(key);
  //         }
  //     });
  //   }else{
  //     this.blocked = false;
  //   }
  // }

  // analyzeImageSimilarity(){

  //   this.blocked = true;
  //   //downloadSimilarityResult(authkey:string, hash:string, img1id:string, img2id:string){
  //   this.dataService.downloadSimilarityResult(this.id, this.img1id, this.img2id)
  //           .subscribe(result => {
              
  //             // console.log('download result');
  //             // console.log(result);
  //             this.analysisResult = result;

  //             let resultText = '';
  //             for(let i=0; i < result['analysis'].length ; i++){
  //               console.log(result['analysis'][i]['name'] + ' : ' + result['analysis'][i]['similarity']);
  //               let pvalue = result['analysis'][i]['similarity'] * 100;
                    
  //               resultText += result['analysis'][i]['name'] + ' : ' + Math.floor(pvalue) + '%   ';
  //             } 

  //             this.blocked = false;
  //             this.hasAnalyzed = true;
    
  //             // Add the result to the canvas
  //             var info = new fabric.Text(resultText, {
  //               fontSize: 14,
  //               fontWeight: 'normal',
  //               stroke: 'gray'
  //             });
    
  //             var left = (this.canvas.width - info.width) /2;
  //             info.set({
  //               left: left,
  //               top: 10
  //             });
    
  //             this.canvas.add(info);


  //           });
  // }


  // switchToFilterImage(type:string){

  //   this.hasAnalyzed = false;

  //   this.adjustmentSourceContrast = 0;
  //   this.adjustmentSourceBrightness = 0;
  //   this.adjustmentSourceSaturation = 0;
  //   this.adjustmentSourceInvert = false;

  //   this.adjustmentSimilarContrast = 0;
  //   this.adjustmentSimilarBrightness = 0;
  //   this.adjustmentSimilarSaturation = 0;
  //   this.adjustmentSimilarInvert = false;
    
  //   if(type == 'source'){
  //     this.resetViewerDisplayByTheCacheKey(this.leftFilenameCacheKey);
  //     if(this.filterListSourceSelected['key'] == 'normal'){
  //       this.sourceImageImgFilterTemp['top'] = this.sourceImageImg['top'];
  //       this.sourceImageImgFilterTemp['left'] = this.sourceImageImg['left'];
  //       this.sourceImageImg = this.sourceImageImgFilterTemp;
  //       this.canvas.add(this.sourceImageImgFilterTemp);
  //       this.removeAllFilter(this.leftFilenameCacheKey);
  //     }else{

  //       fabric.Image.fromURL(this.baseUrl+'/filter/'+this.sourceImageImgFilterID+'?type='+this.filterListSourceSelected['key'], (img) => {
      
  //         let scale = 1;
  //         let top = this.sourceImageImg['top'];
  //         let left = this.sourceImageImg['left'];
  //         let srcWidth = this.sourceImageImg['after_width'];

  //             scale = srcWidth / img.width;

  //         this.sourceImageImg = img;
  //         this.hasAnalyzed = false;
    
  //         img.set({ 
  //             cacheKey: this.leftFilenameCacheKey,
  //             top: top,
  //             left: left,
  //             scaleX: scale,
  //             scaleY: scale,
  //             stroke: 'blue',
  //             strokeWidth: 2,
  //         });
    
  //         this.sourceImageImg['after_width'] = Math.floor(img['width'] * img['scaleX']);
  //         this.sourceImageImg['after_height'] = Math.floor(img['height'] * img['scaleY']);
  //         this.sourceImageImg['original_width'] = this.sourceImageImgFilterTemp['original_width'];
  //         this.sourceImageImg['original_height'] = this.sourceImageImgFilterTemp['original_height'];
    

  //         this.canvas.add(img);
  //         this.removeAllFilter(this.leftFilenameCacheKey);
  //         this.updateWindowPosition();
  //       }, {crossOrigin: 'anonymous'});

  //     }

  //   }else{

  //     this.resetViewerDisplayByTheCacheKey(this.rightFilenameCacheKey);

  //     if(this.filterListSimilarSelected['key'] == 'normal'){
  //       this.similarImageImgFilterTemp['top'] = this.similarImageImg['top'];
  //       this.similarImageImgFilterTemp['left'] = this.similarImageImg['left'];
  //       this.similarImageImg = this.similarImageImgFilterTemp;
  //       this.canvas.add(this.similarImageImgFilterTemp);
  //       this.removeAllFilter(this.rightFilenameCacheKey);
  //     }else{

  //       fabric.Image.fromURL(this.baseUrl+'/filter/'+this.similarImageImgFilterID+'?type='+this.filterListSimilarSelected['key'], (img) => {
      
  //         let scale = 1;
  //         let top = this.similarImageImg['top'];
  //         let left = this.similarImageImg['left'];
  //         let srcWidth = this.similarImageImg['after_width'];

  //             scale = srcWidth / img.width;

  //         this.similarImageImg = img;
  //         this.hasAnalyzed = false;
    
  //         img.set({ 
  //             cacheKey: this.rightFilenameCacheKey,
  //             top: top,
  //             left: left,
  //             scaleX: scale,
  //             scaleY: scale,
  //             stroke: 'red',
  //             strokeWidth: 2,
  //         });
    
  //         this.similarImageImg['after_width'] = Math.floor(img['width'] * img['scaleX']);
  //         this.similarImageImg['after_height'] = Math.floor(img['height'] * img['scaleY']);
  //         this.similarImageImg['original_width'] = this.similarImageImgFilterTemp['original_width'];
  //         this.similarImageImg['original_height'] = this.similarImageImgFilterTemp['original_height'];
    
  //         this.canvas.add(img);
  //         this.removeAllFilter(this.rightFilenameCacheKey);
  //         this.updateWindowPosition();
  //       }, {crossOrigin: 'anonymous'});

  //     }
  //   }
  // }

  
  onChangeFilterBrightness(type:string){

    let idx = 0;
      for(let i=0; i < this.canvas.getObjects().length ; i++){
        if(this.canvas.getObjects()[i]['cacheKey'] == type){
          idx = i;
          break;
        }
      }

      let value = 0;
      if(type == this.leftFilenameCacheKey){
        value = this.adjustmentSourceBrightness / 100;
      }else{
        value = this.adjustmentSimilarBrightness / 100;
      }

      var filter = new fabric.Image.filters.Brightness({
        brightness: value
      });
      
      let hasFilter  = false;
      for(let j = 0; j < this.canvas.getObjects()[idx].filters.length ; j++){
        if(this.canvas.getObjects()[idx].filters[j]['brightness'] != undefined){
          this.canvas.getObjects()[idx].filters[j]['brightness'] = value;
          hasFilter = true;
        }
      }
      if(!hasFilter){
        this.canvas.getObjects()[idx].filters.push(filter);
      }
      if(type == this.leftFilenameCacheKey){
        this.sourceImageImg = this.canvas.getObjects()[idx];
      }else{
        this.similarImageImg = this.canvas.getObjects()[idx];
      }

      this.canvas.getObjects()[idx].applyFilters();
      this.canvas.renderAll();
  }


  onChangeFilterContrast(type:string){

    let idx = 0;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == type){
        idx = i;
        break;
      }
    }


    let value = 0;
    if(type == this.leftFilenameCacheKey){
      value = this.adjustmentSourceContrast / 100;
    }else{
      value = this.adjustmentSimilarContrast / 100;
    }

    var filter = new fabric.Image.filters.Contrast({
      contrast: value
    });
    
    let hasFilter  = false;
    for(let j = 0; j < this.canvas.getObjects()[idx].filters.length ; j++){
      if(this.canvas.getObjects()[idx].filters[j]['contrast'] != undefined){
        this.canvas.getObjects()[idx].filters[j]['contrast'] = value;
        hasFilter = true;
      }
    }
    if(!hasFilter){
      this.canvas.getObjects()[idx].filters.push(filter);
    }
    if(type == this.leftFilenameCacheKey){
      this.sourceImageImg = this.canvas.getObjects()[idx];
    }else{
      this.similarImageImg = this.canvas.getObjects()[idx];
    }

    this.canvas.getObjects()[idx].applyFilters();
    this.canvas.renderAll();
  }
  /**
      var invert = new fabric.Image.filters.Invert({});
    
      img.filters.push(invert);
      img.applyFilters(); */


  onChangeInvertColor(type:string){

        let idx = 0;
        for(let i=0; i < this.canvas.getObjects().length ; i++){
          if(this.canvas.getObjects()[i]['cacheKey'] == type){
            idx = i;
            break;
          }
        }
    
        let value = false;
        if(type == this.leftFilenameCacheKey){
          value = this.adjustmentSourceInvert;
        }else{
          value = this.adjustmentSimilarInvert;
        }
            
    
        var filter = new fabric.Image.filters.Invert({});
            
        let hasFilter  = false;
        for(let j = 0; j < this.canvas.getObjects()[idx].filters.length ; j++){
          if(this.canvas.getObjects()[idx].filters[j]['invert'] != undefined){
            this.canvas.getObjects()[idx].filters[j]['invert'] = value;
            hasFilter = true;
          }
        }
        if(!hasFilter){
          this.canvas.getObjects()[idx].filters.push(filter);
        }

        if(type == this.leftFilenameCacheKey){
          this.sourceImageImg = this.canvas.getObjects()[idx];
        }else{
          this.similarImageImg = this.canvas.getObjects()[idx];
        }
    
        this.canvas.getObjects()[idx].applyFilters();
        this.canvas.renderAll();
  }

  onChangeFilterSaturation(type:string){

    let idx = 0;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == type){
        idx = i;
        break;
      }
    }

    let value = 0;
    if(type == this.leftFilenameCacheKey){
      value = this.adjustmentSourceSaturation / 100;
    }else{
      value = this.adjustmentSimilarSaturation / 100;
    }

    var filter = new fabric.Image.filters.Saturation({
      saturation: value
    });
    
    let hasFilter  = false;
    for(let j = 0; j < this.canvas.getObjects()[idx].filters.length ; j++){
      if(this.canvas.getObjects()[idx].filters[j]['saturation'] != undefined){
        this.canvas.getObjects()[idx].filters[j]['saturation'] = value;
        hasFilter = true;
      }
    }
    if(!hasFilter){
      this.canvas.getObjects()[idx].filters.push(filter);
    }
    if(type == this.leftFilenameCacheKey){
      this.sourceImageImg = this.canvas.getObjects()[idx];
    }else{
      this.similarImageImg = this.canvas.getObjects()[idx];
    }

    this.canvas.getObjects()[idx].applyFilters();
    this.canvas.renderAll();
  }

  
  removeAllFilter(type:string){

    let idx = 0;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == type){
        idx = i;
        break;
      }
    }
    for(let j = 0; j < this.canvas.getObjects()[idx].filters.length ; j++){
      if(this.canvas.getObjects()[idx].filters[j]['saturation'] != undefined){
        this.canvas.getObjects()[idx].filters[j]['saturation'] = 0;
      }
      if(this.canvas.getObjects()[idx].filters[j]['contrast'] != undefined){
        this.canvas.getObjects()[idx].filters[j]['contrast'] = 0;
      }
      if(this.canvas.getObjects()[idx].filters[j]['brightness'] != undefined){
        this.canvas.getObjects()[idx].filters[j]['brightness'] = 0;
      }
      if(this.canvas.getObjects()[idx].filters[j]['invert'] != undefined){
        this.canvas.getObjects()[idx].filters[j]['invert'] = false;
      }
    }
    if(type == this.leftFilenameCacheKey){
      this.sourceImageImg = this.canvas.getObjects()[idx];
    }else{
      this.similarImageImg = this.canvas.getObjects()[idx];
    }

    this.canvas.getObjects()[idx].applyFilters();
    this.canvas.renderAll();

  }
  
  // submitWebImage(type:string){

  //   this.blocked = true;

  //   this.sourceImageImgWebID = null;
  //   this.similarImageImgWebID = null;
  //   this.internetImageImgWebTemp = null;

  //   if(type == 'source'){

  //     let imageid = this.leftFilename;
  //     this.dataService.submitForInternetSearch(this.id, imageid)
  //     .subscribe(result => {
  //       console.log(result);
  //       if(result['status'] == 'success'){
  //         let key = result['key'];
  //         this.sourceImageImgWebID = key;
  //         this.downloadInternetReport(type);
  //       }
  //     });

  //     // fabric.Image.fromURL(this.baseUrl+'/image/'+this.id+'/'+this.leftFilename, (img) => {

  //     //   // var dataURLSrc = img.toDataURL({});
  //     //   // let anaobj:Web = new Web();
  //     //   // anaobj.target = dataURLSrc;


  //     // }, {crossOrigin: 'anonymous'});
      
  //   }else{

  //     let imageid =  this.rightFilenameRecordId + '_' + this.rightFilename;
  //     this.dataService.submitForInternetSearch(this.id, imageid)
  //     .subscribe(result => {
  //       console.log(result);
  //       if(result['status'] == 'success'){
  //         let key = result['key'];
  //         this.similarImageImgWebID = key;
  //         this.downloadInternetReport(type);
  //       }
  //     });

  //     // fabric.Image.fromURL(this.baseUrl+'/image/'+this.rightFilenameRecordId+'/'+this.rightFilename, (img) => {
  
  //     //   // var dataURLSrc = img.toDataURL({});
  //     //   // let anaobj:Web = new Web(); 
  //     //   // anaobj.target = dataURLSrc;

  //     //   this.dataService.submitForInternetSearch(this.id, imageid)
  //     //     .subscribe(result => {
  //     //       if(result['status'] == 'success'){
  //     //         let key = result['key'];
  //     //         this.similarImageImgWebID = key;
  //     //         this.downloadInternetReport(type);
  //     //       }
  //     //   });

  //     // }, {crossOrigin: 'anonymous'});

  //   } 
  // }


  downloadInternetReport(type:string){

    let traceid = '';
    if(type == 'source'){
      traceid = this.sourceImageImgWebID;
    }else{
      traceid = this.similarImageImgWebID;
    }

    this.dataService.downloadInternetResult(this.id, traceid)
            .subscribe(result => {
        
        this.blocked = false;
              
        if(type == 'source'){
          this.internetImageImgWebTemp = result;
          this.internetImageImgWebTemp['type'] = 'source';
        }else{
          this.internetImageImgWebTemp = result;
          this.internetImageImgWebTemp['type'] = 'similar';
        }

        console.log(this.internetImageImgWebTemp);

        this.searchResultDialog = true;

    });

  }

  
  // confirmStoreInternetCase() {

  //   console.log('run');
  //   this.confirmationService.confirm({
  //       message: 'Are you sure that you want to save this Internet search Results?',
  //       header: 'Save Analysis Case',
  //       icon: 'pi pi-save',
  //       accept: () => {
  //         this.processStoreInternetCase();
  //       },
  //       reject: (type) => {
  //       }
  //   });
  // }

  // processStoreInternetCase(){
    
  //   // here to store the Internet Analysis Case

  //   this.blocked = true;

  //   var type = this.internetImageImgWebTemp['type'];
        
  //   if(type == 'source'){

  //       // Here to store the source case result
  //       this.addtionalInformationSource = null;
        
  //       this.dataService.getImageMetadata(this.id, this.leftFilename)
  //       .subscribe(source =>{

  //         if(source['status'] == 'success'){
  //           this.addtionalInformationSource = source;
  //         }
          
  //         let caseobj:Case = new Case();
          
  //         caseobj.type = 'internet';
  //         caseobj.source = 'source';
  //         caseobj.internet = this.sourceImageImgWebID;
  //         caseobj.analysis = 'manipulation';
          
  //         // caseobj.analysis = this.analysis+'_'+this.pair['img1']['original_file']+'_'+this.pair['img2']['original_file']; 
  //         caseobj.institution = this.record['institution'];

  //         caseobj.sourceFilename = this.leftFilename;
  //         caseobj.sourceRecord = this.leftFilenameRecordId;
  //         caseobj.sourceRepository = this.pair['img1']['repository'];
          
  //         if(this.leftFilename != null){
  //           caseobj.sourceId = this.id + "_" + this.leftFilename;
  //         }
          
  //         if(this.addtionalInformationSource != null){
  //           caseobj.sourceTitle = this.addtionalInformationSource['record']['title'];
  //           if(this.addtionalInformationSource['image'] != null){
  //             caseobj.sourceDescription = this.addtionalInformationSource['image']['description'];
  //           }
  //           if(this.addtionalInformationSource['record'] != null && this.addtionalInformationSource['record']['doi'] != null){
  //             caseobj.sourceDoi = this.addtionalInformationSource['record']['doi'];
  //           }
  //         }
      
  //         this.dataService.saveAnalysisCase(this.id, caseobj)
  //         .subscribe(result => {
  //           if(result['status'] == 'success'){
  //             this.messageService.add({life: GlobalConstants.life, severity:'success', summary:'Save Result', detail:'The internet result has been saved successfully.'});
  //           }   
  //           this.loadCaseSummary();
  //           this.blocked = false;   
    
  //         });
    
  //       }); 

  //   }else{

  //       // Here to store the similar case result
  //       this.addtionalInformationSimilar = null;

  //       // this.dataService.getSimilarImageMetadata(this.id, this.rightFilenameRecordId, this.rightFilename)
  //       this.dataService.getImageMetadata(this.id, this.rightFilenameRecordId +'_'+ this.rightFilename)
  //       .subscribe(target =>{
  //         if(target['status'] == 'success'){
  //           this.addtionalInformationSimilar = target;
  //         }

  //         // Here to update the case

  //         let caseobj:Case = new Case();
        
  //         caseobj.type = 'internet';
  //         caseobj.source = 'similar';
  //         caseobj.internet = this.similarImageImgWebID;
          
  //         caseobj.analysis = this.analysis;
  //         caseobj.institution = this.record['institution'];

  //         caseobj.targetFilename = this.rightFilename;
  //         caseobj.targetRecord = this.rightFilenameRecordId;
  //         caseobj.targetRepository = this.pair['img2']['repository'];

  //         if(this.rightFilename != null && this.rightFilenameRecordId != null){
  //           caseobj.targetId = this.rightFilenameRecordId + "_" + this.rightFilename;
  //         }

  //         if(this.addtionalInformationSimilar != null){
  //           caseobj.targetTitle = this.addtionalInformationSimilar['record']['title'];
  //           if(this.addtionalInformationSimilar['image'] != null){
  //            caseobj.targetDescription = this.addtionalInformationSimilar['image']['description'];
  //           }
  //           if(this.addtionalInformationSimilar['record'] != null && this.addtionalInformationSimilar['record']['doi'] != null){
  //            caseobj.targetDoi = this.addtionalInformationSimilar['record']['doi'];
  //           }
  //         }
      
  //         this.dataService.saveAnalysisCase(this.id, caseobj)
  //         .subscribe(result => {
  //           if(result['status'] == 'success'){
  //             this.messageService.add({life: GlobalConstants.life, severity:'success', summary:'Save Result', detail:'The internet result has been saved successfully.'});
  //           }   
  //           this.loadCaseSummary();
  //           this.blocked = false;   
  //         });

  //       });
  
  //   } // end of the setting
    

  // }
  
  confirmStoreCase() {

    console.log('run');
    this.confirmationService.confirm({
        message: this.translate.instant('manipulation-viewer.dialog-saved-case-confirm'),
        header: this.translate.instant('manipulation-viewer.dialog-saved-case-title'),
        icon: 'pi pi-save',
        accept: () => {
          this.processStoreAnalysisCase();  
        },
        reject: (type) => {
        }
    });
  }

  processStoreAnalysisCase(){

    this.blocked = true;
    var dataUrl = this.canvas.toDataURL({
      format: "png",
      left: 0,
      top: 0,
    }); 

    this.addtionalInformationSource = null;
      this.dataService.getImageMetadata(this.id, this.leftFilename)
      .subscribe(source =>{
        if(source['status'] == 'success'){
          this.addtionalInformationSource = source;
        }
        
        this.addtionalInformationSimilar = null;
        // this.dataService.getSimilarImageMetadata(this.id, this.rightFilenameRecordId, this.rightFilename)
        this.dataService.getImageMetadata(this.id, this.rightFilenameRecordId +'_'+ this.rightFilename)
        .subscribe(target =>{
          if(target['status'] == 'success'){
            this.addtionalInformationSimilar = target;
          }

          // Here to update the case

          let caseobj:Case = new Case();
        
          caseobj.type = 'manually';
          
          caseobj.analysis = 'manipulation'; 

          //caseobj.analysis = pair['analysis']+'_'+pair['img1']['original_file']+'_'+pair['img2']['original_file']; 
          // caseobj.institution = this.record['institution'];

          caseobj.sourceFilename = this.leftFilename;
          caseobj.sourceRecord = this.leftFilenameRecordId;
          // caseobj.sourceRepository = this.pair['img1']['repository'];
          
          if(this.leftFilename != null){
            caseobj.sourceId = this.leftFilenameRecordId + "_" + this.leftFilename;
          }
          caseobj.targetFilename = this.rightFilename;
          caseobj.targetRecord = this.rightFilenameRecordId;
          // caseobj.targetRepository = this.pair['img2']['repository'];

          if(this.rightFilename != null && this.rightFilenameRecordId != null){
            caseobj.targetId = this.rightFilenameRecordId + "_" + this.rightFilename;
          }

          caseobj.image = dataUrl;
          
          if(this.addtionalInformationSource != null){
            caseobj.sourceTitle = this.addtionalInformationSource['record']['title'];
           if(this.addtionalInformationSource['image'] != null){
            caseobj.sourceDescription = this.addtionalInformationSource['image']['description'];
           }
           if(this.addtionalInformationSource['record'] != null && this.addtionalInformationSource['record']['doi'] != null){
            caseobj.sourceDoi = this.addtionalInformationSource['record']['doi'];
           }
          }
          if(this.addtionalInformationSimilar != null){
            caseobj.targetTitle = this.addtionalInformationSimilar['record']['title'];
            if(this.addtionalInformationSimilar['image'] != null){
             caseobj.targetDescription = this.addtionalInformationSimilar['image']['description'];
            }
            if(this.addtionalInformationSimilar['record'] != null && this.addtionalInformationSimilar['record']['doi'] != null){
             caseobj.targetDoi = this.addtionalInformationSimilar['record']['doi'];
            }
          }
      
          this.dataService.saveAnalysisCase(this.id, caseobj)
          .subscribe(result => {
            if(result['status'] == 'success'){
              this.messageService.add({life: GlobalConstants.life, severity:'success', summary: this.translate.instant('manipulation-viewer.toast-saved-case-title') , detail: this.translate.instant('manipulation-viewer.toast-saved-case-success') });
            }   
            this.loadCaseSummary(); 
            this.blocked = false;   
          });

        });
  
    }); 

  }


  flipImage(type:string){
    if(type == 'source'){
      for(let i=0; i < this.canvas.getObjects().length ; i++){
        if(this.canvas.getObjects()[i]['cacheKey'] == this.leftFilenameCacheKey){
          this.canvas.getObjects()[i].toggle('flipX');
          this.canvas.renderAll();
        }
      }
    }else{
      for(let i=0; i < this.canvas.getObjects().length ; i++){
        if(this.canvas.getObjects()[i]['cacheKey'] == this.rightFilenameCacheKey){
          this.canvas.getObjects()[i].toggle('flipX');
          this.canvas.renderAll();
        }
      }
    }
  }

  turnRight(type:string){
    if(type == 'source'){
      for(let i=0; i < this.canvas.getObjects().length ; i++){
        if(this.canvas.getObjects()[i]['cacheKey'] == this.leftFilenameCacheKey){
          var tempvalue = this.canvas.getObjects()[i]['angle'];
          if(tempvalue == 270){
            tempvalue = 0;
          }else{
            tempvalue += 90;
          }
          this.canvas.getObjects()[i].rotate(tempvalue);
          this.canvas.renderAll();
        }
      }
    }else{
      for(let i=0; i < this.canvas.getObjects().length ; i++){
        if(this.canvas.getObjects()[i]['cacheKey'] == this.rightFilenameCacheKey){
          var tempvalue = this.canvas.getObjects()[i]['angle'];
          if(tempvalue == 270){
            tempvalue = 0;
          }else{
            tempvalue += 90;
          }
          this.canvas.getObjects()[i].rotate(tempvalue);
          this.canvas.renderAll();
        }
      }
    }
  } 

  reloadImage(type:string){
    if(type == 'source'){ 
      this.addSourceImageToViewer();
      this.filterListSourceSelected = this.filterList[0];
      this.adjustmentSourceContrast = 0;
      this.adjustmentSourceBrightness = 0;
      this.adjustmentSourceSaturation = 0;
      this.adjustmentSourceInvert = false;
    }else{
      this.addSimilarImageToViewer();
      this.filterListSimilarSelected = this.filterList[0];
      this.adjustmentSimilarContrast = 0;
      this.adjustmentSimilarBrightness = 0;
      this.adjustmentSimilarSaturation = 0;
      this.adjustmentSimilarInvert = false;
    }
  }

 
  loadAdditionalInformation(type:string){
    this.addtionalInformationDisplay = null;
    if(type == 'source'){
      this.addtionalInformationSource = null;
      this.dataService.getImageMetadata(this.id, this.leftFilename)
      .subscribe(source =>{
        if(source['status'] == 'success'){
          this.addtionalInformationSource = source;
          this.addtionalInformationDisplay = source;
          this.addtionalInformationDisplay['width'] = this.sourceImageImg.original_width;
          this.addtionalInformationDisplay['height'] = this.sourceImageImg.original_height;
          if(this.addtionalInformationDisplay != null){
            this.addtionalInformationDialog = true;
          }else{
            this.messageService.add({life: GlobalConstants.life, severity:'warn', summary:this.translate.instant('manipulation-viewer.toast-load-metadata-title'), detail:this.translate.instant('manipulation-viewer.toast-load-metadata-error')});     
          }
        }else{
          this.messageService.add({life: GlobalConstants.life, severity:'warn', summary:this.translate.instant('manipulation-viewer.toast-load-metadata-title'), detail:this.translate.instant('manipulation-viewer.toast-load-metadata-error')});     
        }
      });  
    }else{
      this.addtionalInformationSimilar = null;
      //rightFilenameRecordId
      //this.dataService.getImageMetadata(this.id, this.rightFilename)
      // alert(this.rightFilenameRecordId+"_"+this.rightFilename);
      this.dataService.getImageMetadata(this.id, this.rightFilenameRecordId+"_"+this.rightFilename)
      .subscribe(target =>{
        console.log(target);
        if(target['status'] == 'success'){
          this.addtionalInformationSimilar = target;
          this.addtionalInformationDisplay = target;
          this.addtionalInformationDisplay['width'] = this.similarImageImg.original_width;
          this.addtionalInformationDisplay['height'] = this.similarImageImg.original_height;
          if(this.addtionalInformationDisplay != null){
            this.addtionalInformationDialog = true;
          }else{
            this.messageService.add({life: GlobalConstants.life, severity:'warn', summary:this.translate.instant('manipulation-viewer.toast-load-metadata-title'), detail:this.translate.instant('manipulation-viewer.toast-load-metadata-error')});     
          }
        }else{
          this.messageService.add({life: GlobalConstants.life, severity:'warn', summary:this.translate.instant('manipulation-viewer.toast-load-metadata-title'), detail:this.translate.instant('manipulation-viewer.toast-load-metadata-error')});     
        }
      });
    }
  }

  ZoomIn(type:string){
    var idx = 0;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == type){
        idx = i;
        break;
      }
    }
    var current = this.canvas.getObjects()[idx]['scaleX'];
    current = current * 1.25;
    this.canvas.getObjects()[idx].scale(current);
    this.canvas.renderAll(); 
    
    if(type == this.leftFilenameCacheKey){
      this.sourceImageImg['after_width'] = Math.round(this.canvas.getObjects()[idx]['width'] * this.canvas.getObjects()[idx]['scaleX']);
      this.sourceImageImg['after_height'] = Math.round(this.canvas.getObjects()[idx]['height'] * this.canvas.getObjects()[idx]['scaleY']);
    }else{
      this.similarImageImg['after_width'] = Math.round(this.canvas.getObjects()[idx]['width'] * this.canvas.getObjects()[idx]['scaleX']);
      this.similarImageImg['after_height'] = Math.round(this.canvas.getObjects()[idx]['height'] * this.canvas.getObjects()[idx]['scaleY']);
    }

    
  }

  ZoomOut(type:string){
    var idx = 0;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == type){
        idx = i;
        break;
      }
    }
    var current = this.canvas.getObjects()[idx]['scaleX'];
    current = current * 0.75;
    this.canvas.getObjects()[idx].scale(current);
    this.canvas.renderAll();  
    
    if(type == this.leftFilenameCacheKey){
      this.sourceImageImg['after_width'] = Math.round(this.canvas.getObjects()[idx]['width'] * this.canvas.getObjects()[idx]['scaleX']);
      this.sourceImageImg['after_height'] = Math.round(this.canvas.getObjects()[idx]['height'] * this.canvas.getObjects()[idx]['scaleY']);
    }else{
      this.similarImageImg['after_width'] = Math.round(this.canvas.getObjects()[idx]['width'] * this.canvas.getObjects()[idx]['scaleX']);
      this.similarImageImg['after_height'] = Math.round(this.canvas.getObjects()[idx]['height'] * this.canvas.getObjects()[idx]['scaleY']);
    }

  }

  linkViaDoi(doi:string){
    if(doi.indexOf('doi') != -1){
      let afterdoi = doi.substring(doi.indexOf('doi') + 1);
          afterdoi = doi.substring(doi.indexOf('/')+1);
          doi = afterdoi;
    }
    window.open('https://doi.org/'+doi);
  }

  toggleDrawingMode(color:string){
    this.drawingColor = color;
    this.isDrawingMode = true;
    this.canvas.set({
      isDrawingMode: true,
    });
    this.canvas.freeDrawingBrush.color = this.drawingColor;
    this.canvas.freeDrawingBrush.width = 5;
    this.messageService.add({life: GlobalConstants.life, severity:'success', summary:this.translate.instant('duplication-viewer.toast-enter-edit-mode')});
  }

  toggleIntersectingMode(){
    if(this.isIntersectingMode == true){
      this.isIntersectingMode = false;
      this.messageService.add({life: GlobalConstants.life, severity:'success', summary:this.translate.instant('manipulation-viewer.toast-enter-intersection-mode') });
    }else{
      this.isIntersectingMode = true;
      this.messageService.add({life: GlobalConstants.life, severity:'success', summary:this.translate.instant('manipulation-viewer.toast-leave-intersection-mode')});
    }
  }

  
  enterText:string = '';
  enterTextColor:string = 'black';
  enterTextDialog:boolean = false;
  openEnterTextDialog(color:string){
    this.enterTextColor = color;
    this.enterText = '';
    this.enterTextDialog = true;
  }
  
  // Add Text to the canvas
  drawText(note:string){
    var spot = new fabric.Text(note,{
      left: this.canvas.width/2,
      top: 65,
      fill: this.enterTextColor,
      fontSize: 20
    });
    this.canvas.add(spot);
    
  }

  confirmEnterText(){
    this.enterTextDialog = false;
    if(this.enterText.trim().length > 0){
      this.drawText(this.enterText.trim());
    }
  }
  
  addSquareToCanvas(color:string){

    var leftPosition = Math.round((this.canvas.width / 2) - (250/2));
    var rect = new fabric.Rect({
      left: leftPosition,
      top: 100,
      width: 250,
      height: 250,
      fill: '#00000000',
      stroke: color,
      strokeWidth: 3
    });
    this.canvas.add(rect);
  }
  
  addCircleToCanvas(color:string){
    // create an circle which in red color and size is 250*250 then add to the canvas
    
    var leftPoistion = Math.round((this.canvas.width / 2) - (250/2)); 

    var circle = new fabric.Circle({
      left: leftPoistion,
      top: 100,
      radius: 125,
      fill: '#00000000',
      stroke: color,
      strokeWidth: 3
    });
    this.canvas.add(circle);

  }

  leaveDrawingMode(){
    this.isDrawingMode = false;
    this.canvas.set({
      isDrawingMode: false
    });
    this.messageService.add({life: GlobalConstants.life, severity:'success', summary:this.translate.instant('duplication-viewer.toast-leave-edit-mode')});
  }
  

}