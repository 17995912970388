
import { Component, OnInit, HostListener, SystemJsNgModuleLoader} from '@angular/core';
import { Location } from '@angular/common';
import { GlobalConstants } from '../common/global-constants';
import { DataService } from '../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { timer } from 'rxjs/internal/observable/timer';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';

import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

interface filterType {
  name: string,
  code: string
}
@Component({
  selector: 'app-analysis-case',
  templateUrl: './analysis-case.component.html',
  styleUrls: ['./analysis-case.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class AnalysisCaseComponent implements OnInit {

  blocked: boolean;

  filters: filterType[];
  selectedFilterType: filterType;

  baseUrl: string;
  id: string;
  record: any;
  cases: any;
  basecases: any;

  analysisCaseDialog: boolean;
  analysisCaseSelectedId: number;
  analysisCaseRecord: any;

  internetCaseDialog: boolean;
  internetCaseSelectedId: number;
  internetCaseRecord:any; 
  internetCaseImageId: string;
  
  internetImageImgWebTemp: any;

  metadata: any;

  high:number;
  medium:number;
  result:any;

  casescache: any;
  confirmDuplicationResult:any;
  confirmManipulationResult:any;

  hasDuplicationDownloadStatus: boolean;
  isPreparingDuplicationPDF: boolean;
  downloadDuplicationPDFText: string;
  downloadDuplicationPDFSize: string;

  hasManipulationDownloadStatus: boolean;
  isPreparingManipulationPDF: boolean;
  downloadManipulationPDFText: string;
  downloadManipulationPDFSize: string;

  browserLangugae: string;

  duplicationSize: number;
  manipulationSize: number;

  defaultLang: string;

  tabDuplicationSelected: boolean;
  tabManipulationSelected: boolean;
  
  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private location: Location,
    private route: ActivatedRoute,
    private proute: Router,
    public cookieService: CookieService,
    public translate: TranslateService,
    private dataService: DataService) {

    this.blocked = false;
    this.baseUrl = GlobalConstants.apiURL;
    this.analysisCaseDialog = false;
    this.internetCaseDialog = false;
    
    this.high = GlobalConstants.high;
    this.medium = GlobalConstants.medium;
    this.confirmDuplicationResult = [];
    this.confirmManipulationResult = [];
    this.casescache = {};

    this.duplicationSize = 0;
    this.manipulationSize = 0;

    this.tabDuplicationSelected = true;
    this.tabManipulationSelected = false;

    this.browserLangugae = navigator.language;

    let lang = this.proute['browserUrlTree']['queryParams']['lang'];
    if(lang != undefined){        
      this.cookieService.set('lang', lang);
      translate.use(lang);
      this.defaultLang = lang;
    }else{
      let tempLang = this.cookieService.get('lang');
      if(tempLang != '' && tempLang != undefined){
        translate.use(tempLang);
        this.defaultLang = tempLang;
      }else{          
        translate.use(translate.getBrowserLang());
        this.defaultLang = translate.getBrowserLang();
      }
    }

    // translate.use(translate.getBrowserLang());

    this.filters = [
      {name: this.translate.instant('case.filter-all'), code: 'all'},
      {name: this.translate.instant('case.filter-analysis'), code: 'analysis'},
      {name: this.translate.instant('case.filter-duplication'), code: 'duplication'},
      {name: this.translate.instant('case.filter-duplication-within'), code: 'duplication-within'},
      {name: this.translate.instant('case.filter-manipulation'), code: 'manipulation'},
    ];

  }
  
  ngOnInit() {

    this.downloadDuplicationPDFText = this.translate.instant('case.button-download');
    this.isPreparingDuplicationPDF = false;
    this.hasDuplicationDownloadStatus = false;

    this.downloadManipulationPDFText = this.translate.instant('case.button-download');
    this.isPreparingManipulationPDF = false;
    this.hasManipulationDownloadStatus = false;

    this.blocked = true;
    this.selectedFilterType = {name: 'ALL', code: 'all'};
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadRecordMetadata();
    this.loadWholeResult();
    this.loadDuplicationPDFStatus();
    this.loadManipulationPDFStatus();

  }

  back() {
    this.location.back();
  }

  loadDuplicationPDFStatus(){

    this.dataService.getDuplicationPDFStatus(this.id)
      .subscribe(data => {

        this.hasDuplicationDownloadStatus = true;

        if(data['status'] ==  true && data['progress'] == 'processing'){
            this.isPreparingDuplicationPDF = true;
            this.downloadDuplicationPDFText = this.translate.instant('case.button-processing'); 

            timer(3000).subscribe(t => {  
              this.loadDuplicationPDFStatus();
            });

        }else if(data['status'] ==  false){
          this.isPreparingDuplicationPDF = false;
          this.downloadDuplicationPDFText = this.translate.instant('case.button-generate-report'); 
        }else if(data['status'] ==  true && data['progress'] == 'ready'){
          this.isPreparingDuplicationPDF = false;
          this.downloadDuplicationPDFText = this.translate.instant('case.button-download'); 

          if(data.hasOwnProperty('size')){
            this.downloadDuplicationPDFText = this.downloadDuplicationPDFText + ' (' + data['size'] + ' MB)';
          }

        }else{
          this.isPreparingDuplicationPDF = false;
          this.downloadDuplicationPDFText = this.translate.instant('case.button-generate-report'); 
        }
    });

  }

  loadManipulationPDFStatus(){

    this.dataService.getManipulationPDFStatus(this.id)
      .subscribe(data => {

        this.hasManipulationDownloadStatus = true;

        if(data['status'] ==  true && data['progress'] == 'processing'){
            this.isPreparingManipulationPDF = true;
            this.downloadManipulationPDFText = this.translate.instant('case.button-processing'); 

            timer(3000).subscribe(t => {  
              this.loadManipulationPDFStatus();
            });

        }else if(data['status'] ==  false){
          this.isPreparingManipulationPDF = false;
          this.downloadManipulationPDFText = this.translate.instant('case.button-generate-report'); 
        }else if(data['status'] ==  true && data['progress'] == 'ready'){
          this.isPreparingManipulationPDF = false;
          this.downloadManipulationPDFText = this.translate.instant('case.button-download'); 
          if(data.hasOwnProperty('size')){
            this.downloadManipulationPDFText = this.downloadManipulationPDFText + ' (' + data['size'] + ' MB)';
          }
        }else{
          this.isPreparingManipulationPDF = false;
          this.downloadManipulationPDFText = this.translate.instant('case.button-generate-report'); 
        }
    });

  }

  
  downloadDuplicationReport() {

    this.dataService.getDuplicationPDFStatus(this.id)
      .subscribe(data => {

        this.hasDuplicationDownloadStatus = true;
        if(data['status'] ==  true && data['progress'] == 'processing'){
            this.isPreparingDuplicationPDF = true;
            this.downloadDuplicationPDFText = this.translate.instant('case.button-processing'); 
            timer(3000).subscribe(t => {  
              this.loadDuplicationPDFStatus();
            });
        }else if(data['status'] ==  false  ){
          this.isPreparingDuplicationPDF = true;
          this.downloadDuplicationPDFText = this.translate.instant('case.button-processing'); 

          timer(3000).subscribe(t => {              
            this.dataService.initialDuplicationPDF(this.id, this.browserLangugae)
                .subscribe(data => {
                    this.loadDuplicationPDFStatus();
                });
          });
          
          timer(15000).subscribe(t => {    
            this.loadDuplicationPDFStatus();
          });
          
        }else if(data['status'] ==  true && data['progress'] == 'ready'){
          this.isPreparingDuplicationPDF = false;
          this.downloadDuplicationPDFText = this.translate.instant('case.button-download'); 
          window.open(this.baseUrl+'/pdf/duplication/' + this.id + '/download', '_self');
        }else if(data['progress'] == 'error' || data['progress'] == 'failed'){
          
          timer(30000).subscribe(t => {    
            this.loadDuplicationPDFStatus();
          });
          // this.isPreparingDuplicationPDF = false;
          // this.downloadDuplicationPDFText = this.translate.instant('case.button-error'); 
        }
    });
  }

  downloadManipulationReport() {

    this.dataService.getManipulationPDFStatus(this.id)
      .subscribe(data => {

        this.hasManipulationDownloadStatus = true;
        if(data['status'] ==  true && data['progress'] == 'processing'){
            this.isPreparingManipulationPDF = true;
            this.downloadManipulationPDFText = this.translate.instant('case.button-processing'); 
            timer(3000).subscribe(t => {  
              this.loadManipulationPDFStatus();
            });
        }else if(data['status'] ==  false  ){
          this.isPreparingManipulationPDF = true;
          this.downloadManipulationPDFText = this.translate.instant('case.button-processing'); 

          timer(3000).subscribe(t => {              
            this.dataService.initialManipulationPDF(this.id, this.browserLangugae)
                .subscribe(data => {
                    this.loadManipulationPDFStatus();
                });
          });
          
          timer(15000).subscribe(t => {    
            this.loadManipulationPDFStatus();
          });
          
        }else if(data['status'] ==  true && data['progress'] == 'ready'){
          this.isPreparingManipulationPDF = false;
          this.downloadManipulationPDFText = this.translate.instant('case.button-download'); 
          window.open(this.baseUrl+'/pdf/manipulation/' + this.id + '/download', '_self');
        }else if(data['progress'] == 'error' || data['progress'] == 'failed'){
          
          timer(30000).subscribe(t => {    
            this.loadManipulationPDFStatus();
          });
          // this.isPreparingManipulationPDF = false;
          // this.downloadManipulationPDFText = this.translate.instant('case.button-error'); 
        }
    });
  }



  openAnalysisDialog(caseid: string) {
    // this.analysisCaseSelectedId = idx;
    this.analysisCaseDialog = true;
    // var tempCase = this.cases['data'][idx];
    // console.log(tempCase);
    this.dataService.getStoreCaseDetail(this.id, caseid)
      .subscribe(data => {
        this.analysisCaseRecord  = data['data'];
    });

  }

  // openInternetDialog(idx: number) {
  //   this.internetCaseSelectedId = idx;

  //   var tempCase = this.cases['data'][idx];
  //   console.log(tempCase);

  //   if(tempCase['source'] == 'source'){
  //     this.internetCaseImageId = tempCase['sourceFilename'];
  //   }else{
  //     this.internetCaseImageId = tempCase['targetId'];
  //   }

  //   // alert('get Store Internet Case');

  //   this.downloadInternetReport(tempCase['internet']);

  // }

  confirmRemoveStoredCase(pair: any) {

    this.confirmationService.confirm({
      message: this.translate.instant('case.dialog-delete-message'),
      header: this.translate.instant('case.dialog-delete-title'),
      icon: 'pi pi-trash',
      accept: () => {

        let pairKey = '';

        if(pair['duplication_type'] == undefined){
          pairKey = 'manipulation_' + pair['id'] + '_' + pair['id'];
        }else{
          pairKey = pair['analysis'] + '_' + pair['img1']['id'] + '_' + pair['img2']['id'];
        }

        this.blocked = true;
        
        for(let i = 0; i < this.cases['data'].length ; i++){
          let sourceId = this.cases['data'][i]['sourceRecord'] + '_' + this.cases['data'][i]['sourceFilename'];
          let targetId = this.cases['data'][i]['targetRecord'] + '_' + this.cases['data'][i]['targetFilename'];
          let caseKey = this.cases['data'][i]['analysis'] + '_' + sourceId + '_' + targetId;

          if(pairKey == caseKey){
            this.dataService.deleteStoreCase(this.id, this.cases['data'][i]['id'])
            .subscribe(result => {
              this.loadDuplicationPDFStatus();
              this.loadManipulationPDFStatus();
            });
          }
        }

        timer(3000).subscribe(t => {  
          this.loadFullAnalysisCase();
        }); 

      },
      reject: (type) => {
      }
    });
  }

  confirmRemoveCase(key: string) {

    this.confirmationService.confirm({
      message: this.translate.instant('case.dialog-delete-message'),
      header: this.translate.instant('case.dialog-delete-title'),
      icon: 'pi pi-trash',
      accept: () => {
        this.processStoprocessDeleteCasereCase(key);
      },
      reject: (type) => {
      }
    });
  }

  processStoprocessDeleteCasereCase(delkey: string) {

    // this.blocked = true;
    this.dataService.deleteStoreCase(this.id, delkey)
      .subscribe(result => {
        if (result['status'] == 'success') {
          this.messageService.add({ life: GlobalConstants.life, severity: 'success', summary:  this.translate.instant('case.toast-delete-title'), detail:  this.translate.instant('case.toast-delete-success') });
        }
        this.loadFullAnalysisCase();
        this.loadDuplicationPDFStatus();
        this.loadManipulationPDFStatus();
      });

  }

  loadRecordMetadata() {
    this.dataService.metadata(this.id, 'all')
      .subscribe(data => {
        this.record = data;
        this.loadAdditionalInformation();
        // this.loadAnalysisCase();
      });
  } 
  
  loadWholeResult(){
    this.dataService.whole(this.id)
                      .subscribe(data =>{
                        this.result = data;
                        for(let i=0; i < this.result['similarity'].length ; i++){
                            let pair = this.result['similarity'][i];
                            let img1 = pair['img1'];

                            this.result['similarity'][i]['histogramP'] = Math.floor(this.result['similarity'][i]['histogram'] * 100);
                            this.result['similarity'][i]['phashP'] = Math.floor((1-this.result['similarity'][i]['phash']) * 100);
                            this.result['similarity'][i]['similarityP'] = Math.floor(this.result['similarity'][i]['similarity'] * 100);
                            this.result['similarity'][i]['resolutionImg1'] = parseInt(img1['crop_info']['width']) * parseInt(img1['crop_info']['height']);
                        }
                        // console.log('load duplication result');
                        // console.log(this.result);
                        this.loadFullAnalysisCase();
                      });
  }


  loadFullAnalysisCase() {
    this.confirmDuplicationResult = [];
    this.confirmManipulationResult = [];
    this.casescache = {};
    this.cases = [];
    this.dataService.getStoreCaseFull(this.id)
      .subscribe(data => {

        this.cases = data;
        this.cases['data'].sort(this.sortByCreatedDate('createdDate'));
        
        for(var i=0; i < this.cases['data'].length ; i++){
          if(this.cases['data'][i]['sourceId'] == this.cases['data'][i]['targetId'] && 
             this.cases['data'][i]['sourceFilename'] == this.cases['data'][i]['targetFilename'] &&
             this.cases['data'][i]['type'] != 'manually'
          ){
            this.cases['data'][i]['type']  = 'duplication-within';
          }
        }

        for(let i = 0; i < this.cases['data'].length ; i++){
          let sourceId = this.cases['data'][i]['sourceRecord'] + '_' + this.cases['data'][i]['sourceFilename'];
          let targetId = this.cases['data'][i]['targetRecord'] + '_' + this.cases['data'][i]['targetFilename'];
          let key = this.cases['data'][i]['analysis'] + '_' + sourceId + '_' + targetId;

          // console.log(key);
          if(this.casescache[key] == undefined){
            this.casescache[key] = this.cases['data'][i];
          }
        }

        this.updateConfirmAnalysisResult();
    });
  }

  updateConfirmAnalysisResult(){

      this.duplicationSize = 0;

      for(let i=0; i < this.result['similarity'].length ; i++){

          let pair:any = this.result['similarity'][i];
          let pairKey = pair['analysis'] + '_' + pair['img1']['id'] + '_' + pair['img2']['id'];

          if( this.casescache[pairKey]  != undefined){
            let tempcase = this.casescache[pairKey];

            this.result['similarity'][i]['img1']['title'] = tempcase['sourceTitle'];
            this.result['similarity'][i]['img1']['description'] = tempcase['sourceDescription'];
            this.result['similarity'][i]['img1']['doi'] = tempcase['sourceDoi'];
            this.result['similarity'][i]['img2']['title'] = tempcase['targetTitle'];
            this.result['similarity'][i]['img2']['description'] = tempcase['targetDescription'];
            this.result['similarity'][i]['img2']['doi'] = tempcase['targetDoi'];

            //ebcd8e5fa74cc4302b739c940e2d33a0-pdf-page-index-4-crop-14.png
            if(this.result['similarity'][i]['img1']['original_file'].indexOf('pdf') != -1){
                let filename = this.result['similarity'][i]['img1']['original_file'];
                // console.log(filename);
                    filename = filename.substring(filename.indexOf('index')+6);
                    // console.log(filename);
                    filename = filename.substring(0, filename.indexOf('-')+1);
                    // console.log(filename);
                let page = parseInt(filename);
                this.result['similarity'][i]['img1']['page'] = page;
            }
            if(this.result['similarity'][i]['img2']['original_file'].indexOf('pdf') != -1){
              let filename = this.result['similarity'][i]['img2']['original_file'];
              // console.log(filename);
                  filename = filename.substring(filename.indexOf('index')+6);
                  // console.log(filename);
                  filename = filename.substring(0, filename.indexOf('-')+1);
                  // console.log(filename);
              let page = parseInt(filename);
              this.result['similarity'][i]['img2']['page'] = page;
            }
  
            pair['cases'] = [];
            

            for(let j = 0; j < this.cases['data'].length ; j++){

              let sourceId = this.cases['data'][j]['sourceRecord'] + '_' + this.cases['data'][j]['sourceFilename'];
              let targetId = this.cases['data'][j]['targetRecord'] + '_' + this.cases['data'][j]['targetFilename'];
              let casekey = this.cases['data'][j]['analysis'] + '_' + sourceId + '_' + targetId;
              if(casekey == pairKey && this.cases['data'][j]['type'] == 'manually'){
                pair['cases'].push(this.cases['data'][j]);
              }
            }
            this.duplicationSize++;
            this.confirmDuplicationResult.push(pair);
          }

      }

      this.manipulationSize = 0;
      if(this.result['manipulation'] != undefined){

        for(let i=0; i < this.result['manipulation'].length ; i++){

            let pair:any = this.result['manipulation'][i];
            let pairKey = 'manipulation_' + pair['id'] + '_' + pair['id'];
    
            if( this.casescache[pairKey]  != undefined){
              let tempcase = this.casescache[pairKey];
    
              this.result['manipulation'][i]['title'] = tempcase['sourceTitle'];
              this.result['manipulation'][i]['description'] = tempcase['sourceDescription'];
              this.result['manipulation'][i]['doi'] = tempcase['sourceDoi'];
              this.result['manipulation'][i]['title'] = tempcase['targetTitle'];
              this.result['manipulation'][i]['description'] = tempcase['targetDescription'];
              this.result['manipulation'][i]['doi'] = tempcase['targetDoi'];
    
              //ebcd8e5fa74cc4302b739c940e2d33a0-pdf-page-index-4-crop-14.png
              if(this.result['manipulation'][i]['original_file'].indexOf('pdf') != -1){
                  let filename = this.result['manipulation'][i]['original_file'];
                  // console.log(filename);
                      filename = filename.substring(filename.indexOf('index')+6);
                      // console.log(filename);
                      filename = filename.substring(0, filename.indexOf('-')+1);
                      // console.log(filename);
                  let page = parseInt(filename);
                  this.result['manipulation'][i]['page'] = page;
              }
              if(this.result['manipulation'][i]['original_file'].indexOf('pdf') != -1){
                let filename = this.result['manipulation'][i]['original_file'];
                // console.log(filename);
                    filename = filename.substring(filename.indexOf('index')+6);
                    // console.log(filename);
                    filename = filename.substring(0, filename.indexOf('-')+1);
                    // console.log(filename);
                let page = parseInt(filename);
                this.result['manipulation'][i]['page'] = page;
              }
    
              pair['cases'] = [];
    
              for(let j = 0; j < this.cases['data'].length ; j++){
    
                let sourceId = this.cases['data'][j]['sourceRecord'] + '_' + this.cases['data'][j]['sourceFilename'];
                let targetId = this.cases['data'][j]['targetRecord'] + '_' + this.cases['data'][j]['targetFilename'];
                let casekey = this.cases['data'][j]['analysis'] + '_' + sourceId + '_' + targetId;
                if(casekey == pairKey && this.cases['data'][j]['type'] == 'manually'){
                  pair['cases'].push(this.cases['data'][j]);
                }
              }
              
              this.manipulationSize++;
              this.confirmManipulationResult.push(pair);
            }
    
        }
        this.blocked = false;
      }else{
        // Here all the tasks were completed
        this.blocked = false;
      }

  }


  sortByCreatedDate(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return -1;
      } else if (a[prop] < b[prop]) {
        return 1;
      }
      return 0; 
    }
  }


  loadAdditionalInformation() {
    this.dataService.getRecordMetadata(this.id)
      .subscribe(source => {
        if (source['status'] == 'success') {
          this.metadata = source;
        }
      });
  }


  linkViaDoi(doi:string){
    if(doi.indexOf('doi') != -1){
      let afterdoi = doi.substring(doi.indexOf('doi') + 1);
          afterdoi = doi.substring(doi.indexOf('/')+1);
          doi = afterdoi;
    }
    window.open('https://doi.org/'+doi);
  }


  openDuplicationDialog(id: string, img1id: string, img2id: string) {

    var width = document.body.clientWidth;
    var height = document.body.clientHeight;
    var w = Math.round(width * 0.95);
    var h = Math.round(height * 0.9);
    var x = Math.round((width - w) / 2);
    var y = Math.round((height - h) / 2);

    window.open('/duplication-viewer/' + this.id + ';img1=' + img1id + ';img2=' + img2id, this.id,
      'resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no width=' + w + ', height=' + h + ', left=' + x + ', top=' + y);

  }

  updateAnalysisCaseList() {
    this.loadFullAnalysisCase();
    // this.loadAnalysisCase();
  } 

  downloadInternetReport(traceid:string){
    this.dataService.downloadInternetResult(this.id, traceid)
            .subscribe(result => {
              // console.log(result);
              this.internetImageImgWebTemp = result;
              this.internetCaseDialog = true;
    });
  }

  onChangeFilterType(){
    if(this.basecases != undefined){
      if(this.basecases['data'].length > 0){
        let tempCase:any = [];

        // console.log('filter size base:' + this.basecases['data'].length);
        // console.log('filter type:' + this.selectedFilterType['code']);
        for(let i = 0; i < this.basecases['data'].length ; i++){
          // console.log(i + ' filter item:' + this.basecases['data'][i]['type']);
          if(this.selectedFilterType['code'] == 'all'){
            tempCase.push(this.basecases['data'][i]);
          }else if(this.selectedFilterType['code'] == 'analysis' && this.basecases['data'][i]['type'] == 'manually'){
            tempCase.push(this.basecases['data'][i]);
          }else if(this.selectedFilterType['code'] == 'internet' && this.basecases['data'][i]['type'] == 'internet'){
            tempCase.push(this.basecases['data'][i]);
          }else if(this.selectedFilterType['code'] == 'duplication' && this.basecases['data'][i]['type'] == 'duplication'){
            tempCase.push(this.basecases['data'][i]);
          }else if(this.selectedFilterType['code'] == 'duplication-within' && this.basecases['data'][i]['type'] == 'duplication-within'){
            tempCase.push(this.basecases['data'][i]);
          }
        }
        this.cases['data'] = tempCase;
      }
    }
  }

  
  loadWithinImageViewer(img1id:string){

    var width = document.body.clientWidth;
    var height = document.body.clientHeight;
    var w = Math.round(width * 0.95);
    var h = Math.round(height * 0.9);
    var x = Math.round((width - w) / 2);
    var y = Math.round((height - h) / 2);

    window.open('/within-viewer/'+this.id+';img1='+img1id , this.id , 
        'resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no width=' + w + ', height=' + h + ', left=' + x + ', top=' + y );

  }

  // this function is to memorize the selected tab
  handleTabCange(event) {
    if (event.index == 0) {
      this.tabDuplicationSelected = true;
      this.tabManipulationSelected = false;
    }else{
      this.tabDuplicationSelected = false;
      this.tabManipulationSelected = true;
    }
  }

}

