import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { Location } from '@angular/common';
import { GlobalConstants } from '../common/global-constants';
import { fabric } from "fabric";
import { Analysis } from '../entity/Analysis';
import { Filter } from '../entity/Filter';
import { Web } from '../entity/Web';
import { ConfirmationService, ConfirmEventType, MessageService} from 'primeng/api';
import { Case } from '../entity/Case';
import { faGlobeAmericas, faExchangeAlt, faSync, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { timer } from 'rxjs/internal/observable/timer';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

interface Rows{
  value: number;
}

interface ImageFilter{
  name: string,
  key: string
}

@Component({
  selector: 'app-within-viewer',
  templateUrl: './within-viewer.component.html',
  styleUrls: ['./within-viewer.component.scss']
})
export class WithinViewerComponent implements OnInit {
   
  @HostListener('window:resize', ['$event'])
  onScroll(event: any) {
    this.resizeUpdateScreen();
  }

  resizeUpdateScreen(){
    var gap = 25;
    var tempWindowWidth = document.body.clientWidth;
    var tempWindowHeight = document.body.clientHeight;

    var reloadflag = false; 
    if(tempWindowWidth - this.windowWidth > gap ||
      tempWindowHeight - this.windowHeight > gap ||
      this.windowWidth - tempWindowHeight > gap ||
      this.windowHeight - tempWindowHeight > gap){
        reloadflag = true;
    }

    if(reloadflag){
      this.blocked = true;
      const reload = timer(1500);
            reload.subscribe({
              next: function(value) {
                location.reload();
              },
              complete: function() {
                location.reload();
              },
              error: function(error) {
                location.reload();
              }
            });
    }

  }
  
  windowWidth: number;
  windowHeight: number;

  faExchangeAlt = faExchangeAlt;
  faSync = faSync;
  faLayerGroup = faLayerGroup;
  faGlobeAmericas = faGlobeAmericas;

  rows: Rows[];
  canvas: any;

  drawingColor: string;
  baseUrl: string;
  id: string;
  record:any;
  result:any; 
  img1id: string;
  img2id: string;
  pair: any;
  analysis: string;

  leftPanel: boolean;
  rightPanel: boolean;

  asiftPanel: boolean;
  asiftValues: any;

  // pairAnalysis: string;

  leftFilename: string;
  leftFilenameCacheKey: string;
  leftFilenameRecordId: string;

  tempImageImg: any;

  sourceImageImg: any;
  sourceImageImgFilterID: string;
  sourceImageImgFilterTemp: any;
  sourceImageImgWebID: string;
  
  internetImageImgWebTemp: any;
  
  sourceImageFilterPanel: boolean;
  hasAnalyzed: boolean;

  blocked: boolean;

  analysisResult: any;
  analysisTraceId: string;

  filterList: ImageFilter[];
  filterListSourceSelected: ImageFilter;
  filterListSimilarSelected: ImageFilter;

  adjustmentThreshold: number;
  previousAdjustmentThreshold: number;

  searchResultDialog: boolean;
  addtionalInformationDialog: boolean;
  addtionalInformationSource:any;
  addtionalInformationDisplay:any;
 

  isDrawingMode: boolean;
  isIntersectingMode: boolean;

  selectedCount: number;
  caseSummary: any;

  withinDataSet: any;
  withinDataSetSelected: any;

  // selectedWithin: any[] = [];
  // categories: any[] = [];

  withinDataColor: any[];

  defaultLang: string;

  countValidStep: number;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private location: Location,
    private route: ActivatedRoute,
    private proute: Router,
    public cookieService: CookieService,
    public translate: TranslateService,
    private dataService: DataService) { 

      this.withinDataColor = ["#E6194B","#FFE119","#BFEF45",
                              "#3CB44B","#42D4F4","#4363D8",
                              "#911EB4","#F032E6","#DCBEFF",
                              "#AAFFC3","#469990", "#9A6324", 
                              "#808000", "#000075", "#800000"];
      this.withinDataSet = [];
      this.withinDataSetSelected = [];

      this.isDrawingMode = false;
      this.isIntersectingMode = false;

      this.adjustmentThreshold = 4;
      this.previousAdjustmentThreshold = 4;

      this.searchResultDialog = false;
      this.addtionalInformationDialog = false;
      this.hasAnalyzed = false;

      this.leftPanel = true;
      this.rightPanel = true;
      this.asiftPanel = false;
      this.asiftValues = [2,3,5];

      this.countValidStep = 0;

      this.baseUrl = GlobalConstants.apiURL;
      this.filterList = GlobalConstants.filters;

      // this.pairAnalysis = '';
      this.selectedCount = 0;
      this.drawingColor = 'black';

      this.route.params.subscribe(params => {
        if(params['img1'] != undefined){
          this.img1id = params['img1']; 
          this.img2id = params['img1']; 
        }
      });


      let lang = this.proute['browserUrlTree']['queryParams']['lang'];
      if(lang != undefined){        
        this.cookieService.set('lang', lang);
        translate.use(lang);
        this.defaultLang = lang;
      }else{
        let tempLang = this.cookieService.get('lang');
        if(tempLang != '' && tempLang != undefined){
          translate.use(tempLang);
          this.defaultLang = tempLang;
        }else{          
          translate.use(translate.getBrowserLang());
          this.defaultLang = translate.getBrowserLang();
        }
      }
      
    }

  ngOnInit() {
    
    this.windowWidth = document.body.clientWidth;
    this.windowHeight = document.body.clientHeight;
    this.filterListSourceSelected = this.filterList[0];
    this.filterListSimilarSelected = this.filterList[0];

    this.id = this.route.snapshot.paramMap.get('id');
    this.loadRecordMetadata();

    // Fabrics

    this.canvas = new fabric.Canvas('viewer', {
      backgroundColor: '#ebebef',
      selection: false,
      preserveObjectStacking: true
    });

    this.canvas.on("mouse:dblclick", object => {
      if(this.canvas.getActiveObject()['cacheKey'] == undefined){
        this.canvas.remove(this.canvas.getActiveObject());
        this.messageService.add({life: GlobalConstants.life, severity:'warn', summary:'Remove The Object'});  
      }
    });

    this.canvas.on('object:scaling', object => {
      console.log(object);
      if(object['target']['cacheKey'] == this.leftFilenameCacheKey){
        this.sourceImageImg['after_width'] = Math.round(object['target']['width'] * object['target']['scaleX']);
        this.sourceImageImg['after_height'] = Math.round(object['target']['height'] * object['target']['scaleY']);
      }
      this.hasAnalyzed = false;
      if(this.isIntersectingMode == true){
        this.canvas.forEachObject(function(obj) {
          if (obj === object.target) return;
          obj.set('opacity' , object.target.intersectsWithObject(obj) ? 0.5 : 1);
        });
      }
    });
    this.canvas.on("object:rotating", object => {
      if(this.isIntersectingMode == true){
        this.canvas.forEachObject(function(obj) {
          if (obj === object.target) return;
          obj.set('opacity' , object.target.intersectsWithObject(obj) ? 0.5 : 1);
        });
      }
    });
    
    // this.canvas.on("mouse:dblclick", object => {
    //   if(this.canvas.getActiveObject()['cacheKey'] ==undefined){
    //     this.canvas.remove(this.canvas.getActiveObject());
    //     this.messageService.add({life: GlobalConstants.life, severity:'warn', summary:'Remove The Note'});  
    //   }
    // });
    

    this.canvas.on("mouse:wheel", object => {
      // console.log(this.canvas.getActiveObject());
      var delta = object.e.deltaY;
      if(this.canvas.getActiveObject() != undefined){
        if(delta < 0){
          var current = this.canvas.getActiveObject()['scaleX'];
          current = current * 1.05;
          this.canvas.getActiveObject().scale(current);
          this.canvas.renderAll();  
        }else{
          var current = this.canvas.getActiveObject()['scaleX'];
          current = current * 0.95;
          this.canvas.getActiveObject().scale(current);
          this.canvas.renderAll(); 
        }
        
        if(this.canvas.getActiveObject()['cacheKey'] == this.leftFilenameCacheKey){
          this.sourceImageImg['after_width'] = Math.round(this.canvas.getActiveObject()['width'] * this.canvas.getActiveObject()['scaleX']);
          this.sourceImageImg['after_height'] = Math.round(this.canvas.getActiveObject()['height'] * this.canvas.getActiveObject()['scaleY']);
        }

      }
    }); 
    
    this.updateCanvasSize();
    this.loadCaseSummary();
 
  }

  loadCaseSummary(){
    this.dataService.getStoreCaseSummary(this.id)
                      .subscribe(data =>{
                        // console.log('loadCaseSummary');
                        // console.log(data);
                        this.caseSummary = data;
                      });
  }


  loadRecordMetadata(){
    this.dataService.metadata(this.id, 'all')
                      .subscribe(data =>{
                        this.record = data;
                        this.loadDuplicationResult();
                      });
  }

  loadDuplicationResult(){
    
    this.dataService.duplication(this.id)
                      .subscribe(data =>{

                        this.result = data;
                        this.countValidStep = 0;

                        for(let i=0; i < this.result['similarity'].length ; i++){

                          let pair = this.result['similarity'][i];
                          let img1 = pair['img1'];
                          let img1File = img1['original_file'];
                          let img1Page = img1File.match(/index-(\d{1,4})/g)[0].match(/(\d{1,4})/g)[0];
                          let img2 = pair['img2'];
                          let img2File = img2['original_file'];
                          let img2Page = img2File.match(/index-(\d{1,4})/g)[0].match(/(\d{1,4})/g)[0];
                          this.result['similarity'][i]['img1']['page'] = parseInt(img1Page);
                          this.result['similarity'][i]['img2']['page'] = parseInt(img2Page);
                          this.result['similarity'][i]['histogramP'] = Math.floor(this.result['similarity'][i]['histogram'] * 100);
                          this.result['similarity'][i]['phashP'] = Math.floor((1-this.result['similarity'][i]['phash']) * 100);
                          // this.pairAnalysis = pair['analysis'];

                         if(pair['img1']['id'] == this.img1id && pair['img2']['id'] == this.img2id){
                            this.pair = pair;
                            this.analysis = pair['analysis'];
                            this.leftFilename = img1File;
                            this.leftFilenameCacheKey = img1File+"_left";
                            this.leftFilenameRecordId = img1['record'];
                          }
                        }

                        console.log(this.pair);

                        this.withinDataSet = this.pair['duplication_within']['data'];
                        
                        for(let i=0; i < this.withinDataSet.length ; i++){
                          if(this.withinDataSet[i]['data'] != undefined && this.withinDataSet[i]['data'].length > 0){
                            this.countValidStep ++;
                            break;
                          }
                        }
                        
                        var validAdjustmentValue = this.adjustmentThreshold;
                        for(let i=0; i < this.withinDataSet.length ; i++){
                          if(this.withinDataSet[i]['data'] != undefined && this.withinDataSet[i]['data'].length > 0){
                            validAdjustmentValue = i;
                            break;
                          }
                        }

                        // Initial Values 

                        this.withinCurrentSelected = [];
                        this.withinCurrentDataSet = [];
                        
                        if(this.withinDataSet[this.adjustmentThreshold] != undefined){
                          this.withinDataSetSelected = this.withinDataSet[this.adjustmentThreshold]['data'];

                          // Here try to define the adjustmentThreshold if the data == 0 
                          if(this.withinDataSetSelected.length == 0 && validAdjustmentValue !=  this.adjustmentThreshold){
                              this.adjustmentThreshold = validAdjustmentValue;
                              this.withinDataSetSelected = this.withinDataSet[this.adjustmentThreshold]['data'];
                          }

                          // console.log('withinDataSetSelected');
                          if(this.withinDataSet[this.adjustmentThreshold]['threshold'] == 0){
                            this.asiftPanel = true;
                          }
                          for(let i=0; i < this.withinDataSetSelected.length ; i++){
                            var temp = this.withinDataSetSelected[i];
                            var item = {};
                            var itemTemp = [];
                            for(let j=0; j < temp['data'].length ; j++){
                              itemTemp.push('a');
                              if(itemTemp.length == 10){
                                break;
                              }
                            }
                            item['color'] = this.withinDataColor[i];
                            item['size'] = itemTemp;
                            item['data'] = temp['data'];
                            item['key'] = temp['set'];
                            this.withinCurrentDataSet.push(item);
                            this.withinCurrentSelected.push(item);
                          }
                        }

                        if(this.pair != undefined){
                          this.addSourceImageToViewer();
                        }

                      }); 
  } 


  back() {
    this.location.back();
  }

  close(){
    window.close();
  }

  switchLeft(){
    if(this.leftPanel){
      this.leftPanel = false;
    }else{
      this.leftPanel = true;
    }
    this.updateCanvasSize();
  }

  switchRight(){
    if(this.rightPanel){
      this.rightPanel = false;
    }else{
      this.rightPanel = true;
    }
    this.updateCanvasSize();
  }

  updateCanvasSize(){
    
    let gap = 42;
    if(this.leftPanel){
      gap += 200;
    }
    this.canvas.setWidth(document.getElementById("maincontent").offsetWidth - gap);
    this.canvas.setHeight(document.getElementById("viewercontent").offsetHeight - 76);
  } 

  updateWindowPosition(){ 
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }

  addSourceImageToViewer(){

    this.sourceImageFilterPanel = true; 
    this.sourceImageImgWebID = null;
    this.internetImageImgWebTemp = null;

    this.resetViewerDisplayByTheCacheKey(this.leftFilenameCacheKey);

    this.blocked = true;
    fabric.Image.fromURL(this.baseUrl+'/image/'+this.id+'/'+this.leftFilename, (img) => {
   
      let scale = 1;

      let scaleWidth = 1;
      let scaleHeight = 1;

      
      if(img.width > this.canvas.width || img.height > this.canvas.height){
        if(img.width > this.canvas.width){
          scaleWidth = this.canvas.width / img.width;
        }
        if(img.height > this.canvas.height){
          scaleHeight = this.canvas.height / img.height;
        }    
      }else{
        scaleWidth = this.canvas.width / img.width;
        scaleHeight = this.canvas.height / img.height;
      }

      if(scaleWidth > scaleHeight){
        scale = scaleHeight;
      }else{
        scale = scaleWidth;
      }

      scale = scale * 0.95;
      
      let top = (this.canvas.height - (img.height*scale)) / 2;
      let left = (this.canvas.width - (img.width*scale)) / 2;

      this.sourceImageImg = img;
      this.hasAnalyzed = false;

      img.set({
          cacheKey: this.leftFilenameCacheKey,
          scaleX: scale,
          scaleY: scale, 
          top: top,
          left: left,
          stroke: 'black',
          strokeWidth: 1
      });

      this.sourceImageImg['original_width'] = img.width;
      this.sourceImageImg['original_height'] = img.height;

      this.sourceImageImg['after_width'] = Math.round(img.width * scale);
      this.sourceImageImg['after_height'] = Math.round(img.height * scale);
      
      // this.canvas.add(img);

      this.blocked = false;
      
      this.highlightMatchedArea();

    }, {crossOrigin: 'anonymous'});
  }

 
  
  resetViewerDisplayByTheCacheKey(key:string){

    let found = false;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == key){
        this.canvas.remove(this.canvas.getObjects()[i]);
        found = true;
        break;
      }
    }
    if(found){
      this.resetViewerDisplayByTheCacheKey(key);
    }
  }

  resetViewerDisplay(){
    this.reloadImage('source');
  }


  toggleFilterPanel(type: string){
    if(type == 'source'){
      this.switchLeft();
    }
  }
 

  highlightMatchedArea(){
    
    // console.log(this.withinCurrentSelected);
    // console.log('left sourceImageImg W:' + this.sourceImageImg['after_width']);
    // console.log('left sourceImageImg H:' + this.sourceImageImg['after_height']);
    // console.log('left left:' + this.sourceImageImg['left']);

    // console.log(this.sourceImageImg);
    let srcImgWidth = this.sourceImageImg['after_width'];
    let srcImgHeight = this.sourceImageImg['after_height']; 
    let srcImgLeft = this.sourceImageImg['left']; 
    let srcImgTop = this.sourceImageImg['top']; 
    let srcOrigWidth = this.sourceImageImg['original_width'];
    let srcOrigHeight = this.sourceImageImg['original_height'];
    
    // this.sourceImageImg['original_width'] = img.width;
    // this.sourceImageImg['original_height'] = img.height;

    let scale =  srcImgWidth / srcOrigWidth;

    var group = new fabric.Group();
        group.addWithUpdate(this.sourceImageImg);
        
    for(let i = 0; i < this.withinCurrentSelected.length ; i++){
      let subRectSet = this.withinCurrentSelected[i];
      for(let j = 0; j < subRectSet['data'].length ; j++){
        let rect = subRectSet['data'][j];
        
        let rectX = (rect.x*scale) + srcImgLeft;
        let rectY = (rect.y*scale) + srcImgTop;
        let rectWidth = rect.width * scale;
        let rectHeight = rect.height * scale;
        //new fabric.Rect({ top: 100, left: 100, width: 50, height: 50, fill: '#f55' }),
   
        var rectShape = new fabric.Rect({
            top: rectY,
            left: rectX,
            width: rectWidth,
            height: rectHeight,
            fill: '#00000000',
            stroke: subRectSet['color'],
            strokeWidth: 3
          });

          group.addWithUpdate(rectShape);
      }
    }
    
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      this.canvas.remove(this.canvas.getObjects()[i]);
    }

    this.canvas.add(group);

  }

  withinCurrentSelected: any[] = [];
  withinCurrentDataSet: any[] = [];

  onChangeWithinCurrentItem(){
    this.addSourceImageToViewer();
  }

  onChangeThresholdCheck:any;

  selectMaximumAvailable(threshold:number){

  }

  onChangeThreshold(){
    
    if(this.withinDataSet[this.adjustmentThreshold] != undefined){

      this.withinCurrentSelected = [];
      this.withinCurrentDataSet = [];

      this.withinDataSetSelected = this.withinDataSet[this.adjustmentThreshold]['data'];

      for(let i=0; i < this.withinDataSetSelected.length ; i++){
        var temp = this.withinDataSetSelected[i];
        var item = {};
        var itemTemp = [];
        for(let j=0; j < temp['data'].length ; j++){
          itemTemp.push('a');
          if(itemTemp.length == 10){
            break;
          }
        }
        item['color'] = this.withinDataColor[i];
        item['size'] = itemTemp;
        item['data'] = temp['data'];
        item['key'] = temp['set'];
        this.withinCurrentDataSet.push(item);
        this.withinCurrentSelected.push(item);
      }
      this.previousAdjustmentThreshold = this.adjustmentThreshold;
      this.addSourceImageToViewer();
    }

  }

  setWithinLabelColor(idx:number){
    let styles = {
      'background-color' : this.withinDataColor[idx]
    }
    return styles;
  }
  
  submitWebImage(type:string){

    this.blocked = true;

    this.sourceImageImgWebID = null;
    this.internetImageImgWebTemp = null;

    if(type == 'source'){

      let imageid = this.leftFilename;
      this.dataService.submitForInternetSearch(this.id, imageid)
      .subscribe(result => {
        console.log(result);
        if(result['status'] == 'success'){
          let key = result['key'];
          this.sourceImageImgWebID = key;
          this.downloadInternetReport(type);
        }
      });
    }

  }

  downloadInternetReport(type:string){

    let traceid = '';
    if(type == 'source'){
      traceid = this.sourceImageImgWebID;
    }

    this.dataService.downloadInternetResult(this.id, traceid)
            .subscribe(result => {
        
        this.blocked = false;
              
        if(type == 'source'){
          this.internetImageImgWebTemp = result;
          this.internetImageImgWebTemp['type'] = 'source';
        }else{
          this.internetImageImgWebTemp = result;
          this.internetImageImgWebTemp['type'] = 'similar';
        }

        console.log(this.internetImageImgWebTemp);

        this.searchResultDialog = true;

    });

  }
  
  confirmStoreInternetCase() {

    console.log('run');
    this.confirmationService.confirm({
        message: 'Are you sure that you want to save this Internet search Results?',
        header: 'Save Analysis Case',
        icon: 'pi pi-save',
        accept: () => {
          this.processStoreInternetCase();
        },
        reject: (type) => {
        }
    });
  }

  processStoreInternetCase(){
    
    // here to store the Internet Analysis Case

    this.blocked = true;

    var type = this.internetImageImgWebTemp['type'];
        
    if(type == 'source'){

        // Here to store the source case result
        this.addtionalInformationSource = null;
        
        this.dataService.getImageMetadata(this.id, this.leftFilename)
        .subscribe(source =>{

          if(source['status'] == 'success'){
            this.addtionalInformationSource = source;
          }
          
          let caseobj:Case = new Case();
          
          caseobj.type = 'internet';
          caseobj.source = 'source';
          caseobj.internet = this.sourceImageImgWebID;
          caseobj.analysis = this.analysis;
          
          // caseobj.analysis = this.analysis+'_'+this.pair['img1']['original_file']+'_'+this.pair['img2']['original_file']; 
          caseobj.institution = this.record['institution'];

          caseobj.sourceFilename = this.leftFilename;
          caseobj.sourceRecord = this.leftFilenameRecordId;
          caseobj.sourceRepository = this.pair['img1']['repository'];
          
          if(this.leftFilename != null){
            caseobj.sourceId = this.id + "_" + this.leftFilename;
          }
          
          if(this.addtionalInformationSource != null){
            caseobj.sourceTitle = this.addtionalInformationSource['record']['title'];
            if(this.addtionalInformationSource['image'] != null){
              caseobj.sourceDescription = this.addtionalInformationSource['image']['description'];
            }
            if(this.addtionalInformationSource['record'] != null && this.addtionalInformationSource['record']['doi'] != null){
              caseobj.sourceDoi = this.addtionalInformationSource['record']['doi'];
            }
          }
      
          this.dataService.saveAnalysisCase(this.id, caseobj)
          .subscribe(result => {
            if(result['status'] == 'success'){
              this.messageService.add({life: GlobalConstants.life, severity:'success', summary:'Save Result', detail:'The internet result has been saved successfully.'});
            }   
            this.loadCaseSummary();
            this.blocked = false;   
    
          });
    
        }); 

    }
    

  }
  
  confirmStoreCase() {

    console.log('run');
    this.confirmationService.confirm({
      message: this.translate.instant('within-viewer.dialog-saved-case-confirm'),
      header: this.translate.instant('within-viewer.dialog-saved-case-title'),
        icon: 'pi pi-save',
        accept: () => {
          this.processStoreAnalysisCase();  
        },
        reject: (type) => {
        }
    });
  }

  processStoreAnalysisCase(){

    this.blocked = true;
    var dataUrl = this.canvas.toDataURL({
      format: "png",
      left: 0,
      top: 0,
    }); 

    this.addtionalInformationSource = null;
      this.dataService.getImageMetadata(this.id, this.leftFilename)
      .subscribe(source =>{
        if(source['status'] == 'success'){
          this.addtionalInformationSource = source;
        }

        // Here to update the case

        let caseobj:Case = new Case();
      
        caseobj.type = 'manually';
        
        caseobj.analysis = this.analysis; 

        //caseobj.analysis = pair['analysis']+'_'+pair['img1']['original_file']+'_'+pair['img2']['original_file']; 
        caseobj.institution = this.record['institution'];

        caseobj.sourceFilename = this.leftFilename;
        caseobj.sourceRecord = this.leftFilenameRecordId;
        caseobj.sourceRepository = this.pair['img1']['repository'];
        
        if(this.leftFilename != null){
          caseobj.sourceId = this.id + "_" + this.leftFilename;
          caseobj.targetId =  this.id + "_" + this.leftFilename;
        }
        caseobj.targetFilename = this.leftFilename;
        caseobj.targetRecord = this.leftFilenameRecordId;
        caseobj.targetRepository = this.pair['img2']['repository'];

        caseobj.image = dataUrl;
        
        if(this.addtionalInformationSource != null){
          caseobj.sourceTitle = this.addtionalInformationSource['record']['title'];
          caseobj.targetTitle = this.addtionalInformationSource['record']['title'];
         if(this.addtionalInformationSource['image'] != null){
          caseobj.sourceDescription = this.addtionalInformationSource['image']['description'];
          caseobj.targetDescription = this.addtionalInformationSource['image']['description'];
         }
         if(this.addtionalInformationSource['record'] != null && this.addtionalInformationSource['record']['doi'] != null){
          caseobj.sourceDoi = this.addtionalInformationSource['record']['doi'];
          caseobj.targetDoi = this.addtionalInformationSource['record']['doi'];
         }
        }
    
        this.dataService.saveAnalysisCase(this.id, caseobj)
        .subscribe(result => {
          if(result['status'] == 'success'){
            this.messageService.add({life: GlobalConstants.life, severity:'success', summary:this.translate.instant('within-viewer.toast-saved-case-title'), detail:this.translate.instant('within-viewer.toast-saved-case-success')});
          }   
          this.loadCaseSummary();
          this.blocked = false;   
        });
  
    }); 

  }


  flipImage(type:string){
    if(type == 'source'){
      for(let i=0; i < this.canvas.getObjects().length ; i++){
        if(this.canvas.getObjects()[i]['cacheKey'] == this.leftFilenameCacheKey){
          this.canvas.getObjects()[i].toggle('flipX');
          this.canvas.renderAll();
        }
      }
    }
  }

  turnRight(type:string){
    if(type == 'source'){
      for(let i=0; i < this.canvas.getObjects().length ; i++){
        if(this.canvas.getObjects()[i]['cacheKey'] == this.leftFilenameCacheKey){
          var tempvalue = this.canvas.getObjects()[i]['angle'];
          if(tempvalue == 270){
            tempvalue = 0;
          }else{
            tempvalue += 90;
          }
          this.canvas.getObjects()[i].rotate(tempvalue);
          this.canvas.renderAll();
        }
      }
    }
  } 

  reloadImage(type:string){
    if(type == 'source'){ 
      this.addSourceImageToViewer();
      this.filterListSourceSelected = this.filterList[0];
      this.adjustmentThreshold = 4;
      this.previousAdjustmentThreshold = 4;
    }
  }
 
  loadAdditionalInformation(type:string){
    this.addtionalInformationDisplay = null;
    if(type == 'source'){
      this.addtionalInformationSource = null;
      this.dataService.getImageMetadata(this.id, this.leftFilename)
      .subscribe(source =>{
        if(source['status'] == 'success'){
          this.addtionalInformationSource = source;
          this.addtionalInformationDisplay = source;
          this.addtionalInformationDisplay['width'] = this.sourceImageImg.original_width;
          this.addtionalInformationDisplay['height'] = this.sourceImageImg.original_height;
          if(this.addtionalInformationDisplay != null){
            this.addtionalInformationDialog = true;
          }else{
            this.messageService.add({life: GlobalConstants.life, severity:'warn', summary:this.translate.instant('within-viewer.toast-load-metadata-title'), detail:this.translate.instant('within-viewer.toast-load-metadata-error')});     
          }
        }else{
          this.messageService.add({life: GlobalConstants.life, severity:'warn', summary:this.translate.instant('within-viewer.toast-load-metadata-title'), detail:this.translate.instant('within-viewer.toast-load-metadata-error')});     
        }
      });  
    }
  }

  ZoomIn(type:string){
    var idx = 0;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == type){
        idx = i;
        break;
      }
    }
    var current = this.canvas.getObjects()[idx]['scaleX'];
    current = current * 1.25;
    this.canvas.getObjects()[idx].scale(current);
    this.canvas.renderAll(); 
    
    if(type == this.leftFilenameCacheKey){
      this.sourceImageImg['after_width'] = Math.round(this.canvas.getObjects()[idx]['width'] * this.canvas.getObjects()[idx]['scaleX']);
      this.sourceImageImg['after_height'] = Math.round(this.canvas.getObjects()[idx]['height'] * this.canvas.getObjects()[idx]['scaleY']);
    }

    
  }

  ZoomOut(type:string){
    var idx = 0;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == type){
        idx = i;
        break;
      }
    }
    var current = this.canvas.getObjects()[idx]['scaleX'];
    current = current * 0.75;
    this.canvas.getObjects()[idx].scale(current);
    this.canvas.renderAll();  
    
    if(type == this.leftFilenameCacheKey){
      this.sourceImageImg['after_width'] = Math.round(this.canvas.getObjects()[idx]['width'] * this.canvas.getObjects()[idx]['scaleX']);
      this.sourceImageImg['after_height'] = Math.round(this.canvas.getObjects()[idx]['height'] * this.canvas.getObjects()[idx]['scaleY']);
    }

  }

  linkViaDoi(doi:string){
    if(doi.indexOf('doi') != -1){
      let afterdoi = doi.substring(doi.indexOf('doi') + 1);
          afterdoi = doi.substring(doi.indexOf('/')+1);
          doi = afterdoi;
    }
    window.open('https://doi.org/'+doi);
  }

  toggleDrawingMode(color:string){
    this.drawingColor = color;
    this.isDrawingMode = true;
    this.canvas.set({
      isDrawingMode: true,
    });
    this.canvas.freeDrawingBrush.color = this.drawingColor;
    this.canvas.freeDrawingBrush.width = 5;
    this.messageService.add({life: GlobalConstants.life, severity:'success', summary:this.translate.instant('duplication-viewer.toast-enter-edit-mode')});
  }

  // toggleDrawingMode(){
    
  //   if(this.isDrawingMode == true){
  //     this.isDrawingMode = false;
  //     this.canvas.set({
  //       isDrawingMode: false
  //     });
  //     this.messageService.add({life: GlobalConstants.life, severity:'success', summary:this.translate.instant('within-viewer.toast-leave-edit-mode')});
  //   }else{
  //     this.isDrawingMode = true;
  //     this.canvas.set({
  //       isDrawingMode: true,
  //     });
  //     this.canvas.freeDrawingBrush.color = 'red';
  //     this.canvas.freeDrawingBrush.width = 5;
  //     this.messageService.add({life: GlobalConstants.life, severity:'success', summary:this.translate.instant('within-viewer.toast-enter-edit-mode')});
  //   }

  // }
  
  enterText:string = '';
  enterTextColor:string = 'black';
  enterTextDialog:boolean = false;
  openEnterTextDialog(color:string){
    this.enterTextColor = color;
    this.enterText = '';
    this.enterTextDialog = true;
  }
  
  // Add Text to the canvas
  drawText(note:string){
    var spot = new fabric.Text(note,{
      left: this.canvas.width/2,
      top: 65,
      fill: this.enterTextColor,
      fontSize: 20
    });
    this.canvas.add(spot);
    
  }

  confirmEnterText(){
    this.enterTextDialog = false;
    if(this.enterText.trim().length > 0){
      this.drawText(this.enterText.trim());
    }
  }
  
  addSquareToCanvas(color:string){

    var leftPosition = Math.round((this.canvas.width / 2) - (250/2));
    var rect = new fabric.Rect({
      left: leftPosition,
      top: 100,
      width: 250,
      height: 250,
      fill: '#00000000',
      stroke: color,
      strokeWidth: 3
    });
    this.canvas.add(rect);
  }
  
  addCircleToCanvas(color:string){
    // create an circle which in red color and size is 250*250 then add to the canvas
    
    var leftPoistion = Math.round((this.canvas.width / 2) - (250/2)); 

    var circle = new fabric.Circle({
      left: leftPoistion,
      top: 100,
      radius: 125,
      fill: '#00000000',
      stroke: color,
      strokeWidth: 3
    });
    this.canvas.add(circle);

  }

  leaveDrawingMode(){
    this.isDrawingMode = false;
    this.canvas.set({
      isDrawingMode: false
    });
    this.messageService.add({life: GlobalConstants.life, severity:'success', summary:this.translate.instant('duplication-viewer.toast-leave-edit-mode')});
  }

}

