import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { Location } from '@angular/common';
import { GlobalConstants } from '../common/global-constants';
import { fabric } from "fabric";
import { Analysis } from '../entity/Analysis';
import { Filter } from '../entity/Filter';
import { Web } from '../entity/Web';
import {ConfirmationService, ConfirmEventType, MessageService} from 'primeng/api';
import { Case } from '../entity/Case';
import { faExchangeAlt, faSync, faLayerGroup, faSquare, faCircle } from '@fortawesome/free-solid-svg-icons';
import { timer } from 'rxjs/internal/observable/timer';



interface Rows{
  value: number;
}

interface ImageFilter{
  name: string,
  key: string
}

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class ImageViewerComponent implements OnInit {
  
  @HostListener('window:resize', ['$event'])
  onScroll(event: any) {
    this.resizeUpdateScreen();
  }

  resizeUpdateScreen(){
    var gap = 25;
    var tempWindowWidth = document.body.clientWidth;
    var tempWindowHeight = document.body.clientHeight;

    var reloadflag = false; 
    if(tempWindowWidth - this.windowWidth > gap ||
      tempWindowHeight - this.windowHeight > gap ||
      this.windowWidth - tempWindowHeight > gap ||
      this.windowHeight - tempWindowHeight > gap){
        reloadflag = true;
    }

    if(reloadflag){
      this.blocked = true;
      const reload = timer(1500);
            reload.subscribe({
              next: function(value) {
                location.reload();
              },
              complete: function() {
                location.reload();
              },
              error: function(error) {
                location.reload();
              }
            });
    }

  }
  

  windowWidth: number;
  windowHeight: number;
  
  faSquare = faSquare;
  faCircle = faCircle;

  faLayerGroup = faLayerGroup;
  faExchangeAlt = faExchangeAlt;
  faSync = faSync;

  rows: Rows[];
  canvas: any;

  baseUrl: string;
  id: string;
  record:any;
  result:any; 
  original:any ;

  currentImageFile: any;
  originalImageFile: string;
  imagefile: string;
  similar: any;
  similarType: string;
  similarSize: Rows;

  leftPanel: boolean;
  rightPanel: boolean;

  leftFilename: string;
  leftFilenameCacheKey: string;
  rightFilename: string;
  rightFilenameCacheKey: string;
  rightFilenameRecordId: string;

  currentImageSize: number;
  currentPosition: number;
  currentOriginalCache: string[] ;
  sourceImageImg: any;
  sourceImageImgFilterID: string;
  sourceImageImgFilterTemp: any;
  sourceImageImgWebID: string;
  similarImageImg: any;
  similarImageImgFilterID: string;
  similarImageImgFilterTemp: any;
  similarImageImgWebID: string;
  
  internetImageImgWebTemp: any;
  
  sourceImageFilterPanel: boolean;
  similarImageFilterPanel: boolean;
  hasAnalyzed: boolean;

  blocked: boolean;

  analysisResult: any;
  analysisTraceId: string;

  filterList: ImageFilter[];
  filterListSourceSelected: ImageFilter;
  filterListSimilarSelected: ImageFilter;

  adjustmentSourceContrast: number;
  adjustmentSourceBrightness: number;
  adjustmentSourceSaturation: number;
  adjustmentSourceInvert: boolean;

  adjustmentSimilarContrast: number;
  adjustmentSimilarBrightness: number;
  adjustmentSimilarSaturation: number;
  adjustmentSimilarInvert: boolean;

  
  searchResultDialog: boolean;
  addtionalInformationDialog: boolean;
  addtionalInformationSource:any;
  addtionalInformationSimilar:any;
  addtionalInformationDisplay:any;

  isDrawingMode: boolean;
  isIntersectingMode: boolean;


  defaultLang: string;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private location: Location,
    private route: ActivatedRoute,
    private dataService: DataService) { 

      this.isDrawingMode = false;
      this.isIntersectingMode = false;

      this.adjustmentSourceContrast = 0;
      this.adjustmentSourceBrightness = 0;
      this.adjustmentSourceSaturation = 0;
      this.adjustmentSourceInvert = false;

      this.adjustmentSimilarContrast = 0;
      this.adjustmentSimilarBrightness = 0;
      this.adjustmentSimilarSaturation = 0;
      this.adjustmentSimilarInvert = false;

      this.searchResultDialog = false;
      this.addtionalInformationDialog = false;
      
      this.hasAnalyzed = false;
      this.sourceImageFilterPanel = false;
      this.similarImageFilterPanel = false;

      this.currentImageSize = 0;
      this.currentOriginalCache = [];
      this.currentPosition = 0;
      this.leftPanel = true;
      this.rightPanel = true;
      
      this.similarSize = {value: 10};
      this.baseUrl = GlobalConstants.apiURL;
      this.similarType = 'within';

      this.rows = [
        {value: 10},
        {value: 25},
        {value: 50},
        {value: 100}
      ];

      this.filterList = GlobalConstants.filters;

      /*
       "fire", "grays", "ice", "spectrum", "3-3-2 rgb", "red", "green", "blue", "cyan",
							"magenta", "yellow", "red/green" */

      this.route.params.subscribe(params => {
        if(params['image'] != 'all' && params['image'] != '' && params['image'] != undefined){
          let tempfile = params['image']; 
          this.imagefile = tempfile;
          this.originalImageFile = tempfile;
        }
      });
    }

  ngOnInit() {
    
    this.windowWidth = document.body.clientWidth;
    this.windowHeight = document.body.clientHeight;
    this.filterListSourceSelected = this.filterList[0];
    this.filterListSimilarSelected = this.filterList[0];

    this.id = this.route.snapshot.paramMap.get('id');
    this.loadRecordMetadata();

    // Fabrics

    this.canvas = new fabric.Canvas('viewer', {
      backgroundColor: '#ebebef',
      selection: false,
      preserveObjectStacking: true
    });

    this.canvas.on('object:scaling', object => {
      if(object['target']['cacheKey'] == this.leftFilenameCacheKey){
        this.sourceImageImg['after_width'] = Math.round(object['target']['width'] * object['target']['scaleX']);
        this.sourceImageImg['after_height'] = Math.round(object['target']['height'] * object['target']['scaleY']);
      }else if(object['target']['cacheKey'] == this.rightFilenameCacheKey){
        this.similarImageImg['after_width'] = Math.round(object['target']['width'] * object['target']['scaleX']);
        this.similarImageImg['after_height'] = Math.round(object['target']['height'] * object['target']['scaleY']);
      }
      this.hasAnalyzed = false;
      if(this.isIntersectingMode == true){
        this.canvas.forEachObject(function(obj) {
          if (obj === object.target) return;
          obj.set('opacity' , object.target.intersectsWithObject(obj) ? 0.5 : 1);
        });
      }
    });
    this.canvas.on("object:moving", object => {
      if(this.isIntersectingMode == true){
        this.canvas.forEachObject(function(obj) {
          if (obj === object.target) return;
          obj.set('opacity' , object.target.intersectsWithObject(obj) ? 0.5 : 1);
        });
      }
    });
    this.canvas.on("object:rotating", object => {
      if(this.isIntersectingMode == true){
        this.canvas.forEachObject(function(obj) {
          if (obj === object.target) return;
          obj.set('opacity' , object.target.intersectsWithObject(obj) ? 0.5 : 1);
        });
      }
    });

    this.canvas.on("mouse:wheel", object => {
      // console.log(this.canvas.getActiveObject());
      var delta = object.e.deltaY;
      if(this.canvas.getActiveObject() != undefined){
        if(delta < 0){
          var current = this.canvas.getActiveObject()['scaleX'];
          current = current * 1.05;
          this.canvas.getActiveObject().scale(current);
          this.canvas.renderAll();  
        }else{
          var current = this.canvas.getActiveObject()['scaleX'];
          current = current * 0.95;
          this.canvas.getActiveObject().scale(current);
          this.canvas.renderAll(); 
        }
      }
      
        
      if(this.canvas.getActiveObject()['cacheKey'] == this.leftFilenameCacheKey){
        this.sourceImageImg['after_width'] = Math.round(this.canvas.getActiveObject()['width'] * this.canvas.getActiveObject()['scaleX']);
        this.sourceImageImg['after_height'] = Math.round(this.canvas.getActiveObject()['height'] * this.canvas.getActiveObject()['scaleY']);
      }else{
        this.similarImageImg['after_width'] = Math.round(this.canvas.getActiveObject()['width'] * this.canvas.getActiveObject()['scaleX']);
        this.similarImageImg['after_height'] = Math.round(this.canvas.getActiveObject()['height'] * this.canvas.getActiveObject()['scaleY']);
      }

      
    });

    /**
     * canvas.on('mouse:wheel', function(opt) {
  var delta = opt.e.deltaY;
  var zoom = canvas.getZoom();
  zoom *= 0.999 ** delta;
  if (zoom > 20) zoom = 20;
  if (zoom < 0.01) zoom = 0.01;
  canvas.setZoom(zoom);
  opt.e.preventDefault();
  opt.e.stopPropagation();
})
     */

    // 'object:moving': onChange,
    // 'object:scaling': onChange,
    // 'object:rotating': onChange,

    this.canvas.on("mouse:down", object => {
      // console.log(this.canvas.getActiveObject()['cacheKey']);
      // console.log(this.canvas.getObjects()[0]['cacheKey']);
    });
    this.canvas.on("mouse:dblclick", object => {
      if(this.canvas.getActiveObject()['cacheKey'] ==undefined){
        this.canvas.remove(this.canvas.getActiveObject());
        this.messageService.add({life: GlobalConstants.life, severity:'warn', summary:'Remove The Note'});  
      }
    });
    
    this.updateCanvasSize();
 
  }

  loadRecordMetadata(){
    this.currentOriginalCache = [];
    this.dataService.metadata(this.id, 'all')
                      .subscribe(data =>{
                        this.record = data;

                        for(let i = 0; i < this.record['original'].length ; i++){
                          if(this.record['original'][i]['filename'] == this.originalImageFile){
                            this.currentPosition = i;
                          }
                          this.currentOriginalCache.push(this.record['original'][i]);
                        }

                        this.loadOriginalImageDetail();
                        this.loadSimilarImage();

                      });
  }

  switchNextOriginalImage(){
    let newPosition = this.currentPosition +1;
    if( newPosition < this.currentOriginalCache.length){
      this.originalImageFile = this.currentOriginalCache[newPosition]['filename'];
      this.imagefile = this.originalImageFile;
      this.currentPosition = newPosition;
      // console.log('Run Next' + this.originalImageFile );
      this.loadOriginalImageDetail();
      this.loadSimilarImage();
      this.resetViewerDisplay();
      this.updateUrl();
    }
  }

  switchPreviousOriginalImage(){
    let newPosition = this.currentPosition - 1;
    if( newPosition >= 0){
      this.originalImageFile = this.currentOriginalCache[newPosition]['filename'];
      this.imagefile = this.originalImageFile;
      this.currentPosition = newPosition;
      // console.log('Run previous' + this.originalImageFile );
      this.loadOriginalImageDetail();
      this.loadSimilarImage();
      this.resetViewerDisplay();
      this.updateUrl();
    }
  }

  updateUrl(){
    let targetChangeUrl:string = "/image-viewer/" + this.id + ";image=" + this.originalImageFile;
    this.location.replaceState(targetChangeUrl);
  }

  findSimilarImage(imagename:string){
    this.imagefile = imagename;
    
    if(this.original['filename'] == this.imagefile){
      this.currentImageFile = this.original;
      this.currentImageFile['crop'] == null;
    }else{
      for(let i=0; i < this.original['crop'].length ; i++){
        if(this.original['crop'][i]['filename'] == this.imagefile){
          this.currentImageFile = this.original['crop'][i];
        }
      }  
    }
    
    this.addSourceImageToViewer();
    this.loadSimilarImage();
  }

  loadSimilarImage(){
    this.dataService.similar(this.id, this.similarType, this.similarSize['value'], this.imagefile)
    .subscribe(data =>{
      this.similar = data;
      //console.log(this.similar);
    });
  }

  loadOriginalImageDetail(){
    this.dataService.metadataImage(this.id, this.imagefile)
                      .subscribe(data =>{
                        this.original = data;

                        if(this.original['filename'] == this.originalImageFile){
                          this.currentImageFile = this.original;
                          this.currentImageFile['crop'] == null;
                        }
                        
                        this.addSourceImageToViewer();
                      });
  }

  back() {
    this.location.back();
  }

  switchLeft(){
    if(this.leftPanel){
      this.leftPanel = false;
    }else{
      this.leftPanel = true;
    }
    this.updateCanvasSize();
  }

  switchRight(){
    if(this.rightPanel){
      this.rightPanel = false;
    }else{
      this.rightPanel = true;
    }
    this.updateCanvasSize();
  }

  updateCanvasSize(){
    
    let gap = 42;
    if(this.rightPanel){
      gap += 200;
    }
    if(this.leftPanel){
      gap += 200;
    }
    this.canvas.setWidth(document.getElementById("maincontent").offsetWidth - gap);
    this.canvas.setHeight(document.getElementById("viewercontent").offsetHeight - 76);
  }

  updateWindowPosition(){ 
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }

  addSourceImageToViewer(){
 
    this.sourceImageFilterPanel = false; 
    this.sourceImageImgWebID = null;
    this.internetImageImgWebTemp = null;

    this.removeTextFromCanvas();
    this.resetViewerDisplayByTheCacheKey(this.leftFilenameCacheKey);

    this.blocked = true;
    fabric.Image.fromURL(this.baseUrl+'/image/'+this.id+'/'+this.currentImageFile['filename'], (img) => {
   
      this.leftFilename = this.currentImageFile['filename'];
      this.leftFilenameCacheKey = this.id+'_'+this.leftFilename+'_left';
 
      let scale = 1;
      if(img.width > img.height){
        let scaleWidth = (this.canvas.width/2) * 0.8;
        scale = scaleWidth / img.width;
      }else{
        let scaleHeight = this.canvas.height * 0.8;
        scale = scaleHeight / img.height;
      }
      scale = scale * 0.8;
      let top = (this.canvas.height - (img.height*scale)) / 2;
      let left = (this.canvas.width - (img.width*scale)) / 9;

      this.sourceImageImg = img;
      this.hasAnalyzed = false;

      img.set({
          cacheKey: this.leftFilenameCacheKey,
          scaleX: scale,
          scaleY: scale, 
          top: top,
          left: left,
          stroke: 'blue',
          strokeWidth: 2,
      });


      this.sourceImageImg['original_width'] = img.width;
      this.sourceImageImg['original_height'] = img.height;

      this.sourceImageImg['after_width'] = Math.round(img.width * scale);
      this.sourceImageImg['after_height'] = Math.round(img.height * scale);
      

    // let hasFilter  = false;
    // for(let j = 0; j < this.canvas.getObjects()[idx].filters.length ; j++){
    //   if(this.canvas.getObjects()[idx].filters[j]['saturation'] != undefined){
    //     this.canvas.getObjects()[idx].filters[j]['saturation'] = value;
    //     hasFilter = true;
    //   }
    // }
    // if(!hasFilter){
    //   this.canvas.getObjects()[idx].filters.push(filter);
    // }
    // if(type == this.leftFilenameCacheKey){
    //   this.sourceImageImg = this.canvas.getObjects()[idx];
    // }else{
    //   this.similarImageImg = this.canvas.getObjects()[idx];
    // }


      this.canvas.add(img);
      this.currentImageSize = this.canvas.getObjects().length;

      // TODO REmove following line 
      // this.submitWebImage('source')

      this.blocked = false;
    }, {crossOrigin: 'anonymous'});
  }

  addSimilarImageToViewer(recordid:string, filename:string){
    
    this.similarImageFilterPanel = false;
    this.hasAnalyzed = false;
    this.similarImageImgWebID = null;
    this.internetImageImgWebTemp = null;

    this.removeTextFromCanvas();
    this.resetViewerDisplayByTheCacheKey(this.rightFilenameCacheKey);

    this.blocked = true;
    fabric.Image.fromURL(this.baseUrl+'/similar/image/'+this.id+'/'+recordid+'/'+filename, (img) => {
  
      this.rightFilename = filename;
      this.rightFilenameRecordId = recordid;
      this.rightFilenameCacheKey = recordid+'_'+filename+'_right';

      let scale = 1;
      if(img.width > img.height){
        let scaleWidth = (this.canvas.width/2) * 0.8;
        scale = scaleWidth / img.width;
      }else{
        let scaleHeight = this.canvas.height * 0.8;
        scale = scaleHeight / img.height;
      }
      scale = scale * 0.8;
      let top = (this.canvas.height - (img.height*scale)) / 2;
      let left = (this.canvas.width - (img.width*scale)) * 0.9;

      this.similarImageImg = img;
      this.hasAnalyzed = false;

      img.set({
          cacheKey: this.rightFilenameCacheKey,
          scaleX: scale,
          scaleY: scale,
          top: top,
          left: left,
          stroke: 'red',
          strokeWidth: 2,
      });
      
      this.similarImageImg['original_width'] = img.width;
      this.similarImageImg['original_height'] = img.height;
      this.similarImageImg['after_width'] = Math.round(img.width * scale);
      this.similarImageImg['after_height'] = Math.round(img.height * scale);

      this.canvas.add(img);
      this.blocked = false;
    }, {crossOrigin: 'anonymous'});
    
  }
  
  resetViewerDisplayByTheCacheKey(key:string){

    let found = false;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == key){
        this.canvas.remove(this.canvas.getObjects()[i]);
        found = true;
        break;
      }
    }
    if(found){
      this.resetViewerDisplayByTheCacheKey(key);
    }
  }


  removeTextFromCanvas(){
    let hasText = false;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] != this.rightFilenameCacheKey
            && this.canvas.getObjects()[i]['cacheKey'] != this.leftFilenameCacheKey){
        this.canvas.remove(this.canvas.getObjects()[i]);
        hasText = true;
      }
    }
    if(hasText){
      this.removeTextFromCanvas();
    }
  }

  resetViewerDisplay(){
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      this.canvas.remove(this.canvas.getObjects()[i]);
      if(this.canvas.getObjects().length > 0){
        this.resetViewerDisplay();
      }
    }
    this.currentImageSize = 0;
    this.sourceImageImg = null;
    this.similarImageImg = null;
    this.sourceImageFilterPanel = false;
    this.similarImageFilterPanel = false;
    this.hasAnalyzed = false;
    this.filterListSourceSelected = this.filterList[0];
    this.filterListSimilarSelected = this.filterList[0];
    this.sourceImageImgFilterID = null;
    this.similarImageImgFilterID = null;
    this.leftFilename = '';
    this.rightFilename = '';
    this.sourceImageImgWebID = null;
    this.similarImageImgWebID = null;
    this.internetImageImgWebTemp = null;
    this.searchResultDialog = false;
    this.addtionalInformationDialog = false;
      this.adjustmentSourceContrast = 0;
      this.adjustmentSourceBrightness = 0;
      this.adjustmentSourceSaturation = 0; 
      this.adjustmentSourceInvert = false;

      this.adjustmentSimilarContrast = 0;
      this.adjustmentSimilarBrightness = 0;
      this.adjustmentSimilarSaturation = 0;
      this.adjustmentSimilarInvert = false;
  }

  // enterFullscreenMode(){
  //   document.body.requestFullscreen();
  // }

  toggleFilterPanel(type: string){
    if(type == 'source'){
      
      this.adjustmentSourceContrast = 0;
      this.adjustmentSourceBrightness = 0;
      this.adjustmentSourceSaturation = 0;
      this.adjustmentSourceInvert = false;

      this.sourceImageFilterPanel = !this.sourceImageFilterPanel;
      if(this.sourceImageFilterPanel == true){
        this.sourceImageImgFilterID = null;
        this.submitFilterImage('source');
      }else{
        this.filterListSourceSelected = this.filterList[0];
        this.filterListSimilarSelected = this.filterList[0];
        this.switchToFilterImage('source');
      }
    }else{
      
      this.adjustmentSimilarContrast = 0;
      this.adjustmentSimilarBrightness = 0;
      this.adjustmentSimilarSaturation = 0;
      this.adjustmentSimilarInvert = false;

      this.similarImageFilterPanel = !this.similarImageFilterPanel;

      if(this.similarImageFilterPanel == true){
        this.similarImageImgFilterID = null;
        this.submitFilterImage('similar');
      }else{
        this.filterListSourceSelected = this.filterList[0];
        this.filterListSimilarSelected = this.filterList[0];
        this.switchToFilterImage('similar');
      }
    }
  }

  analyzeImageSimilarity(){

    this.blocked = true;

    this.removeTextFromCanvas();

    if(this.sourceImageImg != undefined && this.similarImageImg != undefined){
      
      this.canvas.getObjects()[0].set({strokeWidth: 0});
      this.canvas.getObjects()[1].set({strokeWidth: 0});

      var dataURLSrc = this.canvas.getObjects()[0].toDataURL({
        format: "png",
        left: 0,
        top: 0,
      });
      
      var dataURLTgt = this.canvas.getObjects()[1].toDataURL({
        format: "png",
        left: 0,
        top: 0
      });
      
      this.canvas.getObjects()[0].set({strokeWidth: 2});
      this.canvas.getObjects()[1].set({strokeWidth: 2});

      let anaobj:Analysis = new Analysis();
          anaobj.source = dataURLSrc;
          anaobj.target = dataURLTgt;
  
      // this.dataService.submitForSimilarityAnalysis(anaobj)
      //   .subscribe(result => {
          
      //     if(result['status'] == 'success'){
      //       let key = result['key'];
      //       // this.downloadImageSimilarity(key);
      //     }
      // });
    }else{
      this.blocked = false;
    }
  }

  // downloadImageSimilarity(key:string){

  //   this.dataService.downloadSimilarityResult(key)
  //           .subscribe(result => {
              
  //             this.analysisResult = result;

  //             let resultText = '';
  //             for(let i=0; i < result['analysis'].length ; i++){
  //               console.log(result['analysis'][i]['name'] + ' : ' + result['analysis'][i]['similarity']);
  //               let pvalue = result['analysis'][i]['similarity'] * 100;
                    
  //               resultText += result['analysis'][i]['name'] + ' : ' + Math.floor(pvalue) + '%   ';
  //             }

  //             this.blocked = false;
  //             this.hasAnalyzed = true;
    
  //             // Add the result to the canvas
  //             var info = new fabric.Text(resultText, {
  //               fontSize: 14,
  //               fontWeight: 'normal',
  //               stroke: 'gray'
  //             });
    
  //             var left = (this.canvas.width - info.width) /2;
  //             info.set({
  //               left: left,
  //               top: 10
  //             });
    
  //             this.canvas.add(info);

  //             if(this.analysisResult['asift']['status']){
  //               this.highlightPair();
  //             }

  //     });
  // }


  highlightPair(){ 

    console.log('left sourceImageImg W:' + this.sourceImageImg['after_width']);
    console.log('left sourceImageImg H:' + this.sourceImageImg['after_height']);
    console.log('left left:' + this.sourceImageImg['left']);
    console.log('left leftH:' + this.analysisResult['asift']['leftH']);
    console.log('left leftW:' + this.analysisResult['asift']['leftW']);
    console.log('left leftR:' + this.analysisResult['asift']['leftR']);
    console.log('left leftX:' + this.analysisResult['asift']['leftX']);
    console.log('right similarImageImg W:' + this.similarImageImg['after_width']);
    console.log('right similarImageImg H:' + this.similarImageImg['after_height']);
    console.log('right right:' + this.similarImageImg['left']);
    console.log('right rightH:' + this.analysisResult['asift']['rightH']);
    console.log('right rightW:' + this.analysisResult['asift']['rightW']);
    console.log('right rightR:' + this.analysisResult['asift']['rightR']);
    console.log('right rightX:' + this.analysisResult['asift']['rightX']);

    var leftScale = this.analysisResult['asift']['leftW'] / this.sourceImageImg['after_width'];
    var leftRadius = this.analysisResult['asift']['leftR'] * leftScale;
    var leftX = (this.analysisResult['asift']['leftX'] * leftScale) + this.sourceImageImg['left'];
        leftX = leftX - leftRadius;
    var leftY = (this.analysisResult['asift']['leftY'] * leftScale) + this.sourceImageImg['top'];
        leftY = leftY - leftRadius;
     // Add the result to the canvas
     var leftCircle = new fabric.Circle({
      radius: leftRadius ,
      left: leftX,
      top: leftY,
      fill: '#00000000',
      stroke: 'red', 
      strokeWidth: 2
    });

    // Add the result to the canvas
    var rightScale = this.analysisResult['asift']['rightW'] / this.similarImageImg['after_width'];
    var rightRadius = this.analysisResult['asift']['rightR'] * rightScale;
    var rightX = (this.analysisResult['asift']['rightX'] * rightScale) + this.similarImageImg['left'];
        rightX = rightX - rightRadius;
    var rightY = (this.analysisResult['asift']['rightY'] * rightScale) + this.similarImageImg['top'];
        rightY = rightY - rightRadius;
    var rightCircle = new fabric.Circle({
     radius: rightRadius,
     left: rightX,
     top: rightY,
     fill: '#00000000',
     stroke: 'red',
     strokeWidth: 2
   });

    this.canvas.add(leftCircle);
    this.canvas.add(rightCircle);
  }


  // downloadPairResult(){
  //   this.dataService.downloadSimilarityResult(this.analysisTraceId)
  //     .subscribe(data =>{
  //       this.analysisResult = data;
  //       console.log(data);
  //     });
  // }

  switchToFilterImage(type:string){

    this.hasAnalyzed = false;

    this.adjustmentSourceContrast = 0;
    this.adjustmentSourceBrightness = 0;
    this.adjustmentSourceSaturation = 0;
    this.adjustmentSourceInvert = false;

    this.adjustmentSimilarContrast = 0;
    this.adjustmentSimilarBrightness = 0;
    this.adjustmentSimilarSaturation = 0;
    this.adjustmentSimilarInvert = false;
    
    if(type == 'source'){
      this.resetViewerDisplayByTheCacheKey(this.leftFilenameCacheKey);
      if(this.filterListSourceSelected['key'] == 'normal'){
        this.sourceImageImgFilterTemp['top'] = this.sourceImageImg['top'];
        this.sourceImageImgFilterTemp['left'] = this.sourceImageImg['left'];
        this.sourceImageImg = this.sourceImageImgFilterTemp;
        this.canvas.add(this.sourceImageImgFilterTemp);
        this.removeAllFilter(this.leftFilenameCacheKey);
      }else{

        fabric.Image.fromURL(this.baseUrl+'/filter/'+this.sourceImageImgFilterID+'?type='+this.filterListSourceSelected['key'], (img) => {
      
          let scale = 1;
          let top = this.sourceImageImg['top'];
          let left = this.sourceImageImg['left'];
          let srcWidth = this.sourceImageImg['after_width'];

              scale = srcWidth / img.width;

          this.sourceImageImg = img;
          this.hasAnalyzed = false;
    
          img.set({ 
              cacheKey: this.leftFilenameCacheKey,
              top: top,
              left: left,
              scaleX: scale,
              scaleY: scale,
              stroke: 'blue',
              strokeWidth: 2,
          });
    
          this.sourceImageImg['after_width'] = Math.floor(img['width'] * img['scaleX']);
          this.sourceImageImg['after_height'] = Math.floor(img['height'] * img['scaleY']);
          this.sourceImageImg['original_width'] = this.sourceImageImgFilterTemp['original_width'];
          this.sourceImageImg['original_height'] = this.sourceImageImgFilterTemp['original_height'];
    

          this.canvas.add(img);
          this.currentImageSize = this.canvas.getObjects().length;
          this.removeAllFilter(this.leftFilenameCacheKey);
          this.updateWindowPosition();
        }, {crossOrigin: 'anonymous'});

      }
    }else{

      this.resetViewerDisplayByTheCacheKey(this.rightFilenameCacheKey);

      if(this.filterListSimilarSelected['key'] == 'normal'){
        this.similarImageImgFilterTemp['top'] = this.similarImageImg['top'];
        this.similarImageImgFilterTemp['left'] = this.similarImageImg['left'];
        this.similarImageImg = this.similarImageImgFilterTemp;
        this.canvas.add(this.similarImageImgFilterTemp);
        this.removeAllFilter(this.rightFilenameCacheKey);
      }else{

        fabric.Image.fromURL(this.baseUrl+'/filter/'+this.similarImageImgFilterID+'?type='+this.filterListSimilarSelected['key'], (img) => {
      
          let scale = 1;
          let top = this.similarImageImg['top'];
          let left = this.similarImageImg['left'];
          let srcWidth = this.similarImageImg['after_width'];

              scale = srcWidth / img.width;

          this.similarImageImg = img;
          this.hasAnalyzed = false;
    
          img.set({ 
              cacheKey: this.rightFilenameCacheKey,
              top: top,
              left: left,
              scaleX: scale,
              scaleY: scale,
              stroke: 'red',
              strokeWidth: 2,
          });
    
          this.similarImageImg['after_width'] = Math.floor(img['width'] * img['scaleX']);
          this.similarImageImg['after_height'] = Math.floor(img['height'] * img['scaleY']);
          this.similarImageImg['original_width'] = this.similarImageImgFilterTemp['original_width'];
          this.similarImageImg['original_height'] = this.similarImageImgFilterTemp['original_height'];
    
          this.canvas.add(img);
          this.currentImageSize = this.canvas.getObjects().length;
          this.removeAllFilter(this.rightFilenameCacheKey);
          this.updateWindowPosition();
        }, {crossOrigin: 'anonymous'});

      }
    }
  }

  submitFilterImage(type:string){

    let idx = 0;
    var dataURLSrc = '';
    if(type == 'source'){
      this.sourceImageImgFilterTemp = this.sourceImageImg;
      
      this.sourceImageImg.set({strokeWidth: 0});
      dataURLSrc = this.sourceImageImg.toDataURL({
        format: "png",
        left: 0,
        top: 0,
      });
      this.sourceImageImg.set({strokeWidth: 2});
      
    }else{
      this.similarImageImgFilterTemp = this.similarImageImg;
      
      this.similarImageImg.set({strokeWidth: 0});
      dataURLSrc = this.similarImageImg.toDataURL({
        format: "png",
        left: 0,
        top: 0,
      });
      this.similarImageImg.set({strokeWidth: 2});
    }

    let anaobj:Filter = new Filter();
        anaobj.target = dataURLSrc;

    this.dataService.submitForFilterAnalysis(anaobj)
      .subscribe(result => {
        if(result['status'] == 'success'){
          let key = result['key'];
          if(type == 'source'){
            this.sourceImageImgFilterID = key;
          }else{
            this.similarImageImgFilterID = key;
          }
        }
    });

  }

  
  onChangeFilterBrightness(type:string){

    let idx = 0;
      for(let i=0; i < this.canvas.getObjects().length ; i++){
        if(this.canvas.getObjects()[i]['cacheKey'] == type){
          idx = i;
          break;
        }
      }

      let value = 0;
      if(type == this.leftFilenameCacheKey){
        value = this.adjustmentSourceBrightness / 100;
      }else{
        value = this.adjustmentSimilarBrightness / 100;
      }

      var filter = new fabric.Image.filters.Brightness({
        brightness: value
      });
      
      let hasFilter  = false;
      for(let j = 0; j < this.canvas.getObjects()[idx].filters.length ; j++){
        if(this.canvas.getObjects()[idx].filters[j]['brightness'] != undefined){
          this.canvas.getObjects()[idx].filters[j]['brightness'] = value;
          hasFilter = true;
        }
      }
      if(!hasFilter){
        this.canvas.getObjects()[idx].filters.push(filter);
      }
      if(type == this.leftFilenameCacheKey){
        this.sourceImageImg = this.canvas.getObjects()[idx];
      }else{
        this.similarImageImg = this.canvas.getObjects()[idx];
      }

      this.canvas.getObjects()[idx].applyFilters();
      this.canvas.renderAll();
  }


  onChangeFilterContrast(type:string){

    let idx = 0;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == type){
        idx = i;
        break;
      }
    }


    let value = 0;
    if(type == this.leftFilenameCacheKey){
      value = this.adjustmentSourceContrast / 100;
    }else{
      value = this.adjustmentSimilarContrast / 100;
    }

    var filter = new fabric.Image.filters.Contrast({
      contrast: value
    });
    
    let hasFilter  = false;
    for(let j = 0; j < this.canvas.getObjects()[idx].filters.length ; j++){
      if(this.canvas.getObjects()[idx].filters[j]['contrast'] != undefined){
        this.canvas.getObjects()[idx].filters[j]['contrast'] = value;
        hasFilter = true;
      }
    }
    if(!hasFilter){
      this.canvas.getObjects()[idx].filters.push(filter);
    }
    if(type == this.leftFilenameCacheKey){
      this.sourceImageImg = this.canvas.getObjects()[idx];
    }else{
      this.similarImageImg = this.canvas.getObjects()[idx];
    }

    this.canvas.getObjects()[idx].applyFilters();
    this.canvas.renderAll();
  }
  /**
      var invert = new fabric.Image.filters.Invert({});
    
      img.filters.push(invert);
      img.applyFilters(); */


  onChangeInvertColor(type:string){

        let idx = 0;
        for(let i=0; i < this.canvas.getObjects().length ; i++){
          if(this.canvas.getObjects()[i]['cacheKey'] == type){
            idx = i;
            break;
          }
        }
    
        let value = false;
        if(type == this.leftFilenameCacheKey){
          value = this.adjustmentSourceInvert;
        }else{
          value = this.adjustmentSimilarInvert;
        }
            
    
        var filter = new fabric.Image.filters.Invert({});
            
        let hasFilter  = false;
        for(let j = 0; j < this.canvas.getObjects()[idx].filters.length ; j++){
          if(this.canvas.getObjects()[idx].filters[j]['invert'] != undefined){
            this.canvas.getObjects()[idx].filters[j]['invert'] = value;
            hasFilter = true;
          }
        }
        if(!hasFilter){
          this.canvas.getObjects()[idx].filters.push(filter);
        }

        if(type == this.leftFilenameCacheKey){
          this.sourceImageImg = this.canvas.getObjects()[idx];
        }else{
          this.similarImageImg = this.canvas.getObjects()[idx];
        }
    
        this.canvas.getObjects()[idx].applyFilters();
        this.canvas.renderAll();
  }

  onChangeFilterSaturation(type:string){

    let idx = 0;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == type){
        idx = i;
        break;
      }
    }

    let value = 0;
    if(type == this.leftFilenameCacheKey){
      value = this.adjustmentSourceSaturation / 100;
    }else{
      value = this.adjustmentSimilarSaturation / 100;
    }

    var filter = new fabric.Image.filters.Saturation({
      saturation: value
    });
    
    let hasFilter  = false;
    for(let j = 0; j < this.canvas.getObjects()[idx].filters.length ; j++){
      if(this.canvas.getObjects()[idx].filters[j]['saturation'] != undefined){
        this.canvas.getObjects()[idx].filters[j]['saturation'] = value;
        hasFilter = true;
      }
    }
    if(!hasFilter){
      this.canvas.getObjects()[idx].filters.push(filter);
    }
    if(type == this.leftFilenameCacheKey){
      this.sourceImageImg = this.canvas.getObjects()[idx];
    }else{
      this.similarImageImg = this.canvas.getObjects()[idx];
    }

    this.canvas.getObjects()[idx].applyFilters();
    this.canvas.renderAll();
  }

  
  removeAllFilter(type:string){

    let idx = 0;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == type){
        idx = i;
        break;
      }
    }
    for(let j = 0; j < this.canvas.getObjects()[idx].filters.length ; j++){
      if(this.canvas.getObjects()[idx].filters[j]['saturation'] != undefined){
        this.canvas.getObjects()[idx].filters[j]['saturation'] = 0;
      }
      if(this.canvas.getObjects()[idx].filters[j]['contrast'] != undefined){
        this.canvas.getObjects()[idx].filters[j]['contrast'] = 0;
      }
      if(this.canvas.getObjects()[idx].filters[j]['brightness'] != undefined){
        this.canvas.getObjects()[idx].filters[j]['brightness'] = 0;
      }
      if(this.canvas.getObjects()[idx].filters[j]['invert'] != undefined){
        this.canvas.getObjects()[idx].filters[j]['invert'] = false;
      }
    }
    if(type == this.leftFilenameCacheKey){
      this.sourceImageImg = this.canvas.getObjects()[idx];
    }else{
      this.similarImageImg = this.canvas.getObjects()[idx];
    }

    this.canvas.getObjects()[idx].applyFilters();
    this.canvas.renderAll();

  }
  
  submitWebImage(type:string){

    this.blocked = true;

    this.sourceImageImgWebID = null;
    this.similarImageImgWebID = null;
    this.internetImageImgWebTemp = null;

    if(type == 'source'){

      // TODO need implement this feature
      // fabric.Image.fromURL(this.baseUrl+'/image/'+this.id+'/'+this.currentImageFile['filename'], (img) => {  

      //   var dataURLSrc = img.toDataURL({});
      //   let anaobj:Web = new Web();
      //   anaobj.target = dataURLSrc;

      //   this.dataService.submitForInternetSearch(this.id, anaobj)
      //     .subscribe(result => {
      //       if(result['status'] == 'success'){
      //         let key = result['key'];
      //         this.sourceImageImgWebID = key;
      //         this.downloadInternetReport(type);
      //       }
      //   });

      // }, {crossOrigin: 'anonymous'});
      
      
    }else{

      
      // TODO need implement this feature
      // fabric.Image.fromURL(this.baseUrl+'/similar/image/'+this.id+'/'+this.rightFilenameRecordId+'/'+this.rightFilename, (img) => {
  
      //   var dataURLSrc = img.toDataURL({});
      //   let anaobj:Web = new Web(); 
      //   anaobj.target = dataURLSrc;

      //   this.dataService.submitForInternetSearch(this.id, anaobj)
      //     .subscribe(result => {
      //       if(result['status'] == 'success'){
      //         let key = result['key'];
      //         this.similarImageImgWebID = key;
      //         this.downloadInternetReport(type);
      //       }
      //   });

      // }, {crossOrigin: 'anonymous'});

    }

  }


  downloadInternetReport(type:string){

    let traceid = '';
    if(type == 'source'){
      traceid = this.sourceImageImgWebID;
    }else{
      traceid = this.similarImageImgWebID;
    }

    this.dataService.downloadInternetResult(this.id, traceid)
            .subscribe(result => {
        
        this.blocked = false;
              
        if(type == 'source'){
          this.internetImageImgWebTemp = result;
          this.internetImageImgWebTemp['type'] = 'source';
        }else{
          this.internetImageImgWebTemp = result;
          this.internetImageImgWebTemp['type'] = 'similar';
        }

        console.log(this.internetImageImgWebTemp);

        this.searchResultDialog = true;

    });

  } 

  

  confirmStoreInternetCase() {

    console.log('run');
    this.confirmationService.confirm({
        message: 'Are you sure that you want to save this Internet search Results?',
        header: 'Save Analysis Case',
        icon: 'pi pi-save',
        accept: () => {
          this.processStoreInternetCase();
        },
        reject: (type) => {
        }
    });
  }

  processStoreInternetCase(){
    var type = this.internetImageImgWebTemp['type'];
    this.messageService.add({life: GlobalConstants.life, severity:'success', summary:'Save Result', detail:'The internet result has been saved successfully.'});
        
  }

  confirmStoreCase() {

    console.log('run');
    this.confirmationService.confirm({
        message: 'Are you sure that you want to save this Analysis Results?',
        header: 'Save Analysis Case',
        icon: 'pi pi-save',
        accept: () => {
          this.processStoreCase();
        },
        reject: (type) => {
        }
    });
  }

  processStoreCase(){

    this.blocked = true;
    var dataUrl = this.canvas.toDataURL({
      format: "png",
      left: 0,
      top: 0, 
    });

    this.addtionalInformationSource = null;
      this.dataService.getImageMetadata(this.id, this.leftFilename)
      .subscribe(source =>{
        if(source['status'] == 'success'){
          this.addtionalInformationSource = source;
        }
        
        this.addtionalInformationSimilar = null;
        this.dataService.getImageMetadata(this.id, this.rightFilenameRecordId + '_' + this.rightFilename)
        .subscribe(target =>{
          if(target['status'] == 'success'){
            this.addtionalInformationSimilar = target;
          }

          // Here to update the case

          let caseobj:Case = new Case();
        
          caseobj.type = 'manually';
          caseobj.sourceFilename = this.leftFilename;
          caseobj.sourceRecord = this.id;
          caseobj.sourceRepository = 'user';
          if(this.leftFilename != null){
            caseobj.sourceId = this.id + "_" + this.leftFilename;
          }
          caseobj.targetFilename = this.rightFilename;
          caseobj.targetRecord = this.rightFilenameRecordId;
          caseobj.targetRepository = this.similarType;
          if(this.rightFilename != null && this.rightFilenameRecordId != null){
            caseobj.targetId = this.rightFilenameRecordId + "_" + this.rightFilename;
          }
          caseobj.image = dataUrl;
          caseobj.institution = this.record['institution'];

          if(this.addtionalInformationSource != null){
            caseobj.sourceTitle = this.addtionalInformationSource['record']['title'];
           if(this.addtionalInformationSource['image'] != null){
            caseobj.sourceDescription = this.addtionalInformationSource['image']['description'];
           }
           if(this.addtionalInformationSource['record'] != null && this.addtionalInformationSource['record']['doi'] != null){
            caseobj.sourceDoi = this.addtionalInformationSource['record']['doi'];
           }
          }
          if(this.addtionalInformationSimilar != null){
            caseobj.targetTitle = this.addtionalInformationSimilar['record']['title'];
            if(this.addtionalInformationSimilar['image'] != null){
             caseobj.targetDescription = this.addtionalInformationSimilar['image']['description'];
            }
            if(this.addtionalInformationSimilar['record'] != null && this.addtionalInformationSimilar['record']['doi'] != null){
             caseobj.targetDoi = this.addtionalInformationSimilar['record']['doi'];
            }
          }
      
          this.dataService.saveAnalysisCase(this.id, caseobj)
          .subscribe(result => {
            if(result['status'] == 'success'){
              this.messageService.add({life: GlobalConstants.life, severity:'success', summary:'Save Result', detail:'The analsysis result has been saved successfully.'});
            }
          });

        });
        this.blocked = false;

    }); 

  }

  removeImageFromCanvas(type:string){
    if(type == 'source'){
      this.resetViewerDisplayByTheCacheKey(this.leftFilenameCacheKey);
      this.sourceImageImg = null;
      this.leftFilename = null;
      this.leftFilenameCacheKey = null;
    }else{
      this.resetViewerDisplayByTheCacheKey(this.rightFilenameCacheKey);
      this.similarImageImg = null;
      this.rightFilename = null;
      this.rightFilenameRecordId = null;
      this.rightFilenameCacheKey = null;
    } 
  }

  flipImage(type:string){
    if(type == 'source'){
      for(let i=0; i < this.canvas.getObjects().length ; i++){
        if(this.canvas.getObjects()[i]['cacheKey'] == this.leftFilenameCacheKey){
          this.canvas.getObjects()[i].toggle('flipX');
          this.canvas.renderAll();
        }
      }
    }else{
      for(let i=0; i < this.canvas.getObjects().length ; i++){
        if(this.canvas.getObjects()[i]['cacheKey'] == this.rightFilenameCacheKey){
          this.canvas.getObjects()[i].toggle('flipX');
          this.canvas.renderAll();
        }
      }
    }
  }

  turnRight(type:string){
    if(type == 'source'){
      for(let i=0; i < this.canvas.getObjects().length ; i++){
        if(this.canvas.getObjects()[i]['cacheKey'] == this.leftFilenameCacheKey){
          var tempvalue = this.canvas.getObjects()[i]['angle'];
          if(tempvalue == 270){
            tempvalue = 0;
          }else{
            tempvalue += 90;
          }
          this.canvas.getObjects()[i].rotate(tempvalue);
          this.canvas.renderAll();
        }
      }
    }else{
      for(let i=0; i < this.canvas.getObjects().length ; i++){
        if(this.canvas.getObjects()[i]['cacheKey'] == this.rightFilenameCacheKey){
          var tempvalue = this.canvas.getObjects()[i]['angle'];
          if(tempvalue == 270){
            tempvalue = 0;
          }else{
            tempvalue += 90;
          }
          this.canvas.getObjects()[i].rotate(tempvalue);
          this.canvas.renderAll();
        }
      }
    }
  }
 
  reloadImage(type:string){ 
    if(type == 'source'){
      this.addSourceImageToViewer();
      this.filterListSourceSelected = this.filterList[0];
      this.adjustmentSourceContrast = 0;
      this.adjustmentSourceBrightness = 0;
      this.adjustmentSourceSaturation = 0;
      this.adjustmentSourceInvert = false;
    }else{
      this.addSimilarImageToViewer(this.rightFilenameRecordId, this.rightFilename);
      this.filterListSimilarSelected = this.filterList[0];
      this.adjustmentSimilarContrast = 0;
      this.adjustmentSimilarBrightness = 0;
      this.adjustmentSimilarSaturation = 0;
      this.adjustmentSimilarInvert = false;
    }
  }

  loadAdditionalInformation(type:string){
    this.addtionalInformationDisplay = null;
    if(type == 'source'){
      this.addtionalInformationSource = null;
      this.dataService.getImageMetadata(this.id, this.leftFilename)
      .subscribe(source =>{
        if(source['status'] == 'success'){
          this.addtionalInformationSource = source;
          this.addtionalInformationDisplay = source;
          if(this.addtionalInformationDisplay != null){
            this.addtionalInformationDialog = true;
          }else{ 
            this.messageService.add({life: GlobalConstants.life, severity:'warn', summary:'Load Metadata', detail:'The metadata of the image is not available'});     
          }
        }else{
          this.messageService.add({life: GlobalConstants.life, severity:'warn', summary:'Load Metadata', detail:'The metadata of the image is not available'});     
        }
      });  
    }else{
      this.addtionalInformationSimilar = null;
      this.dataService.getImageMetadata(this.id, this.rightFilenameRecordId +'_'+ this.rightFilename)
      .subscribe(target =>{
        if(target['status'] == 'success'){
          this.addtionalInformationSimilar = target;
          this.addtionalInformationDisplay = target;
          if(this.addtionalInformationDisplay != null){
            this.addtionalInformationDialog = true;
          }else{
            this.messageService.add({life: GlobalConstants.life, severity:'warn', summary:'Load Metadata', detail:'The metadata of the image is not available'});     
          }
        }else{
          this.messageService.add({life: GlobalConstants.life, severity:'warn', summary:'Load Metadata', detail:'The metadata of the image is not available'});     
        }
      });
    }
  }


  ZoomIn(type:string){
    var idx = 0;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == type){
        idx = i;
        break;
      }
    }
    var current = this.canvas.getObjects()[idx]['scaleX'];
    current = current * 1.25;
    this.canvas.getObjects()[idx].scale(current);
    this.canvas.renderAll();  
    
    if(type == this.leftFilenameCacheKey){
      this.sourceImageImg['after_width'] = Math.round(this.canvas.getObjects()[idx]['width'] * this.canvas.getObjects()[idx]['scaleX']);
      this.sourceImageImg['after_height'] = Math.round(this.canvas.getObjects()[idx]['height'] * this.canvas.getObjects()[idx]['scaleY']);
    }else{
      this.similarImageImg['after_width'] = Math.round(this.canvas.getObjects()[idx]['width'] * this.canvas.getObjects()[idx]['scaleX']);
      this.similarImageImg['after_height'] = Math.round(this.canvas.getObjects()[idx]['height'] * this.canvas.getObjects()[idx]['scaleY']);
    }

  }

  ZoomOut(type:string){
    var idx = 0;
    for(let i=0; i < this.canvas.getObjects().length ; i++){
      if(this.canvas.getObjects()[i]['cacheKey'] == type){
        idx = i;
        break;
      }
    }
    //
    // originX: "center", 
    // originY: "center"
    var current = this.canvas.getObjects()[idx]['scaleX'];
    current = current * 0.75;
    this.canvas.getObjects()[idx].scale(current);
    this.canvas.renderAll();  
    
    if(type == this.leftFilenameCacheKey){
      this.sourceImageImg['after_width'] = Math.round(this.canvas.getObjects()[idx]['width'] * this.canvas.getObjects()[idx]['scaleX']);
      this.sourceImageImg['after_height'] = Math.round(this.canvas.getObjects()[idx]['height'] * this.canvas.getObjects()[idx]['scaleY']);
    }else{
      this.similarImageImg['after_width'] = Math.round(this.canvas.getObjects()[idx]['width'] * this.canvas.getObjects()[idx]['scaleX']);
      this.similarImageImg['after_height'] = Math.round(this.canvas.getObjects()[idx]['height'] * this.canvas.getObjects()[idx]['scaleY']);
    }

  }

  linkViaDoi(doi:string){
    window.open('https://doi.org/'+doi);
  }

  toggleDrawingMode(){
    if(this.isDrawingMode == true){
      this.isDrawingMode = false;
      this.canvas.set({
        isDrawingMode: false
      });
      this.messageService.add({life: GlobalConstants.life, severity:'success', summary:'Leave edit mode'});
    }else{
      this.isDrawingMode = true;
      this.canvas.set({
        isDrawingMode: true,
      });
      this.canvas.freeDrawingBrush.color = 'red';
      this.canvas.freeDrawingBrush.width = 5;
      this.messageService.add({life: GlobalConstants.life, severity:'success', summary:'Enter edit mode'});
    }
  }

  toggleIntersectingMode(){
    if(this.isIntersectingMode == true){
      this.isIntersectingMode = false;
      this.messageService.add({life: GlobalConstants.life, severity:'success', summary:'Leave Intersection Mode'});
    }else{
      this.isIntersectingMode = true;
      this.messageService.add({life: GlobalConstants.life, severity:'success', summary:'Enable Intersection Mode'});
    }
  }


}
