export class GlobalConstants {
  
    public static life:number = 1000;
    public static high:number = 0.99;
    public static medium:number = 0.96;
    public static manipulationHigh:number = 0.9; 
    public static manipulationMedium:number = 0.6;

    // public static apiURL: string = "http://localhost:10101/v1";
    public static apiURL: string = "/v1"; 
    // public static apiURL: string = "https://report.imachek.com/v1";
    // public static apiURL: string = "https://analysis-report.imachek.com/v1";
     
    public static filters = [ 
        {name: 'Normal', key: 'normal'},
        {name: '3-3-2 RGB', key: '3-3-2 rgb'},
        {name: '5 Ramps', key: '5_Ramps'},
        {name: '6 Shades', key: '6_Shades'},
        {name: '16 Colors', key: '16_Colors'},
        {name: 'Blue', key: 'blue'},
        {name: 'Cyan', key: 'cyan'},
        {name: 'Cyan Hot', key: 'cyan hot'},
        {name: 'Glow', key: 'glow'},
        {name: 'Edges', key: 'edges'},
        {name: 'Fire', key: 'fire'},
        {name: 'Grays', key: 'grays'},
        {name: 'Green', key: 'green'},
        {name: 'Hilo', key: 'hilo'},
        {name: 'Ice', key: 'ice'},
        {name: 'Magenta', key: 'magenta'},
        {name: 'Red', key: 'red'},
        {name: 'Spectrum', key: 'spectrum'},
        {name: 'Yellow', key: 'yellow'}
      ]
}