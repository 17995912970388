import { NgModule, Directive } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http'; 
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MainComponent } from './main/main.component';
import { DuplicationComponent } from './duplication/duplication.component';
import { DuplicationViewerComponent } from './duplication-viewer/duplication-viewer.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { CaseComponent } from './case/case.component';

import { NotFoundComponent } from './not-found/not-found.component';
import {NgxFilesizeModule} from 'ngx-filesize';

import {ToolbarModule} from 'primeng/toolbar';
import {ButtonModule} from 'primeng/button';
import {SplitButtonModule} from 'primeng/splitbutton';
import {DividerModule} from 'primeng/divider'; 
import {DialogModule} from 'primeng/dialog';
import {RadioButtonModule} from 'primeng/radiobutton';
import {DropdownModule} from 'primeng/dropdown';

import { ElementRef } from '@angular/core';
import {BlockUIModule} from 'primeng/blockui';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ListboxModule} from 'primeng/listbox';
import {SliderModule} from 'primeng/slider';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';
import {TabViewModule} from 'primeng/tabview';
import {InputTextModule} from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import {ToastModule} from 'primeng/toast';
import { AnalysisCaseComponent } from './analysis-case/analysis-case.component';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ScrollTopModule} from 'primeng/scrolltop';
import { NgApexchartsModule } from 'ng-apexcharts';
import { WithinViewerComponent } from './within-viewer/within-viewer.component';
import { CookieService } from 'ngx-cookie-service';
import { ManipulationComponent } from './manipulation/manipulation.component';
import { ManipulationViewerComponent } from './manipulation-viewer/manipulation-viewer.component';

export function createTranslateLoader(http: HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@Directive({ selector: 'img' })
export class LazyImgDirective {
  constructor({ nativeElement }: ElementRef<HTMLImageElement>) {
    const supports = 'loading' in HTMLImageElement.prototype;
    if (supports) {
      nativeElement.setAttribute('loading', 'lazy');
    }
  }
}

 
@NgModule({
  declarations: [												
      AppComponent,
      MainComponent,
      DuplicationComponent,
      DuplicationViewerComponent,
      ImageViewerComponent,
      CaseComponent,
      NotFoundComponent,
      AnalysisCaseComponent,
      WithinViewerComponent,
      ManipulationComponent,
      ManipulationViewerComponent
   ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxFilesizeModule,
    ToolbarModule,
    ButtonModule,
    SplitButtonModule,
    DividerModule,
    DialogModule,
    RadioButtonModule,
    DropdownModule,
    BlockUIModule,
    ProgressSpinnerModule,
    ListboxModule,
    SliderModule,
    ConfirmDialogModule,
    ToastModule,
    FontAwesomeModule,
    CheckboxModule,
    ScrollPanelModule,
    ScrollTopModule,
    NgApexchartsModule,
    CardModule,
    TabViewModule,
    InputTextModule,
    MenuModule,
    OverlayPanelModule
  ],
  providers: [ConfirmationService, MessageService , CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }

