import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  inst:string;

  demo: any[];

  constructor(
    private route: ActivatedRoute,
    private proute: Router,
    private dataService: DataService) {
      // this.inst = '00000000000000000000000000000003';
      // this.demo = [
      //   // {id:'0b0d88ced49649ff940241022d042114'}, 
      //   // {id:'12576b836d5846f4803ececee58a72bd'}, 
      //   // {id:'19a51924d137489e84436627fdc177ae'}, 
      //   // {id:'b8c5884a66024dddaa1fb2c269741640'}, 
      //   // {id:'362e7e92345140989caef47f7be4eaac'}, 
      //   // {id:'0b0d88ced49649ff940241022d042114'}, 
      //   // {id:'9b30aee15c924126b633d51720b675f5'}, 
      //   // {id:'4b59978fbad744e19186a4edfe70aa97'}, 
      //   // {id:'586fa1d244af4ad5bb4c23e28d169265'},  
      //   // {id:'9b63f9ba214843b8ac9afcaaf87c8fdf'},  
      //   // {id:'c1020245696547d3a260ba3e5f835535'}, 
      //   // {id:'c414cf1e3c1a4d16a0e6683a942cf62f'}
      //   ];

      // // this.demo = [
      // //   {id:'3664c59d245a41528dc22768a88c62d7'}, 
      // //   {id:'6b664f6b8c9c472d8f61485da8cdce31'}, 
      // //   {id:'e49ffffb58de458aa03de7e98bdbc74b'}, 
      // //   {id:'21dae495c56249ff8ad22fe6c446bbe7'}, 
      // //   {id:'4751b92483a244e78a11d0e6f5720922'},
      // //   {id:'3e4773b8439543f9b35277895bcc49e6'},
      // //   {id:'0711321406c345e2ac7f579feeed1d28'},
      // //   {id:'847ee3a979c54dfcbf65f35130c335ef'}, 
      // //   {id:'63f78cb1fae94efc8cfbf177a4251fbc'}, 
      // // ];

    }
    
  ngOnInit() {
  }

  goReportPage(id:string){
    // this.proute.navigate(['/main/'+this.inst+'/'+id]);
  }

}
