<p-toolbar>
  <div class="p-toolbar-group-left">
      <button routerLink="/duplication/{{id}}"  *ngIf="resultSimilarityTotal > 0"pButton pRipple type="button" label="{{'duplication.tab' | translate }} ({{resultSimilarityTotal}})" class="p-button-text p-button-plain"></button>
      <button *ngIf="resultManipulationTotal > 0" pButton pRipple type="button" label="{{'manipulation.tab' | translate }} ({{resultManipulationTotal}})" class="p-button-text  "></button>
  </div>

  <div class="p-d-flex p-toolbar-group-right">
      <div>
          <i *ngIf="caseSummary != undefined && caseSummary['size'] > 0" (click)="loadAnalysisCase()" class="pi pi-briefcase manipulation-case-module"></i>
          <i *ngIf="caseSummary != undefined && caseSummary['size'] == 0" (click)="loadAnalysisCase()" class="pi pi-briefcase manipulation-case-module-zero"></i>
      </div>
      <div class="manipulation-case-highlight">
          <span *ngIf="caseSummary != undefined && caseSummary['size'] > 0" class="manipulation-case-highlight-box">{{caseSummary['size']}}</span>
      </div>
  </div>
  
</p-toolbar>

<header *ngIf="result" class="manipulation-header-section">
  <div class="p-grid">
      <div class="p-col">
          <div class="p-d-flex">
              <div class="manipulation-header-title">{{'manipulation.summary' | translate}}</div>
          </div>
          <div *ngIf="result" class="p-d-flex p-flex-column p-jc-between">
              <div *ngIf="toggleListHide == true" class="manipulation-header-item-summary p-mb-1 " >
                  {{'manipulation.result-total' | translate:{total:(resultManipulationHideTotal)} }}                    
              </div>
              <div *ngIf="toggleListHide == false" class="manipulation-header-item-summary p-mb-1 " >
                  {{'manipulation.result-total' | translate:{total:(resultManipulationTotal - resultManipulationHideTotal)} }}                    
              </div>
              <div (click)="toggleFilterSelected('case')" *ngIf="selectedCount > 0 && toggleListHide == false" class="manipulation-header-item-summary-click p-mb-1  p-mt-2 ">
                  <fa-icon *ngIf="filterSelected == false" [icon]="faSquare" class="manipulation-filter-unselected "></fa-icon>
                  <fa-icon *ngIf="filterSelected == true" [icon]="faCheckSquare" class="manipulation-filter-selected "></fa-icon>
                   <label class="manipulation-filter-selected-text">{{'manipulation.result-saved-total' | translate:{total:selectedCount} }} </label>
              </div>
          </div>
          <div class="p-d-inline-flex">
            <div *ngIf="resultManipulationHideTotal > 0" class="p-d-flex p-flex-column p-jc-between">
                <div (click)="switchToggleListHide()" class="manipulation-header-item-summary-click p-mt-2 ">
                    <fa-icon *ngIf="toggleListHide == false" [icon]="faSquare" class="manipulation-filter-unselected "></fa-icon>
                    <fa-icon *ngIf="toggleListHide == true" [icon]="faCheckSquare" class="manipulation-filter-selected "></fa-icon>
                     <label class="manipulation-filter-selected-text">{{'manipulation.display-hide-case' | translate }} </label>
                </div>
            </div>
          </div>
      </div> 

      <div class="p-col">
        <div class="p-d-flex" >
            <div class="manipulation-header-title" >{{'manipulation.image-type' | translate}}</div>
        </div>
        <div class="p-d-flex p-flex-column p-jc-between">
            <!-- (click)="toggleFilterSelected('gel')" -->
            <div (click)="toggleFilterSelected('gel')" *ngIf="countGel > 0" class="p-mr-5 manipulation-header-item-summary-click ">
                <fa-icon *ngIf="filterGel == false" [icon]="faSquare" class="manipulation-filter-unselected "></fa-icon>
                <fa-icon *ngIf="filterGel == true" [icon]="faCheckSquare" class="manipulation-filter-selected "></fa-icon>
                {{"classification.gel" | translate}} ({{countGel}})
            </div>
            <!-- option of the facs image type -->
            <div (click)="toggleFilterSelected('facs')" *ngIf="countFacs > 0" class="p-mr-5 p-mt-1 manipulation-header-item-summary-click ">
                <fa-icon *ngIf="filterFacs == false" [icon]="faSquare" class="manipulation-filter-unselected "></fa-icon>
                <fa-icon *ngIf="filterFacs == true" [icon]="faCheckSquare" class="manipulation-filter-selected "></fa-icon>
                {{"classification.facs" | translate}} ({{countFacs}})
            </div>
            <!-- option of the microscopy image type -->
            <div (click)="toggleFilterSelected('microscopy')" *ngIf="countMicroscopy > 0" class="p-mr-5 p-mt-1 manipulation-header-item-summary-click ">
                <fa-icon *ngIf="filterMicroscopy == false" [icon]="faSquare" class="manipulation-filter-unselected "></fa-icon>
                <fa-icon *ngIf="filterMicroscopy == true" [icon]="faCheckSquare" class="manipulation-filter-selected "></fa-icon>
                {{"classification.microscopy" | translate}} ({{countMicroscopy}})
            </div>
            <!-- option of the macroscopy image type -->
            <div (click)="toggleFilterSelected('macroscopy')" *ngIf="countMacroscopy > 0" class="p-mr-5 p-mt-1 manipulation-header-item-summary-click ">
                <fa-icon *ngIf="filterMacroscopy == false" [icon]="faSquare" class="manipulation-filter-unselected "></fa-icon>
                <fa-icon *ngIf="filterMacroscopy == true" [icon]="faCheckSquare" class="manipulation-filter-selected "></fa-icon>
                {{"classification.macroscopy" | translate}} ({{countMacroscopy}})
            </div>
            <div (click)="toggleFilterSelected('others')" *ngIf="countOther > 0" class="p-mr-5 p-mt-1 manipulation-header-item-summary-click ">
                <fa-icon *ngIf="filterOther == false" [icon]="faSquare" class="manipulation-filter-unselected "></fa-icon>
                <fa-icon *ngIf="filterOther == true" [icon]="faCheckSquare" class="manipulation-filter-selected "></fa-icon>
                Others ({{countOther}})
            </div>
        </div>
    </div> 
    
      <div class="p-col">
          <div class="p-d-flex" >
              <div class="manipulation-header-title" >{{'manipulation.filter-type' | translate}}</div>
          </div>
          <div class="p-d-flex p-flex-column p-jc-between">
              <!-- (click)="toggleFilterSelected('gel')" -->
              <div  (click)="toggleFilterType('contrast')" class="p-mr-5 manipulation-header-item-summary-click ">
                  <fa-icon *ngIf="filterContrast == false" [icon]="faSquare" class="manipulation-filter-unselected "></fa-icon>
                  <fa-icon *ngIf="filterContrast == true" [icon]="faCheckSquare" class="manipulation-filter-selected "></fa-icon>
                  Contrast
              </div>
              <div  (click)="toggleFilterType('edge')"  class="p-mr-5 p-mt-1 manipulation-header-item-summary-click ">
                  <fa-icon *ngIf="filterEdge == false" [icon]="faSquare" class="manipulation-filter-unselected "></fa-icon>
                  <fa-icon *ngIf="filterEdge == true" [icon]="faCheckSquare" class="manipulation-filter-selected "></fa-icon>
                  Edge
              </div>
              <div  (click)="toggleFilterType('noise')"  class="p-mr-5 p-mt-1 manipulation-header-item-summary-click ">
                  <fa-icon *ngIf="filterNoise == false" [icon]="faSquare" class="manipulation-filter-unselected "></fa-icon>
                  <fa-icon *ngIf="filterNoise == true" [icon]="faCheckSquare" class="manipulation-filter-selected "></fa-icon>
                  Noise
              </div>
          </div>
      </div> 
      
      <div class="p-col">
          <div class="p-d-flex">
              <div *ngIf="filterManipulationMin != filterManipulationMax && filterManipulationMax > 0"  class="manipulation-header-title">{{'manipulation.advanced-filter' | translate}}                   
                <span *ngIf="filterUsed == true" (click)="resetList()" class="manipulation-header-title-clear">[Clear]</span>
                <div  class="p-d-flex p-mt-1">
                    <div class="manipulation-result-filter-section-slide">
                        <p-slider (onChange)="onChangeAdvancedFilter()" [style]="{width: '100px', height: '5px'}" [(ngModel)]="filterManipulation" [min]="filterManipulationMin" [max]="filterManipulationMax"></p-slider>
                    </div>
                    <div class="manipulation-result-filter-section-value"> / {{filterManipulation}} / ({{countManipulation}})</div>
                </div>
                <div class="p-d-inline-flex">
                </div>
            </div>
        </div>
        
        <div class="p-d-flex">
            <div class="manipulation-header-title">{{'manipulation.advanced-filter-display-size' | translate}}           
                    <div  class="p-d-flex p-mt-1">
                        <div class="manipulation-result-filter-section-slide">
                            <p-slider (onChange)="updateFilterImageSize()" [style]="{width: '100px', height: '5px'}" [step]="filterImageDisplayStep" [(ngModel)]="filterImageDisplaySize" [min]="filterImageDisplaySizeMin" [max]="filterImageDisplaySizeMax"></p-slider>
                            </div>                    
                        <div class="manipulation-result-filter-section-value"> / {{filterImageDisplaySize}} px</div>
                    </div>
                <div class="p-d-inline-flex"></div>
            </div>
        </div>
    </div>

      <div class="p-col">
          <div class="p-d-flex p-jc-between">
              <div></div>
          </div>
          <div class="p-d-flex p-jc-between">
              <div>
              </div>
              <div *ngIf="filterImageFilename == '' || filterImageFilename == undefined">
                  <i (click)="openFilterImage()" class="pi pi-filter manipulation-header-item-icon" title="Filter Image"></i>
              </div>
              <div (click)="resetFilterFilename()" *ngIf="filterImageFilename != '' && filterImageFilename != undefined" class="p-grid p-mr-1">
                  <div class="p-d-flex p-flex-column ">
                      <div class="p-d-flex p-jc-between">
                          <div></div>
                          <div *ngIf="record" class="p-d-flex">
                              <div class="p-d-flex p-flex-column manipulation-filter-image-selected-box">
                                  <img *ngIf="filterImageFilename != '' && filterImageFilename != undefined" class="manipulation-filter-image-selected" src="{{baseUrl}}/image/{{id}}/{{filterImageFilename}}.png?width=250" />
                                  <div *ngIf="record['recordFormat'] == 'pdf'" class="p-mt-1 manipulation-filter-detail-text">{{'manipulation.list-page' | translate}} {{(filterImageFilenamePage + 1)}}</div>
                              </div>
                              <div>
                                  <i class="pi pi-trash manipulation-header-item-icon" title="Filter Image"></i>
                              </div>
                          </div>
                      </div>
                      <div *ngIf="result" class="p-mt-4 manipulation-filter-found-text">                            
                          {{'manipulation.list-total' | translate:{total:result['manipulation'].length} }} 
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</header>
<p-divider></p-divider>
<section *ngIf="result" class="manipulation-content-section">
  <div *ngFor="let item of manipulation; index as i" class="manipulation-content-section-box">

      <div class="p-d-flex p-jc-between manipulation-content-index">
          <div class="manipulation-content-no" >
                <!-- <p-checkbox [(ngModel)]="item['checkbox']" inputId="{{i}}" class="p-mr-2"></p-checkbox> -->
                <label for="{{i}}">{{'manipulation.list-no' | translate}} {{(i+1)}}</label>
              <!-- <div>{{'manipulation.list-no' | translate}} {{(i+1)}}</div> -->
          </div>
          
          <!-- <div class="duplication-content-no " >
            <p-checkbox [(ngModel)]="item['checkbox']" inputId="{{i}}" class="p-mr-2"></p-checkbox>
            <label for="{{i}}">{{'duplication.list-no' | translate}} {{(i+1)}}</label>
            </div> -->
        
          <div class="p-d-flex">
            <div class="manipulation-content-indicator p-d-flex">
                <div class="manipulation-content-indicator-text-disabled" (click)="toggleCase(i, item['id'])"  *ngIf="item['hide'] == false" >{{ 'manipulation.list-hide' | translate }}</div>
                <div><i  (click)="toggleCase(i, item['id'])" *ngIf="item['hide'] == false" class="pi pi-trash manipulation-case-button"></i></div>    
                <div class="manipulation-content-indicator-text-disabled" (click)="toggleCase(i, item['id'])"  *ngIf="item['hide'] == true" >{{ 'manipulation.list-restore' | translate }}</div>
                <div><i  (click)="toggleCase(i, item['id'])" *ngIf="item['hide'] == true" class="pi pi-undo manipulation-case-button"></i></div>            
            </div>
            <div *ngIf="item['hide'] == false" class="p-ml-2 p-mr-2"></div>  
            <div *ngIf="item['hide'] == false" class="manipulation-content-indicator p-d-flex">
                <div class="manipulation-content-indicator-text-disabled" (click)="submitCase(i, item)"  *ngIf="item['selected'] == null || item['selected'] == false" >{{ 'manipulation.list-save-case' | translate }}</div>
                <div><i  title="Add the Analysis Case" (click)="submitCase(i, item)" *ngIf="item['selected'] == null || item['selected'] == false" class="pi pi-briefcase manipulation-case-button"></i></div>            
                <div class="manipulation-content-indicator-text-enabled" (click)="removeCase(i)" *ngIf="item['selected']" >{{ 'manipulation.list-delete-case' | translate }}</div>
                <div><i title="Remove the Analysis Case" (click)="removeCase(i)" *ngIf="item['selected']" class="pi pi-briefcase manipulation-case-button-selected"></i></div>
            </div>
          </div>
      </div>
      <div class="manipulation-content-main-box">
        <div class="p-grid ">
            <div class="p-col-12">
                <div class="p-d-flex" >
                    <div class=" manipulation-analysis-summary " (click)="loadManipulationImageViewer(item['id'])">
                        <i class="pi pi-search p-mr-2 manipulation-content-link"></i>
                        <span>Open The Image Viewer</span>
                    </div>
                    <div class="manipulation-analysis-beautification">
                        <span style="margin-left: 10px; margin-right: 10px;">/</span>
                        Beautification => 
                        <!-- {{'manipulation.list-good' | translate}}:
                        {{ (item['analysis']['blue'] + item['analysis']['green'] )}} % 
                        <span style="margin-left: 2px; margin-right: 5px;">,</span> -->
                        {{'manipulation.list-bad' | translate}}:
                        {{ (item['analysis']['yellow'] + item['analysis']['orange']) }} %
                    </div>
                </div>
            </div>
            
            <div class="p-col-fixed p-d-flex " style="width: 320px; ">
                <div style="width: 320px; padding-left: 25px;">
                    <div class="p-d-flex p-jc-center manipulation-card-title p-mb-1" >                    
                        {{'classification.'+item['classification'] | translate}}
                            <!-- {{'manipulation.list-processed-image' | translate}} -->
                    </div>
                    <img (click)="openManipulationPanelLeft(item)" class="manipulation-content-img" src="{{baseUrl}}/image/{{id}}/{{item[ 'original_file']}}?width=250"  />
                    <div class="manipulation-card-title-resolution">{{'manipulation.list-resolution' | translate}} {{item['width']}} * {{item['height']}}</div>                                   
                    <div class="p-d-flex p-jc-center manipulation-card-title  p-mb-1" style="margin-top:20px;"></div>
                    <img class="manipulation-content-img-common" src="{{baseUrl}}/image/{{id}}/{{item[ 'original_file']}}?width=250&parent=yes&stroke=5"  />                    
                </div>
            </div>
            
            <div class="p-col">
                <div class="manipulation-content-box" style="float: left;" >
                    <div class="p-d-flex p-jc-center manipulation-card-title  p-mb-1" >Beautification</div>
                    <img *ngIf="filterImageDisplaySize != undefined" (click)="openManipulationBeautificationPanel(item)" class="manipulation-content-filter-img " src="{{baseUrl}}/image/manipulation/{{id}}/{{item[ 'original_file']}}?width={{filterImageDisplaySize}}"  />
                </div>
                <section *ngIf="item['filter']">
                    <div *ngFor="let filterName of filterFilterList(filterList)" class="manipulation-content-box" style="float: left;" >
                        <div class="p-d-flex p-jc-center manipulation-card-title  p-mb-1" >{{filterName}}</div>                    
                        <img  *ngIf="filterImageDisplaySize != undefined" (click)="openManipulationFilterPanel(item, filterName)" class="manipulation-content-filter-img" src="{{baseUrl}}/filter/{{filterName}}/{{id}}/{{item[ 'original_file']}}?width={{filterImageDisplaySize}}"  />
                    </div>
                </section>   

                <section *ngIf="item['filter'] == undefined || item['filter'] == false" >
                    <p-progressSpinner [style]="{width: '50px', height: '50px', margin: '30px'}" ></p-progressSpinner>
                </section>
            </div>
            
            <div class="p-col-12">
                <div class="p-d-flex" style="float: right;">
                    <div class="manipulation-content-indicator p-d-flex" >
                        <div class="manipulation-content-indicator-text-disabled" (click)="toggleCase(i, item['id'])"  *ngIf="item['hide'] == false" >{{ 'manipulation.list-hide' | translate }}</div>
                        <div><i  (click)="toggleCase(i, item['id'])" *ngIf="item['hide'] == false" class="pi pi-trash manipulation-case-button"></i></div>    
                        <div class="manipulation-content-indicator-text-disabled" (click)="toggleCase(i, item['id'])"  *ngIf="item['hide'] == true" >{{ 'manipulation.list-restore' | translate }}</div>
                        <div><i  (click)="toggleCase(i, item['id'])" *ngIf="item['hide'] == true" class="pi pi-undo manipulation-case-button"></i></div>            
                    </div>
                </div>
            </div>


        </div>
      </div>

  </div>
  
  <div *ngIf="manipulation != undefined && manipulation.length == 0" class="p-mt-3 p-mb-3 viewer-item-not-found">
      No results were found ...
  </div>
</section>

<p-dialog  [modal]="true" *ngIf="record" header="{{'duplication.please-select-image' | translate}}" [(visible)]="filterImageDialog" [style]="{width: '60vw'}" [baseZIndex]="10000">
  <div class="p-grid">
      <div class="p-d-flex p-flex-column manipulation-card" *ngFor="let item of filterOriginalList" (click)="applyFilterFilename(item['filename'])">
          <img class="manipulation-card-img" src="{{baseUrl}}/image/{{id}}/{{item['filename']}}?width=250" />
          <div class="manipulation-card-title">
              <p class="manipulation-card-title-text" *ngIf="record['recordFormat'] == 'pdf'" title="Page: {{(item['page']+1)}}">{{'manipulation.list-page' | translate}} {{(item['page']+1)}}</p> 
              <p class="manipulation-card-title-text" *ngIf="record['recordFormat'] != 'pdf'" title="{{item['originalFilename']}}">{{item['originalFilename']}}</p> 
          </div>
      </div>
  </div>
</p-dialog>

<p-scrollTop></p-scrollTop>
<p-toast></p-toast>

<section *ngIf="successLoading == false">
  <div class="p-mt-3 p-mb-3 main-item-not-found">
      {{'manipulation.invalid-request' | translate}}
  </div>
</section>

<footer class="manipulation-footer">
</footer>

<p-dialog [dismissableMask]="true" [modal]="true" [(visible)]="manipulationPanelLeft" (onHide)="manipulationPanelLeft = false; displayManipulationItem = undefined;" >
  <div class="p-d-flex p-jc-center">
      <img *ngIf="displayManipulationItem != undefined" 
      (click)="manipulationPanelLeft = false; displayManipulationItem = undefined;" 
      src="{{baseUrl}}/image/{{id}}/{{displayManipulationItem[ 'original_file']}}?width=720" />    
  </div>
</p-dialog>

<p-dialog header="Beautification" [dismissableMask]="true" [modal]="true" [(visible)]="manipulationBeautificationPanel" (onHide)="manipulationBeautificationPanel = false; displayManipulationItem = undefined;" >
  <div class="p-d-flex p-jc-center">
      <img *ngIf="displayManipulationItem != undefined" 
      (click)="manipulationBeautificationPanel = false; displayManipulationItem = undefined;"
      src="{{baseUrl}}/image/manipulation/{{id}}/{{displayManipulationItem[ 'original_file']}}?width=720" />    
  </div>
</p-dialog>     

<p-dialog header="{{manipulationFilter}}" [dismissableMask]="true" [modal]="true" [(visible)]="manipulationFilterPanel" (onHide)="manipulationFilterPanel = false; displayManipulationItem = undefined;" >
  <div class="p-d-flex p-jc-center"> 
      <img *ngIf="displayManipulationItem != undefined" 
      (click)="manipulationFilterPanel = false; displayManipulationItem = undefined;"
      src="{{baseUrl}}/filter/{{manipulationFilter}}/{{id}}/{{displayManipulationItem[ 'original_file']}}?width=720" />
  </div>
</p-dialog>

<p-blockUI [blocked]="blocked" [baseZIndex]="20001">
    <p-progressSpinner [style]="{width: '150px', height: '150px'}" styleClass="custom-spinner" strokeWidth="2" animationDuration=".5s"></p-progressSpinner>
    <span style="margin-left: 25px; color: white; font-size: 24pt;">Initializing the report...</span>
</p-blockUI>

