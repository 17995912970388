<p-toolbar>
    <div class="p-toolbar-group-left">
        <div (click)="back()" class="p-d-flex analysis-case-back">
            <div class="analysis-case-back-icon"><i class="pi pi-angle-left" title="{{'case.back' | translate}}"></i></div>
            <div class="analysis-case-back-text">{{'case.back' | translate}}</div>
        </div> 
    </div> 

    <div class="p-toolbar-group-right">
    </div>  
</p-toolbar>
<header *ngIf="record" class="analysis-case-record-section p-d-flex p-jc-between">
    <div *ngIf="record" class="p-d-flex ">
        <div class="analysis-case-cover-box">
            <img class="analysis-case-cover-img" src="{{baseUrl}}/image/cover/{{id}}.png" />
        </div>
        <div class="analysis-case-metadata-box">
            <div *ngIf="metadata" class="analysis-case-metadata-title">{{metadata['record']['title']}}</div>
            <!-- <div *ngIf="metadata == undefined" class="analysis-case-metadata-title">{{record['originalFilename']}}</div> -->
            <div (click)="linkViaDoi(metadata['record']['doi']) " *ngIf="metadata != null && metadata['record']['doi']" class="analysis-case-metadata-doi p-mb-3">{{metadata['record']['doi']}}</div>
            <div class="analysis-case-metadata-other">{{'case.format' | translate}} {{record['recordFormat'] | uppercase }}</div>
            <div class="analysis-case-metadata-other">{{'case.size' | translate}} {{record['recordProcessedSize'] | filesize}}</div>
            <div class="analysis-case-metadata-other">{{'case.original' | translate}} {{record['recordOriginalAmount']}}</div>
            <div class="analysis-case-metadata-other">{{'case.created-date' | translate}} {{record['recordCreatedDate'] | date: 'yyyy-MM-dd'}}</div>

            <div *ngIf="cases" class="analysis-case-metadata-box-case p-mt-3" >
                <i *ngIf="cases['data'] != null" class="pi pi-briefcase" title="Open the saved analysis cases"></i>
                <span *ngIf="cases['data'] != null" class="p-ml-2">{{'case.result-saved-total' |  translate:{total:cases['data'].length} }}</span> 
            </div>

        </div>
    </div>
</header>

<p-divider></p-divider>

<section class="tab-section">
    
    <div *ngIf="confirmDuplicationResult.length == 0 && confirmManipulationResult.length == 0" class="p-mt-3 p-mb-3 analysis-case-info-item-not-found">
        {{'case.no-result' | translate}}
    </div>

    <p-tabView (onChange)="handleTabCange($event)">
        
        <p-tabPanel [selected]="tabDuplicationSelected" *ngIf="confirmDuplicationResult != undefined && confirmDuplicationResult.length > 0" header="{{'duplication.tab' | translate}}">
        
            <section *ngIf="cases != undefined " class="p-d-flex p-jc-between analysis-case-control-section">
                <div class="analysis-case-control-section-total">
                    Total {{duplicationSize}} record(s)
                </div>
                <div *ngIf="cases != undefined && cases['data'] != undefined && cases['data'].length > 0" >
                    <p-button clsas="p-button-sm" *ngIf="hasDuplicationDownloadStatus" icon="pi pi-download" [loading]="isPreparingDuplicationPDF" [label]="downloadDuplicationPDFText" (click)="downloadDuplicationReport()"></p-button>
                </div> 
            </section>

            <section class="analysis-case-content-section">

                <div *ngFor="let item of confirmDuplicationResult; index as i" class="duplication-content-section-box">
                        
                    <!-- {{item | json}} -->
                    <div *ngIf="i > 0">
                        <p style="page-break-after:always"></p>
                    </div>

                    <div class="space-section"></div>
                    <div class="p-d-flex p-jc-between duplication-content-index">
                        <div class="duplication-content-no">{{'case.list-no' | translate}} {{(i+1)}}</div>
                        <div class="duplication-content-indicator">
                        </div>
                    </div> 

                    <div class="duplication-content-box ">
                        <div class="p-grid duplication-content-record-section">

                            <!-- Matching template section -->
                            <div class="p-col" *ngIf="item['duplication_type'] == undefined">
                                <div class="p-d-flex p-jc-between">
                                    <div>
                                        <b>{{'case.list-target-image' | translate}}</b> {{item['description']}}; <b>{{'case.list-analysis-type' | translate}}</b> {{'case.list-manipulation' | translate}}
                                    </div>
                                    <div>    
                                        <i (click)="confirmRemoveStoredCase(item)" class="pi pi-trash p-mr-2 analysis-case-icon-button" title="{{'case.list-remove-saved-case' | translate}}"></i>                            
                                    </div>
                                </div>
                            </div>

                            <!-- Matching template section -->
                            <div class="p-col" *ngIf="item['duplication_type'] == 'within'">
                                <div class="p-d-flex p-jc-between">
                                    <div>
                                        <b>{{'case.list-target-image' | translate}}</b> {{item['img1']['description']}}; <b>{{'case.list-analysis-type' | translate}}</b> {{'case.list-analysis-within' | translate}}
                                    </div>
                                    <div>    
                                        <i (click)="confirmRemoveStoredCase(item)" class="pi pi-trash p-mr-2 analysis-case-icon-button" title="{{'case.list-remove-saved-case' | translate}}"></i>                            
                                    </div>
                                </div>
                            </div>
                        
                            <!-- Duplication Section -->
                            <div class="p-col" *ngIf="item['duplication_type'] != 'within' && item['duplication_type'] != undefined">
                                <div class="p-d-flex p-jc-between">
                                    <div>
                                        <b>{{'case.list-target-image' | translate}}</b> {{item['img1']['description']}}
                                        <label *ngIf="item['source'] == 'within'">; <b>{{'case.list-analysis-type' | translate}}</b> {{'case.list-analysis-between' | translate}} </label>
                                        <label *ngIf="item['source'] == 'user'">; <b>{{'case.list-analysis-type' | translate}}</b> {{'case.list-analysis-between' | translate}}; <b>{{'case.list-analysis-repository' | translate}}</b> {{'case.list-analysis-user' | translate}}</label>
                                        <label *ngIf="item['source'] == 'lab'">; <b>{{'case.list-analysis-type' | translate}}</b> {{'case.list-analysis-between' | translate}}; <b>{{'case.list-analysis-repository' | translate}}</b> {{'case.list-analysis-lab' | translate}} </label>
                                        <label *ngIf="item['source'] == 'institution'">; <b>{{'case.list-analysis-type' | translate}}</b> {{'case.list-analysis-between' | translate}}; <b>{{'case.list-analysis-repository' | translate}}</b> {{'case.list-analysis-institution' | translate}} </label>
                                        <label *ngIf="item['source'] != 'institution' && item['source'] != 'within' && item['source'] != 'user' && item['source'] != 'lab'">; <b>{{'case.list-analysis-type' | translate}}</b> {{'case.list-analysis-between' | translate}}; <b>{{'case.list-analysis-internet' | translate}}</b> {{'case.list-analysis-internet' | translate}} </label>
                                    </div>
                                    <div>    
                                        <i (click)="confirmRemoveStoredCase(item)" class="pi pi-trash p-mr-2 analysis-case-icon-button" title="{{'case.list-remove-saved-case' | translate}}"></i>                            
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> 

                    <div class="p-grid analysis-case-content-section-box">

                        <div *ngIf="item['duplication_type'] != undefined" >
                            <div class="p-d-flex p-flex-column analysis-case-original-image">
                                <div class="analysis-case-content-section-image-box" >
                                    <div>
                                        <img class="image-block" src="{{baseUrl}}/image/{{id}}/{{item[ 'img1'][ 'original_file']}}?stroke=10&width=150"  />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngFor="let case of item['cases']; index as c" >

                            <!-- {{case | json}} -->
                            <div *ngIf="case['type'] == 'manually'" class="p-d-flex p-flex-column analysis-case-content-section-img">
                                <div class="p-d-flex p-jc-between p-mb-2 p-mt-1">
                                    <div class="p-ml-2">{{'case.list-no' | translate}} {{(c+1)}} </div>
                                    <div>{{case.createdDate | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
                                    <div (click)="confirmRemoveCase(case.id)">
                                        <i class="pi pi-trash p-mr-2 analysis-case-icon-button" title="{{'case.list-remove-saved-case' | translate}}"></i>
                                    </div>
                                </div>
                
                                <div class="analysis-case-content-section-image-box" (click)="openAnalysisDialog(case.id)">
                                    <div><img src="{{case.image}}" /></div>
                                </div>
                                <!-- <div class="analysis-case-content-section-type">Analysis</div> -->
                            </div>
                            <div *ngIf="case['type'] == 'duplication'" class="p-d-flex p-flex-column analysis-case-content-section-duplication">
                                <div class="p-d-flex p-jc-between p-mb-2 p-mt-1">
                                    <div class="p-ml-2">{{'case.list-no' | translate}} {{(c+1)}}</div>
                                    <div>{{case.createdDate | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
                                    <div (click)="confirmRemoveCase(case.id)">
                                        <i class="pi pi-trash p-mr-2 analysis-case-icon-button" title="{{'case.list-remove-saved-case' | translate}}"></i>
                                    </div>
                                </div>
                                
                                <div class="p-d-flex analysis-case-content-section-duplication-box" (click)="openDuplicationDialog(case['id'], case['sourceId'], case['targetId'])">
                                    <div class="p-d-flex p-jc-center">
                                        <img src="{{baseUrl}}/image/{{id}}/{{case['analysis']}}/1/{{case[ 'sourceRecord']}}_{{case[ 'sourceFilename']}}/{{case[ 'targetRecord']}}_{{case[ 'targetFilename']}}?alpha=0.1&stroke=1" />
                                    </div>
                                    
                                    <div class="p-d-flex p-jc-center">
                                        <img src="{{baseUrl}}/image/{{id}}/{{case['analysis']}}/2/{{case[ 'sourceRecord']}}_{{case[ 'sourceFilename']}}/{{case[ 'targetRecord']}}_{{case[ 'targetFilename']}}?alpha=0.1&stroke=1" />
                                    </div>
                                </div>
                                <div class="analysis-case-content-section-type">{{'case.list-duplication' | translate}}</div>
                            </div>
                            <div *ngIf="case['type'] == 'duplication-within'" class="p-d-flex p-flex-column analysis-case-content-section-duplication">
                                <div class="p-d-flex p-jc-between p-mb-2 p-mt-1">
                                    <div class="p-ml-2">{{'case.list-no' | translate}} {{(c+1)}}</div>
                                    <div>{{case.createdDate | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
                                    <div (click)="confirmRemoveCase(case.id)">
                                        <i class="pi pi-trash p-mr-2 analysis-case-icon-button" title="{{'case.list-remove-saved-case' | translate}}"></i>
                                    </div>
                                </div>
                                
                                <div class="p-d-flex analysis-case-content-section-duplication-box" (click)="loadWithinImageViewer(case['sourceId'])">
                                    <div class="p-d-flex p-jc-center">
                                        <img src="{{baseUrl}}/image/{{id}}/{{case[ 'sourceFilename']}}" />
                                    </div>
                                    
                                    <div class="p-d-flex p-jc-center">
                                        <img src="{{baseUrl}}/image/{{id}}/5/{{case[ 'sourceFilename']}}?stroke=2" />
                                    </div>
                                </div>
                                <div class="analysis-case-content-section-type">{{'case.list-duplication-within' | translate}}</div>
                            </div>

                        </div>

                    </div>

                </div>

            </section>
        </p-tabPanel>

        <p-tabPanel [selected]="tabManipulationSelected" *ngIf="confirmManipulationResult != undefined && confirmManipulationResult.length > 0" header="{{'manipulation.tab' | translate}}">
        
                <section *ngIf="cases != undefined " class="p-d-flex p-jc-between analysis-case-control-section">
                    <div class="analysis-case-control-section-total">
                        Total {{manipulationSize}} record(s)
                    </div>
                    <div *ngIf="cases != undefined && cases['data'] != undefined && cases['data'].length > 0" >
                        <p-button clsas="p-button-sm" *ngIf="hasManipulationDownloadStatus" icon="pi pi-download" [loading]="isPreparingManipulationPDF" [label]="downloadManipulationPDFText" (click)="downloadManipulationReport()"></p-button>
                    </div> 
                </section>
    
                <section class="analysis-case-content-section">
    
                    <div *ngFor="let item of confirmManipulationResult; index as i" class="duplication-content-section-box">
                            
                        <div *ngIf="i > 0">
                            <p style="page-break-after:always"></p>
                        </div>
    
                        <div class="space-section"></div>
                        <div class="p-d-flex p-jc-between duplication-content-index">
                            <div class="duplication-content-no">{{'case.list-no' | translate}} {{(i+1)}}</div>
                            <div class="duplication-content-indicator">
                            </div>
                        </div> 
    
                        <div class="duplication-content-box ">
                            <div class="p-grid duplication-content-record-section">
    
                                <!-- Matching template section -->
                                <div class="p-col" *ngIf="item['duplication_type'] == undefined">
                                    <div class="p-d-flex p-jc-between">
                                        <div>
                                            <b>{{'case.list-target-image' | translate}}</b> {{item['description']}}; <b>{{'case.list-analysis-type' | translate}}</b> {{'case.list-manipulation' | translate}}
                                        </div>
                                        <div>    
                                            <i (click)="confirmRemoveStoredCase(item)" class="pi pi-trash p-mr-2 analysis-case-icon-button" title="{{'case.list-remove-saved-case' | translate}}"></i>                            
                                        </div>
                                    </div>
                                </div>
    
                            </div>
                        </div> 
    
                        <div class="p-grid analysis-case-content-section-box">
    
                            <div *ngIf="item['duplication_type'] == undefined" >
                                <div class="p-d-flex p-flex-column analysis-case-original-image">
                                    <div class="analysis-case-content-section-image-box" >
                                        <div>
                                            <img src="{{baseUrl}}/image/{{id}}/{{item[ 'original_file']}}?stroke=10&width=150"  />
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div *ngFor="let case of item['cases']; index as c" >
    
                                <!-- {{case | json}} -->
                                <div *ngIf="case['type'] == 'manually'" class="p-d-flex p-flex-column analysis-case-content-section-img">
                                    <div class="p-d-flex p-jc-between p-mb-2 p-mt-1">
                                        <div class="p-ml-2">{{'case.list-no' | translate}} {{(c+1)}} </div>
                                        <div>{{case.createdDate | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
                                        <div (click)="confirmRemoveCase(case.id)">
                                            <i class="pi pi-trash p-mr-2 analysis-case-icon-button" title="{{'case.list-remove-saved-case' | translate}}"></i>
                                        </div>
                                    </div>
                    
                                    <div class="analysis-case-content-section-image-box" (click)="openAnalysisDialog(case.id)">
                                        <div><img src="{{case.image}}" /></div>
                                    </div>
                                    <!-- <div class="analysis-case-content-section-type">Analysis</div> -->
                                </div>
                                <div *ngIf="case['type'] == 'manipulation'" class="p-d-flex p-flex-column analysis-case-content-section-duplication">
                                    <div class="p-d-flex p-jc-between p-mb-2 p-mt-1">
                                        <div class="p-ml-2">{{'case.list-no' | translate}} {{(c+1)}}</div>
                                        <div>{{case.createdDate | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
                                        <div (click)="confirmRemoveCase(case.id)">
                                            <i class="pi pi-trash p-mr-2 analysis-case-icon-button" title="{{'case.list-remove-saved-case' | translate}}"></i>
                                        </div>
                                    </div>
                                    
                                    <div class="p-d-flex analysis-case-content-section-duplication-box" (click)="loadWithinImageViewer(case['sourceId'])">
                                        <div class="p-d-flex p-jc-center">
                                            <img src="{{baseUrl}}/image/{{id}}/{{case[ 'sourceFilename']}}" />
                                        </div>
                                        <div class="p-d-flex p-jc-center">
                                            <img src="{{baseUrl}}/image/manipulation/{{id}}/5/{{case[ 'sourceFilename']}}" />
                                        </div>
                                    </div>
                                    <div class="analysis-case-content-section-type">{{'case.list-manipulation' | translate}}</div>
                                </div>
    
                                <!-- <div class="space-section"></div>
                                <div class="p-d-flex duplication-content-analysis-box" >
                                    <div class="duplication-content-analysis-box-text">{{(c+1)}}</div>
                                    <div class="p-d-flex p-jc-center duplication-content-analysis-box-image" >
                                        <div><img src="{{case.image}}" /></div>
                                    </div>
                                </div> -->
                            </div>
    
                        </div>
    
                    </div>
    
                </section>
        </p-tabPanel>
    </p-tabView>
</section>


<!-- <section class="analysis-case-content-section">
    <div *ngIf="cases" class="p-grid analysis-case-content-section-box">
        <div *ngFor="let item of cases['data']; index as i ">
            <div *ngIf="item['type'] == 'internet'" class="p-d-flex p-flex-column analysis-case-content-section-img">
                <div class="p-d-flex p-jc-between p-mb-2 p-mt-1">
                    <div class="p-ml-2">No. {{(i+1)}}</div>
                    <div>{{item.createdDate | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
                    <div (click)="confirmRemoveCase(item.id)">
                        <i class="pi pi-trash p-mr-2 analysis-case-icon-button" title="Remove the analysis case"></i>
                    </div>
                </div>

                <div class="analysis-case-content-section-image-box" (click)="openInternetDialog(i)">
                    <div *ngIf="item.source == 'source'" class="analysis-case-content-section-image-box-internet" >
                        <img src="{{baseUrl}}/image/{{id}}/{{item.sourceFilename}}?width=150" />
                    </div>
                    <div *ngIf="item.source == 'similar'" class="analysis-case-content-section-image-box-internet">
                        <img src="{{baseUrl}}/image/{{id}}/{{item.targetRecord}}_{{item.targetFilename}}?width=150" />
                    </div>
                </div>

                <div class="analysis-case-content-section-type">Internet Case</div>
            </div>
            <div *ngIf="item['type'] == 'manually'" class="p-d-flex p-flex-column analysis-case-content-section-img">
                <div class="p-d-flex p-jc-between p-mb-2 p-mt-1">
                    <div class="p-ml-2">No. {{(i+1)}}</div>
                    <div>{{item.createdDate | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
                    <div (click)="confirmRemoveCase(item.id)">
                        <i class="pi pi-trash p-mr-2 analysis-case-icon-button" title="Remove the analysis case"></i>
                    </div>
                </div>

                <div class="analysis-case-content-section-image-box" (click)="openAnalysisDialog(i)">
                    <div><img src="{{item.image}}" /></div>
                </div>
                <div class="analysis-case-content-section-type">Analysis</div>
            </div>
            <div *ngIf="item['type'] == 'duplication'" class="p-d-flex p-flex-column analysis-case-content-section-duplication">
                <div class="p-d-flex p-jc-between p-mb-2 p-mt-1">
                    <div class="p-ml-2">No. {{(i+1)}}</div>
                    <div>{{item.createdDate | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
                    <div (click)="confirmRemoveCase(item.id)">
                        <i class="pi pi-trash p-mr-2 analysis-case-icon-button" title="Remove the analysis case"></i>
                    </div>
                </div>
                
                <div class="p-d-flex analysis-case-content-section-duplication-box" (click)="openDuplicationDialog(item['id'], item['sourceId'], item['targetId'])">
                    <div class="p-d-flex p-jc-center">
                        <img src="{{baseUrl}}/image/{{id}}/{{item['analysis']}}/1/{{item[ 'sourceRecord']}}_{{item[ 'sourceFilename']}}/{{item[ 'targetRecord']}}_{{item[ 'targetFilename']}}?alpha=0.1&stroke=1" />
                    </div>
                    
                    <div class="p-d-flex p-jc-center">
                        <img src="{{baseUrl}}/image/{{id}}/{{item['analysis']}}/2/{{item[ 'sourceRecord']}}_{{item[ 'sourceFilename']}}/{{item[ 'targetRecord']}}_{{item[ 'targetFilename']}}?alpha=0.1&stroke=1" />
                    </div>
                </div>
                <div class="analysis-case-content-section-type">Duplication</div>
            </div>
            <div *ngIf="item['type'] == 'duplication-within'" class="p-d-flex p-flex-column analysis-case-content-section-duplication">
                <div class="p-d-flex p-jc-between p-mb-2 p-mt-1">
                    <div class="p-ml-2">No. {{(i+1)}}</div>
                    <div>{{item.createdDate | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
                    <div (click)="confirmRemoveCase(item.id)">
                        <i class="pi pi-trash p-mr-2 analysis-case-icon-button" title="Remove the analysis case"></i>
                    </div>
                </div>
                
                <div class="p-d-flex analysis-case-content-section-duplication-box" (click)="loadWithinImageViewer(item['sourceId'])">
                    <div class="p-d-flex p-jc-center">
                        <img src="{{baseUrl}}/image/{{id}}/{{item[ 'sourceFilename']}}" />
                    </div>
                    
                    <div class="p-d-flex p-jc-center">
                        <img src="{{baseUrl}}/image/{{id}}/5/{{item[ 'sourceFilename']}}?stroke=2" />
                    </div>
                </div>
                <div class="analysis-case-content-section-type">Duplication Within</div>
            </div>

        </div>
    </div>

    <div *ngIf="cases != undefined && cases['data'] != undefined && cases['data'].length == 0" class="p-mt-3 p-mb-3 analysis-case-info-item-not-found">
        No results were found ...
    </div>

</section> -->


<footer class="analysis-case-footer">
</footer>

<p-dialog [modal]="true" *ngIf="analysisCaseRecord"  header="{{'case.dialog-analysis-case' | translate}}" [(visible)]="analysisCaseDialog " [style]="{width: '90vw', height: '90vw'} " [baseZIndex]="10000 ">
    <div class="analysis-case-dialog-image ">
        <img [src]="analysisCaseRecord[ 'image'] " />
    </div>
</p-dialog>

<!-- <p-dialog  [modal]="true" *ngIf="record" header="Internet Case" [(visible)]="internetCaseDialog" [style]="{width: '60vw'}" [baseZIndex]="10000">

    <div *ngIf="internetImageImgWebTemp" class="viewer-internet-dialog-section">
        <div *ngIf="internetImageImgWebTemp" class="p-d-inline-flex p-mt-3">
            <div class="viewer-internet-dialog-img">
                <img src="{{baseUrl}}/image/{{id}}/{{internetCaseImageId}}?width=100" />
            </div>
            <div class="viewer-internet-dialog-info-box">
                <div *ngIf="internetImageImgWebTemp['data'][0]['entity'].length > 0" class="p-ml-3 p-mt-1 viewer-internet-dialog-info-result-section-title">Detected Entities</div>
                <div class="p-grid p-ml-2 p-mt-1">
                    <div class="viewer-internet-dialog-info-item-entity" *ngFor="let item of internetImageImgWebTemp['data'][0]['entity']">
                        <span title="{{item['description']}} : {{item['score']}}">{{item['description']}}</span>
                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['page'].length > 0" class="p-mt-3 viewer-internet-dialog-info-result-section-title">Page Contains Images</div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['page'].length > 0" class="p-mt-1 viewer-internet-dialog-info-result-section">
            <div class="p-d-flex p-flex-column" *ngFor="let item of internetImageImgWebTemp['data'][0]['page'] ; index as i ">
                <div class="p-d-flex">
                    <div class="viewer-internet-dialog-info-item-no">{{(i+1)}}</div>
                    <div class="viewer-internet-dialog-info-item-text">
                        <a href="{{item['url']}}" target="_blank">{{item['title']}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['full'].length > 0" class="p-mt-3 viewer-internet-dialog-info-result-section-title">Full Matched</div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['full'].length > 0" class="p-grid p-mt-1 viewer-internet-dialog-info-result-section">
            <div class="p-d-flex" *ngFor="let item of internetImageImgWebTemp['data'][0]['full'] ; index as i ">
                <div class="p-d-flex p-flex-column viewer-internet-dialog-info-item-img">
                    <img src="{{item['url']}}" title="{{item['url']}}" />
                    <div title="{{item['url']}}">
                        <a href="{{item['url']}}" target="_blank">{{item['url'].substring(0, item['url'].indexOf("/", 10)).replace('https://','').replace('http://','')}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['partially'].length > 0" class="p-mt-3 viewer-internet-dialog-info-result-section-title">Partially Matched</div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['partially'].length > 0" class="p-grid p-mt-1 viewer-internet-dialog-info-result-section">
            <div class="p-d-flex" *ngFor="let item of internetImageImgWebTemp['data'][0]['partially'] ; index as i ">
                <div class="p-d-flex p-flex-column viewer-internet-dialog-info-item-img">
                    <img src="{{item['url']}}" title="{{item['url']}}" />
                    <div title="{{item['url']}}">
                        <a href="{{item['url']}}" target="_blank">{{item['url'].substring(0, item['url'].indexOf("/", 10)).replace('https://','').replace('http://','')}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-mt-3 p-mb-3 viewer-internet-dialog-info-item-not-found" *ngIf="internetImageImgWebTemp['data'][0]['partially'].length == 0 && internetImageImgWebTemp['data'][0]['full'].length == 0 && internetImageImgWebTemp['data'][0]['page'].length == 0">
            No results were found ...
        </div>
    </div>

</p-dialog> -->

<p-blockUI [blocked]="blocked" [baseZIndex]="30001">
    <p-progressSpinner [style]="{width: '150px', height: '150px'}" styleClass="custom-spinner" strokeWidth="2" animationDuration=".5s"></p-progressSpinner>
</p-blockUI>

<p-confirmDialog [style]="{width: '50vw'} " [baseZIndex]="30000 " rejectButtonStyleClass="p-button-text "></p-confirmDialog>

<p-scrollTop></p-scrollTop> 