<div class="p-d-flex p-flex-column sample-list-section">
    <div>
        Error ...
    </div>
    <!-- <div class="p-mb-3 sample-list-section-title">
        Please select the sample reports below:
    </div>
    <div *ngFor="let item of demo" class="p-mt-2 sample-list-section-item">
        <div (click)="goReportPage(item['id'])">
            {{item['id']}}
        </div>
    </div> -->
</div>