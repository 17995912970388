
export class ShapePoint {

    left: number;
    top: number;

    constructor(leftSrc:number, topSrc:number) {
        this.left = leftSrc;
        this.top = topSrc;
    }
}
