import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { GlobalConstants } from '../common/global-constants';
import { DataService } from '../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faSquare, faCheckSquare, faThList, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { timer } from 'rxjs/internal/observable/timer';
import { interval } from 'rxjs/internal/observable/interval';
// import { parse } from '@fortawesome/fontawesome-svg-core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Case } from '../entity/Case';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
// import { ViewChild } from '@angular/core';
// import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
 

@Component({
  selector: 'app-duplication',
  templateUrl: './duplication.component.html',
  styleUrls: ['./duplication.component.scss']
})
export class DuplicationComponent implements OnInit {

  // @ViewChild('left') overlayPanelLeft: OverlayPanel;
  // @ViewChild('right') overlayPanelRight: OverlayPanel;
  
  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
      // visible height + pixel scrolled >= total height 
      if ((event['target']['scrollingElement']['offsetHeight'] + event['target']['scrollingElement']['scrollTop'] + 100) >= event['target']['scrollingElement']['scrollHeight']) {
        this.updateResultList();
      }
  }


  // Listen to the keyboard key press event, case insensitive
  // Key A to enter the analysis tools,  
  // Key D to switch the display
  // Key F to show the highlight of the feature 
  // Key R to switch to the red feature dsiplay 
  // Key G to switch to the green feature dsiplay
  // Key B to switch to the blue feature dsiplay
  // Key H to hide the record from the list
  // Key S to save the case or remove the case from selection
  // only when overlayPanelCenter is visible 
  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if(this.overlayPanelCenter){
      if(event.key == 'a' || event.key == 'A'){
        this.overlayPanelCenter = false; 
        if(this.overlayPanelMainItem['duplication_type'] == 'whole'){
          // duplication_type: 'within'
          this.loadWithinImageViewer(this.overlayPanelMainItem['img1']['id']);
        }else{
          // duplication_type: 'within'
          this.loadDuplicationImageViewer(this.overlayPanelMainItem['img1']['id'], this.overlayPanelMainItem['img2']['id']);
        }
      }else if(event.key == 'd' || event.key == 'D'){
        if(this.overlayPanelDisplayMode == 'target'){
          this.overlayPanelDisplayMode = 'original';
        }else{
          this.overlayPanelDisplayMode = 'target';
        }
      }else if(event.key == 'f' || event.key == 'F'){
        if(this.overlayPanelMainItem['asift'] > 0 ){
          if(this.overlayPanelCenterDisplayFeature){
            this.overlayPanelCenterDisplayFeature = false;
          }else{
            this.overlayPanelCenterDisplayFeature = true;
            this.updateoverlayColor(this.overlayPanelCenterDisplayColor);
          }
        }
      }else if(event.key == 'r' || event.key == 'R'){
        if(this.overlayPanelMainItem['asift'] > 0 ){
          this.updateoverlayColor('red');
        }
      }else if(event.key == 'g' || event.key == 'G'){
        if(this.overlayPanelMainItem['asift'] > 0 ){
          this.updateoverlayColor('green');
        }
      }else if(event.key == 'b' || event.key == 'B'){
        if(this.overlayPanelMainItem['asift'] > 0 ){
          this.updateoverlayColor('blue');
        }
      }else if(event.key == 'h' || event.key == 'H'){
        if(this.overlayPanelMainItem['hide'] == false){
          this.toggleCase(this.overlayPanelCenterIdx, this.overlayPanelMainItem['img1']['id']+','+this.overlayPanelMainItem['img2']['id']);
          this.overlayPanelCenter = false; this.overlayDisplayLeftItem = undefined; this.overlayDisplayRightItem = undefined;
        }else{
          this.toggleCase(this.overlayPanelCenterIdx, this.overlayPanelMainItem['img1']['id']+','+this.overlayPanelMainItem['img2']['id']);
          this.overlayPanelCenter = false; this.overlayDisplayLeftItem = undefined; this.overlayDisplayRightItem = undefined;
        }
      }else if(event.key == 's' || event.key == 'S'){
        if(this.overlayPanelMainItem['selected'] == null || this.overlayPanelMainItem['selected'] == false){
          this.submitCase(this.overlayPanelCenterIdx, this.overlayPanelMainItem);
        }else{
          this.removeCase(this.overlayPanelCenterIdx);
        }

      }else if(event.key == 'z' || event.key == 'Z'){
        this.switchOverlayPanelDisplayPanel('left')
      }else if(event.key == 'x' || event.key == 'X'){
        this.switchOverlayPanelDisplayPanel('right')
      }else if(event.key == 'Escape'){
        this.overlayPanelCenter = false; this.overlayDisplayLeftItem = undefined; this.overlayDisplayRightItem = undefined;
      }

    }
  }

 
  hasDuplicationDownloadStatus: boolean;
  isPreparingDuplicationPDF: boolean;
  downloadDuplicationPDFSize: string;


  blocked: boolean;

  browserLangugae: string;

  successLoading: boolean;

  faQuestionCircle = faQuestionCircle;
  faSquare = faSquare;
  faCheckSquare = faCheckSquare;
  
  baseUrl: string;
  id: string;
  record:any;
  result:any;
  resultSimilarityTotal: number;
  resultSimilarityHideTotal: number;
  resultManipulationTotal: number;
  resultManipulationHideTotal: number;

  maximumDisplaySize:number;
  maximumDisplaySizeGap: number;
  similarity: any[] ;

  resultImg1Text: string;

  countWhole: number;
  countPartial: number;
  countHigh: number;
  countMedium: number;
  countLow: number;
  countLowResolution: number;
  countWithin: number;
  countUser: number;
  countLab: number;
  countInstitution: number;
  countInternet: number;

  countFacs: number;
  countMicroscopy: number;
  countMacroscopy: number;
  countGel: number;
  countOther: number;

  helpFilterDialogFlag: boolean;

  filterOriginalList: any[];
  filterImageDialog: boolean;

  filterImageFilename: string;
  filterImageFilenamePage: number;

  filterExcludesLowResolution: boolean;
  
  filterSimilarity: number;
  filterSimilarityMin: number;
  filterSimilarityMax: number;
  filterSimilarityStep: number;
  filterHistogram: number;
  filterHistogramMin: number;
  filterHistogramMax: number;
  filterHistogramStep: number;
  filterPhash: number;
  filterPhashMin: number;
  filterPhashMax: number;
  filterPhashStep: number;
  filterAsift: number;
  filterAsiftMin: number;
  filterAsiftMax: number;
  filterAsiftStep: number;

  filterSelected: boolean;
  filterHigh: boolean;
  filterMedium: boolean;
  filterLow: boolean;
  filterWithin: boolean;
  filterUser: boolean;
  filterLab: boolean;
  filterInstitution: boolean;
  filterUsed:boolean;
  filterInternet: boolean;
  filterDoi: boolean;

  filterGel: boolean;
  filterOther: boolean;
  filterFacs: boolean;
  filterMicroscopy: boolean;
  filterMacroscopy: boolean;

  selectedCount: number;
  caseSummary: any;

  high:number;
  medium:number;

  toggleListHide: boolean;

  defaultLang: string;

  ai: {};

  highlightfeature: string;

  // DOI and Internet DOI Filter setting
  recordDoi: string;
  recordDoiMatchedDetected: boolean;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private location: Location,
    private route: ActivatedRoute,
    private proute: Router,
    public cookieService: CookieService,
    public translate: TranslateService,
    private dataService: DataService,
    private cdRef:  ChangeDetectorRef
    ) {  

      this.baseUrl = GlobalConstants.apiURL;

      this.blocked = true;
      this.successLoading = true;
      this.isPreparingDuplicationPDF = true;
      this.hasDuplicationDownloadStatus = false;
      this.browserLangugae = navigator.language;

      this.filterSelected = false;
      this.filterHigh = false;
      this.filterMedium = false;
      this.filterLow = false;
      this.filterWithin = false;
      this.filterUser = false;
      this.filterLab = false;
      this.filterInstitution = false;
      this.filterUsed = false;
      this.filterInternet = false;
      this.filterDoi = false;
    
      this.filterFacs = false;
      this.filterMicroscopy = false;
      this.filterMacroscopy = false;
      this.filterGel = false;
      this.filterOther = false;

      this.maximumDisplaySize = 3;
      this.maximumDisplaySizeGap = 3;
      this.selectedCount = 0;

      this.helpFilterDialogFlag = false;

      this.medium = GlobalConstants.medium;
      this.high = GlobalConstants.high; 

      this.toggleListHide = false;

      this.ai = undefined;

      this.countGel = 0;
      this.countOther = 0;
      this.countFacs = 0;
      this.countMicroscopy = 0;
      this.countMacroscopy = 0;

      // Setup the Doi Filter
      this.recordDoi = undefined;
      this.recordDoiMatchedDetected = false;

      this.resetCountItems();

      let lang = this.proute['browserUrlTree']['queryParams']['lang'];
      if(lang != undefined){        
        this.cookieService.set('lang', lang);
        translate.use(lang);
        this.defaultLang = lang;
      }else{
        let tempLang = this.cookieService.get('lang');
        if(tempLang != '' && tempLang != undefined){
          translate.use(tempLang);
          this.defaultLang = tempLang;
        }else{          
          translate.use(translate.getBrowserLang());
          this.defaultLang = translate.getBrowserLang();
        }
      }

      
      this.translate.get('duplication.highlight-features').subscribe((text: string) => { this.highlightfeature = text; });

}

loadClassificationResult(){
  this.initial();
}

loadFullDuplicationPDFStatus(){

  this.dataService.getFullDuplicationPDFStatus(this.id)
    .subscribe(data => {
      
      if(data['status'] ==  true && data['progress'] == 'processing'){
        this.isPreparingDuplicationPDF = true;
        timer(3000).subscribe(t => {  
          this.loadFullDuplicationPDFStatus();
        });
      }else if(data['status'] ==  true && data['progress'] == 'ready'){
        this.isPreparingDuplicationPDF = false;
        
        if(data.hasOwnProperty('size')){
          this.downloadDuplicationPDFSize = '(' + data['size'] + ' MB)';
        }

      }else if(data['status'] ==  false){
        this.isPreparingDuplicationPDF = true;
        this.dataService.initialFullDuplicationPDF(this.id, this.browserLangugae)
        .subscribe(data => {
            timer(3000).subscribe(t => {  
              this.loadFullDuplicationPDFStatus();
            });
        });
      }

      this.hasDuplicationDownloadStatus = true;
  });

}



updateResultList(){

  if(this.filterSimilarity == this.filterSimilarityMin && 
    this.filterHistogram == this.filterHistogramMin &&
    this.filterPhash == this.filterPhashMin &&
    this.filterAsift == this.filterAsiftMin &&
    this.filterSelected == false &&
    this.filterHigh == false &&
    this.filterMedium == false &&
    this.filterLow == false &&
    this.filterWithin == false &&
    this.filterUser == false &&
    this.filterLab == false &&
    this.filterInstitution == false &&
    this.filterInternet == false &&
    this.filterDoi == false &&
    this.filterGel == false &&
    this.filterOther == false 
    ){

      var updateTempList = this.result['similarity'];
      let newsize = this.maximumDisplaySize + this.maximumDisplaySizeGap ;
      if(newsize > updateTempList.length){
        for(let i=this.maximumDisplaySize; i < updateTempList.length ; i++){
          this.similarity.push(updateTempList[i]);
        }
        this.maximumDisplaySize = updateTempList.length;
      }else{
        for(let i=this.maximumDisplaySize; i < newsize ; i++){
          this.similarity.push(updateTempList[i]);
        }
        this.maximumDisplaySize = newsize;
      } 
  }
  
}

ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.loadClassificationResult();

    // Initial Values
    this.resultManipulationTotal = 0;
    this.resultManipulationHideTotal = 0;
    this.resultSimilarityTotal = 0;
    this.resultSimilarityHideTotal = 0;

    // Here to detect the changes of the case
    interval(5000).subscribe(x => {
      this.loadCaseSummary();
    });
    
  }

  initial(){

    this.maximumDisplaySize = 5;
    this.blocked = true;
    this.dataService.initial(this.id)
                  .subscribe(data =>{
                    // console.log(data);
                    if(data['status'] == 'success'){
                      this.loadRecordMetadata();
                      this.route.params.subscribe(params => {
                        if(params['image'] != 'all' && params['image'] != '' && params['image'] != undefined){
                          let tempfile = params['image']; 
                          this.filterImageFilename = tempfile.substring(0, tempfile.length -4 );
                          this.filterImageFilenamePage = +this.filterImageFilename.substring(this.filterImageFilename.lastIndexOf("-")+1);
                        }
                      });

                      this.loadFullDuplicationPDFStatus();
                      
                    }else{
                      // console.log('failed')
                      // this.blocked = false;
                      // this.successLoading = false;
                      // Here route to not-found page
                      this.proute.navigate(['/not-found']);
                    }
                  });


  }

  loadRecordMetadata(){
    this.dataService.metadata(this.id, 'all')
                      .subscribe(data =>{
                        this.record = data;
                        this.loadCaseSummary();
                        // this.ai = {};
                        this.dataService.getClassificationResult(this.id).subscribe(data =>{
                          this.ai = data;
                          this.blocked = false;
                          this.loadDuplicationResult();
                        });
                      });
  }

  loadDuplicationResult(){
    
    this.resetCountItems();
    this.filterUsed = false;
    this.resultImg1Text = '';

    this.selectedCount = 0;
    
    this.dataService.getDuplicationCase(this.id)
    .subscribe(target =>{

      var check = '';
      var ref = {};
      
      for(var i=0; i < target['data'].length ; i++){
        if(target['data'][i]['type'] != 'similarity'){
          check += target['data'][i]['value'];
          ref[target['data'][i]['value']] = target['data'][i]['key'];          
        } 
      }


      this.dataService.duplication(this.id)
        .subscribe(data =>{

        this.result = data;

        // if this.result has doi 
        if(this.result['doi'] != undefined){
          this.recordDoi = this.result['doi'];
        }

        this.resultSimilarityTotal = this.result['similarity_count'];
        this.resultSimilarityHideTotal = this.result['similarity_count_hide'];
        this.resultManipulationTotal = this.result['manipulation_count'];
        this.resultManipulationHideTotal = this.result['manipulation_count_hide'];
         
        // let confirmList =  [];

        for(let i=0; i < this.result['similarity'].length ; i++){

          let pair = this.result['similarity'][i];
          let pairDoi = undefined;
          if(pair.hasOwnProperty('doi')){
            pairDoi = pair['doi'];
            if(this.recordDoi != undefined && pairDoi == this.recordDoi){
              this.recordDoiMatchedDetected = true;
              // this.toggleFilterSelected('doi');
            }
          }

          // console.log(this.result['similarity'][i]);
          // console.log(this.result['similarity'][i]['phash']);

          let img1 = pair['img1'];
          let img1File = img1['original_file'];
          let img1Page = img1File.match(/index-(\d{1,4})/g)[0].match(/(\d{1,4})/g)[0];
          let img2 = pair['img2'];
          let img2File = img2['original_file'];
          let img2Page = img2File.match(/index-(\d{1,4})/g)[0].match(/(\d{1,4})/g)[0];
          this.result['similarity'][i]['img1']['page'] = parseInt(img1Page);
          this.result['similarity'][i]['img2']['page'] = parseInt(img2Page);
          this.result['similarity'][i]['asift'] = this.result['similarity'][i]['asift'];
          this.result['similarity'][i]['histogramP'] = Math.floor(this.result['similarity'][i]['histogram'] * 100);
          // this.result['similarity'][i]['phashP'] = Math.floor((1-this.result['similarity'][i]['phash']) * 100);
          this.result['similarity'][i]['phashP'] = Math.floor((this.result['similarity'][i]['phash']) * 100);
          this.result['similarity'][i]['similarityP'] = Math.floor(this.result['similarity'][i]['similarity'] * 100);
          this.result['similarity'][i]['resolutionImg1'] = parseInt(img1['crop_info']['width']) * parseInt(img1['crop_info']['height']);
          this.result['similarity'][i]['resolutionImg1'] = parseInt(img2['crop_info']['width']) * parseInt(img2['crop_info']['height']);
          this.resultImg1Text += img1['original_file'];
        
          // Update Selected Cases
          
          var sourceId = this.result['similarity'][i]['img1']['id'];
          var targetId = this.result['similarity'][i]['img2']['id'];
          var key = sourceId + '-' + targetId;
          if(check.indexOf(key) != -1){
            this.result['similarity'][i]['selected'] = true;
            this.result['similarity'][i]['selected_key'] = ref[key];
            this.selectedCount += 1;
          }else{
            this.result['similarity'][i]['selected'] = false;
            this.result['similarity'][i]['selected_key'] = '';
          }
          
          if(this.result['similarity'][i]['hide'] == undefined){
            this.result['similarity'][i]['hide'] = false;
          }
          
          if(this.ai[img1['original_file']] != undefined && 
                  this.ai[img1['original_file']]['classification'] != undefined ){
              this.result['similarity'][i]['classification'] = this.ai[img1['original_file']]['classification'];
              if( this.ai[img1['original_file']]['classification'] == 'gel' ){
                this.countGel = this.countGel + 1;
              }else if(this.ai[img1['original_file']]['classification'] == 'facs' ){
                this.countFacs = this.countFacs + 1;
              }else if(this.ai[img1['original_file']]['classification'] == 'microscopy' ){
                this.countMicroscopy = this.countMicroscopy + 1;
              }else if(this.ai[img1['original_file']]['classification'] ==  'macroscopy' ){
                this.countMacroscopy = this.countMacroscopy + 1;
              }else {
                this.countOther = this.countOther + 1;
              }
          }

          // Add similarity to confirm list with display type
          this.result['similarity'][i]['feature'] = true;
          this.result['similarity'][i]['color'] = 'red';   
          this.result['similarity'][i]['timestamp'] = Date.now();
          this.result['similarity'][i]['checkbox'] = false; 
          
          // create the image url for img1 and img2
          // based on baseUrl+'/image/'+id+'/'+item[ 'analysis']+'/1/'+item[ 'img1'][ 'id']+'/'+item[ 'img2'][ 'id']+'?alpha=0.1&stroke=5&width=255&feature='+item['feature']+'&color='+item['color']+'&timestamp='+item['timestamp']
          this.result['similarity'][i]['img1']['url'] = this.baseUrl+'/image/'+this.id+'/'+ this.result['similarity'][i]['analysis']+'/1/'+this.result['similarity'][i]['img1']['id']+'/'+this.result['similarity'][i]['img2']['id']+'?alpha=0.1&stroke=5&width=255&feature='+this.result['similarity'][i]['feature']+'&color='+this.result['similarity'][i]['color']+'&timestamp='+this.result['similarity'][i]['timestamp'];
          this.result['similarity'][i]['img2']['url'] = this.baseUrl+'/image/'+this.id+'/'+ this.result['similarity'][i]['analysis']+'/2/'+this.result['similarity'][i]['img1']['id']+'/'+this.result['similarity'][i]['img2']['id']+'?alpha=0.1&stroke=5&width=255&feature='+this.result['similarity'][i]['feature']+'&color='+this.result['similarity'][i]['color']+'&timestamp='+this.result['similarity'][i]['timestamp'];
         
          // check if it has transformFactors
          // if(this.result['similarity'][i]['transformFactors'] != undefined){
          //   // Now fill in the info into this.result['similarity'][i]['transformFactors']['explain']
          //   // vFlip: false
          //   // shapeLeftDuplicatedAreaRatio: 13.33
          //   // tx: -1.5304753322946874
          //   // ty: -210.62226249393746
          //   // shapeAngle: 0
          //   // rotation: -1.157238838226812
          //   // scale: 1.0055963883396526
          //   // mFlip: false
          //   // shapeRightDuplicatedAreaRatio: 13.22
          //   // hFlip: false
          //   var explain = ' : ';
          //   // first check if shapeLeftDuplicatedAreaRatio is exists and not 0 then 
          //   // explain the Original Image Duplicated Area Ratio
          //   if(this.result['similarity'][i]['transformFactors']['shapeLeftDuplicatedAreaRatio'] != 0){
          //     explain += 'Original Image Duplicated: ' + this.result['similarity'][i]['transformFactors']['shapeLeftDuplicatedAreaRatio'] + '%';
          //   }
          //   // second check if shapeRightDuplicatedAreaRatio is exists and not 0 then
          //   // explain the Target Image Duplicated Area Ratio
          //   if(this.result['similarity'][i]['transformFactors']['shapeRightDuplicatedAreaRatio'] != 0){
          //     explain += '  / Target Image Duplicated: ' + this.result['similarity'][i]['transformFactors']['shapeRightDuplicatedAreaRatio'] + '%';
          //   }
          //   // Then based on the shapeAngle, explain the rotated angle. only if > 0
          //   if(this.result['similarity'][i]['transformFactors']['shapeAngle'] != 0 &&
          //     this.result['similarity'][i]['transformFactors']['shapeAngle'] != 360
          //   ){
          //     explain += ' ; Angle ∡: ' + this.result['similarity'][i]['transformFactors']['shapeAngle'] + ' °';
          //   }
          //   // based on the mFlip, hFlip, vFlip, explain the flip status, either value is true
          //   if(this.result['similarity'][i]['transformFactors']['mFlip'] == true ||
          //     this.result['similarity'][i]['transformFactors']['vFlip'] == true ||
          //     this.result['similarity'][i]['transformFactors']['hFlip'] == true
          //   ){
          //     explain += ' ; Mirror: True';
          //   }
          //   this.result['similarity'][i]['transformFactors']['explain'] = explain;
          // }

        }

        // Update Record Metadata
        this.updateRecordMetadata();

        // Sorting the result by similarity level
        this.result['similarity'].sort(this.sortBySimilarity('similarity'));

        // Apply Image Selection Filter
        let templist = [];                        
        for(let i=0; i < this.result['similarity'].length ; i++){
          var isValid = true;
          if(this.filterImageFilename != undefined && this.filterImageFilename != ''){
            if(this.result['similarity'][i]['img1']['original_file'].indexOf(this.filterImageFilename + '-') == -1
            && this.result['similarity'][i]['img2']['original_file'].indexOf(this.filterImageFilename + '-') == -1){
              isValid = false;
            }
          }
          if(isValid){

            if(this.toggleListHide == true){
              if(this.result['similarity'][i]['hide'] == true){
                templist.push(this.result['similarity'][i]);  
                // confirmList.push(this.result['similarity'][i]);
              }
            }else{
              if(this.result['similarity'][i]['hide'] == false){
                templist.push(this.result['similarity'][i]);  
                // confirmList.push(this.result['similarity'][i]);
              }
            }                       
          }

        }

        // console.log('prepare result ' + templist.length);

        this.result['similarity'] = templist;
        // console.log('prepare result ' + this.result['similarity'].length);
        
        this.countResultItem();
        this.countResultItemSimilarity();   

        if(this.result['similarity'].length <= this.maximumDisplaySize){
          this.similarity = this.result['similarity'];
        }else{
          this.similarity = this.result['similarity'].slice(0, this.maximumDisplaySize);
        }

        // if the length of the this.similarity = 0 then route to manipulation page
        if(this.similarity.length == 0){
          this.proute.navigate(['/manipulation/' + this.id]);
        }

      });


    });
    
  }

  resetList(){
    this.loadDuplicationResult();
  }


  updateFilterList:any;

  onChangeAdvancedFilter(){
    try{
      this.updateFilterList.unsubscribe();
      console.log('unsubscribe');
    }catch(e){}

    this.updateFilterList = timer(1000).subscribe(t => {
      this.applyAdvancedFilter();
    }); 
  
  }

  applyAdvancedFilter(){
    
    // console.log('subscribe run');
    // console.log('Color: '+this.filterHistogram + ' / ' + this.filterHistogramMin + ' / ' + this.filterHistogramMax);
    // console.log('Phash: '+this.filterPhash + ' / ' + this.filterPhashMin + ' / ' + this.filterPhashMax);
    // console.log('Phash: '+this.filterAsift + ' / ' + this.filterAsiftMin + ' / ' + this.filterAsiftMax);
    // console.log('Similarity: '+this.filterSimilarity + ' / ' + this.filterSimilarityMin + ' / ' + this.filterSimilarityMax);
    
    if(this.filterSimilarity == this.filterSimilarityMin && 
      this.filterHistogram == this.filterHistogramMin &&
      this.filterPhash == this.filterPhashMin && 
      this.filterAsift == this.filterAsiftMin && 
      this.filterSelected == false &&
      this.filterHigh == false &&
      this.filterMedium == false &&
      this.filterLow == false &&
      this.filterWithin == false &&
      this.filterGel == false &&
      this.filterFacs == false &&
      this.filterMicroscopy == false &&
      this.filterMacroscopy == false &&
      this.filterOther == false &&
      this.filterUser == false && 
      this.filterLab == false &&
      this.filterInternet == false &&
      this.filterDoi == false &&
      this.filterInstitution == false 
      ){
        // console.log('not running');
      this.loadDuplicationResult();
    }else{
      // console.log('running ... ' + this.result['similarity'].length);
      this.filterUsed = true;
      var templist = [];
      var count = 0;
      for(let j=0; j < this.result['similarity'].length ; j++){
        count++;
        if(this.filterSimilarity > parseInt(this.result['similarity'][j]['similarityP']) ){
          continue;
        }else{
          // console.log('Similarity: '+this.filterSimilarity + ' / ' + this.filterSimilarityMin + ' / ' + this.filterSimilarityMax);
        }
        if(this.filterHistogram  > parseInt(this.result['similarity'][j]['histogramP']) ){
          console.log(' not matched histogramP');
          continue;
        }
        if(this.filterPhash > parseInt(this.result['similarity'][j]['phashP']) ){
          console.log(' not matched phashP');
          continue;
        }
        if(this.filterAsift > parseInt(this.result['similarity'][j]['asift']) ){
          console.log(' not matched Asift');
          continue;
        }

        if(this.filterSelected == true){
          if(this.result['similarity'][j]['selected'] == false){
            continue;
          }
        }

        if(this.filterHigh == true){
          if(this.result['similarity'][j]['similarity'] < GlobalConstants.high){
            continue;
          }
        }

        if(this.filterMedium == true){
          if(this.result['similarity'][j]['similarity'] < GlobalConstants.medium || this.result['similarity'][j]['similarity'] >= GlobalConstants.high){
            continue;
          }
        }

        if(this.filterLow == true){
          if(this.result['similarity'][j]['similarity'] > GlobalConstants.medium){
            continue;
          }
        }

        if(this.filterWithin == true){
          if(this.result['similarity'][j]['source'] != 'within'){
            continue;
          }
        }

        if(this.filterUser == true){
          if(this.result['similarity'][j]['source'] != 'user'){
            continue;
          }
        }

        if(this.filterLab == true){
          if(this.result['similarity'][j]['source'] != 'lab'){
            continue;
          }
        }

        if(this.filterInstitution == true){
          if(this.result['similarity'][j]['source'] != 'institution'){
            continue;
          }
        }
        if(this.filterInternet == true){
          if(this.result['similarity'][j]['source'] == 'institution' ||
            this.result['similarity'][j]['source'] == 'lab' ||
            this.result['similarity'][j]['source'] == 'user' ||
            this.result['similarity'][j]['source'] == 'within' 
          ){
            continue;
          }
        }
        if(this.filterDoi == true && this.recordDoiMatchedDetected){
          if(this.result['similarity'][j].hasOwnProperty('doi')){
            if(this.result['similarity'][j]['doi'].trim() == this.recordDoi.trim()){
              continue;
            }else{
              console.log('DOI not matched, ' + this.result['similarity'][j]['doi'] + ' / ' + this.recordDoi);
            }
          }
        }

        if(this.filterGel == true){
          if(this.result['similarity'][j]['classification'] != 'gel'){
            continue;
          }
        }

        if(this.filterFacs == true){
          if(this.result['similarity'][j]['classification'] != 'facs'){
            continue;
          }
        }

        if(this.filterMicroscopy == true){
          if(this.result['similarity'][j]['classification'] != 'microscopy'){
            continue;
          }
        }

        if(this.filterMacroscopy == true){
          if(this.result['similarity'][j]['classification'] != 'macroscopy'){
            continue;
          }
        }

        if(this.filterOther == true){
          // console.log('check filterOther = '+this.result['similarity'][j]['classification']);
          if(this.result['similarity'][j]['classification'] != 'others'){
            continue;
          }
        }

        templist.push(this.result['similarity'][j]);  
      }   

      // console.log('count:'+ count + ' / ' + templist.length);

      if(templist.length > 0){
        this.similarity = templist;
        this.similarity.sort(this.sortBySimilarity('similarity'));
        this.countTempResultItem();
      }else{
        this.similarity = [];
      }
    }
  }

  countResultItemSimilarity(){

    for(let i=0; i < this.result['similarity'].length ; i++){
      var item = this.result['similarity'][i];
      if(parseInt(item['asift']) < this.filterAsiftMin){this.filterAsiftMin = parseInt(item['asift']); }
      if(parseInt(item['asift']) >= this.filterAsiftMax){this.filterAsiftMax = parseInt(item['asift']);}
      if(parseInt(item['phashP']) < this.filterPhashMin){this.filterPhashMin = parseInt(item['phashP']); }
      if(parseInt(item['phashP']) >= this.filterPhashMax){this.filterPhashMax = parseInt(item['phashP']);}
      if(parseInt(item['histogramP']) < this.filterHistogramMin){this.filterHistogramMin = parseInt(item['histogramP']);}
      if(parseInt(item['histogramP']) >= this.filterHistogramMax){this.filterHistogramMax = parseInt(item['histogramP']);}
      if(parseInt(item['similarityP']) < this.filterSimilarityMin){this.filterSimilarityMin = parseInt(item['similarityP']);}
      if(parseInt(item['similarityP']) >= this.filterSimilarityMax){this.filterSimilarityMax = parseInt(item['similarityP']);}
    }

    this.filterSimilarity = this.filterSimilarityMin;
    this.filterHistogram = this.filterHistogramMin;
    this.filterPhash = this.filterPhashMin;
    this.filterAsift = this.filterAsiftMin;
   
  }

  countTempResultItem(){
    
    this.countWhole = 0;
    this.countPartial = 0;
    this.countHigh = 0;
    this.countMedium = 0;
    this.countLow = 0;
    this.countLowResolution = 0;
    this.countWithin = 0;
    this.countUser = 0;
    this.countLab = 0; 
    this.countInternet = 0;
    this.countInstitution = 0;
    this.countGel = 0;
    this.countFacs = 0;
    this.countMicroscopy = 0;
    this.countMacroscopy = 0;
    this.countOther = 0;

    for(let i=0; i < this.similarity.length ; i++){
      var item = this.similarity[i];
      if(item['similarity'] >= GlobalConstants.high){
        this.countHigh += 1;
      }else if(item['similarity'] >= GlobalConstants.medium && item['similarity'] < GlobalConstants.high){
        this.countMedium += 1;
      }else{
        this.countLow += 1;
      }
      if(item['img1']['low_resolution'] || item['img2']['low_resolution']){
        this.countLowResolution += 1;
      }
      if(item['duplication_type'] == 'whole'){
        this.countWhole += 1;
      }else{
        this.countPartial += 1;
      }
      if(item['source'] == 'within'){
        this.countWithin += 1;
      }else if(item['source'] == 'user'){
        this.countUser += 1;
      }else if(item['source'] == 'lab'){
        this.countLab += 1;
      }else if(item['source'] == 'institution'){
        this.countInstitution += 1;
      }else{
        this.countInternet += 1;
      }
      
      if(item['classification'] == 'gel'){
        this.countGel += 1;
      }else if(item['classification'] == 'facs'){
        this.countFacs += 1;
      }else if(item['classification'] == 'microscopy'){
        this.countMicroscopy += 1;
      }else if (item['classification'] == 'macroscopy'){
        this.countMacroscopy += 1;
      }else{
        this.countOther += 1;
      }

    }
  }     

  countResultItem(){
    
    this.countWhole = 0;
    this.countPartial = 0;
    this.countHigh = 0;
    this.countMedium = 0;
    this.countLow = 0;
    this.countLowResolution = 0;
    this.countWithin = 0;
    this.countUser = 0;
    this.countLab = 0;
    this.countInternet = 0;
    this.countInstitution = 0;
    this.countGel = 0;
    this.countFacs = 0;
    this.countMicroscopy = 0;
    this.countMacroscopy = 0;
    this.countOther = 0;

    for(let i=0; i < this.result['similarity'].length ; i++){

      var item = this.result['similarity'][i];

      if(item['similarity'] >= GlobalConstants.high){
        this.countHigh += 1;
      }else if(item['similarity'] >= GlobalConstants.medium && item['similarity'] < GlobalConstants.high){
        this.countMedium += 1;
      }else{
        this.countLow += 1;
      }
      if(item['img1']['low_resolution'] || item['img2']['low_resolution']){
        this.countLowResolution += 1;
      }
      if(item['duplication_type'] == 'whole'){
        this.countWhole += 1;
      }else{
        this.countPartial += 1;
      }
      if(item['source'] == 'within'){
        this.countWithin += 1;
      }else if(item['source'] == 'user'){
        this.countUser += 1;
      }else if(item['source'] == 'lab'){
        this.countLab += 1;
      }else if(item['source'] == 'institution'){
        this.countInstitution += 1;
      }else{
        this.countInternet += 1;
      }
      
      if(item['classification'] == 'gel'){
        this.countGel += 1;
      }else if (item['classification'] == 'facs'){
        this.countFacs += 1;
      }else if (item['classification'] == 'microscopy'){
        this.countMicroscopy += 1;
      }else if (item['classification'] == 'macroscopy'){
        this.countMacroscopy += 1;
      }else{
        this.countOther += 1;
      }

    }
    
  }
  
  updateRecordMetadata(){
    this.filterOriginalList = [];
    // console.log(this.record['original']);
    for(let i=0; i < this.record['original'].length ; i++){
      let original = this.record['original'][i];
      let originalStr = original['filename'];
          originalStr = originalStr.substring(0, originalStr.length -4)+'-';
      if(this.resultImg1Text.indexOf(originalStr) != -1){
        // console.log(this.record['original'][i]);
        this.filterOriginalList.push(this.record['original'][i]);
      } 
    }
  }

  
 sortBySimilarity(prop){
  return function(a,b){
     if (a[prop] > b[prop]){
      return -1;         
     } else if(a[prop] < b[prop]){
      return 1;
     }
     return 0;
  }
}

  openFilterImage(){
    this.filterImageDialog =true;
  }

  applyFilterFilename(filename:string){
    filename = filename.substring(0, filename.length - 4);
    this.filterImageFilename = filename;
    this.filterImageFilenamePage = +this.filterImageFilename.substring(this.filterImageFilename.lastIndexOf("-")+1);
    this.filterImageDialog = false;
    this.loadDuplicationResult();
    this.updateUrl();
  } 

  resetCountItems(){
    this.resultImg1Text = '';
    this.countWhole = 0;
    this.countPartial = 0;
    this.countHigh = 0;
    this.countMedium = 0;
    this.countLow = 0;
    this.countLowResolution = 0;
    this.countWithin = 0;
    this.countUser = 0;
    this.countLab = 0;
    this.countInternet = 0;
    this.countInstitution = 0;
    this.countGel = 0;
    this.countFacs = 0;
    this.countMicroscopy = 0;
    this.countMacroscopy = 0;
    this.countOther = 0;

    this.filterSimilarity = 0;
    this.filterSimilarityMin = 1;
    this.filterSimilarityMax = 0;
    this.filterSimilarityStep = 0;
    this.filterHistogram = 0;
    this.filterHistogramMin = 100;
    this.filterHistogramMax = 0;
    this.filterHistogramStep = 0;
    this.filterPhash = 0;
    this.filterPhashMin = 100;
    this.filterPhashMax = 0;
    this.filterPhashStep = 0;
    this.filterAsift = 0;
    this.filterAsiftMin = 0;
    this.filterAsiftMax = 0;
    this.filterAsiftStep = 0;
    this.filterSelected = false;
    this.filterHigh = false;
    this.filterMedium = false;
    this.filterLow = false;
    this.filterWithin = false;
    this.filterUser = false;
    this.filterLab = false;
    this.filterInternet = false;
    this.filterDoi = false;
    this.filterInstitution = false;
    this.filterGel = false;
    this.filterOther = false;

  }
 
  resetFilterFilename(){
    this.filterImageFilename = '';
    this.loadDuplicationResult();
    this.updateUrl();
  }
  
  updateUrl(){
    if(this.filterImageFilename == ''){
      let targetChangeUrl:string = "/duplication/" + this.id + ";image=all";
      this.location.replaceState(targetChangeUrl);  
    }else{
      let targetChangeUrl:string = "/duplication/" + this.id + ";image=" + this.filterImageFilename + ".png";
      this.location.replaceState(targetChangeUrl);  
    }
  }

  back() {
    this.location.back();
  }
  
  //duplication-viewer/:id/:hash
  loadWithinImageViewer(img1id:string){

    var width = document.body.clientWidth;
    var height = document.body.clientHeight;
    var w = Math.round(width * 0.95);
    var h = Math.round(height * 0.9);
    var x = Math.round((width - w) / 2);
    var y = Math.round((height - h) / 2);

    window.open('/within-viewer/'+this.id+';img1='+img1id+'?lang='+this.defaultLang, this.id , 
        'resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no width=' + w + ', height=' + h + ', left=' + x + ', top=' + y );

  }


  //duplication-viewer/:id/:hash
  loadDuplicationImageViewer(img1id:string, img2id: string){

    //alert(document.body.clientWidth + ' / ' + document.body.clientHeight);

    var width = document.body.clientWidth;
    var height = document.body.clientHeight;
    var w = Math.round(width * 0.95);
    var h = Math.round(height * 0.9);
    var x = Math.round((width - w) / 2);
    var y = Math.round((height - h) / 2);

    window.open('/duplication-viewer/'+this.id+';img1='+img1id+';img2='+img2id+'?lang='+this.defaultLang, this.id , 
        'resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no width=' + w + ', height=' + h + ', left=' + x + ', top=' + y );

  }

  /**
   * Load the metadata of the record then append to the UI
   * @param idx 
   * @param pair 
   */
  appendInformation(idx:number, pair:any){

    if(this.similarity[idx]['information_control'] == undefined){
      this.similarity[idx]['information_control'] = true;
    }else if(this.similarity[idx]['information_control'] == false){
      this.similarity[idx]['information_control'] = true;
    }else{
      this.similarity[idx]['information_control'] = false;
    }

    if(this.similarity[idx]['information'] == undefined){
      // alert(pair['img1']['original_file'] + ' / ' + pair['img2']['original_file']);
      // alert(pair['img1']['id'] + ' / ' + pair['img2']['id']);
      this.dataService.getImageMetadata(this.id, pair['img1']['original_file'])
      .subscribe(source =>{
        if(source['status'] == 'success'){
          this.similarity[idx]['img1']['information'] = source;
        }
      });
      this.dataService.getImageMetadata(this.id, pair['img2']['id'])
      .subscribe(target =>{
        if(target['status'] == 'success'){
          this.similarity[idx]['img2']['information'] = target;
        }
      });
    }
    this.similarity[idx]['information'] = true;

  }
  

  linkViaDoi(doi:string){
    window.open('https://doi.org/'+doi);
  }

  updateSimilarityCase(){

    this.selectedCount = 0;

    this.dataService.getDuplicationCase(this.id)
    .subscribe(target =>{

      var check = '';
      var ref = {};
      for(var i=0; i < target['data'].length ; i++){
        check += target['data'][i]['value'];
        ref[target['data'][i]['value']] = target['data'][i]['key'];
      }

      for(var j=0; j < this.result['similarity'].length ; j++){
        var sourceId = this.result['similarity'][j]['img1']['id'];
        var targetId = this.result['similarity'][j]['img2']['id'];
        var key = sourceId + '-' + targetId;
        if(check.indexOf(key) != -1){
          this.result['similarity'][j]['selected'] = true;
          this.result['similarity'][j]['selected_key'] = ref[key];
          this.selectedCount += 1;
        }else{
          this.result['similarity'][j]['selected'] = false;
          this.result['similarity'][j]['selected_key'] = '';
        }
      }

    });
  }
  
  removeCase(idx:number){

      this.dataService.deleteStoreCase(this.id, this.similarity[idx]['selected_key'])
      .subscribe(result => {
        if(result['status'] == 'success'){
          this.messageService.add({life: GlobalConstants.life, severity:'success', summary: this.translate.instant('duplication.toast-remove-case-title') , detail: this.translate.instant('duplication.toast-remove-case-success')  });
        }
    
        this.dataService.getStoreCaseSummary(this.id)
            .subscribe(data =>{
          this.caseSummary = data;
          this.updateSimilarityCase();
          // alert(this.caseSummary['size']);
          if(this.caseSummary['size'] == 0 ){
            this.filterSelected = false;
            this.applyAdvancedFilter();
          }
        });

      });

  }

  appendInformationSimple(idx:number, pair:any){
    this.dataService.getImageMetadata(this.id, pair['img1']['original_file'])
    .subscribe(source =>{
      if(source['status'] == 'success'){
        this.similarity[idx]['img1']['information'] = source;
      }
    });
    this.dataService.getImageMetadata(this.id, pair['img2']['original_file'])
    .subscribe(target =>{
      if(target['status'] == 'success'){
        this.similarity[idx]['img2']['information'] = target;
      }
    });
  }


submitCase(idx:number, pair:any) {

  this.appendInformationSimple(idx, pair);
  
    this.dataService.getImageMetadata(this.id, pair['img1']['original_file'])
    .subscribe(leftMetadata =>{
      
      this.dataService.getImageMetadata(this.id, pair['img2']['id'])
      .subscribe(rightMetadata =>{

        // console.log('right metadata');
        // console.log(rightMetadata);
        // console.log(pair['img2']['id']);
        let caseobj:Case = new Case();
      
        caseobj.type = 'duplication';
        caseobj.institution = pair['institution'];
        caseobj.analysis = pair['analysis']; 

        caseobj.sourceFilename = pair['img1']['original_file'];
        caseobj.sourceRecord = pair['img1']['record'];
        caseobj.sourceRepository = pair['img1']['repository'];
        caseobj.sourceId = pair['img1']['id'];

        caseobj.targetFilename = pair['img2']['original_file'];
        caseobj.targetRecord = pair['img2']['record'];
        caseobj.targetRepository = pair['img2']['repository'];
        caseobj.targetId = pair['img2']['id'];

        if(leftMetadata['status'] == 'success'){
          
          if(leftMetadata['record']['title'] != null){
            caseobj.sourceTitle = leftMetadata['record']['title'];
          }
          if(leftMetadata['image'] != null){
            caseobj.sourceDescription = leftMetadata['image']['description'];
          }
          if(leftMetadata['record'] != null && leftMetadata['record']['doi'] != null){
            caseobj.sourceDoi = leftMetadata['record']['doi'];
          }
        }
        if(rightMetadata['status'] == 'success'){
          if(rightMetadata['record']['title'] != null){
            caseobj.targetTitle = rightMetadata['record']['title'];
          }
          if(rightMetadata['image'] != null){
            caseobj.targetDescription = rightMetadata['image']['description'];
          }
          if(rightMetadata['record'] != null && rightMetadata['record']['doi'] != null){
            caseobj.targetDoi = rightMetadata['record']['doi'];
          }
        }
    
        this.dataService.saveAnalysisCase(this.id, caseobj)
        .subscribe(result => {
          if(result['status'] == 'success'){
            this.messageService.add({life: GlobalConstants.life, severity:'success', summary: this.translate.instant('duplication.toast-saved-case-title'), detail: this.translate.instant('duplication.toast-saved-case-success')});
          }
          this.updateSimilarityCase();
          this.loadCaseSummary();
        });

      });
    }); 

  }

  toggleFilterSelected(type:string){
    if(type == 'case'){
      if(this.filterSelected == true){
        this.filterSelected = false;
      }else{
        this.filterSelected = true;
      }  
    }else if(type == 'high'){
      if(this.filterHigh == true){
        this.filterHigh = false;
      }else{
        this.filterHigh = true;
      }  
    }else if(type == 'medium'){
      if(this.filterMedium == true){
        this.filterMedium = false;
      }else{
        this.filterMedium = true;
      }  
    }else if(type == 'low'){
      if(this.filterLow == true){
        this.filterLow = false;
      }else{
        this.filterLow = true;
      }  
    }else if(type == 'within'){
      if(this.filterWithin == true){
        this.filterWithin = false;
      }else{
        this.filterWithin = true;
      }  
    }else if(type == 'user'){
      if(this.filterUser == true){
        this.filterUser = false;
      }else{
        this.filterUser = true;
      }  
    }else if(type == 'lab'){
      if(this.filterLab == true){
        this.filterLab = false;
      }else{
        this.filterLab = true;
      }  
    }else if(type == 'internet'){
      if(this.filterInternet == true){
        this.filterInternet = false;
      }else{
        this.filterInternet = true;
      } 
    }else if(type == 'institution'){
      if(this.filterInstitution == true){
        this.filterInstitution = false;
      }else{
        this.filterInstitution = true;
      } 
    }else if(type == 'doi'){
      if(this.filterDoi == true){
        this.filterDoi = false;
      }else{
        this.filterDoi = true;
      } 
    }else if(type == 'gel'){
      if(this.filterGel == true){
        this.filterGel = false;
      }else{
        this.filterGel = true;
      } 
    }else if(type == 'facs'){
      if(this.filterFacs == true){
        this.filterFacs = false;
      }else{
        this.filterFacs = true;
      }
    }else if(type == 'microscopy'){
      if(this.filterMicroscopy == true){
        this.filterMicroscopy = false;
      }else{
        this.filterMicroscopy = true;
      }
    }else if(type == 'macroscopy'){
      if(this.filterMacroscopy == true){
        this.filterMacroscopy = false;
      }else{
        this.filterMacroscopy = true;
      }
    }else{
      if(this.filterOther == true){
        this.filterOther = false;
      }else{
        this.filterOther = true;
      }
    }

    this.applyAdvancedFilter();
  }

  openHelpDialogFilter(){
    this.helpFilterDialogFlag = true;
  }
  closeHelpDialogFilter(){
    this.helpFilterDialogFlag = false;
  }

  loadAnalysisCase(){
    this.dataService.getStoreCaseSummary(this.id)
      .subscribe(data =>{
        this.caseSummary = data;
        if(this.caseSummary['size'] > 0){
          this.proute.navigate(['/analysis-case/'+this.id]);
        }
    });
  }

  
  updateCaseSummary:any;

  loadCaseSummary(){
    // console.log('run load case summary');
    try{
      this.updateCaseSummary.unsubscribe();
    }catch(e){}

    if(this.updateCaseSummary == null){
      this.dataService.getStoreCaseSummary(this.id)
        .subscribe(data =>{
          this.caseSummary = data;
        });
      this.updateCaseSummary = timer(3000).subscribe(t => {
        this.loadCaseSummary();
      });

    }else{
      this.updateCaseSummary = timer(3000).subscribe(t => {
        this.dataService.getStoreCaseSummary(this.id)
        .subscribe(data =>{
          this.caseSummary = data;
          // console.log('run load case summary ' + data);
        });
      });
    }

  }

  overlayPanelCenter:boolean;
  overlayDisplayLeftItem:any;
  overlayDisplayRightItem:any;
  overlayPanelLeft:boolean;
  overlayPanelRight:boolean;
  overlayPanelLeftUrl:string = '';
  overlayPanelRightUrl:string = '';
  overlayPanelLeftFullUrl:string = '';
  overlayPanelRightFullUrl:string = '';
  overlayPanelLeftWidth: number = 0;
  overlayPanelRightWidth: number = 0;
  overlayPanelBothPDF:boolean = false;
  overlayPanelMainItem:any;
  overlayPanelLeftGPTResult:any = null;
  overlayPanelRightGPTResult:any = null;
  overlayPanelIsAnalysing:boolean = false;
  overlayPanelCenterDisplayResolution = 500;
  overlayPanelCenterDisplayColor = 'red';
  overlayPanelCenterDisplayFeature = true;
  overlayPanelCenterIdx:number = 0;
  overlayPanelDisplayPanel:string = 'both';

  overlayPanelDisplayMode:string = 'original';

  overlayPanelDisplayPercent:string = '50%';

  overlayPanelMainTimestamp:number; 
 
  // openOverlayPanelLeft(item:any){
  //   this.overlayDisplayLeftItem = item;
  //   this.overlayPanelLeft = true;
    
  //   var leftWidth  = Math.round( screen.availWidth /1.5 );
  //   if(this.overlayDisplayLeftItem['original_file'].indexOf('pdf') == -1 
  //   && this.overlayPanelDisplayMode == 'original'){
  //     leftWidth = Math.round( screen.availWidth /1.5 ); 
  //   }else{
  //     leftWidth = leftWidth * 0.9;
  //   }
  //   console.log(leftWidth);
  //   var timestamp = Date.now();
  //   this.overlayPanelLeftUrl = this.baseUrl + '/image/' + this.id + '/' + this.overlayDisplayLeftItem[ 'original_file'] + '?timestamp='+timestamp+'&parent=yes&stroke=10&width=' + leftWidth;
  // }

  // openOverlayPanelRight(item:any){
  //   this.overlayDisplayRightItem = item;
  //   this.overlayPanelRight = true;
  //   var rightWidth  = Math.round( screen.availWidth /1.5 );
  //   if(this.overlayDisplayRightItem['original_file'].indexOf('pdf') == -1 
  //   && this.overlayPanelDisplayMode == 'original'){
  //     rightWidth = Math.round( screen.availWidth /1.5 ); 
  //   }else{ 
  //     rightWidth = rightWidth * 0.9;
  //   }
  //   console.log(rightWidth);
  //   var timestamp = Date.now();
  //   this.overlayPanelRightUrl = this.baseUrl + '/image/' + this.id + '/' + this.overlayDisplayRightItem[ 'original_file'] + '?timestamp='+timestamp+'&parent=yes&stroke=10&width=' + rightWidth;
  // }

  openOverlayPanelCenter(idx:number, target:any, itemLeft:any, itemRight:any, mode:any){
    
    // console.log('left');
    // console.log(itemLeft);

    // console.log('right');
    // console.log(itemRight);

    this.overlayPanelDisplayPanel = 'both';
    this.overlayPanelDisplayPercent = '50%';
    this.overlayPanelCenterIdx = idx;
    this.overlayPanelMainTimestamp = Date.now();
    this.overlayPanelCenterDisplayColor = 'red';
    this.overlayPanelCenterDisplayFeature = true;
    
    this.overlayPanelDisplayMode = mode;
    this.overlayPanelCenter = true;
    this.overlayDisplayLeftItem = itemLeft; 
    this.overlayDisplayRightItem = itemRight;
    this.overlayPanelMainItem = target;

    this.overlayPanelMainItem['img1']['feature'] = true;
    this.overlayPanelMainItem['img2']['feature'] = true;
    this.overlayPanelMainItem['color'] = 'red';
    
    this.overlayPanelIsAnalysing = false;
    this.overlayPanelLeftGPTResult = null;
    this.overlayPanelRightGPTResult = null;

    if(this.overlayDisplayLeftItem['original_file'].indexOf('pdf') != -1 && 
       this.overlayDisplayRightItem['original_file'].indexOf('pdf') != -1){
      this.overlayPanelBothPDF = true;
    }else{
      this.overlayPanelBothPDF = false;
    }

    // get the window width / 2 * 0.8 then set to overlayPanelCenterDisplayResolution
    if( this.overlayPanelMainItem['img1']['original_file'].indexOf('pdf') != -1
          && this.overlayPanelDisplayMode == 'original'){
      this.overlayPanelCenterDisplayResolution = Math.round( (window.screen.width / 2) * 0.9);
    }else{ 
      this.overlayPanelCenterDisplayResolution = Math.round( (window.screen.width / 2)  * 0.9);
    }

    this.overlayPanelLeftWidth = Math.round( screen.availWidth /2 ); 
    this.overlayPanelLeftUrl = this.baseUrl + '/image/' + this.id + '/' + this.overlayDisplayLeftItem[ 'original_file'] + '?parent=yes&stroke=10&width=' + this.overlayPanelLeftWidth;
    this.overlayPanelLeftFullUrl = this.baseUrl + '/image/' + this.id + '/' + this.overlayDisplayLeftItem[ 'original_file'] + '?parent=yes&stroke=10&width=' + (this.overlayPanelLeftWidth*2);

    this.overlayPanelRightWidth = Math.round( screen.availWidth /2 );
    this.overlayPanelRightUrl = this.baseUrl + '/image/' + this.id + '/' + this.overlayDisplayRightItem['id'] + '?parent=yes&stroke=10&width=' + this.overlayPanelRightWidth;
    this.overlayPanelRightFullUrl = this.baseUrl + '/image/' + this.id + '/' + this.overlayDisplayRightItem['id'] + '?parent=yes&stroke=10&width=' + (this.overlayPanelRightWidth*2);
  
    // alert(this.overlayPanelRightFullUrl);
    // console.log(target);
    this.appendInformationDialog(idx, target);

  }

  switchOverlayPanelDisplayPanel(mode:string){
    
    if(mode == this.overlayPanelDisplayPanel){
      this.overlayPanelDisplayPanel = 'both';
      this.overlayPanelDisplayPercent = '50%';
    }else{
      if(mode=='both'){
        this.overlayPanelDisplayPanel = 'both';
        this.overlayPanelDisplayPercent = '50%';
      }else if(mode=='left'){
        this.overlayPanelDisplayPanel = 'left';
        this.overlayPanelDisplayPercent = '100%';
      }else if(mode=='right'){
        this.overlayPanelDisplayPanel = 'right';
        this.overlayPanelDisplayPercent = '100%';
      }
    }

  }

  analyzePageText(){

    // if the overlayDisplayLeftItem['record'] same as overlayDisplayRightItem['record'] then run the data.gpt
    // store the result to the overlayPanelLeftGPTResult and overlayPanelRightGPTResult

    // if not then run the data.gpt twice for overlayDisplayLeftItem and overlayDisplayRightItem
    // store the result to the overlayPanelLeftGPTResult and overlayPanelRightGPTResult
       
    this.overlayPanelIsAnalysing = true;
    this.overlayPanelLeftGPTResult = null;
    this.overlayPanelRightGPTResult = null;
  
    // console.log(this.overlayDisplayRightItem);

    if( this.overlayDisplayLeftItem['record'] == this.overlayDisplayRightItem['record'] &&
       this.overlayDisplayLeftItem['page'] == this.overlayDisplayRightItem['page']
      ){
      // Here to execute the gpt analsyis the store the result
      this.dataService.gpt(this.id, this.overlayDisplayLeftItem['record'], this.overlayDisplayLeftItem['original_file'])
          .subscribe(data =>{
            this.overlayPanelLeftGPTResult = data;
            this.overlayPanelRightGPTResult = data;
      }); 
    }else{
      // Here to execute the gpt analysis twice and store the result
      this.dataService.gpt(this.id, this.overlayDisplayLeftItem['record'], this.overlayDisplayLeftItem['original_file'])
          .subscribe(data =>{
            this.overlayPanelLeftGPTResult = data;
      });
      this.dataService.gpt(this.id, this.overlayDisplayRightItem['record'], this.overlayDisplayRightItem['original_file'])
          .subscribe(data =>{
            this.overlayPanelRightGPTResult = data;
      });

    }
  
  }

  
  displayItem:any;
  panelLeft:boolean;
  panelRight:boolean;

  openPanelLeft(item:any){
    this.displayItem = item;
    this.panelLeft = true;
  }

  openPanelRight(item:any){
    this.displayItem = item;
    this.panelRight = true;
  }

  
  displayWithinItem:any;
  withinPanelLeft:boolean;
  withinPanelRight:boolean;

  // openWithinPanelLeft(item:any){
  //   this.displayWithinItem = item;
  //   this.withinPanelLeft = true;
  // }

  // openWithinPanelRight(item:any){
  //   this.displayWithinItem = item;
  //   this.withinPanelRight = true;
  // }
  
  toggleCase(i:number, caseid:string){

    if(this.similarity[i]['hide']){
      this.similarity[i]['hide'] = false;
      this.similarity.splice(i, 1);
      this.resultSimilarityHideTotal--;
      this.messageService.add({life: GlobalConstants.life, severity:'success', summary: this.translate.instant('duplication.toast-restore-case-title'), detail: this.translate.instant('duplication.toast-restore-case-success')});
    }else{
      if(this.similarity[i]['selected'] == true){
        this.removeCase(i);
      }
      this.similarity[i]['hide'] = true;
      this.similarity.splice(i, 1);
      this.resultSimilarityHideTotal++;
      this.messageService.add({life: GlobalConstants.life, severity:'success', summary: this.translate.instant('duplication.toast-hide-case-title'), detail: this.translate.instant('duplication.toast-hide-case-success')});
    }
    this.dataService.toggleCase(this.id, 'similarity', caseid).subscribe(data =>{
      if(this.resultSimilarityHideTotal == 0){
        this.toggleListHide = false;   
        this.initial();  
      } else {
        this.updateResultList();
      }
    });
  }

  
  /**
   * Load the metadata of the record then append to the UI
   * @param idx 
   * @param pair 
   */
  appendInformationDialog(idx:number, pair:any){

    if(this.overlayPanelMainItem['information_control'] == undefined){
      this.overlayPanelMainItem['information_control'] = true;
    }else if(this.overlayPanelMainItem['information_control'] == false){
      this.overlayPanelMainItem['information_control'] = true;
    }else{
      this.overlayPanelMainItem['information_control'] = false;
    }


    if(this.overlayPanelMainItem['information'] == undefined){
      // alert(pair['img1']['original_file'] + ' / ' + pair['img2']['original_file']);
      // alert(pair['img1']['id'] + ' / ' + pair['img2']['id']);
      this.dataService.getImageMetadata(this.id, pair['img1']['original_file'])
      .subscribe(source =>{
        if(source['status'] == 'success'){
          this.overlayPanelMainItem['img1']['information'] = source;
          if(this.recordDoi != null && this.recordDoi != ''){
            let informationObj = this.overlayPanelMainItem['img1']['information'];
            if(informationObj.hasOwnProperty('record')){
              informationObj['record']['doi'] = this.recordDoi;
            }
            if(informationObj.hasOwnProperty('image')){
              informationObj['image']['doi'] = this.recordDoi;
            }
            this.overlayPanelMainItem['img1']['information'] = informationObj;
          }
        }
      });
      this.dataService.getImageMetadata(this.id, pair['img2']['id'])
      .subscribe(target =>{
        if(target['status'] == 'success'){
          this.overlayPanelMainItem['img2']['information'] = target;
        }
      });
    }
    this.overlayPanelMainItem['information'] = true;

  }

  updateoverlayColor(color:string){
    this.overlayPanelCenterDisplayFeature = true;
    this.overlayPanelCenterDisplayColor = color;
    this.overlayPanelMainTimestamp = Date.now();
    var tempMainItem = this.overlayPanelMainItem;
    // sleep 500 milliseconds
    setTimeout(function(){
      this.overlayPanelMainItem = tempMainItem;
    }.bind(this), 500);
  }

  updateItemFeatureColor(color:string, item:any){
    item['feature'] = true;
    item['color'] = color;
    item['timestamp'] = Date.now();
    this.updateItemImages(item);
  }

  
  switchToggleListHide(){
    this.toggleListHide = !this.toggleListHide;
    this.initial();
  }

  toggleItemFeature(item:any){
    if( item['feature'] == false){
      item['feature'] = true;
    }else{
      item['feature'] = false;
    }
    item['timestamp'] = Date.now();
    this.updateItemImages(item);
  }

  updateItemImages(item:any){

    this.cdRef.detectChanges();
    // wait after 500ms to update the image
    // get the variables out of item then construcut the url
    var analysis = item['analysis'];
    var img1 = item['img1']['id'];
    var img2 = item['img2']['id'];
    var feature = item['feature'];
    var color = item['color'];
    var timestamp = item['timestamp'];
    var img1_url = this.baseUrl+'/image/'+this.id+'/'+ analysis+'/1/'+img1+'/'+img2+'?alpha=0.1&stroke=5&width=255&feature='+feature+'&color='+color+'&timestamp='+timestamp;
    var img2_url = this.baseUrl+'/image/'+this.id+'/'+ analysis+'/2/'+img1+'/'+img2+'?alpha=0.1&stroke=5&width=255&feature='+feature+'&color='+color+'&timestamp='+timestamp;

    setTimeout(() => {
      // copy a not reference object based on the item
      var temp = item;
          temp['img1']['url'] = img1_url;
          temp['img2']['url'] = img2_url; 
      // replace the item within the similarity based on the analysis value
      for(var i=0; i<this.similarity.length; i++){
        if(this.similarity[i]['img1']['id'] == img1 && this.similarity[i]['img2']['id'] == img2 ){
          this.similarity[i] = temp;
        }
      }
    }, 500);
  }

  downloadFullDuplicationReport() {
    this.fullReportDialog = false;
    this.dataService.getFullDuplicationPDFStatus(this.id)
      .subscribe(data => {
        if(data['status'] ==  true && data['progress'] == 'ready'){
          window.open(this.baseUrl+'/pdf/duplication/' + this.id + '/full/download', '_self');
        }
    });
  } 

  deleteFullDuplicationReport() {
    this.fullReportDialog = false;
    this.dataService.deleteFullDuplicationPDF(this.id)
      .subscribe(data => {
        if(data['status'] ==  true){
          // after 1 seconds execute loadFullDuplicationPDFStatus()
          setTimeout(() => {
            this.loadFullDuplicationPDFStatus();
          }
          , 1000);
        }
    });
  }

  // deleteFullDuplicationPDF

  fullReportDialog: boolean = false;
  openFullReportDialog(){
    this.fullReportDialog = true;
  }


}
