<p-toolbar>
    <div class="p-toolbar-group-left">
        <div (click)="back()" class="p-d-flex viewer-back">
            <div class="viewer-back-icon"><i class="pi pi-angle-left" title="Back"></i></div>
            <div class="viewer-back-text">Back</div>
        </div> 
    </div>
    <div class="p-toolbar-group-center">
        <span (click)="switchPreviousOriginalImage()" class="p-toolbar-group-center-previous"> <i class="pi pi-caret-left"></i></span>
        <span class="p-toolbar-group-center-text">{{(this.currentPosition + 1)}} / {{this.currentOriginalCache.length}}</span>
        <span (click)="switchNextOriginalImage()" class="p-toolbar-group-center-next"><i class="pi pi-caret-right"></i></span>
    </div>
    <div class="p-toolbar-group-right">
        <div>ID: {{id}}</div>
    </div>
</p-toolbar>
<section id="maincontent">
    <div class="p-d-flex viewer-content-section" id="left">
        <div class="viewer-content-section-left" *ngIf="original != undefined && leftPanel == true && sourceImageFilterPanel == false">
            <div class="viewer-content-section-subtitle p-mt-3">Original Image</div>
            <div (click)="findSimilarImage(originalImageFile)" [ngClass]="{'class': true}" [ngClass]="{'viewer-content-section-left-box': originalImageFile != leftFilename, 'viewer-content-section-left-box-selected': originalImageFile == leftFilename}" class="p-d-flex p-flex-column  ">
                <img class="viewer-content-section-left-box-img" src="{{baseUrl}}/image/{{id}}/{{imagefile}}?parent=yes&width=250" />
            </div>
            <p-divider></p-divider>
            <div class="viewer-content-section-subtitle">Auto Cropped Images</div>
            <div (click)="findSimilarImage(item['filename'])" [ngClass]="{'viewer-content-section-left-box': item['filename'] != leftFilename, 'viewer-content-section-left-box-selected': item['filename'] == leftFilename}" class="p-d-flex p-flex-column " *ngFor="let item of original['crop']">
                <img class="viewer-content-section-left-box-img" src="{{baseUrl}}/image/{{id}}/{{item['filename']}}?width=250" />
            </div>
        </div>
        <div class="viewer-content-section-left" *ngIf="sourceImageFilterPanel == true && leftPanel == true">
            <div class="viewer-content-section-subtitle p-mt-3">Adjustment</div>
            <div class="viewer-content-section-subtitle-item p-mt-2 p-mb-1">Contrast : {{adjustmentSourceContrast}}</div>
            <div class="viewer-content-section-adjustment-box p-ml-2 p-mt-2">
                <p-slider (onSlideEnd)="onChangeFilterContrast(leftFilenameCacheKey)" [style]="{width: '130px', height: '5px'}" [(ngModel)]="adjustmentSourceContrast" [min]="-100" [max]="100"></p-slider>
            </div>
            <div class="viewer-content-section-subtitle-item p-mt-3 p-mb-1">Brightness : {{adjustmentSourceBrightness}}</div>
            <div class="viewer-content-section-adjustment-box p-ml-2 p-mt-2">
                <p-slider (onSlideEnd)="onChangeFilterBrightness(leftFilenameCacheKey)" [style]="{width: '130px', height: '5px'}" [(ngModel)]="adjustmentSourceBrightness" [min]="-100" [max]="100"></p-slider>
            </div>
            <div class="viewer-content-section-subtitle-item p-mt-3 p-mb-1">Saturation : {{adjustmentSourceSaturation}}</div>
            <div class="viewer-content-section-adjustment-box p-ml-2 p-mt-2">
                <p-slider (onSlideEnd)="onChangeFilterSaturation(leftFilenameCacheKey)" [style]="{width: '130px', height: '5px'}" [(ngModel)]="adjustmentSourceSaturation" [min]="-100" [max]="100"></p-slider>
            </div> 
            <p-divider></p-divider>
            <div class="viewer-content-section-adjustment-box p-mt-2 p-mb-2">
                <span class="viewer-content-section-adjustment-checkbox">{{'duplication-viewer.invert-color' | translate}} : </span>
                <p-checkbox (onChange)="onChangeInvertColor(leftFilenameCacheKey)" [(ngModel)]="adjustmentSourceInvert" [binary]="true"></p-checkbox>
            </div>
            <p-divider></p-divider>
            <div class="viewer-content-section-subtitle p-mt-3 ">Filter</div>

            <p-scrollPanel *ngIf="sourceImageImgFilterID" [style]="{padding: '5px', width: '95%', height: '300px', border: '1px solid lightgray'}">
                <div *ngIf="sourceImageImgFilterID">
                    <div class="p-field-radiobutton viewer-content-section-right-radio" *ngFor="let item of filterList">
                        <p-radioButton (click)="switchToFilterImage('source')" [inputId]="item.name" name="item" [value]="item" [(ngModel)]="filterListSourceSelected"></p-radioButton>
                        <label [for]="item.name">{{item.name}}</label>
                    </div>
                </div>
            </p-scrollPanel>

            <div *ngIf="sourceImageImgFilterID == undefined" class="viewer-content-section-filter-box-text">
                <div>
                    Preparing ...
                </div>
            </div>
        </div>

        <div class="viewer-content-section-center" id="viewercontent">
            <div class="p-d-flex p-jc-between">
                <div class="viewer-content-section-center-left" (click)="switchLeft()">
                    <i *ngIf="leftPanel == true" class="pi pi-chevron-left" title="Close the Panel"></i>
                    <i *ngIf="leftPanel == false" class="pi pi-chevron-right" title="Open the Panel"></i>
                </div>
                <div class="p-d-flex p-flex-column viewer-content-section-center-canvas">
                    <div class="viewer-content-section-center-info p-d-flex p-jc-between">
                        <div>
                            <span class="viewer-content-section-center-info-item" (click)="resetViewerDisplay()" title="Remove all images from the display area">
                                <i class="pi pi-refresh viewer-icon-hover"> </i>
                            </span>
                        </div>
                        <div *ngIf="sourceImageImg != undefined ">
                            <span (click)="submitWebImage('source')" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="Find similar image on the Internet">
                                <i class="pi pi-search viewer-icon-hover"> </i>
                            </span>
                            <span (click)="toggleFilterPanel('source')" *ngIf=" sourceImageImg != undefined && sourceImageFilterPanel == true" class="viewer-content-section-center-info-item" title="Open the filter panel">
                                <i class="pi pi-filter viewer-icon-hover"> </i>
                            </span>
                            <span (click)="toggleFilterPanel('source')" *ngIf="sourceImageImg != undefined && sourceImageFilterPanel == false" class="viewer-content-section-center-info-item" title="Open the filter panel">
                                <i class="pi pi-filter-slash viewer-icon-hover"> </i>
                            </span>
                            <span (click)="flipImage('source')" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="Flip Image">
                                <fa-icon [icon]="faExchangeAlt" class="viewer-icon-hover-fontawesome"></fa-icon>
                            </span>
                            <span (click)="turnRight('source')" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="Turn to right 90 degree">
                                <i class="pi pi-reply viewer-icon-hover"> </i>
                            </span>
                            <span (click)="ZoomIn(leftFilenameCacheKey)" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="Zoom In">
                                <i class="pi pi-search-plus viewer-icon-hover"> </i>
                            </span>
                            <span (click)="ZoomOut(leftFilenameCacheKey)" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="Zoom Out">
                                <i class="pi pi-search-minus viewer-icon-hover"> </i>
                            </span>
                            <span (click)="reloadImage('source')" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="Reload the Image">
                                <fa-icon [icon]="faSync" class="viewer-icon-hover-fontawesome"></fa-icon>
                            </span>
                            <span (click)="removeImageFromCanvas('source')" *ngIf="sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="Remove the image">
                                <i class="pi pi-trash viewer-icon-hover"> </i>
                            </span>
                        </div>
                        <div *ngIf="sourceImageImg != undefined && similarImageImg != undefined">
                            <span *ngIf="sourceImageImg != undefined && similarImageImg != undefined && hasAnalyzed == false" (click)="analyzeImageSimilarity()" title="Click to upload the image for similarity analysis">
                                <i class="pi pi-check-circle viewer-icon-hover" ></i>
                            </span>
                            <span *ngIf="sourceImageImg != undefined && similarImageImg != undefined && hasAnalyzed == true">
                                <i class="pi pi-check-circle viewer-icon-hover viewer-content-section-center-has-analyzed" ></i>
                            </span>
                        </div>
                        <div *ngIf="similarImageImg != undefined ">
                            <span (click)="submitWebImage('similar')" *ngIf="similarImageImg != undefined " class="viewer-content-section-center-info-item" title="Find similar image on the Internet">
                                <i class="pi pi-search viewer-icon-hover"> </i>
                            </span>
                            <span (click)="toggleFilterPanel('similar')" *ngIf="similarImageImg != undefined && similarImageFilterPanel == true" class="viewer-content-section-center-info-item" title="Open the filter panel">
                                <i class="pi pi-filter viewer-icon-hover"> </i>
                            </span>
                            <span (click)="toggleFilterPanel('similar')" *ngIf="similarImageImg != undefined && similarImageFilterPanel == false" class="viewer-content-section-center-info-item" title="Open the filter panel">
                                <i class="pi pi-filter-slash viewer-icon-hover"> </i>
                            </span>
                            <span (click)="flipImage('similar')" *ngIf="similarImageImg != undefined " class="viewer-content-section-center-info-item" title="Flip Image">
                                <fa-icon [icon]="faExchangeAlt" class="viewer-icon-hover-fontawesome"></fa-icon>
                            </span>
                            <span (click)="turnRight('similar')" *ngIf="similarImageImg != undefined " class="viewer-content-section-center-info-item" title="Turn to right 90 degree">
                                <i class="pi pi-reply viewer-icon-hover"> </i>
                            </span>
                            <span (click)="ZoomIn(rightFilenameCacheKey)" *ngIf="similarImageImg != undefined " class="viewer-content-section-center-info-item" title="Zoom In">
                                <i class="pi pi-search-plus viewer-icon-hover"> </i>
                            </span>
                            <span (click)="ZoomOut(rightFilenameCacheKey)" *ngIf="similarImageImg != undefined " class="viewer-content-section-center-info-item" title="Zoom Out">
                                <i class="pi pi-search-minus viewer-icon-hover"> </i>
                            </span>
                            <span (click)="reloadImage('similar')" *ngIf="similarImageImg != undefined " class="viewer-content-section-center-info-item" title="Reload the Image">
                                <fa-icon [icon]="faSync" class="viewer-icon-hover-fontawesome"></fa-icon>
                            </span>
                            <span (click)="removeImageFromCanvas('similar')" *ngIf="similarImageImg != undefined " class="viewer-content-section-center-info-item" title="Remove the image">
                                <i class="pi pi-trash viewer-icon-hover"> </i>
                            </span>
                        </div>
                        <div>
                            <span (click)="toggleIntersectingMode()" class="viewer-content-section-center-info-item" title="Image Intersection Mode">
                                <fa-icon [icon]="faLayerGroup" class="viewer-icon-hover-fontawesome" [ngClass]="{'viewer-drawing-mode-selected': isIntersectingMode == true}"></fa-icon>
                            </span>
                            <span (click)="toggleDrawingMode()" class="viewer-content-section-center-info-item" [ngClass]="{'viewer-drawing-mode-selected': isDrawingMode == true}" title="Drawing on the canvas">
                                <i class="pi pi-pencil viewer-icon-hover"> </i>
                            </span>
                            <span (click)="confirmStoreCase()" *ngIf="similarImageImg != undefined || sourceImageImg != undefined " class="viewer-content-section-center-info-item" title="Save the analysis result">
                                <i class="pi pi-save viewer-icon-hover"> </i>
                            </span>
                        </div>
                    </div>
                    <div class="p-d-flex p-jc-center">
                        <canvas id="viewer" #viewer></canvas>
                    </div>
                    <div *ngIf="similarImageImg == undefined && sourceImageImg == undefined " class="viewer-content-section-center-control p-d-flex p-jc-center">
                        <div>
                            Please select the image to be display from the side panel.
                        </div>
                    </div>
                    <div *ngIf="similarImageImg == undefined || sourceImageImg == undefined" class="viewer-content-section-center-control p-d-flex p-jc-center">
                        <div *ngIf="sourceImageImg != undefined && similarImageImg == undefined && sourceImageImg" class="p-d-flex p-jc-center">

                            <span (click)="loadAdditionalInformation('source')" *ngIf="sourceImageImg" class="viewer-content-section-center-info-item" title="Display the metadata of the image">
                                <i class="pi pi-info-circle viewer-icon-hover"> </i>
                            </span>
                            <span *ngIf="sourceImageImg">Resolution: {{sourceImageImg.after_width}} * {{sourceImageImg.after_height}} ( original: {{sourceImageImg.original_width}} * {{sourceImageImg.original_height}} )</span>
                        </div>
                        <div *ngIf="sourceImageImg == undefined && similarImageImg != undefined && similarImageImg" class="p-d-flex p-jc-center">

                            <span (click)="loadAdditionalInformation('similar')" *ngIf="similarImageImg" class="viewer-content-section-center-info-item" title="Display the metadata of the image">
                                <i class="pi pi-info-circle viewer-icon-hover"> </i>
                            </span>
                            <span *ngIf="similarImageImg">Resolution: {{similarImageImg.after_width}} * {{similarImageImg.after_height}} ( original: {{similarImageImg.original_width}} * {{similarImageImg.original_height}} )</span>
                        </div>
                    </div>
                    <div *ngIf="sourceImageImg != undefined && similarImageImg != undefined" class="viewer-content-section-center-control p-d-flex p-jc-between">
                        <div> </div>
                        <div>
                            <span (click)="loadAdditionalInformation('source')" *ngIf="sourceImageImg" class="viewer-content-section-center-info-item" title="Display the metadata of the image">
                                <i class="pi pi-info-circle viewer-icon-hover"> </i>
                            </span>
                            <span *ngIf="sourceImageImg">  
                                Resolution: {{sourceImageImg.after_width}} * {{sourceImageImg.after_height}} ( original: {{sourceImageImg.original_width}} * {{sourceImageImg.original_height}} )
                            </span>
                        </div>
                        <div></div>
                        <div>
                            <span (click)="loadAdditionalInformation('similar')" *ngIf="similarImageImg" class="viewer-content-section-center-info-item" title="Display the metadata of the image">
                                <i class="pi pi-info-circle viewer-icon-hover"> </i>
                            </span>
                            <span *ngIf="similarImageImg">Resolution: {{similarImageImg.after_width}} * {{similarImageImg.after_height}} ( original: {{similarImageImg.original_width}} * {{similarImageImg.original_height}} )</span>
                        </div>
                        <div></div>
                    </div>
                </div>
                <div class="viewer-content-section-center-right" (click)="switchRight()">
                    <i *ngIf="rightPanel == true" class="pi pi-chevron-right" title="Close the Panel"></i>
                    <i *ngIf="rightPanel == false" class="pi pi-chevron-left" title="Open the Panel"></i>
                </div>
            </div>
        </div>

        <div class="viewer-content-section-right" *ngIf="similar != undefined && rightPanel == true && similarImageFilterPanel == false" id="right">
            <div>
                <div class="viewer-content-section-subtitle p-mt-3">Repository</div>
                <div class="p-field-radiobutton viewer-content-section-right-radio">
                    <p-radioButton name="type" value="within" [(ngModel)]="similarType" (click)="loadSimilarImage()" inputId="r1"></p-radioButton>
                    <label for="r1">Within Paper</label>
                </div>
                <div class="p-field-radiobutton viewer-content-section-right-radio">
                    <p-radioButton name="type" value="user" [(ngModel)]="similarType" (click)="loadSimilarImage()" inputId="r2"></p-radioButton>
                    <label for="r2">Personal</label>
                </div>
                <div class="p-field-radiobutton viewer-content-section-right-radio">
                    <p-radioButton name="type" value="lab" [(ngModel)]="similarType" (click)="loadSimilarImage()" inputId="r3"></p-radioButton>
                    <label for="r3">Laboratory</label>
                </div>
                <div class="p-field-radiobutton viewer-content-section-right-radio">
                    <p-radioButton name="type" value="institution" [(ngModel)]="similarType" (click)="loadSimilarImage()" inputId="r4"></p-radioButton>
                    <label for="r4">Institution</label>
                </div>
            </div>
            <div>
                <p-dropdown [options]="rows" [(ngModel)]="similarSize" optionLabel="value" (onChange)="loadSimilarImage()"></p-dropdown>
            </div>
            <p-divider></p-divider>
            <div class="viewer-content-section-subtitle">Similar Images</div>
            <div class="p-d-flex p-flex-column " [ngClass]="{'viewer-content-section-right-box': item['filename'] != rightFilename, 'viewer-content-section-right-box-selected': item['filename'] == rightFilename}" *ngFor=" let item of similar ">
                <img class="viewer-content-section-right-box-img " (click)="addSimilarImageToViewer(item[ 'record'], item[ 'filename'])" src="{{baseUrl}}/similar/image/{{id}}/{{item[ 'record']}}/{{item[ 'filename']}}?width=250 " />
            </div>
        </div>

        <div class="viewer-content-section-right" *ngIf="similarImageFilterPanel == true  && rightPanel == true">

            <div class="viewer-content-section-subtitle p-mt-3">Adjustment</div>
            <div class="viewer-content-section-subtitle-item p-mt-2 p-mb-1">Contrast : {{adjustmentSimilarContrast}}</div>
            <div class="viewer-content-section-adjustment-box p-ml-2 p-mt-2">
                <p-slider (onSlideEnd)="onChangeFilterContrast(rightFilenameCacheKey)" [style]="{width: '130px', height: '5px'}" [(ngModel)]="adjustmentSimilarContrast" [min]="-100" [max]="100"></p-slider>
            </div>
            <div class="viewer-content-section-subtitle-item p-mt-3 p-mb-1">Brightness : {{adjustmentSimilarBrightness}}</div>
            <div class="viewer-content-section-adjustment-box p-ml-2 p-mt-2">
                <p-slider (onSlideEnd)="onChangeFilterBrightness(rightFilenameCacheKey)" [style]="{width: '130px', height: '5px'}" [(ngModel)]="adjustmentSimilarBrightness" [min]="-100" [max]="100"></p-slider>
            </div>
            <div class="viewer-content-section-subtitle-item p-mt-3 p-mb-1">Saturation : {{adjustmentSimilarSaturation}}</div>
            <div class="viewer-content-section-adjustment-box p-ml-2 p-mt-2">
                <p-slider (onSlideEnd)="onChangeFilterSaturation(rightFilenameCacheKey)" [style]="{width: '130px', height: '5px'}" [(ngModel)]="adjustmentSimilarSaturation" [min]="-100" [max]="100"></p-slider>
            </div>
            <p-divider></p-divider>
            <div class="viewer-content-section-adjustment-box p-mt-2 p-mb-2">
                <span class="viewer-content-section-adjustment-checkbox">{{'duplication-viewer.invert-color' | translate}} : </span>
                <p-checkbox (onChange)="onChangeInvertColor(rightFilenameCacheKey)" [(ngModel)]="adjustmentSimilarInvert" [binary]="true"></p-checkbox>
            </div>
            <p-divider></p-divider>
            <div class="viewer-content-section-subtitle p-mt-3">Filter</div>

            <p-scrollPanel *ngIf="similarImageImgFilterID" [style]="{padding: '5px', width: '95%', height: '300px', border: '1px solid lightgray'}">
                <div *ngIf="similarImageImgFilterID">
                    <div class="p-field-radiobutton viewer-content-section-right-radio" *ngFor="let item of filterList">
                        <p-radioButton (click)="switchToFilterImage('similar')" [inputId]="item.name" name="item" [value]="item" [(ngModel)]="filterListSimilarSelected"></p-radioButton>
                        <label [for]="item.name">{{item.name}}</label>
                    </div>
                </div>
            </p-scrollPanel>

            <div *ngIf="similarImageImgFilterID == undefined" class="viewer-content-section-filter-box-text">
                <div>
                    Preparing ...
                </div>
            </div>
        </div>
    </div>

</section>

<p-blockUI [blocked]="blocked" [baseZIndex]="20001">
    <p-progressSpinner [style]="{width: '150px', height: '150px'}" styleClass="custom-spinner" strokeWidth="2" animationDuration=".5s"></p-progressSpinner>
</p-blockUI>


<p-dialog *ngIf="record" header="Metadata" [(visible)]="addtionalInformationDialog" [style]="{width: '50vw'}" [baseZIndex]="40000">

    <div *ngIf="addtionalInformationDisplay" class="p-col viewer-info-content-section">
        <div *ngIf="addtionalInformationDisplay['image']" class="viewer-info-content-section-record-figure">Figure. {{addtionalInformationDisplay['image']['description']}}</div>
        <div *ngIf="addtionalInformationDisplay['record']" class="viewer-info-content-section-record-title">{{addtionalInformationDisplay['record']['title']}}</div>
        <div *ngIf="addtionalInformationDisplay['record']" (click)="linkViaDoi(addtionalInformationDisplay['record']['doi'])" class="viewer-info-content-section-record-doi">{{addtionalInformationDisplay['record']['doi']}}</div>
    </div>

</p-dialog>

<p-dialog *ngIf="record" header="Internet Search Result" [(visible)]="searchResultDialog" [style]="{width: '60vw'}" [baseZIndex]="10000">

    <div *ngIf="internetImageImgWebTemp" class="viewer-internet-dialog-section">
        <div *ngIf="internetImageImgWebTemp" class="p-d-inline-flex p-mt-3">
            <div *ngIf="internetImageImgWebTemp['type'] == 'source'" class="viewer-internet-dialog-img">
                <img src="{{baseUrl}}/image/{{id}}/{{currentImageFile['filename']}}?width=150" />
            </div>
            <div *ngIf="internetImageImgWebTemp['type'] == 'similar'" class="viewer-internet-dialog-img">
                <img src="{{baseUrl}}/similar/image/{{id}}/{{rightFilenameRecordId}}/{{rightFilename}}?width=150" />
            </div>
            <div class="viewer-internet-dialog-info-box">
                <div *ngIf="internetImageImgWebTemp['data'][0]['entity'].length > 0" class="p-ml-3 p-mt-1 viewer-internet-dialog-info-result-section-title">Detected Entities</div>
                <div class="p-grid p-ml-2 p-mt-1">
                    <div class="viewer-internet-dialog-info-item-entity" *ngFor="let item of internetImageImgWebTemp['data'][0]['entity']">
                        <span title="{{item['description']}} : {{item['score']}}">{{item['description']}}</span>
                    </div>
                </div>
            </div>
            <div>
                <span (click)="confirmStoreInternetCase()" class="viewer-content-section-center-info-item" title="Save the internet search result">
                    <i class="pi pi-save viewer-icon-hover"> </i>
                </span>
            </div>
        </div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['page'].length > 0" class="p-mt-3 viewer-internet-dialog-info-result-section-title">Page Contains Images</div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['page'].length > 0" class="p-mt-1 viewer-internet-dialog-info-result-section">
            <div class="p-d-flex p-flex-column" *ngFor="let item of internetImageImgWebTemp['data'][0]['page'] ; index as i ">
                <div class="p-d-flex">
                    <div class="viewer-internet-dialog-info-item-no">{{(i+1)}}</div>
                    <div class="viewer-internet-dialog-info-item-text">
                        <a href="{{item['url']}}" target="_blank">{{item['title']}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['full'].length > 0" class="p-mt-3 viewer-internet-dialog-info-result-section-title">Full Matched</div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['full'].length > 0" class="p-grid p-mt-1 viewer-internet-dialog-info-result-section">
            <div class="p-d-flex" *ngFor="let item of internetImageImgWebTemp['data'][0]['full'] ; index as i ">
                <div class="p-d-flex p-flex-column viewer-internet-dialog-info-item-img">
                    <img src="{{item['url']}}" title="{{item['url']}}" />
                    <div title="{{item['url']}}">
                        <a href="{{item['url']}}" target="_blank">{{item['url'].substring(0, item['url'].indexOf("/", 10)).replace('https://','').replace('http://','')}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['partially'].length > 0" class="p-mt-3 viewer-internet-dialog-info-result-section-title">Partially Matched</div>
        <div *ngIf="internetImageImgWebTemp['data'][0]['partially'].length > 0" class="p-grid p-mt-1 viewer-internet-dialog-info-result-section">
            <div class="p-d-flex" *ngFor="let item of internetImageImgWebTemp['data'][0]['partially'] ; index as i ">
                <div class="p-d-flex p-flex-column viewer-internet-dialog-info-item-img">
                    <img src="{{item['url']}}" title="{{item['url']}}" />
                    <div title="{{item['url']}}">
                        <a href="{{item['url']}}" target="_blank">{{item['url'].substring(0, item['url'].indexOf("/", 10)).replace('https://','').replace('http://','')}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-mt-3 p-mb-3 viewer-internet-dialog-info-item-not-found" *ngIf="internetImageImgWebTemp['data'][0]['partially'].length == 0 && internetImageImgWebTemp['data'][0]['full'].length == 0 && internetImageImgWebTemp['data'][0]['page'].length == 0">
            No results were found ...
        </div>
    </div>

</p-dialog>
<p-toast [baseZIndex]="90000"></p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="30000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>