<p-toolbar>
    <div class="p-toolbar-group-left">
        <button *ngIf="resultSimilarityTotal > 0" pButton pRipple type="button"
            label="{{'duplication.tab' | translate }} ({{resultSimilarityTotal}})" class="p-button-text"></button>
        <button routerLink="/manipulation/{{id}}" *ngIf="resultManipulationTotal > 0" pButton pRipple type="button"
            label="{{'manipulation.tab' | translate }} ({{resultManipulationTotal}}) "
            class="p-button-text  p-button-plain"></button>
        <!-- <p-divider layout="vertical"></p-divider> -->
    </div>

    <div class="p-d-flex p-toolbar-group-right">
        <div class="p-d-flex p-mr-2">
            <button *ngIf="hasDuplicationDownloadStatus == true && isPreparingDuplicationPDF == true" pButton pRipple type="button" icon="pi pi-spin pi-spinner"  
                label="{{'duplication.prepareing-full-report' | translate}}"
                disabled="true"
                class="p-button-text  p-button-plain"></button>
            <button *ngIf="hasDuplicationDownloadStatus == true && isPreparingDuplicationPDF == false" pButton pRipple type="button" icon="pi pi-download" 
                label="{{'duplication.download-full-report' | translate}} {{downloadDuplicationPDFSize}}"
                (click)="openFullReportDialog()"
                class="p-button-text  p-button-plain"></button>
            <p-divider layout="vertical"></p-divider>
        </div>
        <div>
            <i *ngIf="caseSummary != undefined && caseSummary['size'] > 0" (click)="loadAnalysisCase()"
                class="pi pi-briefcase duplication-case-module"></i>
            <i *ngIf="caseSummary != undefined && caseSummary['size'] == 0" (click)="loadAnalysisCase()"
                class="pi pi-briefcase duplication-case-module-zero"></i>
        </div>
        <div class="duplication-case-highlight">
            <span *ngIf="caseSummary != undefined && caseSummary['size'] > 0"
                class="duplication-case-highlight-box">{{caseSummary['size']}}</span>
        </div>
    </div>


</p-toolbar>
<header *ngIf="result" class="duplication-header-section">
    <div class="p-grid">
        <div class="p-col">
            <div class="p-d-flex">
                <div class="duplication-header-title">{{'duplication.summary' | translate}}</div>
            </div>
            <div *ngIf="result" class="p-d-flex p-flex-column p-jc-between">
                <div *ngIf="toggleListHide == true" class="duplication-header-item-summary p-mb-1 ">
                    {{'duplication.result-total' | translate:{total:(resultSimilarityHideTotal)} }}
                </div>
                <div *ngIf="toggleListHide == false" class="duplication-header-item-summary p-mb-1 ">
                    {{'duplication.result-total' | translate:{total:(resultSimilarityTotal - resultSimilarityHideTotal)}
                    }}
                </div>
                <div (click)="toggleFilterSelected('case')" *ngIf="selectedCount > 0"
                    class="duplication-header-item-summary-click p-mb-1  p-mt-2 ">
                    <fa-icon *ngIf="filterSelected == false" [icon]="faSquare"
                        class="duplication-filter-unselected "></fa-icon>
                    <fa-icon *ngIf="filterSelected == true" [icon]="faCheckSquare"
                        class="duplication-filter-selected "></fa-icon>
                    <label class="duplication-filter-selected-text">{{'duplication.result-saved-total' |
                        translate:{total:selectedCount} }} </label>
                </div>
            </div>
            <div class="p-d-inline-flex">
                <div *ngIf="resultSimilarityHideTotal > 0" class="p-d-flex p-flex-column p-jc-between">
                    <div (click)="switchToggleListHide()" class="duplication-header-item-summary-click p-mt-2 ">
                        <fa-icon *ngIf="toggleListHide == false" [icon]="faSquare"
                            class="duplication-filter-unselected "></fa-icon>
                        <fa-icon *ngIf="toggleListHide == true" [icon]="faCheckSquare"
                            class="duplication-filter-selected "></fa-icon>
                        <label class="duplication-filter-selected-text">{{'duplication.display-hide-case' | translate }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="p-d-inline-flex">
                <div *ngIf="recordDoiMatchedDetected == true" class="p-d-flex p-flex-column p-jc-between">
                    <div (click)="toggleFilterSelected('doi')" class="duplication-header-item-summary-click p-mt-2 ">
                        <fa-icon *ngIf="filterDoi == false" [icon]="faSquare"
                            class="duplication-filter-unselected "></fa-icon>
                        <fa-icon *ngIf="filterDoi == true" [icon]="faCheckSquare"
                            class="duplication-filter-selected "></fa-icon>
                        <label class="duplication-filter-selected-text">{{'duplication.hide-same-doi-case' | translate }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-col">
            <div class="p-d-flex">
                <div class="duplication-header-title">{{'duplication.data-source' | translate}}</div>
            </div>
            <div class="p-d-flex p-flex-column p-jc-between">
                <div (click)="toggleFilterSelected('within')" *ngIf="countWithin > 0"
                    class="duplication-header-item-summary-click ">

                    <fa-icon *ngIf="filterWithin == false" [icon]="faSquare"
                        class="duplication-filter-unselected "></fa-icon>
                    <fa-icon *ngIf="filterWithin == true" [icon]="faCheckSquare"
                        class="duplication-filter-selected "></fa-icon>
                    {{'duplication.result-filter-within' | translate:{total:countWithin} }}
                </div>
                <div (click)="toggleFilterSelected('user')" *ngIf="countUser > 0"
                    class="duplication-header-item-summary-click p-mt-1">
                    <fa-icon *ngIf="filterUser == false" [icon]="faSquare"
                        class="duplication-filter-unselected "></fa-icon>
                    <fa-icon *ngIf="filterUser == true" [icon]="faCheckSquare"
                        class="duplication-filter-selected "></fa-icon>
                    {{'duplication.result-filter-user' | translate:{total:countUser} }}
                </div>
                <div (click)="toggleFilterSelected('lab')" *ngIf="countLab > 0"
                    class="duplication-header-item-summary-click p-mt-1">
                    <fa-icon *ngIf="filterLab == false" [icon]="faSquare"
                        class="duplication-filter-unselected "></fa-icon>
                    <fa-icon *ngIf="filterLab == true" [icon]="faCheckSquare"
                        class="duplication-filter-selected "></fa-icon>
                    {{'duplication.result-filter-lab' | translate:{total:countLab} }}
                </div>
                <div (click)="toggleFilterSelected('institution')" *ngIf="countInstitution > 0"
                    class="duplication-header-item-summary-click p-mt-1">
                    <fa-icon *ngIf="filterInstitution == false" [icon]="faSquare"
                        class="duplication-filter-unselected "></fa-icon>
                    <fa-icon *ngIf="filterInstitution == true" [icon]="faCheckSquare"
                        class="duplication-filter-selected "></fa-icon>
                    {{'duplication.result-filter-institution' | translate:{total:countInstitution} }}
                </div>
                <div (click)="toggleFilterSelected('internet')" *ngIf="countInternet > 0"
                    class="duplication-header-item-summary-click p-mt-1">
                    <fa-icon *ngIf="filterInternet == false" [icon]="faSquare"
                        class="duplication-filter-unselected "></fa-icon>
                    <fa-icon *ngIf="filterInternet == true" [icon]="faCheckSquare"
                        class="duplication-filter-selected "></fa-icon>
                    {{'duplication.result-filter-internet' | translate:{total:countInternet} }}
                </div>
            </div>
        </div>
        <div class="p-col">
            <div class="p-d-flex">
                <div class="duplication-header-title">{{'duplication.image-type' | translate}}</div>
            </div>
            <div class="p-d-flex p-flex-column p-jc-between">

                <div (click)="toggleFilterSelected('gel')" *ngIf="countGel > 0"
                    class="p-mr-5 duplication-header-item-summary-click ">
                    <fa-icon *ngIf="filterGel == false" [icon]="faSquare"
                        class="duplication-filter-unselected "></fa-icon>
                    <fa-icon *ngIf="filterGel == true" [icon]="faCheckSquare"
                        class="duplication-filter-selected "></fa-icon>
                    {{'classification.gel' | translate}} ({{countGel}})
                </div>
                <div (click)="toggleFilterSelected('facs')" *ngIf="countFacs > 0"
                    class="p-mr-5 p-mt-1 duplication-header-item-summary-click ">
                    <fa-icon *ngIf="filterFacs == false" [icon]="faSquare"
                        class="duplication-filter-unselected "></fa-icon>
                    <fa-icon *ngIf="filterFacs == true" [icon]="faCheckSquare"
                        class="duplication-filter-selected "></fa-icon>
                    {{'classification.facs' | translate}} ({{countFacs}})
                </div>
                <div (click)="toggleFilterSelected('microscopy')" *ngIf="countMicroscopy > 0"
                    class="p-mr-5 p-mt-1 duplication-header-item-summary-click ">
                    <fa-icon *ngIf="filterMicroscopy == false" [icon]="faSquare"
                        class="duplication-filter-unselected "></fa-icon>
                    <fa-icon *ngIf="filterMicroscopy == true" [icon]="faCheckSquare"
                        class="duplication-filter-selected "></fa-icon>
                    {{'classification.microscopy' | translate}} ({{countMicroscopy}})
                </div>
                <div (click)="toggleFilterSelected('macroscopy')" *ngIf="countMacroscopy > 0"
                    class="p-mr-5 p-mt-1 duplication-header-item-summary-click ">
                    <fa-icon *ngIf="filterMacroscopy == false" [icon]="faSquare"
                        class="duplication-filter-unselected "></fa-icon>
                    <fa-icon *ngIf="filterMacroscopy == true" [icon]="faCheckSquare"
                        class="duplication-filter-selected "></fa-icon>
                    {{'classification.macroscopy' | translate}} ({{countMacroscopy}})
                </div>
                <div (click)="toggleFilterSelected('others')" *ngIf="countOther > 0"
                    class="p-mr-5 p-mt-1 duplication-header-item-summary-click ">
                    <fa-icon *ngIf="filterOther == false" [icon]="faSquare"
                        class="duplication-filter-unselected "></fa-icon>
                    <fa-icon *ngIf="filterOther == true" [icon]="faCheckSquare"
                        class="duplication-filter-selected "></fa-icon>
                    Others ({{countOther}})
                </div>
                
            </div>
        </div>

        <div class="p-col">
            <div class="p-d-flex">
                <div class="duplication-header-title">{{'duplication.similarity' | translate}}</div>
            </div>
            <div *ngIf="result" class="p-d-flex p-flex-column p-jc-between">
                <div (click)="toggleFilterSelected('high')" *ngIf="countHigh > 0"
                    class="duplication-header-item-summary-click ">
                    <fa-icon *ngIf="filterHigh == false" [icon]="faSquare"
                        class="duplication-filter-unselected "></fa-icon>
                    <fa-icon *ngIf="filterHigh == true" [icon]="faCheckSquare"
                        class="duplication-filter-selected "></fa-icon>
                    {{'duplication.result-filter-high' | translate:{total:countHigh} }}
                </div>
                <div (click)="toggleFilterSelected('medium')" *ngIf="countMedium > 0"
                    class="duplication-header-item-summary-click p-mt-1">
                    <fa-icon *ngIf="filterMedium == false" [icon]="faSquare"
                        class="duplication-filter-unselected "></fa-icon>
                    <fa-icon *ngIf="filterMedium == true" [icon]="faCheckSquare"
                        class="duplication-filter-selected "></fa-icon>
                    {{'duplication.result-filter-medium' | translate:{total:countMedium} }}
                </div>
                <div (click)="toggleFilterSelected('low')" *ngIf="countLow > 0"
                    class="duplication-header-item-summary-click p-mt-1">
                    <fa-icon *ngIf="filterLow == false" [icon]="faSquare"
                        class="duplication-filter-unselected "></fa-icon>
                    <fa-icon *ngIf="filterLow == true" [icon]="faCheckSquare"
                        class="duplication-filter-selected "></fa-icon>
                    {{'duplication.result-filter-low' | translate:{total:countLow} }}
                </div>
            </div>
            <div class="p-d-inline-flex">
            </div>
        </div>
        <div class="p-col">
            <div class="p-d-flex">
                <div class="duplication-header-title">{{'duplication.advanced-filter' | translate}}
                    <span *ngIf="filterUsed == true" (click)="resetList()"
                        class="duplication-header-title-clear">[Clear]</span>
                    <fa-icon [icon]="faQuestionCircle" (click)="openHelpDialogFilter()"
                        class="duplication-help-icon p-ml-2"></fa-icon>
                </div>
            </div>
            <div *ngIf="result" class="p-d-flex p-flex-column p-jc-between">
                <div *ngIf="filterHistogramMin != filterHistogramMax && filterHistogramMax > 0" class="p-d-flex">
                    <div class="duplication-result-filter-section-item">{{'duplication.result-filter-color' |
                        translate}} </div>
                    <div class="duplication-result-filter-section-slide">
                        <p-slider (onChange)="onChangeAdvancedFilter()" [style]="{width: '100px', height: '5px'}"
                            [(ngModel)]="filterHistogram" [min]="filterHistogramMin"
                            [max]="filterHistogramMax"></p-slider>
                    </div>
                    <div class="duplication-result-filter-section-value"> / {{filterHistogram}} </div>
                </div>
                <div *ngIf="filterPhashMin != filterPhashMax && filterPhashMax > 0" class="p-d-flex p-mt-1">
                    <div class="duplication-result-filter-section-item ">{{'duplication.result-filter-fingerprint' |
                        translate}} </div>
                    <div class="duplication-result-filter-section-slide">
                        <p-slider (onChange)="onChangeAdvancedFilter()" [style]="{width: '100px', height: '5px'}"
                            [(ngModel)]="filterPhash" [min]="filterPhashMin" [max]="filterPhashMax"></p-slider>
                    </div>
                    <div class="duplication-result-filter-section-value"> / {{filterPhash}} </div>
                </div>
                <div *ngIf="filterAsiftMin != filterAsiftMax && filterAsiftMax > 0" class="p-d-flex p-mt-1">
                    <div class="duplication-result-filter-section-item ">{{'duplication.result-filter-features' |
                        translate}} </div>
                    <div class="duplication-result-filter-section-slide">
                        <p-slider (onChange)="onChangeAdvancedFilter()" [style]="{width: '100px', height: '5px'}"
                            [(ngModel)]="filterAsift" [min]="filterAsiftMin" [max]="filterAsiftMax"></p-slider>
                    </div>
                    <div class="duplication-result-filter-section-value"> / {{filterAsift}} </div>
                </div>
                <!-- <div class="p-d-flex  p-mt-1">
                    <div class="duplication-result-filter-section-item">Similarity: </div>
                    <div class="duplication-result-filter-section-slide">
                        <p-slider (onChange)="onChangeAdvancedFilter()" [style]="{width: '100px', height: '5px'}" [(ngModel)]="filterSimilarity" [min]="filterSimilarityMin" [max]="filterSimilarityMax"></p-slider>
                    </div>
                    <div class="duplication-result-filter-section-value"> / {{filterSimilarity}} </div>
                </div> -->
            </div>
            <div class="p-d-inline-flex">
            </div>
        </div>
        <div class="p-col">
            <div class="p-d-flex p-jc-between">
                <div></div>
            </div>
            <div class="p-d-flex p-jc-between">
                <div>
                </div>
                <div *ngIf="filterImageFilename == '' || filterImageFilename == undefined">
                    <i (click)="openFilterImage()" class="pi pi-filter duplication-header-item-icon"
                        title="Filter Image"></i>
                </div>
                <div (click)="resetFilterFilename()"
                    *ngIf="filterImageFilename != '' && filterImageFilename != undefined" class="p-grid p-mr-1">
                    <div class="p-d-flex p-flex-column ">
                        <div class="p-d-flex p-jc-between">
                            <div></div>
                            <div *ngIf="record" class="p-d-flex">
                                <div class="p-d-flex p-flex-column duplication-filter-image-selected-box">
                                    <img *ngIf="filterImageFilename != '' && filterImageFilename != undefined"
                                        class="duplication-filter-image-selected"
                                        src="{{baseUrl}}/image/{{id}}/{{filterImageFilename}}.png?width=250" />
                                    <div *ngIf="record['recordFormat'] == 'pdf'"
                                        class="p-mt-1 duplication-filter-detail-text">{{'duplication.list-page' |
                                        translate}} {{(filterImageFilenamePage + 1)}}</div>
                                </div>
                                <div>
                                    <i class="pi pi-trash duplication-header-item-icon" title="Filter Image"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="result" class="p-mt-4 duplication-filter-found-text">
                            {{'duplication.list-total' | translate:{total:result['similarity'].length} }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="p-d-flex batch-process-item" >
        <div class="p-mr-2">Select all Case(s)</div>
        <div class="p-mr-2">Save all selected Case(s)</div>
        <div class="p-mr-2">Delete all selected Cas(s)</div>
    </div> -->
</header>
<p-divider></p-divider>
<section *ngIf="result" class="duplication-content-section">
    <div *ngFor="let item of similarity; index as i" class="duplication-content-section-box">

        <div class="p-d-flex p-jc-between duplication-content-index">
            <div class="duplication-content-no " >
                <!-- <p-checkbox [(ngModel)]="item['checkbox']" [binary]="true" inputId="{{i}}" class="p-mr-2"></p-checkbox> -->
                <label for="{{i}}">{{'duplication.list-no' | translate}} {{(i+1)}}</label>

                <!-- <label *ngIf="item['transformFactors']">
                    {{item['transformFactors']['explain']}}
                </label> -->
            </div>

            <div class="p-d-flex">
                <div class="duplication-content-indicator p-d-flex">
                    <div (click)="toggleCase(i, item['img1']['id']+','+item['img2']['id'])"
                        class="duplication-content-indicator-text-disabled" *ngIf="item['hide'] == false">{{
                        'duplication.list-hide' | translate }}</div>
                    <div><i (click)="toggleCase(i, item['img1']['id']+','+item['img2']['id'])"
                            *ngIf="item['hide'] == false" class="pi pi-trash duplication-case-button"></i></div>
                    <div (click)="toggleCase(i, item['img1']['id']+','+item['img2']['id'])"
                        class="duplication-content-indicator-text-disabled" *ngIf="item['hide'] == true">{{
                        'duplication.list-restore' | translate }}</div>
                    <div><i (click)="toggleCase(i, item['img1']['id']+','+item['img2']['id'])"
                            *ngIf="item['hide'] == true" class="pi pi-undo duplication-case-button"></i></div>
                </div>
                <div *ngIf="item['hide'] == false" class="p-ml-2 p-mr-2"></div>
                <div *ngIf="item['hide'] == false" class="manipulation-content-indicator p-d-flex">
                    <div class="duplication-content-indicator-text-disabled" (click)="submitCase(i, item)"
                        *ngIf="item['selected'] == null || item['selected'] == false">{{ 'duplication.list-save-case' |
                        translate }}</div>
                    <div><i title="Add the Analysis Case" (click)="submitCase(i, item)"
                            *ngIf="item['selected'] == null || item['selected'] == false"
                            class="pi pi-briefcase duplication-case-button"></i></div>
                    <div class="duplication-content-indicator-text-enabled" (click)="removeCase(i)"
                        *ngIf="item['selected']">{{ 'duplication.list-delete-case' | translate }}</div>
                    <div><i title="Remove the Analysis Case" (click)="removeCase(i)" *ngIf="item['selected']"
                            class="pi pi-briefcase duplication-case-button-selected"></i></div>
                </div>
            </div>

        </div>
        <div class="duplication-content-box">
            <div class="p-grid ">

                <!-- Within Result -->

                <div class="p-col" *ngIf="item['duplication_type'] == 'within'"></div>
                <div class="p-grid duplication-content-img-within-section" *ngIf="item['duplication_type'] == 'within'">
                    <div class="p-col " *ngIf="item['duplication_type'] == 'within'">
                        <div class="p-d-flex p-flex-column p-mr-5 p-ml-3">
                            <div class="duplication-card-title-image-source">
                                {{'duplication.list-original-image' | translate}}
                            </div>
                            <!-- <img (click)="openWithinPanelLeft(item['img1'])" class="duplication-content-img-within " -->
                            <img (click)="openOverlayPanelCenter(i, item, item['img1'], item['img2'], 'within')" class="duplication-content-img-within "
                                src="{{baseUrl}}/image/{{id}}/{{item[ 'img1'][ 'original_file']}}?stroke=10&width=350&parent=yes" />
                            <div class="duplication-card-title ">{{'duplication.list-resolution' | translate}}
                                {{item['img1']['crop_info']['width']}} * {{item['img1']['crop_info']['height']}}</div>
                            <div class="duplication-card-low-resolution ">
                                <span *ngIf="item['img1']['low_resolution'] == true">Low Resolution</span>
                            </div>
                        </div>
                    </div>
                    <div class="p-col duplication-content-between-box ">
                        <div class="p-d-flex p-jc-center duplication-content-between-click"
                            (click)="loadWithinImageViewer(item['img1']['id'])">
                            <i class="pi pi-search p-mr-2 duplication-content-link"></i>
                            <span *ngIf="item['similarity'] >= high"
                                class="duplication-content-link-text">{{'duplication.list-high-similarity' |
                                translate}}</span>
                            <span *ngIf="item['similarity'] >= medium && item['similarity'] < high"
                                class="duplication-content-link-text">{{'duplication.list-medium-similarity' |
                                translate}}</span>
                            <span *ngIf="item['similarity'] < medium"
                                class="duplication-content-link-text">{{'duplication.list-low-similarity' |
                                translate}}</span>
                        </div>
                        <p-divider></p-divider>
                        <div class="p-d-flex p-jc-center duplication-content-source-repository">
                            <span *ngIf="item['source'] == 'within'">{{'duplication.list-source-within' |
                                translate}}</span>
                            <span *ngIf="item['source'] == 'user'">{{'duplication.list-source-user' | translate}}</span>
                            <span *ngIf="item['source'] == 'lab'">{{'duplication.list-source-lab' | translate}}</span>
                            <span *ngIf="item['source'] == 'institution'">{{'duplication.list-source-institution' |
                                translate}}</span>
                        </div>
                        <div class="duplication-content-between-control">
                        </div>
                    </div>
                    <div class="p-col duplication-content-img" *ngIf="item['duplication_type'] == 'within'">
                        <div class="p-d-flex p-flex-column p-ml-5 p-mr-3">
                            <div class="duplication-card-title-image-source">
                                <!-- {{'duplication.list-processed-image' | translate}} -->
                                {{'classification.'+item['classification'] | translate}}
                            </div>
                            <!-- (target:any, itemLeft:any, itemRight:any, mode:any) -->
                            <!-- <img (click)="openWithinPanelRight(item['img2'])" class="duplication-content-img-within " -->
                            <img (click)="openOverlayPanelCenter(i, item, item['img1'], item['img2'], 'within')" class="duplication-content-img-within "
                                src="{{baseUrl}}/image/{{id}}/5/{{item[ 'img1'][ 'original_file']}}?stroke=2&width=350" />
                            <div class="duplication-card-title ">{{'duplication.list-resolution' | translate}}
                                {{item['img1']['crop_info']['width']}} * {{item['img1']['crop_info']['height']}}</div>
                            <div class="duplication-card-low-resolution ">
                                <span *ngIf="item['img1']['low_resolution'] == true">Low Resolution</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-col" *ngIf="item['duplication_type'] == 'within'"></div>

                <!-- Duplication Result-->
                <div *ngIf=" item[ 'img1'][ 'original_file'].indexOf( 'crop') !=- 1 && item['duplication_type'] != 'within'"
                    class="p-col duplication-content-img-box">
                    <div class="duplication-card-title-repository duplication-content-img-box-left">
                        {{'duplication.list-target-file' | translate}}
                    </div>
                    <div class="p-d-flex p-flex-column "
                        (click)="openOverlayPanelCenter(i, item, item['img1'], item['img2'], 'original')">
                        <div class="duplication-card-title-image-source">
                            {{'duplication.list-original-image' | translate}}
                        </div>
                        <img class="duplication-content-img-single-original "
                            src="{{baseUrl}}/image/{{id}}/{{item[ 'img1'][ 'original_file']}}?parent=yes&stroke=10&width=150" />
                        <div *ngIf="record['recordFormat'] == 'pdf'" class="duplication-card-title ">
                            {{'duplication.list-page' | translate}} {{ (item['img1']['page']+1)}}</div>
                        <div class="duplication-card-low-resolution ">
                            <span *ngIf="item['img1']['low_resolution'] == true">&nbsp;</span>
                        </div>
                    </div>
                </div>

                <div *ngIf="item['duplication_type'] != 'within'" class="duplication-card-central-box p-d-flex ">
                    <div *ngIf=" item[ 'img1'][ 'original_file'].indexOf( 'crop') !=- 1 && item['duplication_type'] != 'within'"
                        class="p-col duplication-content-img-box ">
                        <div class="p-d-flex p-flex-column " >
                            <!-- <div class="p-d-flex p-flex-column " (click)="openPanelLeft(item)"> -->
                            <div class="duplication-card-title-image-source">
                                <!-- {{'duplication.list-processed-image' | translate}} -->
                                {{'classification.'+item['classification'] | translate}}
                            </div>
                            <!-- <img class="duplication-content-img-single " src="{{baseUrl}}/image/{{id}}/{{item[ 'img1'][ 'original_file']}}?stroke=10&width=250" /> -->
                            <div class="duplication-content-img-single">
                                <img 
                                (click)="openOverlayPanelCenter(i, item, item['img1'], item['img2'], 'target')"
                                [src]="item['img1']['url']" />
                            </div> 
                            <div class="duplication-card-title ">{{'duplication.list-resolution' | translate}}
                                {{item['img1']['crop_info']['width']}} * {{item['img1']['crop_info']['height']}}</div>
                            <div class="duplication-card-low-resolution ">
                                <span *ngIf="item['img1']['low_resolution'] == true">Low Resolution</span>
                            </div>
                        </div>
                    </div>
                    <div class="p-col duplication-content-between-box ">
                        <!-- <div class="duplication-content-between-control" title="Add/Remove the Analysis Case">
                            <i (click)="submitCase(i, item)" *ngIf="item['selected'] == null || item['selected'] == false" class="pi pi-briefcase duplication-case-button"></i>
                            <i (click)="removeCase(i)" *ngIf="item['selected']" class="pi pi-briefcase duplication-case-button-selected"></i>
                        </div>  -->
                        <div class="p-d-flex p-jc-center duplication-content-between-click"
                            (click)="loadDuplicationImageViewer(item['img1']['id'], item['img2']['id'])">
                            <i class="pi pi-search p-mr-2 duplication-content-link"></i>
                            <span *ngIf="item['similarity'] >= high"
                                class="duplication-content-link-text">{{'duplication.list-high-similarity' |
                                translate}}</span>
                            <span *ngIf="item['similarity'] >= medium && item['similarity'] < high"
                                class="duplication-content-link-text">{{'duplication.list-medium-similarity' |
                                translate}}</span>
                            <span *ngIf="item['similarity'] < medium"
                                class="duplication-content-link-text">{{'duplication.list-low-similarity' |
                                translate}}</span>
                        </div>
                        <p-divider></p-divider>
                        
                        <div *ngIf="item['asift'] != undefined && item['asift'] >= 1 && item['asift'] < 6" class="p-d-flex p-jc-center " >
                            <p-button label="{{'duplication.list-features' | translate}} {{item['asift']}}" styleClass="p-button-outlined p-button-info" [disabled]="true"></p-button>
                        </div>
                        <div *ngIf="item['asift'] != undefined && item['asift'] >= 6" class="p-d-flex p-jc-center " >
                            <p-button *ngIf="item['feature'] == true" (click)="toggleItemFeature(item)" label="{{'duplication.list-features' | translate}} {{item['asift']}}" icon="pi pi-eye" styleClass="p-button-outlined p-button-info"></p-button>
                            <p-button *ngIf="item['feature'] == false" (click)="toggleItemFeature(item)" label="{{'duplication.list-features' | translate}} {{item['asift']}}" icon="pi pi-eye-slash" styleClass="p-button-outlined p-button-info"></p-button>
                        </div>
                        <div *ngIf="item['asift'] != undefined && item['asift'] >= 6" class="p-d-flex p-jc-center " >
                            <div *ngIf="item['color'] == 'red'" (click)="updateItemFeatureColor('red', item)" class="feature-color-red">⬤ </div>
                            <div *ngIf="item['color'] != 'red'" (click)="updateItemFeatureColor('red', item)" class="feature-color-normal">⬤</div>
                            <div *ngIf="item['color'] == 'green'" (click)="updateItemFeatureColor('green', item)" class="feature-color-green">⬤</div>
                            <div *ngIf="item['color'] != 'green'" (click)="updateItemFeatureColor('green', item)" class="feature-color-normal">⬤</div>
                            <div *ngIf="item['color'] == 'blue'" (click)="updateItemFeatureColor('blue', item)" class="feature-color-blue">⬤</div>
                            <div *ngIf="item['color'] != 'blue'"  (click)="updateItemFeatureColor('blue', item)" class="feature-color-normal">⬤</div>
                        </div>
                        
                        <p-divider *ngIf="item['asift'] != undefined && item['asift'] > 0" class="pt-0 mt-0"> </p-divider>
                        <!-- <div class="p-d-flex p-jc-center duplication-content-source-repository">
                            <span *ngIf="item['source'] == 'within'">{{'duplication.list-target-source-within' |
                                translate}}</span>
                            <span *ngIf="item['source'] == 'user'">{{'duplication.list-target-source-user' |
                                translate}}</span>
                            <span *ngIf="item['source'] == 'lab'">{{'duplication.list-target-source-lab' |
                                translate}}</span>
                            <span *ngIf="item['source'] == 'institution'">{{'duplication.list-target-source-institution'
                                | translate}}</span>
                        </div> -->
                        <div class="p-d-flex p-flex-column ">
                            <div class="p-grid">
                                <div class="p-col-6" style="text-align: right;">
                                    <span class="duplication-indicator-title">{{'duplication.list-color' |
                                        translate}}</span>
                                </div>
                                <div class="p-col-6" style="text-align: left;">
                                    <span class="duplication-indicator-value">{{item['histogramP']}}%</span>
                                </div>
                                <div class="p-col-6" style="text-align: right;">
                                    <span class="duplication-indicator-title">{{'duplication.list-fingerprint' |
                                        translate}}</span> 
                                </div>
                                <div class="p-col-6" style="text-align: left;">
                                    <span class="duplication-indicator-value">{{item['phashP']}}%</span>
                                </div>
                            </div>
                            <!-- <div class="p-d-flex p-jc-center p-mb-2">
                                <span class="duplication-indicator-title">{{'duplication.list-color' |
                                    translate}}</span>
                                <span class="duplication-indicator-value">{{item['histogramP']}}%</span>
                            </div>
                            <div class="p-d-flex p-jc-center p-mb-2">
                                <span class="duplication-indicator-title">{{'duplication.list-fingerprint' |
                                    translate}}</span>
                                <span class="duplication-indicator-value">{{item['phashP']}}%</span>
                            </div> -->
                        </div>
                        <div class="duplication-content-between-control">
                        </div>
                    </div>
                    <div *ngIf="item[ 'img2'][ 'original_file'].indexOf( 'crop') !=- 1 && item['duplication_type'] != 'within'"
                        class="p-col duplication-content-img-box ">
                        <div class="p-d-flex p-flex-column " >
                            <!-- <div class="p-d-flex p-flex-column " (click)="openPanelRight(item)"> -->
                            <div class="duplication-card-title-image-source">
                                <!-- {{'duplication.list-processed-image' | translate}} -->
                                {{'classification.'+item['classification'] | translate}}
                            </div>
                            <div class="duplication-content-img-single " >
                                <img 
                                (click)="openOverlayPanelCenter(i, item, item['img1'], item['img2'], 'target')"
                                [src]="item['img2']['url']" />
                            </div>
                            <!-- <img class="duplication-content-img-single " src="{{baseUrl}}/image/{{this.id}}/{{item[ 'img2'][ 'id']}}?stroke=10&width=250" /> -->
                            <div class="duplication-card-title ">{{'duplication.list-resolution' | translate}}
                                {{item['img2']['crop_info']['width']}} * {{item['img2']['crop_info']['height']}}</div>
                            <div class="duplication-card-low-resolution ">
                                <span *ngIf="item['img2']['low_resolution'] == true">Low Resolution</span>
                            </div>
                            <!-- <div class="duplication-card-low-resolution ">
                                <span>{{item['img2']['id']}}</span>
                            </div> -->
                        </div>
                    </div>
                </div>

                <div *ngIf="item[ 'img2'][ 'original_file'].indexOf( 'crop') !=- 1  && item['duplication_type'] != 'within'"
                    class="p-col duplication-content-img-box ">
                    <div class="duplication-card-title-repository duplication-content-img-box-right">
                        <label *ngIf="item['source'] == 'within'">{{'duplication.list-target-source-within' |
                            translate}}</label>
                        <label *ngIf="item['source'] == 'user'">{{'duplication.list-target-source-user' |
                            translate}}</label>
                        <label *ngIf="item['source'] == 'lab'">{{'duplication.list-target-source-lab' |
                            translate}}</label>
                        <label *ngIf="item['source'] == 'institution'">{{'duplication.list-target-source-institution' |
                            translate}}</label>
                        <label *ngIf="item['source'] != 'within' && item['source'] != 'user' && item['source'] != 'lab' && item['source'] != 'institution'">
                            {{'duplication.list-target-source-internet' | translate}}
                        </label>
                            
                    </div>
                    <div class="p-d-flex p-flex-column "
                        (click)="openOverlayPanelCenter(i, item, item['img1'], item['img2'], 'original')">
                        <div class="duplication-card-title-image-source">
                            {{'duplication.list-original-image' | translate}}
                        </div>
                        <img class="duplication-content-img-single-original "
                            src="{{baseUrl}}/image/{{id}}/{{item[ 'img2'][ 'id']}}?parent=yes&stroke=10&width=150" />
                        <div *ngIf="record['recordFormat'] == 'pdf'" class="duplication-card-title ">
                            {{'duplication.list-page' | translate}}{{ (item['img2']['page'] +1)}}</div>
                        <div class="duplication-card-low-resolution ">
                            <span *ngIf="item['img2']['low_resolution'] == true">&nbsp;</span>
                        </div>
                    </div>
                </div>

            </div>
            
        </div>
    </div>
    <div *ngIf="similarity != undefined && similarity.length == 0" class="p-mt-3 p-mb-3 viewer-item-not-found">
        No results were found ...
    </div>
</section>
<p-dialog [modal]="true" *ngIf="record" header="{{'duplication.please-select-image' | translate}}" [(visible)]="filterImageDialog"
    [style]="{width: '60vw'}" [baseZIndex]="10000">
    <div class="p-grid">
        <div class="p-d-flex p-flex-column duplication-card" *ngFor="let item of filterOriginalList"
            (click)="applyFilterFilename(item['filename'])">
            <img class="duplication-card-img" src="{{baseUrl}}/image/{{id}}/{{item['filename']}}?width=250" />
            <div class="duplication-card-title">
                <p class="duplication-card-title-text" *ngIf="record['recordFormat'] == 'pdf'"
                    title="Page: {{(item['page']+1)}}">{{'duplication.list-page' | translate}} {{(item['page']+1)}}</p>
                <p class="duplication-card-title-text" *ngIf="record['recordFormat'] != 'pdf'"
                    title="{{item['originalFilename']}}">{{item['originalFilename']}}</p>
            </div>
        </div>
    </div>
</p-dialog>


<p-dialog [modal]="true" header="{{'duplication.dialog-algorithm-title' | translate}}"
    (onHide)="closeHelpDialogFilter()" [(visible)]="helpFilterDialogFlag" [style]="{width: '50vw'}"
    [baseZIndex]="10000">
    <div class="p-d-flex p-flex-column">
        <div class="p-d-flex">
            {{'duplication.dialog-algorithm-color' | translate}}
        </div>
        <div class="p-d-flex">
            {{'duplication.dialog-algorithm-fingerprint' | translate}}
        </div>
        <div class="p-d-flex">
            {{'duplication.dialog-algorithm-features' | translate}}
        </div>
    </div>
</p-dialog>

<p-scrollTop></p-scrollTop>

<p-toast></p-toast>

<section *ngIf="successLoading == false">
    <div class="p-mt-3 p-mb-3 main-item-not-found">
        {{'duplication.invalid-request' | translate}}
    </div>
</section>

<footer class="duplication-footer">
</footer>

<p-dialog  [modal]="true" [(visible)]="fullReportDialog" header="{{'duplication.reset-full-report-dialog-title' | translate}}"
    (onHide)="overlayPanelLeft = false; overlayDisplayLeftItem = undefined;">
    <p style="text-align: center;">
        {{'duplication.reset-full-report-dialog-info' | translate}} {{downloadDuplicationPDFSize}} <br/><br/>
        {{'duplication.reset-full-report-dialog-message' | translate}}
    </p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-times" (click)="fullReportDialog = false" label="{{'duplication.reset-full-report-dialog-cancel' | translate}}" styleClass="p-button-text"></p-button>
        <p-button icon="pi pi-refresh" (click)="deleteFullDuplicationReport()" label="{{'duplication.reset-full-report-dialog-yes' | translate}}" styleClass="p-button-text"></p-button>
        <p-button icon="pi pi-download" (click)="downloadFullDuplicationReport()" label="{{'duplication.reset-full-report-dialog-download' | translate}}" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>

<p-dialog [position]="'center'" [modal]="true" [(visible)]="overlayPanelCenter" [style]="{width: '95vw', height: 'auto'}"
    (onHide)="overlayPanelCenter = false; overlayDisplayLeftItem = undefined; overlayDisplayRightItem = undefined;">

    <ng-template pTemplate="header">
        <div class="p-d-flex">
            
                <p-button icon="pi pi-search" styleClass="p-button-text" 
                *ngIf="overlayPanelMainItem['similarity'] >= high && overlayPanelMainItem['duplication_type'] == 'within'"
                    label="{{'duplication.list-high-similarity' |  translate}} [A]" (click)="overlayPanelCenter = false; loadWithinImageViewer(overlayPanelMainItem['img1']['id'])"></p-button>
            
                <p-button icon="pi pi-search" styleClass="p-button-text" 
                *ngIf="overlayPanelMainItem['similarity'] >= medium && overlayPanelMainItem['similarity'] < high && overlayPanelMainItem['duplication_type'] == 'within'"
                label="{{'duplication.list-medium-similarity' | translate}} [A]" (click)="overlayPanelCenter = false; loadWithinImageViewer(overlayPanelMainItem['img1']['id'])"></p-button>
            
                <p-button icon="pi pi-search" styleClass="p-button-text" 
                *ngIf="overlayPanelMainItem['similarity'] < medium && overlayPanelMainItem['duplication_type'] == 'within'"
                label="{{'duplication.list-low-similarity' | translate}} [A]" (click)="overlayPanelCenter = false; loadWithinImageViewer(overlayPanelMainItem['img1']['id'])"></p-button>         

                <p-button icon="pi pi-search" styleClass="p-button-text" 
                *ngIf="overlayPanelMainItem['similarity'] >= high && overlayPanelMainItem['duplication_type'] != 'within'"
                    label="{{'duplication.list-high-similarity' |  translate}} [A]" (click)="overlayPanelCenter = false; loadDuplicationImageViewer(overlayPanelMainItem['img1']['id'], overlayPanelMainItem['img2']['id'])"></p-button>
            
                <p-button icon="pi pi-search" styleClass="p-button-text" 
                *ngIf="overlayPanelMainItem['similarity'] >= medium && overlayPanelMainItem['similarity'] < high && overlayPanelMainItem['duplication_type'] != 'within'"
                label="{{'duplication.list-medium-similarity' | translate}} [A]" (click)="overlayPanelCenter = false; loadDuplicationImageViewer(overlayPanelMainItem['img1']['id'], overlayPanelMainItem['img2']['id'])"></p-button>
            
                <p-button icon="pi pi-search" styleClass="p-button-text" 
                *ngIf="overlayPanelMainItem['similarity'] < medium && overlayPanelMainItem['duplication_type'] != 'within'"
                label="{{'duplication.list-low-similarity' | translate}} [A]" (click)="overlayPanelCenter = false; loadDuplicationImageViewer(overlayPanelMainItem['img1']['id'], overlayPanelMainItem['img2']['id'])"></p-button>
               
            <p-button icon="pi pi-file"styleClass="p-button-text"  
                *ngIf="overlayPanelDisplayMode == 'original'"  label="{{'duplication.display-cropped-image' | translate}} [D]"
                    (click)="overlayPanelDisplayMode = 'target'"></p-button>
            <p-button icon="pi pi-image" styleClass="p-button-text"  
                *ngIf="overlayPanelDisplayMode == 'target'"  label="{{'duplication.display-original-image' | translate}} [D]"
                    (click)="overlayPanelDisplayMode = 'original'"></p-button> 
            
            <p-button icon="pi pi-eye" styleClass="p-button-text" 
                *ngIf="overlayPanelDisplayMode == 'target' && overlayPanelCenterDisplayFeature == true && overlayPanelMainItem['asift'] != undefined && overlayPanelMainItem['asift'] >= 6"  
                [label]="highlightfeature+': ' + overlayPanelMainItem['asift'] +' [F]'"
                    (click)="overlayPanelCenterDisplayFeature = false"></p-button> 
            
            <p-button icon="pi pi-eye-slash" styleClass="p-button-text"
                *ngIf="overlayPanelDisplayMode == 'target' && overlayPanelCenterDisplayFeature == false  && overlayPanelMainItem['asift'] != undefined && overlayPanelMainItem['asift'] >= 6"  
                [label]="highlightfeature+': ' + overlayPanelMainItem['asift'] +' [F]'"
                    (click)="overlayPanelCenterDisplayFeature = true"></p-button> 
                     
            <div *ngIf="overlayPanelDisplayMode == 'target' && overlayPanelMainItem['asift'] != undefined && overlayPanelMainItem['asift'] >= 6" class="p-d-flex p-jc-center"  >
                <div *ngIf="overlayPanelCenterDisplayColor == 'red'" (click)="updateoverlayColor('red')" class="dialog-feature-color-red">⬤ [R]</div>
                <div *ngIf="overlayPanelCenterDisplayColor != 'red'" (click)="updateoverlayColor('red')" class="dialog-feature-color-normal">⬤ [R]</div>
                <div *ngIf="overlayPanelCenterDisplayColor == 'green'" (click)="updateoverlayColor('green')" class="dialog-feature-color-green">⬤ [G]</div>
                <div *ngIf="overlayPanelCenterDisplayColor != 'green'" (click)="updateoverlayColor('green')" class="dialog-feature-color-normal">⬤ [G]</div>
                <div *ngIf="overlayPanelCenterDisplayColor == 'blue'" (click)="updateoverlayColor('blue')" class="dialog-feature-color-blue">⬤ [B]</div>
                <div *ngIf="overlayPanelCenterDisplayColor != 'blue'"  (click)="updateoverlayColor('blue')" class="dialog-feature-color-normal">⬤ [B]</div>
            </div>

            <!-- Vertical p-divider -->
            <p-divider layout="vertical"></p-divider>

            <div class="duplication-content-indicator p-d-flex ">
                <!-- <div (click)="toggleCase(overlayPanelCenterIdx, overlayPanelMainItem['img1']['id']+','+overlayPanelMainItem['img2']['id'])"
                    class="duplication-content-indicator-text-disabled" *ngIf="overlayPanelMainItem['hide'] == false">{{
                    'duplication.list-hide' | translate }}</div> -->
                <p-button icon="pi pi-trash" styleClass="p-button-text" label="{{'duplication.list-hide' | translate }} [H]" (click)="toggleCase(overlayPanelCenterIdx, overlayPanelMainItem['img1']['id']+','+overlayPanelMainItem['img2']['id']); overlayPanelCenter = false; overlayDisplayLeftItem = undefined; overlayDisplayRightItem = undefined;"
                        *ngIf="overlayPanelMainItem['hide'] == false " class=" duplication-case-button"></p-button>
                <!-- <div (click)="toggleCase(overlayPanelCenterIdx, overlayPanelMainItem['img1']['id']+','+overlayPanelMainItem['img2']['id'])"
                    class="duplication-content-indicator-text-disabled" *ngIf="overlayPanelMainItem['hide'] == true"></div> -->
                <p-button icon="pi pi-undo" styleClass="p-button-text" label="{{'duplication.list-restore' | translate }} [H]" (click)="toggleCase(overlayPanelCenterIdx, overlayPanelMainItem['img1']['id']+','+overlayPanelMainItem['img2']['id']); "
                        *ngIf="overlayPanelMainItem['hide'] == true" class=" duplication-case-button"></p-button>
            </div>
            <!-- <div *ngIf="overlayPanelMainItem['hide'] == false" class="p-ml-2 p-mr-2"></div> -->
            <div *ngIf="overlayPanelMainItem['hide'] == false" class="manipulation-content-indicator p-d-flex">
                <!-- <div class="duplication-content-indicator-text-disabled" (click)="submitCase(overlayPanelCenterIdx, overlayPanelMainItem)"
                    *ngIf="overlayPanelMainItem['selected'] == null || overlayPanelMainItem['selected'] == false"></div> -->
                <p-button icon="pi pi-briefcase" label="{{ 'duplication.list-save-case' | translate }} [S]" styleClass="p-button-text" title="Add the Analysis Case" (click)="submitCase(overlayPanelCenterIdx, overlayPanelMainItem)"
                        *ngIf="overlayPanelMainItem['selected'] == null || overlayPanelMainItem['selected'] == false"
                        class=" duplication-case-button"></p-button>
                <!-- <div class="duplication-content-indicator-text-enabled" (click)="removeCase(overlayPanelCenterIdx)"
                    *ngIf="overlayPanelMainItem['selected']">{{ 'duplication.list-delete-case' | translate }}</div> -->
                <p-button icon="pi pi-briefcase " styleClass="p-button-text p-button-danger" label="{{ 'duplication.list-delete-case' | translate }} [S]" title="Remove the Analysis Case" (click)="removeCase(overlayPanelCenterIdx)" *ngIf="overlayPanelMainItem['selected']"
                        class=" duplication-case-button-selected"></p-button>
            </div>
        </div>

    </ng-template>

    <p-divider></p-divider>

    <div class="p-d-flex p-ai-center" >
        <!-- <p-button *ngIf="item['feature'] == true" (click)="toggleItemFeature(item)" label="{{'duplication.list-features' | translate}} {{item['asift']}}" icon="pi pi-eye" styleClass="p-button-outlined p-button-info"></p-button>
        <p-button *ngIf="item['feature'] == false" (click)="toggleItemFeature(item)" label="{{'duplication.list-features' | translate}} {{item['asift']}}" icon="pi pi-eye-slash" styleClass="p-button-outlined p-button-info"></p-button> -->

        <div (click)="switchOverlayPanelDisplayPanel('left')" 
            [ngClass]="overlayPanelDisplayPanel != 'left' && overlayPanelDisplayPanel != 'both'? 'orignalPageTextTitleInactive':''"
            *ngIf="overlayPanelMainItem != undefined && overlayDisplayLeftItem != undefined" class="p-d-flex  p-jc-center orignalPageTextTitle" style="width: calc((100%) / 2);">
            <i *ngIf="overlayPanelDisplayPanel == 'both' ||
                overlayPanelDisplayPanel == 'left'"
                 class="pi pi-eye p-pt-1 p-mr-2" ></i>
            <i *ngIf="overlayPanelDisplayPanel == 'right'"
                  class="pi pi-eye-slash p-pt-1 p-mr-2" ></i>
            {{'duplication.list-original-image' | translate}}
            <label *ngIf="overlayPanelMainItem['img1']['original_file'].indexOf('pdf') != -1" > / {{'duplication.page' | translate}}: {{overlayPanelMainItem['img1']['page']+1}}</label>
            &nbsp;[Z]
        </div>

        <div  (click)="switchOverlayPanelDisplayPanel('right')"
            [ngClass]="overlayPanelDisplayPanel != 'right' && overlayPanelDisplayPanel != 'both'? 'orignalPageTextTitleInactive':''"
                *ngIf="overlayPanelMainItem != undefined" class="p-d-flex p-jc-center orignalPageTextTitle" style="width: calc((100%) / 2);">
            <i *ngIf="overlayPanelDisplayPanel == 'both' ||
                overlayPanelDisplayPanel == 'right'"
                class="pi pi-eye p-pt-1 p-mr-2" ></i>
            <i *ngIf="overlayPanelDisplayPanel == 'left'"
                class="pi pi-eye-slash p-pt-1 p-mr-2" ></i>
            <span
                *ngIf="overlayPanelMainItem['source'] == 'within' && overlayDisplayRightItem != undefined">{{'duplication.list-source-within'
                | translate}}</span>
            <span
                *ngIf="overlayPanelMainItem['source'] == 'user' && overlayDisplayRightItem != undefined">{{'duplication.list-source-user'
                | translate}}</span>
            <span
                *ngIf="overlayPanelMainItem['source'] == 'lab' && overlayDisplayRightItem != undefined">{{'duplication.list-source-lab'
                | translate}}</span>
            <span
                *ngIf="overlayPanelMainItem['source'] == 'institution' && overlayDisplayRightItem != undefined">{{'duplication.list-source-institution'
                | translate}}</span>
            <label *ngIf="overlayPanelMainItem['img2']['original_file'].indexOf('pdf') != -1" > / {{'duplication.page' | translate}}: {{overlayPanelMainItem['img2']['page']+1}}</label>
            &nbsp;[X]
        </div>

    </div>

    <p-divider></p-divider>

    <div *ngIf="overlayPanelMainItem != undefined && overlayPanelDisplayMode == 'original' " class="p-d-flex justify-content-center mt-5 mb-5 margin-top: 25px;" >
        
        <div *ngIf="this.overlayPanelDisplayPanel == 'both'" class="p-d-flex p-flex-column p-ai-center" [ngStyle]="{'width': overlayPanelDisplayPercent}">
            <img *ngIf="overlayDisplayLeftItem != undefined" [src]="overlayPanelLeftUrl" class="dialog-image-original-control"/>
        </div>
        <div *ngIf="this.overlayPanelDisplayPanel == 'both'"  class="p-d-flex p-flex-column p-ai-center" [ngStyle]="{'width': overlayPanelDisplayPercent}">
            <img *ngIf="overlayDisplayRightItem != undefined " [src]="overlayPanelRightUrl" class="dialog-image-original-control"/>
        </div>
        <div *ngIf="this.overlayPanelDisplayPanel == 'left'" class="p-d-flex p-flex-column p-ai-center" [ngStyle]="{'width': overlayPanelDisplayPercent}">
            <img width="100%" *ngIf="overlayDisplayLeftItem != undefined" [src]="overlayPanelLeftFullUrl" class="dialog-image-original-control"/>
        </div>
        <div *ngIf="this.overlayPanelDisplayPanel == 'right'"  class="p-d-flex p-flex-column p-ai-center" [ngStyle]="{'width': overlayPanelDisplayPercent}">
            <img width="100%" *ngIf="overlayDisplayRightItem != undefined " [src]="overlayPanelRightFullUrl" class="dialog-image-original-control"/>
        </div>

    </div> 

    <div *ngIf="overlayPanelMainItem != undefined && overlayPanelDisplayMode == 'target' " class="p-d-flex justify-content-center mt-5 mb-5">
        
        <div *ngIf="overlayPanelMainItem != undefined && (this.overlayPanelDisplayPanel == 'both' || this.overlayPanelDisplayPanel == 'left') " class="p-d-flex p-flex-column p-ai-center" [ngStyle]="{'width': overlayPanelDisplayPercent}">
            <img 
            class="dialog-image-switch-control"
                [src]="baseUrl+'/image/'+id+'/'+overlayPanelMainItem[ 'analysis']+'/1/'+overlayPanelMainItem[ 'img1'][ 'id']+'/'+overlayPanelMainItem[ 'img2'][ 'id']+'?alpha=0.1&stroke=5&width='+overlayPanelCenterDisplayResolution+'&feature='+overlayPanelCenterDisplayFeature+'&color='+overlayPanelCenterDisplayColor+'&timestamp='+overlayPanelMainTimestamp" />
        </div>
        <div *ngIf="overlayPanelMainItem != undefined && (this.overlayPanelDisplayPanel == 'both' || this.overlayPanelDisplayPanel == 'right') "  class="p-d-flex p-flex-column p-ai-center" [ngStyle]="{'width': overlayPanelDisplayPercent}">
            <img 
            class="dialog-image-switch-control"
                [src]="baseUrl+'/image/'+id+'/'+overlayPanelMainItem[ 'analysis']+'/2/'+overlayPanelMainItem[ 'img1'][ 'id']+'/'+overlayPanelMainItem[ 'img2'][ 'id']+'?alpha=0.1&stroke=5&width='+overlayPanelCenterDisplayResolution+'&feature='+overlayPanelCenterDisplayFeature+'&color='+overlayPanelCenterDisplayColor+'&timestamp='+overlayPanelMainTimestamp" />
         </div>
    </div>

    <div *ngIf="overlayPanelMainItem != undefined && overlayPanelDisplayMode == 'within' " class="p-d-flex justify-content-center mt-5 mb-5">
        <div *ngIf="overlayPanelMainItem != undefined && (this.overlayPanelDisplayPanel == 'both' || this.overlayPanelDisplayPanel == 'left') " class="p-d-flex p-flex-column p-ai-center" [ngStyle]="{'width': overlayPanelDisplayPercent}">
            <img [src]="baseUrl+'/image/'+id+'/'+overlayPanelMainItem[ 'img1'][ 'original_file']+'?parent=yes&width='+overlayPanelCenterDisplayResolution+'&timestamp='+overlayPanelMainTimestamp" />
        </div>
        <div *ngIf="overlayPanelMainItem != undefined && (this.overlayPanelDisplayPanel == 'both' || this.overlayPanelDisplayPanel == 'right') " class="p-d-flex p-flex-column p-ai-center" [ngStyle]="{'width': overlayPanelDisplayPercent}">
            <img [src]="baseUrl+'/image/'+id+'/5/'+overlayPanelMainItem[ 'img1'][ 'original_file']+'?width='+overlayPanelCenterDisplayResolution+'&stroke=5&timestamp='+overlayPanelMainTimestamp" />
        </div>
    </div>

    <p-divider></p-divider>
    <div *ngIf="overlayPanelMainItem != undefined && overlayPanelDisplayMode != undefined " class="p-d-flex justify-content-center mt-5 mb-5">
        <div 
        class="p-d-flex p-flex-column p-ai-center" 
        *ngIf="overlayPanelMainItem != undefined && (this.overlayPanelDisplayPanel == 'both' || this.overlayPanelDisplayPanel == 'left') "
            [ngStyle]="{'width': overlayPanelDisplayPercent}">
            
            <div *ngIf="overlayPanelMainItem['img1']['information']" title="{{overlayPanelMainItem['img1']['information']['record']}}"
                class="p-col " style="text-align: center;">
                <div *ngIf="overlayPanelMainItem['img1']['information']['image']"
                    class="dialog-metadata-figure">
                    {{'duplication.list-figure' | translate}}
                    {{overlayPanelMainItem['img1']['information']['image']['description']}}</div>
                <div *ngIf="overlayPanelMainItem['img1']['information']['record']"
                    class="dialog-metadata-title">
                    {{overlayPanelMainItem['img1']['information']['record']['title']}}</div>
                <div *ngIf="overlayPanelMainItem['img1']['information']['record'] && overlayPanelMainItem['img1']['information']['record']['doi'] != ''"
                    (click)="linkViaDoi(overlayPanelMainItem['img1']['information']['record']['doi'])"
                    class="dialog-metadata-doi">
                    DOI: {{overlayPanelMainItem['img1']['information']['record']['doi']}}</div>
            </div>
            <div *ngIf="overlayPanelMainItem['img1']['information'] == undefined"
                class="p-col duplication-more-info-content-section-left" style="text-align: center;">
                <div class="dialog-metadata-title">
                    {{'duplication.list-no-result' | translate}}</div>
            </div>

        </div>

        <div *ngIf="overlayPanelMainItem != undefined && (this.overlayPanelDisplayPanel == 'both' || this.overlayPanelDisplayPanel == 'right') " 
            class="p-d-flex p-flex-column p-ai-center" 
                    [ngStyle]="{'width': overlayPanelDisplayPercent}">
            
            <div *ngIf="overlayPanelMainItem['img2']['information']" title="{{overlayPanelMainItem['img2']['information']['record']}}"
                class="p-col " style="text-align: center;">
                <div *ngIf="overlayPanelMainItem['img2']['information']['image']"
                    class="dialog-metadata-figure">
                    {{'duplication.list-figure' | translate}}
                    {{overlayPanelMainItem['img2']['information']['image']['description']}}</div>
                <div *ngIf="overlayPanelMainItem['img2']['information']['record']"
                    class="dialog-metadata-title">
                    {{overlayPanelMainItem['img2']['information']['record']['title']}}</div>
                <div *ngIf="overlayPanelMainItem['img2']['information']['record'] && overlayPanelMainItem['img2']['information']['record']['doi'] != ''"
                    (click)="linkViaDoi(overlayPanelMainItem['img2']['information']['record']['doi'])"
                    class="dialog-metadata-doi">
                    DOI: {{overlayPanelMainItem['img2']['information']['record']['doi']}}</div>
            </div>
            <div *ngIf="overlayPanelMainItem['img2']['information'] == undefined"
                class="p-col duplication-more-info-content-section-right" style="text-align: center;">
                <div class="dialog-metadata-title">
                    {{'duplication.list-no-result' | translate}}</div>
            </div>

        </div>

    </div>

</p-dialog>


<p-blockUI [blocked]="blocked" [baseZIndex]="20001">
    <p-progressSpinner [style]="{width: '150px', height: '150px'}" styleClass="custom-spinner" strokeWidth="2"
        animationDuration=".5s"></p-progressSpinner>
    <span style="margin-left: 25px; color: white; font-size: 24pt;">Initializing the report...</span>
</p-blockUI>