export class Analysis {

    source: string;
    target: string;
    
    constructor() {
        this.source = '';
        this.target = '';
    }

}
