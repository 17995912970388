
export class Shape {

    ltx: number;
    lty: number;
    ltidx: number;

    lbx: number;
    lby: number;
    lbidx: number;

    rbx: number;
    rby: number;
    rbidx: number;

    rtx: number;
    rty: number;
    rtidx: number;

    scaleX: number;
    scaleY: number;

    resizeScaleX: number;
    resizeScaleY: number;

    constructor(){

        // initialize the values to -1
        this.ltx = -1;
        this.lty = -1;
        this.ltidx = -1;

        this.lbx = -1;
        this.lby = -1;
        this.lbidx = -1;

        this.rbx = -1;
        this.rby = -1;
        this.rbidx = -1;

        this.rtx = -1;
        this.rty = -1;
        this.rtidx = -1;

        this.scaleX = -1;
        this.scaleY = -1;
        
    }

    // function to get the width of the shape
    getWidth(): number {
        // based on the most left x and most right x
        let mostLeftX = Math.min(this.ltx, this.lbx, this.rbx, this.rtx);
        let mostRightX = Math.max(this.ltx, this.lbx, this.rbx, this.rtx);
        return mostRightX - mostLeftX;
    }

    getScaleWidth(): number {
        // based on the most left x and most right x
        let mostLeftX = Math.min(this.ltx, this.lbx, this.rbx, this.rtx);
        let mostRightX = Math.max(this.ltx, this.lbx, this.rbx, this.rtx);
        return (mostRightX - mostLeftX) * this.scaleX;
    }

    getScaleHeight(): number {
        // based on the most top y and most bottom y
        let mostTopY = Math.min(this.lty, this.lby, this.rby, this.rty);
        let mostBottomY = Math.max(this.lty, this.lby, this.rby, this.rty);
        return (mostBottomY - mostTopY) * this.scaleY;
    }

    // function to get the height of the shape
    getHeight(): number {
        // based on the most top y and most bottom y
        let mostTopY = Math.min(this.lty, this.lby, this.rby, this.rty);
        let mostBottomY = Math.max(this.lty, this.lby, this.rby, this.rty);
        return mostBottomY - mostTopY;
    }

    // get the most left x
    getMostLeftX(): number {
        return Math.min(this.ltx, this.lbx, this.rbx, this.rtx);
    }

    // get the most Scale left x
    getScaleMostLeftX(): number {
        return Math.min(this.ltx, this.lbx, this.rbx, this.rtx) * this.scaleX;
    }

    // get the most bottom y
    getMostBottomY(): number {
        return Math.max(this.lty, this.lby, this.rby, this.rty);
    }

    // get the most Scale bottom y
    getScaleMostBottomY(): number {
        return Math.max(this.lty, this.lby, this.rby, this.rty) * this.scaleY;
    }

    getMostTopY(): number {
        return Math.min(this.lty, this.lby, this.rby, this.rty);
    }

    getScaleMostTopY(): number {
        return Math.min(this.lty, this.lby, this.rby, this.rty) * this.scaleY;
    }

    getScaleLtx(): number {
        return this.ltx * this.scaleX;
    }
    
    getScaleLty(): number {
        return this.lty * this.scaleY;
    }

    getScaleLbx(): number {
        return this.lbx * this.scaleX;
    }

    getScaleLby(): number {
        return this.lby * this.scaleY;
    }

    getScaleRbx(): number {
        return this.rbx * this.scaleX;
    }

    getScaleRby(): number {
        return this.rby * this.scaleY;
    }

    getScaleRtx(): number {
        return this.rtx * this.scaleX;
    }

    getScaleRty(): number {
        return this.rty * this.scaleY;
    }

    
}
