export class Case {

    type:string;
    sourceTitle: string;
    sourceDoi: string;
    sourceDescription: string;
    sourceFilename: string;
    sourceRecord: string;
    sourceRepository: string;
    sourceId: string;
    sourceLab: string;
    sourceCreatedDate: string;

    targetTitle: string;
    targetDoi: string;
    targetDescription: string;
    targetFilename: string;
    targetRecord: string;
    targetRepository: string;
    targetId: string;
    targetLab: string;
    targetCreatedDate: string;

    user: string;
    institution: string;
    image: any;
    similarity: number;
    histogram: number;
    mssim: number;
    phash: number; 
    asift: number;
    analysis: string;
    
    internet: string;
    source: string;

    constructor() {
        
        this.type = '';
        this.sourceTitle = '';
        this.sourceDoi = '';
        this.sourceDescription = '';
        this.sourceFilename = '';
        this.sourceRecord = '';
        this.sourceRepository = '';
        this.sourceId = '';
        this.sourceLab = '';
        this.sourceCreatedDate = '';
        this.targetTitle = '';
        this.targetDoi = '';
        this.targetDescription = '';
        this.targetFilename = '';
        this.targetRecord = '';
        this.targetRepository = '';
        this.targetId = '';
        this.targetLab = '';
        this.targetCreatedDate = '';

        this.user = '';
        this.institution = '';
        this.image = '';
        this.similarity = 0;
        this.histogram = 0;
        this.mssim = 0;
        this.phash = 0;
        this.asift = 0;
        this.analysis = '';
        this.internet = '';
        this.source = '';
    }

}
