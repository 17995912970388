import { NgModule } from '@angular/core';
import { Routes,  RouterModule } from '@angular/router';
import { DuplicationComponent } from './duplication/duplication.component';
import { MainComponent } from './main/main.component';
import { DuplicationViewerComponent } from './duplication-viewer/duplication-viewer.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { CaseComponent } from './case/case.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AnalysisCaseComponent } from './analysis-case/analysis-case.component';
import { WithinViewerComponent } from './within-viewer/within-viewer.component';
import { ManipulationViewerComponent } from './manipulation-viewer/manipulation-viewer.component';
import { ManipulationComponent } from './manipulation/manipulation.component';

const routes: Routes = [
  { path: 'main/:id', component: DuplicationComponent },
  { path: 'initial/:id', component: DuplicationComponent },
  { path: 'duplication/:id', component: DuplicationComponent },
  { path: 'duplication-viewer/:id', component: DuplicationViewerComponent },
  { path: 'manipulation/:id', component: ManipulationComponent },
  { path: 'manipulation-viewer/:id', component: ManipulationViewerComponent },
  { path: 'within-viewer/:id', component: WithinViewerComponent },
  { path: 'image-viewer/:id', component: ImageViewerComponent },
  { path: 'analysis-case/:id', component: AnalysisCaseComponent },
  { path: 'case', component: CaseComponent },
  { path: '**', component: NotFoundComponent }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
