<p-toolbar>
    <div class="p-toolbar-group-left">
        <div>ImaChek Analysis Tools</div>
    </div>

    <div class="p-toolbar-group-right">
        <div *ngIf="successLoading">ID: {{id}}</div>
    </div>
</p-toolbar>

<header *ngIf="record" class="main-record-section p-d-flex p-jc-between">
    <div class="p-d-flex">
        <div class="main-cover-box">
            <img class="main-cover-img" src="{{baseUrl}}/image/cover/{{id}}.png" />
        </div>
        <div class="main-metadata-box">
            <div *ngIf="metadata" class="main-metadata-title">{{metadata['record']['title']}}</div>
            <div *ngIf="metadata == null" class="main-metadata-title">{{record['originalFilename']}}</div>
            <div (click)="linkViaDoi(metadata['record']['doi']) " *ngIf="metadata != null && metadata['record']['doi']" class="main-metadata-doi p-mb-3">{{metadata['record']['doi']}}</div>
            <div class="main-metadata-other">Format: {{record['recordFormat'] | uppercase }}</div>
            <div class="main-metadata-other">Size: {{record['recordProcessedSize'] | filesize}}</div>
            <div class="main-metadata-other">Original: {{record['recordOriginalAmount']}}</div>
            <div class="main-metadata-other">Created Date: {{record['recordCreatedDate'] | date: 'yyyy-MM-dd'}}</div>
            <div *ngIf="hasSimilarity == true" class="main-metadata-open-duplication" (click)="loadDuplicationPage('all')">
                <i class="pi pi-file p-mr-1"></i> Found {{result['similarity'].length}} similar image(s)
            </div>

            <div *ngIf="caseSummary != undefined && caseSummary['size'] > 0" class="main-metadata-box-case p-mt-2" (click)="loadAnalysisCase()">
                <i class="pi pi-briefcase" title="Open the saved analysis cases"></i>
                <span class="p-ml-2">You have saved {{caseSummary['size']}} analysis case(s)</span>
            </div>
        </div>
    </div>
    <div *ngIf="hasSimilarity == true" class="main-metadata-chart" (click)="loadDuplicationPage('all')">
        <apx-chart [series]="chartOptions.series" [labels]="chartOptions.labels" [plotOptions]="chartOptions.options" [legend]="chartOptions.legend" [colors]="chartOptions.colors" [chart]="chartOptions.chart"></apx-chart>
    </div>
    <div *ngIf="hasSimilarity == false" class="main-metadata-chart-empty">
        <apx-chart [series]="chartOptionsEmpty.series" [tooltip]="chartOptionsEmpty.tooltip" [labels]="chartOptionsEmpty.labels" [plotOptions]="chartOptionsEmpty.options" [legend]="chartOptionsEmpty.legend" [colors]="chartOptionsEmpty.colors" [chart]="chartOptionsEmpty.chart"></apx-chart>
    </div>
</header>
<section *ngIf="successLoading">
    <p-divider></p-divider>
    <div class="p-grid main-card-section" *ngIf="record">
        <div class="p-d-flex p-flex-column main-card" *ngFor="let item of record['original']">
            <div class="p-d-flex p-jc-between main-card-control">
                <div (click)="loadImageViewer(item['filename'])" class="main-card-icon"><i class="pi pi-search main-card-icon-search" title="Click to open the image"></i></div>
                <div class="main-card-title"><span *ngIf="record['recordFormat'] == 'pdf'">Page: {{(item['page']+1)}}</span> </div>
                <div (click)="loadDuplicationPage(item['filename'])" class=" main-card-icon-highlight "><i *ngIf="item[ 'hasDuplication']==true " class="pi pi-file " title="Click to see the analysis results "></i></div>
            </div>
            <img class="main-card-img " src="{{baseUrl}}/image/{{id}}/{{item[ 'filename']}}?width=250 " />
        </div>
    </div>
</section>
<section *ngIf="successLoading == false">
    <div class="p-mt-3 p-mb-3 main-item-not-found">
        Invalid request ...
    </div>
</section>
<footer class="main-footer ">

</footer>

<p-scrollTop></p-scrollTop>
<p-blockUI [blocked]="blocked" [baseZIndex]="20001">
    <p-progressSpinner [style]="{width: '150px', height: '150px'}" styleClass="custom-spinner" strokeWidth="2" animationDuration=".5s"></p-progressSpinner>
</p-blockUI>