import { Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalConstants } from './common/global-constants';
import { Analysis } from './entity/Analysis';
import { Filter } from './entity/Filter';
import { Web } from './entity/Web';
import { Case } from './entity/Case';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = GlobalConstants.apiURL;
  }

  /**
   * 初使化 Report 模組，透過授權碼取得 Report 模組的基本資訊
   * @param key 授權 Token
   * @returns 
   */
  initial(key:string){
    return this.http.get(this.baseUrl+'/initial/'+key);
  }

  /**
   * 取得 Report 模組的基本資訊
   * @param key 
   * @param type 如果設定為 Type 則取得 original 資料的 Metadata 資訊，或全部的 metadata 資訊
   * @returns 
   */
  metadata(key:string, type:string){
    if(type != null){
      return this.http.get(this.baseUrl+'/metadata/'+key+"?type="+type);
    }else{
      return this.http.get(this.baseUrl+'/metadata/'+key);
    }
  }

  /**
   * 取得 Report 單一模組 Image 的 Metadata 資訊
   * @param key 
   * @param imagefile 
   * @returns 
   */
  metadataImage(key:string, imagefile:string){
    return this.http.get(this.baseUrl+'/metadata/'+key+'/'+imagefile);
  }

  /**
   * 取得 Manipulation 及 Duplication 兩種結果的完整列表資訊
   * @param key 授權 Token
   * @returns 
   */
  whole(key:string){
    return this.http.get(this.baseUrl+'/whole/'+key);
  }

  /**
   * 取得本案件完整 Duplication 結果的列表
   * @param key 
   * @returns 
   */
  duplication(key:string){
    return this.http.get(this.baseUrl+'/duplication/'+key);
  }

  /**
   * 取得本案件完整 Manipulation 結果的列表
   * @param key 授權 Token
   * @returns 
   */
  manipulation(key:string){
    return this.http.get(this.baseUrl+'/manipulation/'+key);
  }

  /**
   * 沒用到 (找出相似的圖片)
   * @param key 
   * @param type 
   * @param similarSize 
   * @param imagefile 
   * @returns 
   */
  similar(key:string, type:string, similarSize:number, imagefile:string){
    return this.http.get(this.baseUrl+'/similar/'+key+'/'+type+'/'+imagefile+'?size='+similarSize);
  }

  /**
   * 沒用到 (GPT 分析頁面內容)
   * @param key 
   * @param recordid 
   * @param imagefile 
   * @returns 
   */
  gpt(key:string, recordid:string, imagefile:string){
    return this.http.get(this.baseUrl+'/ai/gpt/'+key+'/'+recordid+'/'+imagefile);
  }


  /**
   * 進階圖片分析介面，取得圖片的相似度分析結果。依目前兩張圖片的 ID 做為比較的來源，並產生結果。
   * @param key 授權 Token 
   * @param img1id 圖片 1 的 ID
   * @param img2id 圖片 2 的 ID
   * @returns 
   */
  downloadSimilarityResult(key:string, img1id:string, img2id:string){
    ///analysis/detail/{authkey}/{hash}/{img1id}/{img2id}"
    return this.http.get(this.baseUrl+'/analysis/detail/'+key+'/'+img1id+'/'+img2id);
  }

  /**
   * 沒用到
   * @param filter 
   * @returns 
   */
  submitForFilterAnalysis(filter: Filter): Observable<Filter>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      }) 
    };
    return this.http.post<Filter>(this.baseUrl+'/filter/submit', filter, httpOptions);    
  }

  /**
   * 沒用到
   * @param key 
   * @param imageId 
   * @returns 
   */
  submitForInternetSearch(key:string, imageId: string) {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type':  'application/json',
    //   }) 
    // };
    ///web/{authkey}/submit/{imageid}
    return this.http.get(this.baseUrl+'/web/'+key+'/submit/' + imageId);    
  }

  /**
   * 沒用到
   * @param key 
   * @param traceid 
   * @returns 
   */
  downloadInternetResult(key:string, traceid:string){
    return this.http.get(this.baseUrl+'/web/'+key + '/'+traceid);
    ///analysis/{traceid}
  } 

  /**
   * 儲存手動分析案件項目
   * @param key 授權 Token
   * @param acase 案件項目
   * @returns 
   */
  saveAnalysisCase(key:string, acase: Case): Observable<Case>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      }) 
    };
    // @PostMapping("/case/{recordid}/submit")
    return this.http.post<Case>(this.baseUrl+'/case/'+key+'/submit', acase, httpOptions);    
  }

  /**
   * 取得已儲存的案件項目的詳細資訊
   * @param key 授權 Token
   * @param caseid 案件項目的 ID
   * @returns 
   */
  getStoreCaseDetail(key:string, caseid:string){
    // console.log('about to run:' + caseid);
    return this.http.get(this.baseUrl+'/case/'+key+'/'+caseid);
  }

  /**
   * 刪除已儲存的案件項目
   * @param key 授權 Token 
   * @param caseid 案件項目的 ID
   * @returns 
   */
  deleteStoreCase(key:string, caseid:string){
    return this.http.delete(this.baseUrl+'/case/'+key+'/'+caseid);
  }

  /**
   * 取得已儲存的案件項目的摘要資訊
   * @param key 授權 Token
   * @returns 
   */
  getStoreCaseSummary(key:string){
    return this.http.get(this.baseUrl+'/case/'+key+'/summary');
  }

  /**
   * 取得已儲存的案件項目的列表
   * @param key 授權 Token
   * @returns 
   */
  getStoreCase(key: string){
    return this.http.get(this.baseUrl+'/case/'+key+'/list');
  }

  /**
   * 取得已儲存的案件項目的完整資訊列表
   * https://report.imachek.com/v1/case/0d2a8e090db540a49cb251b81ed50502/list/full
   * @param key 授權 Token
   * @returns 
   */
  getStoreCaseFull(key: string){
    return this.http.get(this.baseUrl+'/case/'+key+'/list/full');
  }

  /**
   * 取得這一個案件的 Image Metadata 資訊
   * https://report.imachek.com/v1/info/0d2a8e090db540a49cb251b81ed50502/a71f41163ede633039e2a18ee0ccd8e0-jpg-page-index-0-crop-6.png
   * @param key 授權 Token
   * @param imageid 圖片檔案名稱
   * @returns 
   */
  getImageMetadata(key: string, imageid:string){
    // console.log(this.baseUrl+'/info/'+id+'/'+imageid);
    return this.http.get(this.baseUrl+'/info/'+key+'/'+imageid);
  }

  /**
   * 取得這一個案件的 Record Metadata 資訊
   * @param key 授權 Token
   * @returns 
   */
  getRecordMetadata(key: string){
    return this.http.get(this.baseUrl+'/info/'+key);
  }

  /**
   * 取得這一個案件的 Duplication 的分析結果狀態
   * @param key 授權 Token
   * @returns 
   */
  getDuplicationCase(key: string){
    return this.http.get(this.baseUrl + '/case/'+key+'/summary/duplication');
  }

  /**
   * 初使用化 Filter 模組，取得初始化的資訊
   * @param key 授權 Token
   * @param img 圖片檔案名稱
   * @returns 
   */
  initialFilterImage(key: string, img: string){
    return this.http.get(this.baseUrl + '/filter/initial/'+key+'/'+img);
  }

  /**
   * 取得單一圖片的 Filter 類型資訊
   * https://report.imachek.com/v1/filter/list/26d9eab35c1d47079de65b24cb7ba81d/e70a0fc5ca3364d3ae846fce54aadd2a-png-page-index-0-crop-1.png
   * @param key 授權 Token
   * @param img 圖片檔案名稱
   * @returns 
   */
  listFilterImage(key: string, img: string){
    return this.http.get(this.baseUrl + '/filter/list/'+key+'/'+img);
  }

  /**
   * 刪除完整 Duplication PDF 已產生的檔案
   * @param key 授權 Token
   * @returns 
   */
  deleteFullDuplicationPDF(key: string){
    return this.http.delete(this.baseUrl + '/pdf/duplication/'+key+'/full/delete');
  }

  /**
   * 取得完整 Duplication PDF 的產生狀態
   * @param key 授權 Token
   * @returns 
   */
  getFullDuplicationPDFStatus(key: string){
    return this.http.get(this.baseUrl + '/pdf/duplication/'+key+'/full/status');
  }

  /**
   * 初始化完整 Duplication PDF 的產生操作
   * @param key 授權 Token
   * @param lang 語言類型設定，和前端的語言設定一致
   * @returns 
   */
  initialFullDuplicationPDF(key: string, lang: string){
    return this.http.get(this.baseUrl + '/pdf/duplication/'+key+'/full/initial?lang='+lang);
  }

  /**
   * 取得 Duplication PDF 的產生狀態
   * @param key 授權 Token
   * @returns 
   */
  getDuplicationPDFStatus(key: string){
    return this.http.get(this.baseUrl + '/pdf/duplication/'+key+'/status');
  }

  /**
   * 初始化 Duplication PDF 的產生操作
   * @param key 授權 Token
   * @param lang 語言類型設定，和前端的語言設定一致
   * @returns 
   */
  initialDuplicationPDF(key: string, lang: string){
    return this.http.get(this.baseUrl + '/pdf/duplication/'+key+'/initial?lang='+lang);
  }

  /**
   * 查看 Manipulation PDF 的產生狀態
   * @param key 授權 Token
   * @returns 
   */
  getManipulationPDFStatus(key: string){
    return this.http.get(this.baseUrl + '/pdf/manipulation/'+key+'/status');
  }

  /**
   * 初始化 Manipulation PDF 的產生操作
   * @param key 授權 Token
   * @param lang 語言類型設定，和前端的語言設定一致
   * @returns 
   */
  initialManipulationPDF(key: string, lang: string){
    return this.http.get(this.baseUrl + '/pdf/manipulation/'+key+'/initial?lang='+lang);
  }


  /**
   * 切換這一個案件的單一分析項目的顯示狀態，預設為顯示，切換為不顯示，兩個狀態之間切換。
   * 範例: https://report.imachek.com/v1/case/toggle/be38958a4f1f4de29f255d2e3cd8dc09/similarity/67ef4c9f128345efa175bedec1375abb_84924fef7585332db8979c79d6c5569a-pdf-page-index-9-crop-2.png,67ef4c9f128345efa175bedec1375abb_84924fef7585332db8979c79d6c5569a-pdf-page-index-9-crop-4.png
   * @param key 授權 Token
   * @param type 支援兩種 Type，一種是 similarity (duplication) 及 manipulation
   * @param caseid 這一個案件的單一分析項目的 ID 值
   * @returns 
   */
  toggleCase(key:string, type:string, caseid:string){ 
    return this.http.get(this.baseUrl+'/case/toggle/'+key+'/'+type+'/'+caseid);    
  }

  /**
   * 取得這一個案件所有圖片 AI 分類的結果列表。 (沒用到)
   * @param key 授權 Token
   * @returns JSON Object 的格式，依 Image filename 作為 Key，Value 是 AI 分類的結果
   */
  getClassificationResult(key: string){
    //http://localhost:10101/v1/ai/classification/37f064afd4a94cffbdb224b092e9b747
    return this.http.get(this.baseUrl+'/ai/classification/' + key);
  }

} 
